import React from 'react'
import Transparent from '../assets/transparent.gif'
import "../assets/style.css"
import { Link } from "react-router-dom";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
// import TabPanel from "@material-ui/lab/TabPanel";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Grid, AppBar, Tab } from '@material-ui/core';
import TimeField from 'react-simple-timefield';
import Appconfig from '../config/config';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import SideNav from '../admin/SideNav';
import { isMobile } from "react-device-detect";
import Header from '../admin/Header'

const noReport = <div id="noReportMessage" >
    <p>Betting Profit &amp; Loss enables you to review the bets you have placed.
        <br />Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p>
    <p>Betting Profit &amp; Loss is available online for the past 62 days.</p>
    <p>User can search up to 14 days records per query only .</p>
</div>
function TabPanel(props) {

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const ProfitLossUser = (props) => {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const eventIDQuery = new URLSearchParams(search).get('eventid');
    const selectionIDQuery = new URLSearchParams(search).get('selection_id');
    const usernameQuery = new URLSearchParams(search).get('username');
    const startDate = new URLSearchParams(search).get('startDate');
    const endDate = new URLSearchParams(search).get('endDate');
    const [betHistoryList, SetBetHistoryList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));


    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/current-bets') {
        segment = 3;
    }


    const gridType = isMobile ? 10 : 8;

    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(search);
    const [valuen, setnValue] = React.useState(0);
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 2,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            height: "100%",
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            width: '230px',
            marginLeft: 'auto',
            background: '#ffffff'
        },
        table: {
            minWidth: 650,
        },
    }));

    const classes = useStyles();
    const [memeberUserData, setMemberUserData] = React.useState([]);
    const [formData, setFormData] = React.useState({
        "event_id": "",
        "user_id": uidQuery,
        'event_type': "",
        'status': "All",
        'from_date': startDate?startDate:moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': endDate?endDate:moment().format("YYYY-MM-DD"),
        "bet_type": "Exchange"
    })
    const [formData1, setFormData1] = React.useState({
        "event_id": eventIDQuery,
        "user_id": userInfo._id,
        'event_type': "",
        'status': "All",
        'matchUnmatch': " ",
        'selection_id': selectionIDQuery,
        'from_date': "",
        'to_date': "",
    })

    function justFor(days) {
        if (days == 1) {
            formData.from_date = moment().startOf('day').format("YYYY-MM-DD");
            formData.to_date = moment().endOf('day').format("YYYY-MM-DD");
        }
        if (days == 2) {
            formData.from_date = moment().subtract(days, 'days').format("YYYY-MM-DD");
            formData.to_date = moment().format("YYYY-MM-DD");
        }
        getPL();
    }
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        setFormData1({
            ...formData1,
            [event.target.name]: event.target.value,
        });
        console.log(formData);
    };
    function getMembersDataUsersData() {
        // var user_type = params.user_type;
        var data = JSON.stringify({
            user_id: uidQuery,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                setMemberUserData(response.data);

                let tmpUserList = response.data;
                //         console.log("data new", tmpUserList);
                let tempSetData = tmpUserList.resultData[0];


                setMemberUserData(tempSetData);
                //        console.log('userList', response.data);
            })
            .catch(function (error) {
                //            console.log(error);
            });
    }
    function showCurrentBookmaker(click_id, rowarray) {
        var expandHtml = document.getElementById("expandCurrentBookmaker-" + click_id + "-toggle");
        var linkHtml = document.getElementById("expandCurrentBookmaker-" + click_id);
        // updateTotalStake(rowarray);

        if (linkHtml.getAttribute('data-id') == 0) {

            linkHtml.setAttribute('data-id', '1');
            expandHtml.style.display = "table-row";
            linkHtml.classList.add("expand-open");
            linkHtml.classList.remove("expand-close");

        } else {
            linkHtml.setAttribute('data-id', '0');
            expandHtml.style.display = "none";
            linkHtml.classList.add("expand-close");
            linkHtml.classList.remove("expand-open");

        }


    }
    React.useEffect(() => {
        // getPL()
        getMembersDataUsersData();
    }, [])
    function getPL(event_type) {
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossNew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                SetBetHistoryList(response.data.resultData);
                if (!response.data.resultData.length) {
                    document.getElementById("message").style.display = "block";
                    document.getElementById("msgtext").textContent = "you have no bets in this time period";
                    setTimeout(function () {
                        closeMessage();
                    }, 3000);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    const handleFilterSubmit = () => {
        getPL(formData.event_type);
    }
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
        if (newValue == 1) {
            formData.bet_type = "Fancy";
        } else if (newValue == 2) {
            formData.bet_type = "Premium";
        } else if (newValue == 3) {
            formData.bet_type = "Bookmaker";
        }
        else if (newValue == 4) {
            formData.bet_type = "Casino";
        } else {
            formData.bet_type = "Exchange"
        }
        SetBetHistoryList([]);

    };

    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    function getTotalStakes(temparray) {
        let totalll = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalll += Number(rowinner.stake);
        });
        return totalll;
        //   settotalStakes(totalll);
    }
    function getYesTotalStakes(temparray) {
        let totalYes = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.is_back == 1) {

                if (rowinner.bet_result == 'Plus') {
                    totalYes += parseFloat(rowinner.profit?.toFixed(2))

                }
                else if (rowinner.bet_result == 'Minus') {
                    totalYes -= parseFloat(rowinner.loss?.toFixed(2))

                }
            }
        });


        return totalYes.toFixed(2);
        //   settotalStakes(totalll);
    }
    function getNoTotalStakes(temparray) {
        let totalNo = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.is_back == 0) {

                if (rowinner.bet_result == 'Plus') {
                    totalNo += parseFloat(rowinner.profit?.toFixed(2))

                }
                else if (rowinner.bet_result == 'Minus') {
                    totalNo -= parseFloat(rowinner.loss?.toFixed(2))

                }
            }
        });

        return totalNo.toFixed(2);
        //   settotalStakes(totalll);
    }
    function getSubTotalStakes(temparray) {
        let totalSub = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalSub += Number(rowinner.stake)
        });

        return totalSub.toFixed(2);
        //   settotalStakes(totalll);
    }
    function getMarketTotalStakes(temparray) {
        let totalSub = 0;
        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.bet_result == 'Plus') {
                totalSub += parseFloat(rowinner.profit?.toFixed(2))

            }
            else if (rowinner.bet_result == 'Minus') {
                totalSub -= parseFloat(rowinner.loss?.toFixed(2))

            }
        });

        return totalSub.toFixed(2);
        //   settotalStakes(totalll);
    }
    return (
        <div className="dashboard bethistory-user">
            <>
                <Header />
                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L" >
                        {memeberUserData.length ? prevUser.length && memeberUserData?._id != userInfo._id && prevUser.map((row, index) => {
                            return <li id="path6" className="" key={"path" + index}>
                                <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                    <span className={`lv_${index}`}>
                                        {row.user_type.match(/\b\w/g).join('')}
                                    </span>
                                    <strong>{row.user_name}</strong>
                                </Link>
                            </li>
                        }) : ""}
                        <li id="path6" className="" key={"pathuser"}>
                            <Link to={window.location}>
                                <span className={`lv_3`}>
                                    {memeberUserData.user_type}
                                </span>
                                <strong>{memeberUserData?.user_name}</strong>
                            </Link>
                        </li>

                    </ul>
                </div>
                <Grid className="current-bets-cont m-width1300" container spacing={0} style={{ backgroundColor: '#F0ECE1' }}>
                    <div className="col-left">
                        <SideNav memeberUserData={memeberUserData} />
                    </div>

                    <Grid item lg={gridType} xs={12} spacing={2}>
                        <div id="message" className="message-wrap-new" style={{ background: "yellow", color: "#000" }}>
                            <Link to="#" onClick={closeMessage} className="btn-close">Close</Link>
                            <p id="msgtext"> </p>
                        </div>

                        <div className="Bets History bet-history-pd">
                            <h2 style={{ fontSize: 19 }}>Betting Profit & Loss : Main wallet
                            </h2>
                            <ul class="acc-info acc-info1">
                                <li class="user user1">{memeberUserData?.user_name}
                                </li>
                            </ul>
                            <br />
                            <TabContext value={valuen}>
                                <AppBar position="static" className="tab">
                                    <TabList value={valuen} variant="fullWidth" onChange={handlenChange}>
                                        <Tab label="Exchange" {...a11yProps(0)} />
                                        <Tab label="FancyBet" {...a11yProps(1)} />
                                        <Tab label="Sportsbook" {...a11yProps(2)} />
                                        <Tab label="BookMaker" {...a11yProps(3)} />
                                        <Tab label="Casino" {...a11yProps(4)} />
                                    </TabList>
                                </AppBar>

                                <TabPanel className="Bets History bet-history-combine bet-history-pdd" value={valuen} index={0}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>

                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" max={moment().format("YYYY-MM-DD")} />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                        </Box>
                                        <div>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => getPL()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </div>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Start Time</TableCell>
                                                    <TableCell align="left">Settled date</TableCell>
                                                    <TableCell align="left">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowfancy) => (
                                                    <>
                                                        <TableRow className="pandlbets">
                                                            <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell align="left" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><span data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close expandopclose" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}><span> {
                                                                rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</span></span></TableCell>
                                                        </TableRow>
                                                        <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                            <TableCell colspan="1"></TableCell>
                                                            <TableCell colspan="9">
                                                                {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                <Table className={`table-commission ${classes.table}`}>
                                                                    <TableHead className="table_head">
                                                                        <TableCell>Bet Id</TableCell>
                                                                        <TableCell>Selection</TableCell>
                                                                        <TableCell>Odds</TableCell>
                                                                        <TableCell>Stake</TableCell>
                                                                        <TableCell>Type</TableCell>
                                                                        <TableCell>Placed</TableCell>
                                                                        <TableCell>Profit/Loss</TableCell>
                                                                    </TableHead>
                                                                    <TableBody className="table_body">

                                                                        {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                            <TableRow>
                                                                                <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                <TableCell >{rowinner.selection_id}</TableCell>
                                                                                <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : `(${rowinner.loss?.toFixed(2)})`}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Total Stakes
                                                                            </TableCell>
                                                                            <TableCell colspan="3">
                                                                                {getTotalStakes(rowfancy.bettings)}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Back Subtotal
                                                                            </TableCell>
                                                                            <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getYesTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getYesTotalStakes((rowfancy.bettings)))})` : Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Lay Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getNoTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getNoTotalStakes((rowfancy.bettings)))})` : Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getSubTotalStakes(rowfancy.bettings) < 0 ? `(${Math.abs(getSubTotalStakes((rowfancy.bettings)))})` : Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="dottedborder-td">
                                                                            <TableCell align="right" colspan="6">
                                                                                Net Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getMarketTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getMarketTotalStakes((rowfancy.bettings)))})` : Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </> : noReport
                                    }
                                </TabPanel>

                                <TabPanel className="Bets History bet-history-combine bethistory-filters-fancy" value={valuen} index={1}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>

                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" max={moment().format("YYYY-MM-DD")} />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getPL()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Start Time</TableCell>
                                                    <TableCell align="left">Settled date</TableCell>
                                                    <TableCell align="left">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowfancy) => (
                                                    <>
                                                        <TableRow className="pandlbets">
                                                            <TableCell style={{ width: "41%" }}>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}<img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.place_name}</TableCell>
                                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell align="left" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><span data-id="0" id={`expandCurrentBookmaker-${rowfancy._id.x}${rowfancy._id.y}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(`${rowfancy._id.x}${rowfancy._id.y}`)}><span> {
                                                                rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</span></span></TableCell>
                                                        </TableRow>
                                                        <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id.x}${rowfancy._id.y}-toggle`} style={{ display: "none" }}>
                                                            <TableCell colspan="1"></TableCell>
                                                            <TableCell colspan="9">
                                                                {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                <Table className={`table-commission ${classes.table}`}>
                                                                    <TableHead className="table_head">
                                                                        <TableCell>Bet Id</TableCell>
                                                                        <TableCell>Selection</TableCell>
                                                                        <TableCell>Odds</TableCell>
                                                                        <TableCell>Stake</TableCell>
                                                                        <TableCell>Type</TableCell>
                                                                        <TableCell>Placed</TableCell>
                                                                        <TableCell>Profit/Loss</TableCell>
                                                                    </TableHead>
                                                                    <TableBody className="table_body">

                                                                        {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                            <TableRow>
                                                                                <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                <TableCell >{rowinner.selection_id}</TableCell>
                                                                                <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : `(${rowinner.loss?.toFixed(2)})`}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Total Stakes
                                                                            </TableCell>
                                                                            <TableCell colspan="3">
                                                                                {getTotalStakes(rowfancy.bettings)}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Back Subtotal
                                                                            </TableCell>
                                                                            <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getYesTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getYesTotalStakes((rowfancy.bettings)))})` : Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Lay Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getNoTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getNoTotalStakes((rowfancy.bettings)))})` : Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getSubTotalStakes(rowfancy.bettings) < 0 ? `(${Math.abs(getSubTotalStakes((rowfancy.bettings)))})` : Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="dottedborder-td">
                                                                            <TableCell align="right" colspan="6">
                                                                                Net Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getMarketTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getMarketTotalStakes((rowfancy.bettings)))})` : Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </> : noReport}
                                </TabPanel>

                                <TabPanel className="Bets History bet-history-combine bethistory-filters-matchodds" value={valuen} index={2}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>

                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" max={moment().format("YYYY-MM-DD")} />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getPL()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Start Time</TableCell>
                                                    <TableCell align="left">Settled date</TableCell>
                                                    <TableCell align="left">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowfancy) => (
                                                    <>
                                                        <TableRow className="pandlbets">
                                                            <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell align="left" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><span data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}><span> {
                                                                rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</span></span></TableCell>
                                                        </TableRow>
                                                        <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                            <TableCell colspan="1"></TableCell>
                                                            <TableCell colspan="9">
                                                                {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                <Table className={`table-commission ${classes.table}`}>
                                                                    <TableHead className="table_head">
                                                                        <TableCell>Bet Id</TableCell>
                                                                        <TableCell>Selection</TableCell>
                                                                        <TableCell>Odds</TableCell>
                                                                        <TableCell>Stake</TableCell>
                                                                        <TableCell>Type</TableCell>
                                                                        <TableCell>Placed</TableCell>
                                                                        <TableCell>Profit/Loss</TableCell>
                                                                    </TableHead>
                                                                    <TableBody className="table_body">

                                                                        {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                            <TableRow>
                                                                                <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                <TableCell >{rowinner.selection_id}</TableCell>
                                                                                <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : `(${rowinner.loss?.toFixed(2)})`}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Total Stakes
                                                                            </TableCell>
                                                                            <TableCell colspan="3">
                                                                                {getTotalStakes(rowfancy.bettings)}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Back Subtotal
                                                                            </TableCell>
                                                                            <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getYesTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getYesTotalStakes((rowfancy.bettings)))})` : Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Lay Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getNoTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getNoTotalStakes((rowfancy.bettings)))})` : Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getSubTotalStakes(rowfancy.bettings) < 0 ? `(${Math.abs(getSubTotalStakes((rowfancy.bettings)))})` : Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="dottedborder-td">
                                                                            <TableCell align="right" colspan="6">
                                                                                Net Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getMarketTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getMarketTotalStakes((rowfancy.bettings)))})` : Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </> : noReport}
                                </TabPanel>

                                <TabPanel className="Bets History bet-history-combine bethistory-filters-bookmaker" value={valuen} index={3}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>

                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" max={moment().format("YYYY-MM-DD")} />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getPL()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Start Time</TableCell>
                                                    <TableCell align="left">Settled date</TableCell>
                                                    <TableCell align="left">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowfancy) => (
                                                    <>
                                                        <TableRow className="pandlbets">
                                                            <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell align="left" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><span data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}><span> {
                                                                rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</span></span></TableCell>
                                                        </TableRow>
                                                        <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                            <TableCell colspan="1"></TableCell>
                                                            <TableCell colspan="9">
                                                                {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                <Table className={`table-commission ${classes.table}`}>
                                                                    <TableHead className="table_head">
                                                                        <TableCell>Bet Id</TableCell>
                                                                        <TableCell>Selection</TableCell>
                                                                        <TableCell>Odds</TableCell>
                                                                        <TableCell>Stake</TableCell>
                                                                        <TableCell>Type</TableCell>
                                                                        <TableCell>Placed</TableCell>
                                                                        <TableCell>Profit/Loss</TableCell>
                                                                    </TableHead>
                                                                    <TableBody className="table_body">

                                                                        {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                            <TableRow>
                                                                                <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                <TableCell >{rowinner.selection_id}</TableCell>
                                                                                <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : `(${rowinner.loss?.toFixed(2)})`}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Total Stakes
                                                                            </TableCell>
                                                                            <TableCell colspan="3">
                                                                                {getTotalStakes(rowfancy.bettings)}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Back Subtotal
                                                                            </TableCell>
                                                                            <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getYesTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getYesTotalStakes((rowfancy.bettings)))})` : Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Lay Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getNoTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getNoTotalStakes((rowfancy.bettings)))})` : Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getSubTotalStakes(rowfancy.bettings) < 0 ? `(${Math.abs(getSubTotalStakes((rowfancy.bettings)))})` : Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="dottedborder-td">
                                                                            <TableCell align="right" colspan="6">
                                                                                Net Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getMarketTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getMarketTotalStakes((rowfancy.bettings)))})` : Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </> : noReport}
                                </TabPanel>

                                <TabPanel className="Bets History bet-history-combine" value={valuen} index={4}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>

                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" max={moment().format("YYYY-MM-DD")} />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <TimeField onChange={handleChange} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getPL()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Start Time</TableCell>
                                                    <TableCell align="left">Settled date</TableCell>
                                                    <TableCell align="left">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowfancy) => (
                                                    <>
                                                        <TableRow className="pandlbets">
                                                            <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                            <TableCell align="left" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><span data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}><span> {
                                                                rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</span></span></TableCell>
                                                        </TableRow>
                                                        <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                            <TableCell colspan="1"></TableCell>
                                                            <TableCell colspan="9">
                                                                {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                <Table className={`table-commission ${classes.table}`}>
                                                                    <TableHead className="table_head">
                                                                        <TableCell>Bet Id</TableCell>
                                                                        <TableCell>Selection</TableCell>
                                                                        <TableCell>Odds</TableCell>
                                                                        <TableCell>Stake</TableCell>
                                                                        <TableCell>Type</TableCell>
                                                                        <TableCell>Placed</TableCell>
                                                                        <TableCell>Profit/Loss</TableCell>
                                                                    </TableHead>
                                                                    <TableBody className="table_body">

                                                                        {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                            <TableRow>
                                                                                <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                <TableCell >{rowinner.selection_id}</TableCell>
                                                                                <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : `(${rowinner.loss?.toFixed(2)})`}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Total Stakes
                                                                            </TableCell>
                                                                            <TableCell colspan="3">
                                                                                {getTotalStakes(rowfancy.bettings)}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Back Subtotal
                                                                            </TableCell>
                                                                            <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getYesTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getYesTotalStakes((rowfancy.bettings)))})` : Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Lay Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getNoTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getNoTotalStakes((rowfancy.bettings)))})` : Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="sum-pl">
                                                                            <TableCell align="right" colspan="6">
                                                                                Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getSubTotalStakes(rowfancy.bettings) < 0 ? `(${Math.abs(getSubTotalStakes((rowfancy.bettings)))})` : Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="dottedborder-td">
                                                                            <TableCell align="right" colspan="6">
                                                                                Net Market Subtotal
                                                                            </TableCell>
                                                                            <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{getMarketTotalStakes((rowfancy.bettings)) < 0 ? `(${Math.abs(getMarketTotalStakes((rowfancy.bettings)))})` : Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </> : noReport}

                                </TabPanel>
                            </TabContext>
                        </div>
                    </Grid>
                </Grid></>
        </div >
    )
}

export default ProfitLossUser
