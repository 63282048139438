import React, { useState } from 'react';

import "./assets/style.css"

import Appconfig from "./config/config";
import axios from "axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Trans from './assets/transparent.gif'



const CreditRefsTab = () => {

    const history = useHistory();
    const params = useParams();
    const userType = params.user_type;
    const [userList, setUserList] = useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    React.useEffect(() => {
        getCreditRefsList();
    }, []);

    function getCreditRefsList() {
        var user_type = params.user_type;
        var data = JSON.stringify({
            user_id: params.user_id
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}userCreditRef/getList`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                console.log("response", response);
                setUserList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="log-wrap" style={{ width: "600px" }}>
            <table className="game-team">
                <tbody><tr>
                    <td className="game-name">
                        Credit Reference Logs
                        <span>{params.user_name}</span>
                        <a className="pop-close" onClick={() => window.open('', '_self', '') && window.close()}>
                            <img className="icon-back" src={Trans} />Close</a>
                    </td>
                </tr>
                </tbody></table>

            <div className="over-wrap" style={{ height: "513px" }}>

                <table className="table01">
                    <tbody>
                        <tr>
                            <th width="20%" className="align-L">Date/Time</th>
                            <th width="40%">Old Value</th>
                            <th width="40%">New Value</th>
                        </tr>
                    </tbody>
                    <tbody id="content">

                        {userList.length > 0 && userList.map((row, index) => {
                            return <tr id="tempTr">
                                <td className="align-L" id="dateTime">2021-12-27 17:01:58</td>
                                <td id="oldValue"> {row.old_credit_reference}</td>
                                <td id="newValue"> {row.new_credit_reference}</td>
                            </tr>
                        })}
                        {userList.length <= 0 && <tr id="noDataTempTr">
                            <td className="no-data" colspan="3">
                                <p>No Data</p>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>



          
        </div >

    );
}
export default CreditRefsTab