import React, { useEffect, useState } from "react";
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import { Link } from "react-router-dom";
import moment from 'moment';
// import { useState } from 'react'
import Appconfig from '../config/config';
import axios from 'axios';
import { useParams } from "react-router-dom";
import Loader from '../assets/images/loading40.gif';

const displaynone = {
    display: "none"
}
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const checkIfNeg = (val, type = null) => {
    if (val >= 0) {
        return parseFloat(val).toFixed(2);
    } else {
        if (type == "html") {
            return `<span class="red">(${Math.abs(parseFloat(val).toFixed(2))})</span>`
        } else {
            return <span className="red">({Math.abs(parseFloat(val).toFixed(2))})</span>
        }
    }
}

function DownlineProfitloss() {
    const params = useParams();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const nPrevu = window.sessionStorage.getItem("downLineprofitLoss_tree");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    React.useEffect(() => {
        removeExpang()
        removeshort();
        loader_remove();
        if (params.fromdata) {
            setProfitAndLossC({
                ...profitAndLossCa,
                ['from_date']: params.fromdata,
                ['to_date']: params.todate,
            });
            profitLossDownline();
        }
        // getProfitLoss();
        // getOpenBets();
        const nPrevut = window.sessionStorage.getItem("downLineprofitLoss_tree");
        setPrevuser(nPrevut ? JSON.parse(nPrevut) : []);
    }, [params.user_id]);

    const [profitAndLossCa, setProfitAndLossC] = useState({
        "user_id": userInfo._id,
        'from_date': params.from_date ? params.from_date : moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': params.to_date ? params.to_date : moment().format("YYYY-MM-DD"),
        'type': "All",
        startTime: "09:00",
        endTime: "08:59"
    })
    const [downProfitLoss, setdownProfitLoss] = React.useState([])
    const [downProfitLossInter, setdownProfitLossInter] = React.useState([])

    const handleChangeProfit = (event) => {
        setProfitAndLossC({
            ...profitAndLossCa,
            [event.target.name]: event.target.value,
        });

    };
    function profitLossDownline() {
        loader_default();
        // profitAndLossCa.from_date = params.fromdata ? params.fromdata : (profitAndLossCa.from_date ? moment(profitAndLossCa.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
        // profitAndLossCa.to_date = params.todate ? params.todate : (profitAndLossCa.to_date ? moment(profitAndLossCa.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

        profitAndLossCa.user_id = params.user_id ? params.user_id : userInfo._id;

        var data = JSON.stringify(profitAndLossCa);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossByDownlineNew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                setdownProfitLoss(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((betHistoryList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                // alert("successss");

                setdownProfitLoss(tmpAccountList);

                if (response.data.resultData.length == 0) {
                    document.querySelector("#stakeTotal").textContent = 0;
                    document.querySelector("#pl_total").textContent = 0;
                    document.querySelector("#m_comm_total").textContent = 0;
                    document.querySelector("#upline_total").textContent = 0;
                }

                loader_remove()
                if (response.data.result) {
                    if (!response.data.resultData.length) {
                        document.getElementById("message").style.display = "block";
                        document.getElementById("msgtext").textContent = "you have no bets in this time period";
                        setTimeout(function () {
                            closeMessage("message");
                        }, 3000);
                    }
                } else {
                    document.getElementById("errorMessage").style.display = "block";
                    document.getElementById("errorMessageText").textContent = response.data.resultMessage;
                    setTimeout(function () {
                        closeMessage("errorMessage");
                    }, 3000);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function profitLossByDownlineInternal(user_id, tr_id) {
        loader_default();
        profitAndLossCa.from_date = profitAndLossCa.from_date ? moment(profitAndLossCa.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        profitAndLossCa.to_date = profitAndLossCa.to_date ? moment(profitAndLossCa.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');



        var data = JSON.stringify({
            user_id: user_id,
            from_date: profitAndLossCa.from_date ? moment(profitAndLossCa.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            to_date: profitAndLossCa.to_date ? moment(profitAndLossCa.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossByDownlineInternal`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                setdownProfitLossInter(response.data.resultData);
                createTRinnerHtml(response.data.resultData, tr_id);
                loader_remove()

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function createTRinnerHtml(data, tr_id) {
        let temphtml = "";
        if (data.length > 0) {
            data.map((row, index) => {

                temphtml += `<tr id="sub_deep0107_1" class="expand first expand_${tr_id}" style="display: table-row;">
<td class="align-L">
    <a id="_sportName" class="ico_account" href="#">${Appconfig.event_types[row.event_type] ? Appconfig.event_types[row.event_type] : "Casino"}-${row.market_name}</a>
</td>
<td id="sport_stake">${row.totalStake}</td>
<td id="sport_profitLoss">
${checkIfNeg(row.totalPL, 'html')}
 </td>
 ${checkExtraTd() ? `<td id="sport_profitLoss">
 ${checkIfNeg(row.totalPL, 'html')}
  </td>` : ``}
<td id="sport_profitLossDownLine">00.00</td>
<td id="sport_profitLossUpLine">
${checkIfNeg(row.upline_pl, 'html')}</td>

</tr>`;
            })
        }

        document.getElementsByClassName(`${tr_id}`)[0].insertAdjacentHTML('afterEnd', temphtml)
    }
    const handleFilterSubmitProfit = () => {
        // getProfit(formData.event_type);
        profitLossDownline()
    }


    // console.log("ndewww", userInfo); 
    let stakeTotal = 0;
    let pl_total = 0;
    let m_comm_total = 0;
    let upline_total = 0;
    function update_total(fancy) {
        stakeTotal += fancy.totalStake;
        document.querySelector("#stakeTotal").textContent = stakeTotal.toFixed(2)

        pl_total += fancy.totalPL;
        document.querySelector("#pl_total").innerHTML = checkIfNeg(pl_total.toFixed(2), "html");
        if (document.querySelector("#pl_total1")) document.querySelector("#pl_total1").innerHTML = checkIfNeg(pl_total.toFixed(2), "html")

        m_comm_total += m_comm_total;
        document.querySelector("#m_comm_total").innerHTML = checkIfNeg(m_comm_total.toFixed(2), "html")
        upline_total += fancy.upline_pl;
        document.querySelector("#upline_total").innerHTML = checkIfNeg(upline_total.toFixed(2), "html");
    }
    function justFor(days) {
        if (days == 1) {
            profitAndLossCa.from_date = moment().startOf('day').format("YYYY-MM-DD");
            profitAndLossCa.to_date = moment().endOf('day').format("YYYY-MM-DD");
        }
        if (days == 2) {
            console.log("fsadf", days)
            profitAndLossCa.from_date = moment().subtract(2, 'days').format("YYYY-MM-DD");
            profitAndLossCa.to_date = moment().format("YYYY-MM-DD");
        }
        profitLossDownline();
    }
    function closeMessage(id) {
        if(document.getElementById(id)){
            document.getElementById(id).style.display = "none";
        }
    }
    function removeshort() {
        let ts = window.sessionStorage.getItem("downLineprofitLoss_tree");
        if (ts) {
            ts = JSON.parse(window.sessionStorage.getItem("downLineprofitLoss_tree"))
            if (ts.length > 0) {

                let newArr = [];
                let flag = false;
                ts.map((val) => {
                    if (!flag) {
                        newArr.push(val);
                    }

                    if (val._id.x == params.user_id) {
                        flag = true;
                    }
                })

                if (!params.user_id) {
                    let Arr = window.sessionStorage.setItem(`downLineprofitLoss_tree`, "");
                } else {
                    let Arr = window.sessionStorage.setItem(`downLineprofitLoss_tree`, JSON.stringify(newArr));
                }
            }
        }
    }
    function removeExpang() {
        var slides = document.getElementsByClassName("expand");
        for (var i = 0; i < slides.length; i++) {
            slides.item(i).innerHTML = "";
        }
        var expanOpen = document.getElementsByClassName("expand-open");
        for (var i = 0; i < expanOpen.length; i++) {
            expanOpen.item(i).classList.remove("expand-open");
            expanOpen.item(i).classList.add("expand-close");
        }

    }
    function userShortCut(url, userdata) {
        userdata.url = url;
        let ts = window.sessionStorage.getItem("downLineprofitLoss_tree");

        let nArr = [];
        if (ts) {
            ts = JSON.parse(window.sessionStorage.getItem("downLineprofitLoss_tree"));
            if (ts.length > 0) {
                nArr = ts;
                nArr.push(userdata);
            } else {
                nArr.push(userdata);
            }
        } else {
            nArr.push(userdata);
        }
        let Arr = window.sessionStorage.setItem(`downLineprofitLoss_tree`, JSON.stringify(nArr));
        // const tabIndex = JSON.parse();
    }
    function getExplicitData(user_id, plus_id, tr_id) {
        if (document.querySelector(`#${plus_id}`).classList.contains('expand-close')) {
            document.querySelector(`#${plus_id}`).classList.remove("expand-close");
            document.querySelector(`#${plus_id}`).classList.add("expand-open");
            profitLossByDownlineInternal(user_id, tr_id);
        }
        else {
            document.querySelector(`#${plus_id}`).classList.add("expand-close");
            document.querySelector(`#${plus_id}`).classList.remove("expand-open");

            const boxes = document.getElementsByClassName(`expand_${tr_id}`);

            for (var box of boxes) {
                box.style.setProperty("display", "none", "important")
            }
        }


    }
    const checkExtraTd = () => {
        return prevUser && prevUser.length > 0 ? (
            prevUser[prevUser.length - 1].user_type != "Agent" && userInfo.user_type != "Agent" ? true : false) : true;
    }
    return (
        <div className="dashboard downlineprofitloss">
            {/* Top Start */}
            <Header />
            {/* Top End */}

            {/* Main Wrap Start */}
            <div className="main_wrap">
                <div id="message" className="message-wrap-new" style={{ background: "yellow", color: "#000" }}>
                    <Link to="#" onClick={closeMessage("message")} className="btn-close">Close</Link>
                    <p id="msgtext"> </p>
                </div>
                <div id="errorMessage" className="message-wrap-new" style={{display:"none", background: "red", color: "#000" }}>
                    <Link to="#" onClick={closeMessage("errorMessage")} className="btn-close">Close</Link>
                    <p id="errorMessageText"> </p>
                </div>
                <div className="head-wrap-profit">
                    <h2>Profit/Loss Report by Downline</h2>
                    {/* <p>Data source :</p>
                    <select id="dataSource" name="dataSource">
                        <option value="db">DB</option>
                        <option value="elk">ELK</option>
                    </select> */}
                </div>
                {/* Time Zone Container */}
                <div className="function-wrap">
                    <ul className="input-list">
                        <div id="statusCondition" style={displaynone}>
                            <li><label>Bet Status:</label></li>
                            <li>
                                <select name="betStatus" id="betStatus">
                                </select>
                            </li>
                        </div>
                        <li>
                            <label>Time Zone</label></li>
                        <li>
                            <select name="timezone" id="timezone">

                                <option value="Pacific/Midway"

                                >Pacific/Midway(GMT-11:00)</option>

                                <option value="Pacific/Honolulu"

                                >Pacific/Honolulu(GMT-10:00)</option>

                                <option value="America/Juneau"

                                >America/Juneau(GMT-9:00)</option>

                                <option value="America/Los_Angeles"

                                >America/Los_Angeles(GMT-8:00)</option>

                                <option value="America/Phoenix"

                                >America/Phoenix(GMT-7:00)</option>

                                <option value="America/Chicago"

                                >America/Chicago(GMT-6:00)</option>

                                <option value="America/New_York"

                                >America/New_York(GMT-5:00)</option>

                                <option value="America/Santiago"

                                >America/Santiago(GMT-4:00)</option>

                                <option value="America/Sao_Paulo"

                                >America/Sao_Paulo(GMT-3:00)</option>

                                <option value="Atlantic/South_Georgia"

                                >Atlantic/South_Georgia(GMT-2:00)</option>

                                <option value="Atlantic/Azores"

                                >Atlantic/Azores(GMT-1:00)</option>

                                <option value="Europe/London"

                                >Europe/London(GMT+0:00)</option>

                                <option value="Europe/Paris"

                                >Europe/Paris(GMT+1:00)</option>

                                <option value="Africa/Cairo"

                                >Africa/Cairo(GMT+2:00)</option>

                                <option value="Asia/Qatar"

                                >Asia/Qatar(GMT+3:00)</option>

                                <option value="Asia/Dubai"

                                >Asia/Dubai(GMT+4:00)</option>

                                <option value="Asia/Karachi"

                                >Asia/Karachi(GMT+5:00)</option>

                                <option value="IST"

                                    selected="selected"

                                >IST(Bangalore / Bombay / New Delhi) (GMT+5:30)</option>

                                <option value="Asia/Kathmandu"

                                >Asia/Kathmandu(GMT+5:45)</option>

                                <option value="Asia/Dhaka"

                                >Asia/Dhaka(GMT+6:00)</option>

                                <option value="Asia/Bangkok"

                                >Asia/Bangkok(GMT+7:00)</option>

                                <option value="Asia/Hong_Kong"

                                >Asia/Hong_Kong(GMT+8:00)</option>

                                <option value="Asia/Tokyo"

                                >Asia/Tokyo(GMT+9:00)</option>

                                <option value="Australia/Adelaide"

                                >Australia/Adelaide(GMT+9:30)</option>

                                <option value="Australia/Melbourne"

                                >Australia/Melbourne(GMT+10:00)</option>

                                <option value="Asia/Magadan"

                                >Asia/Magadan(GMT+11:00)</option>

                                <option value="Pacific/Fiji"

                                >Pacific/Fiji(GMT+12:00)</option>

                            </select>
                        </li>
                        <li></li>

                        <li><label>Period</label></li>
                        <li>
                            <input id="startDate" onChange={handleChangeProfit} value={profitAndLossCa.from_date} className="cal-input" name="from_date" type="date" placeholder="YYYY-MM-DD" />
                            <input id="startTime" onChange={handleChangeProfit} value={profitAndLossCa.startTime} style={{ width: 75 }} className="time-input" type="time" placeholder="09:00" maxLength="5" />
                            to
                            <input id="endDate" onChange={handleChangeProfit} value={profitAndLossCa.to_date} className="cal-input" name="to_date" type="date" placeholder="YYYY-MM-DD" />
                            <input id="endTime" onChange={handleChangeProfit} value={profitAndLossCa.endTime} style={{ width: 75 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                        </li>
                        <li style={displaynone}>(TimeZone:IST)</li>
                    </ul>

                    <ul className="input-list">
                        <li><Link id="today" to="#" className="btn" onClick={() => justFor(1)}>Just For Today</Link></li>
                        <li><Link id="yesterday" to="#" className="btn" onClick={() => justFor(2)}>From Yesterday</Link></li>
                        <li style={displaynone}><Link id="last7days" to="#" className="btn">Last 7 days</Link></li>
                        <li style={displaynone}><Link id="last30days" to="#" className="btn">Last 30 days</Link></li>
                        <li style={displaynone}><Link id="last2months" to="#" className="btn">Last 2 Months</Link></li>
                        <li style={displaynone}><Link id="last3months" to="#" className="btn">Last 3 Months</Link></li>
                        <li><Link id="getPL" to="#" onClick={handleFilterSubmitProfit} className="btn-send">Get P & L</Link></li>
                    </ul>
                </div>
                <div className="main_wrap">
                    <div className="agent_path" style={{ width: "100%", marginTop: 5 }}>
                        <ul id="agentPath" className="agent_path-L" >
                            <li id="path3" className="last_li">
                                <Link to={`/downLineprofitLoss`}>
                                    <span className="lv_3">
                                        {userInfo.user_type.match(/\b\w/g).join('')}
                                    </span>
                                    <strong>{userInfo.user_name}</strong>
                                </Link>
                            </li>
                            {prevUser.length > 0 && prevUser.map((row, index) => {
                                return <li id="path6" className="" key={"path" + index}>
                                    <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                        <span className={`lv_${index}`}>
                                            {row.user_type.match(/\b\w/g).join('')}
                                        </span>
                                        <strong>{row.user_name}</strong>
                                    </Link>
                                </li>
                            })}

                        </ul>
                    </div>
                </div>
                <table id="table_log" className="table01">
                    <thead>
                        <tr>
                            <th width="15%" className="align-L">UID</th>
                            <th width="18%">Stake</th>
                            <th width="18%">Player P/L</th>
                            {checkExtraTd() ? <th width="18%">Downline P/L</th> : ""}
                            <th width="18%">{prevUser && prevUser.length > 0 ? prevUser[prevUser.length - 1].user_type : userInfo.user_type} Comm.</th>
                            <th width="16%">Upline P/L</th>
                        </tr>
                    </thead>
                    <tbody id="content">

                        {/* {accountList.length>0 && accountList.map(accountData =>  */}
                        {downProfitLoss.map((rowfancy) => (

                            <tr id="tempTr" className={`downline-profit-tr downline_profit_tr_${rowfancy._id.x}`}>
                                <td id="createDate" className="align-L" onClick={() => userShortCut(`/downLineprofitLoss/${rowfancy._id.x}/${profitAndLossCa.from_date}/${profitAndLossCa.to_date}`, rowfancy)}>

                                    {rowfancy.user_type == "User" ? <Link to={`/memberList/profitAndLoss?uid=${rowfancy._id.x}&startDate=${profitAndLossCa.from_date}&endDate=${profitAndLossCa.to_date}`}><span className={`lv_1`}>
                                        {rowfancy.user_type.match(/\b\w/g).join('')}
                                    </span>{rowfancy.user_name}</Link>
                                        :
                                        <><span id={`_bySport_${rowfancy._id.x}`} href="javascript:void(0)" onClick={() => getExplicitData(rowfancy._id.x, `_bySport_${rowfancy._id.x}`, `downline_profit_tr_${rowfancy._id.x}`)} class="expand-close">
                                        </span><Link to={`/downLineprofitLoss/${rowfancy._id.x}/${profitAndLossCa.from_date}/${profitAndLossCa.to_date}`}><span className={`lv_1`}>
                                            {rowfancy.user_type.match(/\b\w/g).join('')}
                                        </span>{rowfancy.user_name}</Link></>
                                    }

                                </td>

                                <td >{rowfancy.totalStake.toFixed(2)}</td>
                                <td >
                                    {checkIfNeg(rowfancy.totalPL)}
                                </td>
                                {prevUser && prevUser.length > 0 ? (
                                    prevUser[prevUser.length - 1].user_type != "Agent" && userInfo.user_type != "Agent" ? (<td> {checkIfNeg(rowfancy.totalPL)}</td>) : "") : <td>{checkIfNeg(rowfancy.totalPL)}</td>}
                                <td >0.00{/* accountData.amount */}</td>
                                <td >{checkIfNeg(rowfancy.upline_pl)}{update_total(rowfancy)}</td>
                                {/* <td>
                                <span id="from">Super</span>
                                <img className="fromto" src={Trans} />
                                <span id="to">boss2021</span>
                            </td> */}
                            </tr>

                        ))}
                        {/* )} */}
                    </tbody>

                    <tfoot>
                        <td className="align-L">Total</td>
                        <td id="stakeTotal">Stake</td>
                        <td id="pl_total">Player P/L</td>
                        {prevUser && prevUser.length > 0 ? (
                            prevUser[prevUser.length - 1].user_type != "Agent" && userInfo.user_type != "Agent" ? <td id="pl_total1">Downline P/L</td> : "") : <td id="pl_total1">Downline P/L</td>}
                        <td id="m_comm_total">Master Comm.</td>
                        <td id="upline_total">Upline P/L</td>
                    </tfoot>
                </table>


                <div id="noReportMessage"></div>
                {/* Loading Wrap */}
                <div id="poker_loading" class="loading-wrap" style={{ 'marginTop': '50px' }}>
                    <ul class="loading">
                        <li><img src={Loader} /></li>
                        <li>Loading…</li>
                    </ul>
                </div>
                {/* Message */}

                <table style={displaynone}>
                    <tbody>
                        <tr id="tempTr">
                            <td className="align-L">
                                <Link id="_bySport" to="#" className="expand-close"></Link>
                                <Link id="_userName" className="ico_account" to="#">
                                </Link>
                            </td>
                            <td id="_stake"></td>
                            <td id="_profitLoss"></td>
                            <td id="_profitLossDownLine"></td>

                            <td id="_tax3"></td>
                            <td id="_rebate3"></td>
                            <td id="_tax2"></td>
                            <td id="_rebate2"></td>
                            <td id="_payout1"></td>
                            <td id="_tax1"></td>
                            <td id="_rebate1"></td>
                            <td id="_profitLoss1"></td>
                            <td id="_profitLossUpLine"></td>

                        </tr>

                        <tr id="subTempTr" className="expand">
                            <td className="align-L">
                                <Link id="_sportName" className="ico_account" to="#"></Link>
                            </td>
                            <td id="sport_stake"></td>
                            <td id="sport_profitLoss"></td>
                            <td id="sport_profitLossDownLine"></td>

                            <td id="sport_tax3"></td>
                            <td id="sport_rebate3"></td>
                            <td id="sport_tax2"></td>
                            <td id="sport_rebate2"></td>
                            <td id="sport_payout1"></td>
                            <td id="sport_tax1"></td>
                            <td id="sport_rebate1"></td>
                            <td id="sport_profitLoss1"></td>
                            <td id="sport_profitLossUpLine"></td>

                        </tr>

                        <tr id="tempTotalTr" className="total">
                            <td className="align-L">Total</td>
                            <td id="_totalStake"></td>
                            <td id="_totalProfitLoss"></td>
                            <td id="_totalProfitLossDownLine"></td>

                            <td id="_totalTax3"></td>
                            <td id="_totalRebate3"></td>
                            <td id="_totalTax2"></td>
                            <td id="_totalRebate2"></td>
                            <td id="_totalPayout1"></td>
                            <td id="_totalTax1"></td>
                            <td id="_totalRebate1"></td>
                            <td id="_totalProfitLoss1"></td>
                            <td id="_totalProfitLossUpLine"></td>

                        </tr>
                    </tbody>
                </table>
                <div className="total_all" id="userTreeDiv">
                    <ul style={displaynone}>
                        <li id="tempTree" className=""><Link><span className="lv_1"></span></Link></li>
                        <li id="tempCurrencyTree" className="currency_tag"><strong></strong></li>
                    </ul>

                    <div id="treePath" className="agent_path">
                        <ul className="agent_path-L" id="userTree">
                        </ul>
                    </div>
                    <div id="expandAllDiv" style={displaynone}>
                        <Link id="showDetail" to="#" className="btn_expand">Expand All</Link>
                    </div>
                </div>
                <div id="reportDiv" className="over-wrap" style={{ 'maxHeight': "calc(100% - 32px - 93px - 55px)" }}>
                    <table id="table_DL" className="table01 table-pt" style={displaynone}>
                        <thead>
                            <tr id="head">
                                <th id="header_userName" width="" className="align-L">UID</th>
                                <th width="11%" className="">Stake</th>
                                <th width="11%" className="">Player P/L</th>
                                <th id="header_profitLoss_downLine" width="11%" className="">Downline P/L</th>

                                <th id="header_tax_agent_3" width="11%" className="">Master Comm.</th>
                                <th id="header_rebate_agent_3" width="11%" className="">Master Rebate</th>
                                <th id="header_tax_agent_2" width="11%" className="">Super Comm.</th>
                                <th id="header_rebate_agent_2" width="11%" className="">Super Rebate</th>
                                <th id="header_payout_agent_1" width="11%" className="">SS PT</th>
                                <th id="header_tax_agent_1" width="11%" className="">SS Comm.</th>
                                <th id="header_rebate_agent_1" width="11%" className="">SS Rebate</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">SS Total</th>
                                <th width="11%" className="">Upline P/L</th>

                            </tr>
                        </thead>
                        <tbody id="content">

                        </tbody>
                    </table>
                </div>
            </div>
            {/* Main Wrap End */}
        </div>
    )
}

export default DownlineProfitloss

