import React from 'react'
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import SideNav from '../admin/SideNav'
import { Link } from "react-router-dom";
import Appconfig from '../config/config';
import axios from "axios";
import moment from "moment";
import { useState } from 'react'
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function AccountStatement() {
    const params = useParams();
    const history = useHistory();
    const displaynone = {
        display: "none"
    }
    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [accountList, SetAccountList] = React.useState([]);
    const [memeberUserData, setMemberUserData] = React.useState([]);
    const [formData, setFormData] = React.useState({
        "user_id": params.user_id ? params.user_id : userInfo._id,
        // "type": "0",
        // "from_date": moment().add(-1, 'days').format('YYYY-MM-DD'),
        // "to_date": moment().format('YYYY-MM-DD'),
        "type": "0",
        "from_date": moment().add(-7, 'days').format('YYYY-MM-DD'),
        "to_date": moment().format('YYYY-MM-DD'),
        "game_type": "All",
    })
    React.useEffect(() => {
        getAccount(0);
        getMembersDataUsersData();
    }, []);
    function getMembersDataUsersData() {
        // var user_type = params.user_type;
        var data = JSON.stringify({
            "user_id": params.user_id ? params.user_id : userInfo._id,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                setMemberUserData(response.data);

                let tmpUserList = response.data;
                //         console.log("data new", tmpUserList);
                let tempSetData = tmpUserList.resultData[0];


                setMemberUserData(tempSetData);
                //        console.log('userList', response.data);
            })
            .catch(function (error) {
                //            console.log(error);
            });
    }
    function getAccount(type) {
        setFormData({
            ...formData,
            ["type"]: type,
        });
        var data = JSON.stringify(formData);
        //  console.log(data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/accountStatementTransNew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                SetAccountList(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((accountList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetAccountList(tmpAccountList);
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            <div className="agent_path">
                <ul id="agentPath" className="agent_path-L" >

                    {memeberUserData.length ? prevUser.length && memeberUserData?._id != userInfo._id && prevUser.map((row, index) => {
                        return <li id="path6" className="" key={"path" + index}>
                            <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                <span className={`lv_${index}`}>
                                    {row.user_type.match(/\b\w/g).join('')}
                                </span>
                                <strong>{row.user_name}</strong>
                            </Link>
                        </li>
                    }) : ""}
                    <li id="path6" className="" key={"pathuser"}>
                        <Link to={window.location}>
                            <span className={`lv_3`}>
                                {memeberUserData?.user_type?.match(/\b\w/g).join('')}
                            </span>
                            <strong>{memeberUserData?.user_name}</strong>
                        </Link>
                    </li>
                </ul>
            </div>
            {/* Top End */}
            <div className="">
                {/*  */}

                {/* Side Bar Code */}
                <div className="col-left">
                    <SideNav memeberUserData={memeberUserData} />
                </div>
                <div className="col-center report account-summary">
                    <h2>{memeberUserData._id !== userInfo._id ? "Transaction History" : "Account Statement"}</h2>
                    <div id="loading" className="loading-wrap" style={displaynone}>
                        <ul className="loading">
                            <li><img src="./images/loading40.gif" /></li>
                            <li>Loading...</li>
                        </ul>
                    </div>

                    <div id="message" className="message-wrap success" style={displaynone}>
                        <Link className="btn-close" to="#">Close</Link>
                        <p></p>
                    </div>
                    <table style={displaynone}>
                        <tbody>
                            <tr id="tempTr">
                                <td id="createDate" className="align-L"></td>
                                <td id="deposit">-</td>
                                <td id="withdraw">-</td>
                                <td id="balance"></td>
                                <td id="remark"></td>
                                <td>
                                    <span id="from"></span>
                                    <img className="fromto" src={Trans} />
                                    <span id="to"></span>
                                </td>
                            </tr>

                            <tr id="noDataTempTr">
                                <td className="no-data" colSpan="5">
                                    <p>No Data</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table id="table_log" className="table01">
                        <thead>
                            <tr>
                                <th width="15%" className="align-L">Date/Time</th>
                                <th width="18%">Deposit</th>
                                <th width="18%">Withdraw</th>
                                <th width="18%">Balance</th>
                                <th width="16%">Remark</th>
                                <th width="">From/To</th>
                            </tr>
                        </thead>
                        <tbody id="content">

                            {accountList.length > 0 && accountList.map(accountData => <tr id="tempTr">
                                <td id="createDate" className="align-L">{moment(accountData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                <td id="deposit" className="inttype" data-value={accountData.transaction_type == "Credit" || accountData.transaction_type != "Debit" && accountData.amount > 0
                                    ? accountData.amount : 0}>{accountData.transaction_type == "Credit" || accountData.transaction_type != "Debit" && accountData.amount > 0
                                        ? accountData.amount.toFixed(2) : "-"}</td>
                                <td id="withdraw" className="inttype" data-value={accountData.transaction_type == "Debit" || accountData.transaction_type != "Credit" && accountData.amount < 0 ?
                                    accountData.amount : 0}>{accountData.transaction_type == "Debit" || accountData.transaction_type != "Credit" && accountData.amount < 0 ?
                                        accountData.amount.toFixed(2) : "-"}</td>
                                <td id="balance">{accountData.balance.toFixed(2)}</td>
                                <td>

                                    {accountData.type == "Settlement" ? accountData.type + " / " : ""}

                                    {accountData.betting_type ? <Link onClick={() => history.push(`/memberList/bethistory?eventid=${accountData._id.x}&selection_id=${accountData.betting_type == 'Match' ? 'Match' : accountData.selection_id}`)}>{`${accountData.remarks}` + (accountData.betting_type ? `(${accountData.betting_type} / ${accountData.place_name})` : "")}</Link> : accountData.remarks}
                                </td>
                                <td>
                                    <span id="from">{accountData.from ? accountData.from : "-"}</span>
                                    <img className="fromto" src={Trans} />
                                    <span id="to">{accountData.to ? accountData.to : "-"}</span>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};


