
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './admin/Header'

// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import SideNav from './admin/SideNav';
import Loader from './assets/images/loading40.gif';


import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from './config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams, useHistory } from "react-router-dom";


import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
export default function DeleteUser() {
    const displaynone = {
        display: "none"
    }
    const history = useHistory();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [marketList, setmarketList] = React.useState([]);
    const [selectedIndex, setSelectedUserIndex] = React.useState([]);
    const [chekeduser, setSelectedUser] = React.useState(Array);

    const [searched, setSearched] = React.useState("");
    const [tempMarketList, setTempMarketList] = React.useState([]);
    React.useEffect(() => {

        getUsers();
        // contansClass();
        // getNextUserType();
    }, [params.user_id]);

    function getUsers() {
        let tmpEventList;
        loader_default();
        var user_type = params.user_type;
        var data = JSON.stringify({
            master_id: params.user_id ? params.user_id : userInfo._id,
            // user_type: params.user_type ? params.user_type : user_type,
            // page: "AddAccount"
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getSimplyUser`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                setmarketList(response.data.resultData);
                console.log(response.data.resultData)
                tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((marketList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('after match odds click', response.data.resultData);
                setmarketList(tmpEventList)
                setTempMarketList(tmpEventList);
                //  console.log(EventList);
                loader_remove()

                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function userList(eventId) {

        let tmpEventList;
        var data = JSON.stringify({
            // "user_id": userInfo._id,
            "event_id": eventId
        });


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/getBettingByEventId`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setmarketList(response.data.resultData);
                console.log(response.data.resultData)
                tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((marketList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('after match odds click', response.data.resultData);
                setmarketList(tmpEventList)
                setTempMarketList(tmpEventList);
                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            }).then(() => {

                if (!tmpEventList.length) {
                    document.getElementsByClassName("MuiDataGrid-window")[0].innerHTML = "<div class='no-row'><span>No Rows</span></div>"
                }

            });


    }

    const classes = useStyles();
    function setSelectedUsers(newUserList) {
        let tmpSelectUserList = [...newUserList];

        console.log("tmpSelectUserList", tmpSelectUserList);
        setSelectedUser(tmpSelectUserList);
        console.log("select", chekeduser);
    }

    const handleAction = (e) => {
        e.preventDefault();
        document.querySelector("#creditReferenceModel").style.display = "block";
    };

    const closeReferenceModal = () => {
        document.querySelector("#creditReferenceModel").style.display = "none";
    }
    const DeleteAction = () => {

        var axios = require("axios");
        var data = JSON.stringify({
            user_ids: selectedIndex,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/deleteUser`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {

                    notify(response.data.resultMessage, "success");
                    setSelectedUserIndex([]);
                    console.log(JSON.stringify(response.data));
                }
                else {
                    notify(response.data.resultMessage, "error");
                    console.log(JSON.stringify(response.data));
                }
                getUsers();
                closeReferenceModal();
            })
            .catch(function (error) {
                notify("Some Error Occured", "error");
                console.log(error);
            });

    };
    function notify(message, type) {
        document.querySelector("#notifyMsg").textContent = message;
        if (type === 'error') {
            document.querySelector("#message").style.background = "red";
        }
        else {

            document.querySelector("#message").style.background = "green";
        }
        document.getElementById("message").style.display = "block";
        setTimeout(function () {
            closeMessage();
        }, 3000);
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);
        // console.log("searchedVal",searchedVal);
        const filteredRows = marketList.filter((row) => {
            return row.user_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setTempMarketList(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    function collectId(id, e) {

        if (!e.target.checked) {
            setSelectedUserIndex(selectedIndex.filter(item => item !== id));
        }
        else {
            setSelectedUserIndex([...selectedIndex, id]);
        }
    }
    return (

        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div className="main_wrap">
                <div id="creditReferenceModel" className="pop_bg" style={displaynone}>
                    <div className="pop_refer">
                        <Link className="close_pop" onClick={closeReferenceModal}>close_pop</Link>
                        <h3>Are You Sure ?</h3>


                        <ul className="btn-wrap">
                            <li style={{ display: "grid" }}><a id="changeCreditReferenceBtn" className="btn-send" href="javascript:void(0)" onClick={DeleteAction}>Submit</a></li>
                        </ul>
                    </div>
                </div>
                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L">
                        <li id="path6" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_0">
                                    COM
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path5" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_1">
                                    SS
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path4" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_2">
                                    SUP
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path3" className="last_li">
                            <Link >
                                <span className="lv_3">
                                    {userInfo.user_type.match(/\b(\w)/g).join('')}
                                </span>
                                <strong>{userInfo.user_name}</strong>
                            </Link>
                        </li>

                        <li id="path0" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_4">
                                    PL
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <ul className="account_pop" id="accountPop">
                            <li id="popTmp" style={displaynone}>
                                <Link to="/"></Link>
                            </li>
                        </ul>
                    </ul>
                </div>

                {/* Side Bar Code */}
                <div className="col-left">
                    <SideNav />
                </div>
                <div id="poker_loading" class="loading-wrap" style={{ 'marginTop': '50px' }}>
                    <ul class="loading">
                        <li><img src={Loader} /></li>
                        <li>Loading…</li>
                    </ul>
                </div>
                <div className="col-center report account-summary">
                    <div style={{ display: "flex" }}>
                        <div> <h2>User Listing</h2></div>
                        <div style={{ marginLeft: "50%" }}> <input
                            value={searched}
                            placeholder='search'
                            onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        />
                        </div>
                        <div>
                            <button style={{ float: "right" }} className="close-btn" disabled={selectedIndex.length > 0 ? false : true} onClick={handleAction}>
                                Delete
                            </button>
                        </div>
                    </div>
                    <div id="loading" className="loading-wrap" style={displaynone}>
                        <ul className="loading">
                            <li><img src="./images/loading40.gif" /></li>
                            <li>Loading...</li>
                        </ul>
                    </div>

                    <div id="message" className="message-wrap success" style={displaynone}>
                        <Link className="btn-close" onClick={closeMessage} to="#">Close</Link>
                        <p id="notifyMsg"></p>
                    </div>

                    <table id="table_log" className="table01" style={{ textAlign: "left" }}>
                        <thead>
                            <tr>
                                <th >&nbsp;</th>
                                <th >User Name</th>
                                <th >Ac Type</th>
                                <th >Created At</th>


                            </tr>
                        </thead>
                        <tbody id="content">

                            {tempMarketList.length > 0 && tempMarketList.map(row =>
                                <tr id="tempTr">
                                    <td ><input onClick={(e) => collectId(row._id, e)} type='checkbox' /></td>
                                    <td>
                                        <span class="lv_3">{row.user_type.match(/\b\w/g).join('')}</span>
                                        {row.user_type != "User" ? <Link to={`/deleteuser/${row._id}`}>{row.user_name}</Link> : row.user_name}</td>
                                    <td >{row.user_type}</td>
                                    <td >{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss ddd')}</td>


                                </tr>
                            )}
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    )
}