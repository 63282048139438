import React from 'react'
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import SideNav from '../admin/SideNav'
import { ReactTable } from "react-table";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from "axios";
import Appconfig from "../config/config";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
    Toolbar,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    ButtonGroup,
    Menu,
    Backdrop,
    Fade,
    Grid,
    Modal,
    Button,
    TextField,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

const displaynone = {
    display: "none"
}
function Profile() {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [opens, setOpens] = React.useState(false);
    const [expLimitOpens, setExpLimitOpens] = React.useState(false);
    const [commOpens, setCommOpens] = React.useState(false)
    const classes = useStyles();
    const [passwordData, setPasswordData] = React.useState({});
    const [memeberUserData, setMemberUserData] = React.useState([]);
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const usernameQuery = new URLSearchParams(search).get('username');
    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);

    function checkBothPasss() {
        var newconfirmpass = passwordData.new_confirm_password;
        var newpassfirst = passwordData.new_password;
        if (newpassfirst != newconfirmpass) {
            document.getElementById("validateConfirmPass").innerHTML = "Password Did Not Match";
            return false
        }
        if (newpassfirst == newconfirmpass) {
            document.getElementById("validateConfirmPass").innerHTML = "";
            return true
        }
    }
    function checkPasswordCorrect() {
        var oldPassword = memeberUserData.password
        var newSubPass = document.getElementById("password_exp").value;
        if (oldPassword != newSubPass) {
            document.getElementById("validateConfirmPass").innerHTML = "Password is Incorrect";
            return false
        }
        if (oldPassword == newSubPass) {
            document.getElementById("validateConfirmPass").innerHTML = "";
            return true
        }
    }
    React.useEffect(() => {
        getMembersDataUsersData();
    }, []);
    function getMembersDataUsersData() {
        // var user_type = params.user_type;
        var data = JSON.stringify({
            user_id: uidQuery,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                setMemberUserData(response.data);

                let tmpUserList = response.data;
                //         console.log("data new", tmpUserList);
                let tempSetData = tmpUserList.resultData[0];


                setMemberUserData(tempSetData);
                //        console.log('userList', response.data);
            })
            .catch(function (error) {
                //            console.log(error);
            });
    }

    function notify(message, type) {

        if (type === 'error') {
            document.querySelector("#message").style.backgroundColor = "red";
        }
        else {
            document.querySelector("#message").style.backgroundColor = "green";
        }
        document.querySelector("#message-text").textContent = message;
        document.querySelector("#message").style.display = "block";
        setTimeout(function () { closeMessage() }, 3000)
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    const handlePasswordChange = (event) => {
        setPasswordData({
            ...passwordData,
            [event.target.name]: event.target.value

        })

    }
    const handleExpChange = (event) => {
        setMemberUserData({
            ...memeberUserData,
            [event.target.name]: event.target.value
        })
    }
    const handleCommmChange = (event) => {
        setMemberUserData({
            ...memeberUserData,
            [event.target.name]: event.target.value
        })
    }
    const handlePasswordSubmit = (e) => {

        e.preventDefault();
        if (uidQuery) {
            var data = JSON.stringify({
                "user_id": uidQuery,
                "UpdateField": {
                    "password": passwordData.new_password,
                    "cpmodal": "No"
                }
            });
        }
        else {
            var data = JSON.stringify({
                "old_password": passwordData.old_password,
                "new_password": passwordData.new_password,
                "user_id": userInfo._id
            });
        }
        let url = uidQuery ? "updateUserInfo" : "changePassword";
        if (checkBothPasss()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/${url}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {

                    if (response.data.result) {
                        notify("Password Successfully Updated", 'success');
                        // console.log('success', response.data.message);
                    }
                    else {
                        notify(response.data.message, 'error');
                    }
                    handleClose();
                    setPasswordData({
                        old_password: '',
                        new_password: '',
                    })
                    getMembersDataUsersData();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }
    const handleExpSubmiy = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
            "user_id": uidQuery,
            "UpdateField": {
                "exposer_limit": memeberUserData.exposer_limit
            }
        });
        if (checkPasswordCorrect()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/updateUserInfo`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {

                    if (response.data.result) {
                        // notify(response.data.message, 'success');

                    }
                    else {
                        notify(response.data.message, 'error');
                    }


                    handleExpLimitClose();
                    setPasswordData({
                        old_password: '',
                        new_password: '',
                    })
                    getMembersDataUsersData();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    // Commision API Update

    const handleCommSubmit = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
            "user_id": uidQuery,
            "UpdateField": {
                "commission": memeberUserData.commission
            }
        });
        if (checkPasswordCorrect()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/updateUserInfo`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {

                    if (response.data.result) {
                        // notify(response.data.message, 'success');
                    }
                    else {
                        notify(response.data.message, 'error');
                    }

                    handleCommissionClose();
                    getMembersDataUsersData();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }



    const handleClose = () => {
        setOpens(false);
    };

    const handleOpen = () => {
        setOpens(true);
    };


    const handleExpLimitClose = () => {
        setExpLimitOpens(false);
    };

    const handleExpLimitOpen = () => {
        setExpLimitOpens(true);
    };
    const handleCommissionOpen = () => {
        setCommOpens(true);
    }
    const handleCommissionClose = () => {
        setCommOpens(false);
    }


    return (
        <div className="dashboard profile-pge  m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={opens}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={opens}>
                    <div className="user_modal custom-sky-passwordChange">
                        <h2 id="transition-modal-title">Change Password</h2>
                        <a className="close_pop" href="#" onClick={handleClose}>close_pop</a>
                        <ValidatorForm

                            onSubmit={handlePasswordSubmit}
                            autoComplete="off"
                        >
                            <Grid container spacing={2}>
                                <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                    New Password
                                </Grid>
                                <Grid className="form-input-changepas" item lg={5} xs={5}>
                                    <TextValidator size="small"
                                        value={passwordData.new_password}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        onChange={handlePasswordChange}
                                        id="outlined-basic2" className="inputtext-pass" type="password" placeholder="Enter" name="new_password" variant="outlined" />
                                </Grid>
                                <Grid className="star-must" item lg={2} xs={2}>
                                    <span className="must">＊</span>
                                </Grid>
                                <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                    New Password Confirm
                                </Grid>
                                <Grid item lg={5} xs={5} className="form-input-changepas">
                                    <TextValidator size="small"
                                        value={passwordData.new_confirm_password}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        onChange={handlePasswordChange}
                                        onKeyUp={checkBothPasss}
                                        id="outlined-basic2" className="inputtext-pass" type="password" placeholder="Enter" name="new_confirm_password" variant="outlined" />
                                    <div id="validateConfirmPass"></div>
                                </Grid>
                                <Grid className="star-must" item lg={2} xs={2}>
                                    <span className="must">＊</span>
                                </Grid>
                                {
                                    uidQuery ? "" : <>

                                        <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                            Your Password
                                        </Grid>
                                        <Grid item lg={5} xs={5} className="form-input-changepas">
                                            <TextValidator size="small"
                                                value={passwordData.old_password}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handlePasswordChange}
                                                id="outlined-basic2" className="inputtext-pass" type="password" placeholder="Enter" name="old_password" variant="outlined" />
                                        </Grid>
                                        <Grid className="star-must" item lg={2} xs={2}>
                                            <span className="must">＊</span>
                                        </Grid></>
                                }

                                <Grid item lg={12} xs={12} className="chaPassSubmitCont">
                                    <Button variant="contained" className="chaPassSubmit" type="submit">
                                        Change
                                    </Button>
                                    {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button> */}
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </div>
                </Fade>
            </Modal>
            {/* Exposure Limit */}

            {uidQuery != null ?
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={expLimitOpens}
                    onClose={handleExpLimitClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={expLimitOpens}>
                        <div className="user_modal custom-sky-passwordChange">
                            <h2 id="transition-modal-title">Change Exposure Limit</h2>
                            <a className="close_pop" href="#" onClick={handleExpLimitClose}>close_pop</a>
                            <ValidatorForm

                                onSubmit={handleExpSubmiy}
                                autoComplete="off"
                            >
                                <Grid container spacing={2}>
                                    <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                        Exposure
                                    </Grid>
                                    <Grid className="form-input-changepas" item lg={5} xs={5}>
                                        <TextValidator size="small"
                                            value={memeberUserData.exposer_limit}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            onChange={handleExpChange}
                                            id="exp_update" className="inputtext-pass" type="text" placeholder="Enter" name="exposer_limit" variant="outlined" />
                                    </Grid>
                                    <Grid className="star-must" item lg={2} xs={2}>
                                        <span className="must">＊</span>
                                    </Grid>
                                    {
                                        uidQuery ? "" : <><Grid className="form-lable-changepass" item lg={5} xs={5}>
                                            Your Password
                                        </Grid>
                                            <Grid item lg={5} xs={5} className="form-input-changepas">
                                                <TextValidator size="small"
                                                    value={passwordData.old_password}
                                                    validators={['required']}
                                                    errorMessages={['this field is required']}
                                                    onChange={handlePasswordChange}
                                                    id="password_exp" className="inputtext-pass" type="password" placeholder="Enter" name="old_password" variant="outlined" />
                                                <div id="validateConfirmPass"></div>
                                            </Grid>
                                            <Grid className="star-must" item lg={2} xs={2}>
                                                <span className="must">＊</span>
                                            </Grid></>
                                    }

                                    <Grid item lg={12} xs={12} className="chaPassSubmitCont">
                                        <Button variant="contained" className="chaPassSubmit" type="submit">
                                            Change
                                        </Button>
                                        {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button> */}
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </div>
                    </Fade>
                </Modal>
                : null}
            {/* Commision Popup */}
            {uidQuery != null ?

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={commOpens}
                    onClose={handleCommissionClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={commOpens}>
                        <div className="user_modal custom-sky-passwordChange">
                            <h2 id="transition-modal-title">Change Commission</h2>
                            <a className="close_pop" href="#" onClick={handleCommissionClose}>close_pop</a>
                            <ValidatorForm

                                onSubmit={handleCommSubmit}
                                autoComplete="off"
                            >
                                <Grid container spacing={2}>
                                    <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                        Commission
                                    </Grid>
                                    <Grid className="form-input-changepas" item lg={5} xs={5}>
                                        <TextValidator size="small"
                                            value={memeberUserData.commission}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            onChange={handleCommmChange}
                                            id="comm_update" className="inputtext-pass" type="text" placeholder="Enter" name="commission" variant="outlined" />
                                    </Grid>
                                    <Grid className="star-must" item lg={2} xs={2}>
                                        <span className="must">＊</span>
                                    </Grid>
                                    {
                                        uidQuery ? "" : <>
                                            <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                                Your Password
                                            </Grid>
                                            <Grid item lg={5} xs={5} className="form-input-changepas">
                                                <TextValidator size="small"
                                                    value={passwordData.old_password}
                                                    validators={['required']}
                                                    errorMessages={['this field is required']}
                                                    onChange={handlePasswordChange}
                                                    id="password_exp" className="inputtext-pass" type="password" placeholder="Enter" name="old_password" variant="outlined" />
                                                <div id="validateConfirmPass"></div>
                                            </Grid>
                                            <Grid className="star-must" item lg={2} xs={2}>
                                                <span className="must">＊</span>
                                            </Grid>
                                        </>
                                    }




                                    <Grid item lg={12} xs={12} className="chaPassSubmitCont">
                                        <Button variant="contained" className="chaPassSubmit" type="submit">
                                            Change
                                        </Button>
                                        {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button> */}
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </div>
                    </Fade>
                </Modal>
                : null}


            <div className="">

            <div className="agent_path">
                <ul id="agentPath" className="agent_path-L" >

                   {memeberUserData.length&&prevUser.length && memeberUserData?._id != userInfo._id    && prevUser.map((row, index) => {
                        return <li id="path6" className="" key={"path" + index}>
                            <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                <span className={`lv_${index}`}>
                                    {row.user_type.match(/\b\w/g).join('')}
                                </span>
                                <strong>{row.user_name}</strong>
                            </Link>
                        </li>
                    })}
                    <li id="path6" className="" key={"pathuser"}>
                        <Link to={window.location}>
                            <span className={`lv_3`}>
                           {memeberUserData?.user_type?.match(/\b\w/g).join('')}
                            </span>
                            <strong>{memeberUserData?.user_name}</strong>
                        </Link>
                    </li>
                </ul>
            </div>

                {/* Side Bar Code */}

                {uidQuery != null ?
                    <><div className="col-left">
                        <SideNav memeberUserData={memeberUserData} />
                    </div>
                        <div id="message" className="message-wrap success" style={displaynone}>
                            <Link to="" className="btn-close" onClick={closeMessage} >Close</Link>
                            <p id='message-text' ></p>
                        </div>
                        <div className="col-center report account-summary memberList-profile">
                            <div id="loading" className="loading-wrap" style={displaynone}>
                                <ul className="loading">
                                    <li><img src="./images/loading40.gif" /></li>
                                    <li>Loading...</li>
                                </ul>
                            </div>

                            {/* <div id="message" className="message-wrap success" style={displaynone}>
                                <Link to="" className="btn-close">Close</Link>
                                <p id='message-text'></p>
                            </div> */}
                            {memeberUserData ?
                                <>
                                <h2>Account Summary</h2>
                                    <ul class="acc-info">
                                        <li class="user">{memeberUserData?.user_name}
                                        </li>
                                    </ul>
                                   
                                    <table className="table01">
                                        <tbody>
                                            <tr>
                                                <th className="align-L">Wallet</th>
                                                <th>Available to Bet</th>
                                                <th width="">Funds available to withdraw</th>
                                                <th>Current exposure</th>
                                            </tr>
                                            <tr>
                                                <td className="align-L">Main wallet</td>
                                                <td id="availableToBet">{memeberUserData.balance}</td>
                                                <td id="availableToWithDraw">{memeberUserData.credit_limit}</td>
                                                <td id="currentExposure">{memeberUserData.exposure}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h2>Profile</h2><div className="event-left">
                                        <div className="profile-wrap">
                                            <h3>About You</h3>
                                            <dl>
                                                <dt>First Name</dt>
                                                <dd>{memeberUserData.name}</dd>
                                                <dt>Last Name</dt>
                                                <dd>{memeberUserData.last_name}</dd>

                                                <dt>Birthday</dt>
                                                <dd>{userInfo.birthday}</dd>

                                                <dt>E-mail</dt>
                                                <dd>{memeberUserData.email}</dd>

                                                <dt>Password</dt>
                                                <dd>******
                                                    {/* {memeberUserData.password} */}
                                                    <Link to={`profile?uid=${memeberUserData._id}`} className="favor-set" onClick={handleOpen}>Edit</Link>
                                                </dd>

                                                <dt>Time Zone</dt>
                                                <dd>{memeberUserData.timezone}</dd>
                                            </dl>
                                        </div>
                                        <div className="profile-wrap">
                                            <h3>Contact Details</h3>
                                            <dl>
                                                <dt>Primary number</dt>
                                                <dd>{memeberUserData.phone}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                    {/* <div className="event-right">
                                        <div className="profile-wrap">
                                            <h3>Limits &amp; Commission</h3>
                                            <dl>
                                                <dt>Exposure Limit</dt>
                                                <dd>{memeberUserData.exposer_limit}
                                                    <Link to={`profile?uid=${memeberUserData._id}`} className="favor-set" onClick={handleExpLimitOpen}>Edit</Link>
                                                </dd>
                                                <dt>Commission</dt>
                                                <dd>{memeberUserData.commission ? `${memeberUserData.commission}%` : "-"}
                                                    <Link to={`profile?uid=${memeberUserData._id}`} className="favor-set" onClick={handleCommissionOpen}>Edit</Link>
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="profile-wrap" style={displaynone}>
                                            <h3>PT</h3>
                                            <dl>
                                                <dt>My PT Allowed</dt>
                                                <dd>0%</dd>
                                            </dl>
                                            <dl>
                                                <dt>My Fancy Bet PT</dt>
                                                <dd>0%</dd>
                                            </dl>
                                            <dl>
                                                <dt>Sportsbook PT</dt>
                                                <dd>0%</dd>
                                            </dl>
                                            <dl>
                                                <dt>Sportsbook Premium PT</dt>
                                                <dd>0%</dd>
                                            </dl>
                                            <dl>
                                                <dt>My Book Maker PT</dt>
                                                <dd>0%</dd>
                                            </dl>
                                            <dl>
                                                <dt>Casino PT Allowed</dt>
                                                <dd>LIVE: 0%, RNG: 0%, SLOT: 0%, VIRTUAL: 0%</dd>
                                            </dl>
                                        </div>
                                    </div> */}
                                    <table style={displaynone}>
                                        <tbody>
                                            <tr id="tempTr">
                                                <td id="createDate" className="align-L"></td>
                                                <td id="deposit">-</td>
                                                <td id="withdraw">-</td>
                                                <td id="balance"></td>
                                                <td id="remark"></td>
                                                <td>
                                                    <span id="from"></span>
                                                    <img className="fromto" src={Trans} />
                                                    <span id="to"></span>
                                                </td>
                                            </tr>

                                            <tr id="noDataTempTr">
                                                <td className="no-data" colSpan="5">
                                                    <p>No Data</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table></>
                                : null
                            }

                        </div></>


                    :
                    <><div className="col-left">
                        <SideNav />
                    </div><div className="col-center report account-summary">
                            <div id="loading" className="loading-wrap" style={displaynone}>
                                <ul className="loading">
                                    <li><img src="./images/loading40.gif" /></li>
                                    <li>Loading...</li>
                                </ul>
                            </div>

                            <div id="message" className="message-wrap success" style={displaynone}>
                                <Link to="" className="btn-close" onClick={closeMessage} >Close</Link>
                                <p id='message-text' ></p>
                            </div>

                            <h2>Profile</h2>
                            <div className="event-left">
                                <div className="profile-wrap">
                                    <h3>About You</h3>
                                    <dl>
                                        <dt>First Name</dt>
                                        <dd>{userInfo.name}</dd>

                                        <dt>Last Name</dt>
                                        <dd></dd>

                                        <dt>Birthday</dt>
                                        <dd>{userInfo.birthday}</dd>

                                        <dt>E-mail</dt>
                                        <dd></dd>

                                        <dt>Password</dt>
                                        <dd>******
                                            <Link to="#" className="favor-set" onClick={handleOpen}>Edit</Link>
                                        </dd>

                                        <dt>Time Zone</dt>
                                        <dd>{userInfo.timezone}</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="event-right">
                                <div className="profile-wrap">
                                    <h3>Contact Details</h3>
                                    <dl>
                                        <dt>Primary number</dt>
                                        <dd>{userInfo.phone}</dd>
                                    </dl>
                                </div>
                                <div className="profile-wrap" style={displaynone}>
                                    <h3>PT</h3>
                                    <dl>
                                        <dt>My PT Allowed</dt>
                                        <dd>0%</dd>
                                    </dl>
                                    <dl>
                                        <dt>My Fancy Bet PT</dt>
                                        <dd>0%</dd>
                                    </dl>
                                    <dl>
                                        <dt>Sportsbook PT</dt>
                                        <dd>0%</dd>
                                    </dl>
                                    <dl>
                                        <dt>Sportsbook Premium PT</dt>
                                        <dd>0%</dd>
                                    </dl>
                                    <dl>
                                        <dt>My Book Maker PT</dt>
                                        <dd>0%</dd>
                                    </dl>
                                    <dl>
                                        <dt>Casino PT Allowed</dt>
                                        <dd>LIVE: 0%, RNG: 0%, SLOT: 0%, VIRTUAL: 0%</dd>
                                    </dl>
                                </div>
                            </div>
                            <table style={displaynone}>
                                <tbody>
                                    <tr id="tempTr">
                                        <td id="createDate" className="align-L"></td>
                                        <td id="deposit">-</td>
                                        <td id="withdraw">-</td>
                                        <td id="balance"></td>
                                        <td id="remark"></td>
                                        <td>
                                            <span id="from"></span>
                                            <img className="fromto" src={Trans} />
                                            <span id="to"></span>
                                        </td>
                                    </tr>

                                    <tr id="noDataTempTr">
                                        <td className="no-data" colSpan="5">
                                            <p>No Data</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div></>
                }
            </div>
        </div>
    )
}

export default Profile
