import React, { useState } from 'react';
import './login.css';
import Logo from './assets/images/lce.png'
import Arrow from './assets/login-arrow.png'
import Transparent from './assets/transparent.gif'
import axios from 'axios';
import Appconfig from './config/config';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import validate from './validateInfo';
import useForm from './useForm';
import Loader from './assets/images/loading40.gif';
// import { FormControl, Input, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Alert } from '@material-ui/lab';

const box = {
    width: '150px',
    height: '50px',
    border: "none"
}
const displaynone = {
    display: "none"
}

function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const Login = (props) => {
    const history = useHistory();
    const [formData, setformData] = useState({
        user_name: '',
        password: '',
        site_code: Appconfig.sitecodes,
        user_type: "Super"
    });

    const [validationError, SetValidationError] = useState("");

    function checkValidation() {
        let errorText;
        errorText = formData.user_name.trim() != "" ? "" : "Username is only allow a-z and 0-9."

        if (formData.user_name != "") {

            errorText = formData.password.trim() != "" ? "" : "Password is empty"
        } if (formData.password != "" && formData.user_name != '') {

            errorText = '';
            SetValidationError(errorText);

            return true;
        }

        SetValidationError(errorText);

        return false;

    }
    const handleInputChange = (event) => {
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
        // console.log(formData);
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        if (checkValidation()) {



            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/userAuthenticate`,
                headers: {
                    'Content-Type': 'application/json',

                },

                data: JSON.stringify(formData)

            };

            axios(config)
                .then(function (response) {

                    console.log(response);
                    // storeUserinfo(response.data.resultData);
                    if (response.data.result) {

                        document.querySelector("#successMsg").textContent = response.data.resultMessage;
                        notify(response.data.resultMessage, 'success')
                        storeUserinfo(response.data.resultData);
                        loader_default();
                        props.setLoggedIn(true)
                    }
                    else {

                        SetValidationError(response.data.resultMessage);

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }
    function notify(message, type) {
        if (type === 'error') {
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    function storeUserinfo(userdata) {
        window.sessionStorage.setItem("loggedIn", true);
        window.sessionStorage.setItem("userData", JSON.stringify(userdata));
        sessionStorage.setItem("tabIndex", 0);
        // setTimeout(function () {
        history.push('/dashboard');
        // }, 2000);
    }
    return (
        <div>
            <div id="poker_loading" class="loading-wrap" style={{ 'marginTop': '50px', display: "none" }}>
                <ul class="loading">
                    <li><img src={Loader} /></li>
                    <li>Loading…</li>
                </ul>
            </div>
            <div className="bgBlack">

                <div className="skyexchange">
                    <div className="taoster-cont">
                        <ToastContainer />
                    </div>

                    <div className="login-wrap">
                        <div >
                            <img src={Logo} className='login-banner' />
                        </div>
                        <div className="kv" style={{ "background": "#000" }}></div>

                        <dl className="login-panel">
                            <form method="post" onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <dt>Agent login</dt>
                                <dd><input
                                    id="loginName"
                                    type="text"
                                    name="user_name"
                                    value={formData.user_name}
                                    onChange={handleInputChange}
                                    placeholder="Username"
                                />

                                </dd>
                                <dd><input
                                    id="password"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange} />
                                </dd>
                                <dd className="valid-code">
                                    <input id="validCode" type="number" placeholder="Validation Code" maxLength="4" />
                                    <img id="authenticateImage" src="https://agawscf.skyexchange.com/verifycode.gr?v=1631542230014" />
                                </dd>
                                <dd style={displaynone}><input id="valid" name="valid" type="hidden" value="c3526a6f-b96f-4076-9320-f77b333c0b26" /></dd>
                                <dd><button id="loginBtn" className="btn-send">Login<img className="icon-login" src={Arrow} /></button></dd>
                                <dd id="errorMsg" className="error">{validationError}</dd>
                                <dd id="successMsg" className="success"></dd>
                            </form>
                        </dl>
                    </div>

                    <div className="moreinfo-wrap">


                        <div id="supportWrap" className="support-wrap">
                            <div className="support-service">
                                <Link id="support_email" to="#" className="support-mail open">
                                    <img src={Transparent} title="Email" />
                                </Link>
                                <a id="support_whatsapp" href="#" className="support-whatsapp" target="_blank">
                                    <img src={Transparent} title="WhatsApp" />
                                </a>
                                <a id="support_telegram" href="#" target="_blank" className="support-telegram">
                                    <img src={Transparent} title="Telegram" /></a>       <Link id="support_skype" to="#" className="support-skype"><img src={Transparent} title="Skype" /></Link>
                                <Link id="support_instagram" to="#" className="support-ig"><img src={Transparent} title="Instagram" /></Link>                                                       </div>
                            <div className="support-info">
                                {/* <div id="supportDetail_email" className="support-detail"><Link to="mailto:info@skyexchange.com">info@skyexchange.com</Link></div> */}


                                {/* <div id="supportDetail_whatsapp" className="support-detail">
                                    <a href='#' target='_blank'>+9173740005791</a>

                                    <a href='#' target='_blank'>+9173740005791</a>

                                </div> */}


                                {/* <div id="supportDetail_telegram" className="support-detail">
                                    <Link to="http://www.t.me/officialskyexchangeinfo">www.t.me/officialskyexchangeinfo</Link>

                                    <Link to="http://www.t.me/skyexchangecustomersupport">www.t.me/skyexchangecustomersupport</Link>

                                </div> */}



                                {/* <div id="supportDetail_skype" className="support-detail">
                                    <Link to="skype:skyexchangeofficial?chat">skyexchangeofficial</Link>
                                </div> */}


                                {/* <div id="supportDetail_instagram" className="support-detail">
                                    <Link to="https://www.instagram.com/officialskyexchange" className="ui-link" target="_blank">officialskyexchange</Link>
                                </div> */}


                            </div>
                        </div>



                        <div className="power-wrap">
                            <h3><span>Powered by</span><img src={Transparent} /></h3>


                        </div>


                        <div className="browser-wrap">
                            <img src={Transparent} /><br />Our website works best in the newest and last prior version of these browsers: <br />Google Chrome. Firefox
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Login;