import React from 'react';
import Trans from './assets/transparent.gif'
import "./assets/style.css"
import Header from './admin/Header'
import { useState } from 'react'
import { Link } from "react-router-dom";
import Appconfig from "./config/config";
import axios from "axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import moment from "moment";
import activeinactive from "../src/assets/icons/transparent (1).gif"
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { CURRENCY_TYPE } from '../src/config/config';
import Loader from './assets/images/loading40.gif';
import { useQuery } from 'react-query';


const displaynone = {
    display: "none"
}
function loader_default() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "grid";
    }

}
function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }
}
const Dashboard = () => {


    let totalBalance = 0;
    let totalExposure = 0;


    const params = useParams();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    const [opens, setOpens] = React.useState(false);

    const [userList, setUserList] = React.useState([]);

    const [News, setNews] = React.useState([]);
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');

    const [memeberUserData, setMemberUserData] = React.useState([]);



    const [disableActive, setDisableActive] = React.useState([]);
    const [disableSuspend, setDisableSuspend] = React.useState([]);
    const [disableLocked, setDisableLocked] = React.useState([]);



    const [show, setShow] = useState(false)

    const [searched, setSearched] = React.useState("");
    const [FilterList, setFilterList] = React.useState([]);
    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    const [MainBalance, setMainBalance] = React.useState(0);

    const [settingsShow, setSettingsShow] = useState(false);
    const [accountStatus, setAccountStatus] = useState("ALL");
    const [currentUserCreditRef, setCurrentUserCreditRef] = useState(0);
    const [formData, setFormData] = useState({
        master_id: params.user_id ? params.user_id : userInfo?._id,
        name: "",
        last_name: "",
        email: "",
        user_name: "",
        password: "",
        cpassword: "",
        website: userInfo.website,
        user_type: params.user_type ? currentname(params.user_type) : getNextUserType(),
        is_closed: userInfo.is_closed,
        is_betting_open: userInfo.is_betting_open,
        is_locked: userInfo.is_locked,
        sessional_commision: "",
        casino_partnership: "",
        partnership: "",
        teenpati_partnership: "",
        master_commision: "",
        casino_commision: "",
        credit_reference: "",
        exposer_limit: "",
        phone: "",
        city: "",
        deposite_bal: "",
        registration_date: moment().format("DD-MMM-YYYY"),
        is_delete: userInfo.is_delete,
        site_code: userInfo.site_code,
    });

    const [userRefData, setUserRefData] = useState({
        user_id: "",
        credit_reference: "",
        password: "",
        user_name: ""
    });

    const handleCredRefChange = (event) => {
        setUserRefData({
            ...userRefData,
            [event.target.name]: event.target.value,
        });
    };


    function getNextUserType() {
        let sesstionUser = userInfo.user_type;
        let nextType = sesstionUser.toLowerCase().replace(" ", "_");
        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let tempUser = Appconfig.userTypes[index + 1];
        return tempUser;
    }
    function newGetNextUserType(nextType) {

        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let temp = Appconfig.userTypes[index + 1];
        return temp.toLowerCase().replace(" ", "_");
    }
    function currentname(nextType) {

        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let temp = Appconfig.userTypes[index];
        console.log("temp", temp);
        return temp;
    }
    const handleAccountStatus = (event) => {
        setAccountStatus(event.target.value);
        requestSearch(event.target.value)
    }
    React.useEffect(() => {
        removeshort();
        getBalance();
        // getUsers();
        // contansClass();
        // getNextUserType();
        const nPrevut = window.sessionStorage.getItem("tech_sh");
        setPrevuser(nPrevut ? JSON.parse(nPrevut) : []);
    }, [params.user_id, MainBalance]);

    const [validationError, SetValidationError] = useState("");
    const [addedUser, SetAddedUser] = useState("");

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        console.log(formData)
    };
    function notify(message, type) {

    }
    const handleSubmit = async (e) => {
        document.querySelector("#message p").innerHTML = "";

        e.preventDefault();
        let checkval = await checkValidation();
        if (checkval) {
            // document.querySelector("#message").style.backgroundColor = "green";
            document.getElementById("message").style.display = "none";
            document.querySelector(".add_user_btn").innerHTML = "loading...";
            document.querySelector(".add_user_btn").disabled = true;
            formData.registration_date = moment(formData.registration_date).format(
                "YYYY-MM-DD"
            );
            formData.sessional_commision = formData.master_commision;
            formData.casino_commision = formData.master_commision;
            formData.teenpati_partnership = formData.partnership;
            formData.casino_partnership = formData.partnership;
            formData.user_type = params.user_type ? currentname(params.user_type) : getNextUserType();
            formData.master_id = params.user_id ? params.user_id : userInfo._id;
            var axios = require("axios");
            var data = JSON.stringify(formData);
            var successMesg;
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}users/addUsers`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {

                    refetch();
                    // getUsers();
                    if (response.data.result) {
                        setShow(false);
                        notify(response.data.resultMessage, "success");
                        successMesg = "User Added";
                        document.querySelector("#message").style.backgroundColor = "green";
                        document.getElementById("message").style.display = "block";
                        document.querySelector("#message p").innerHTML = response.data.resultMessage;
                        SetAddedUser(successMesg);
                        setOpens(false);
                        document.querySelector(".add_user_btn").innerHTML = "Create";
                        document.querySelector(".add_user_btn").disabled = false;
                        formData.name = "";
                        formData.user_name = "";
                        formData.password = "";
                        formData.master_commision = "";
                        formData.deposite_bal = "";
                        formData.partnership = "";
                        formData.casino_partnership = "";
                    }
                    else {
                        document.getElementById("message").style.display = "block";
                        document.querySelector("#message").style.backgroundColor = "red";
                        document.querySelector("#message p").innerHTML = response.data.resultMessage;
                        document.querySelector(".add_user_btn").innerHTML = "Create";
                        document.querySelector(".add_user_btn").disabled = false;
                    }

                    //        console.log(JSON.stringify(response.data));

                })
                .catch(function (error) {
                    notify("Some Error Occured", "error");
                    console.log(error);
                });

        }
        else {
            document.querySelector("#message").style.backgroundColor = "red";

        }
        setTimeout(function () {
            closeMessage();
        }, 3000);

    };
    async function checkUserNameExist(user_name) {


        var data = JSON.stringify({
            user_name: user_name,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/checkExistUserName`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let val = axios(config)
            .then(function (response) {
                if (!response.data.result) {


                    return false;


                }
                else {

                    return true;


                }


            })
            .catch(function (error) {
                console.log(error);
            });
        return val;
    }
    async function checkValidation() {
        let errorText;


        document.getElementById("message").style.display = "block";
        document.getElementById("message").style.backgroundColor = "red";
        // if (formData.email == "") {
        //     errorText = formData.email.trim() != "" ? "" : "The field is error format: Email";
        //     document.querySelector("#message p").innerHTML = errorText;
        //     return false;
        // }

        if (formData.user_name == "") {
            errorText = formData.user_name.trim() != "" ? "" : "Username is only allow a-z and 0-9.";
            document.querySelector("#message p").innerHTML = errorText;
            // SetValidationError(errorText);
            return false;
        }
        // else if (formData.user_name.length > 0) {
        //     let res = await checkUserNameExist(formData.user_name.trim());
        //     if (!res) {
        //         errorText = "Username Already Exist";
        //         SetValidationError(errorText);
        //         return false;
        //     }
        // }
        else if (formData.password == "") {
            errorText = formData.password.trim() != "" ? "" : "Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..): Password"
            document.querySelector("#message p").innerHTML = errorText;
            // SetValidationError(errorText);
            return false;


        }
        else if (formData.cpassword == "") {
            errorText = formData.cpassword.trim() != "" ? "" : "New Password and Confirm Password Fields do not match"
            document.querySelector("#message p").innerHTML = errorText;
            // SetValidationError(errorText);
            return false;


        }
        // else if (formData.master_commision == "") {
        //     errorText = formData.master_commision.trim() != "" ? "" : "Enter Commission"
        //     document.querySelector("#message p").innerHTML = errorText;

        //     return false;


        // }
        else {

            return true;
        }



        // if(formData.user_name!=""){
        //     errorText= formData.password.trim()!=""? "":"Password is empty"
        // } if(formData.user_name!=""){
        //     errorText= formData.password.trim()!=""? "":"Password is empty"
        // }
        // if(errorText==""){
        //     return true;
        // }
        // else{
        //     return false;
        // }
    }
    function removeshort() {
        let ts = window.sessionStorage.getItem("tech_sh");
        if (ts) {
            ts = JSON.parse(window.sessionStorage.getItem("tech_sh"))
            if (ts.length > 0) {

                let newArr = [];
                let flag = false;
                ts.map((val) => {

                    if (!flag) {
                        newArr.push(val);
                    }
                    if (val._id == params.user_id) {
                        flag = true;
                    }
                })

                // console.log("tempTs", newArr);
                if (!params.user_id) {
                    let Arr = window.sessionStorage.setItem(`tech_sh`, "");
                } else {
                    let Arr = window.sessionStorage.setItem(`tech_sh`, JSON.stringify(newArr));
                }

            }
        }
    }
    function userShortCut(url, userdata) {
        userdata.url = url;
        let ts = window.sessionStorage.getItem("tech_sh");
        console.log(ts);
        let nArr = [];
        if (ts) {
            ts = JSON.parse(window.sessionStorage.getItem("tech_sh"));
            if (ts.length > 0) {
                nArr = ts;
                nArr.push(userdata);
            } else {
                nArr.push(userdata);
            }
        } else {
            nArr.push(userdata);

        }
        let Arr = window.sessionStorage.setItem(`tech_sh`, JSON.stringify(nArr));

        // const tabIndex = JSON.parse();
    }

    const getUsersNew = () => {
        console.log('REQUEST START',new Date());
        let config = JSON.stringify({
            master_id: params.user_id ? params.user_id : userInfo._id,
            user_type: params.user_type ? params.user_type : params.user_type,
            page: "AddAccount"
        })
        return axios.post(`${Appconfig.apiUrl}users/getUsersTest`, config, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        console.log('REQUEST END',new Date(),data.data);

        setMainBalance(0)
        setUserList(data.data);
        // console.log('data.data',data.data);
        setFilterList(data.data);
        setAccountStatus("ACTIVE");
        // requestSearch("ACTIVE", data.data);
    }
    const { isLoading, isError, error, data, refetch } = useQuery(['userData', params.user_id ? params.user_id : userInfo._id], getUsersNew
        , { onSuccess })

    isLoading ? loader_default() : loader_remove();

    console.log('userList',userList)
    console.log('FilterList',FilterList);
    function updateTotalBalance(bal) {
        totalBalance += parseFloat(bal);
        if (document.querySelector("#totalBalance")) {
            document.querySelector("#totalBalance").textContent = `INR ${totalBalance.toFixed(2)}`;
        }

    }
    function updateTotalExposure(bal) {
        totalExposure += parseFloat(bal);

        if (document.querySelector("#totalExposure")) {
            document.querySelector("#totalExposure").innerHTML = `<div>INR ${totalExposure < 0 ? `<span class="red">(${parseFloat(Math.abs(totalExposure)).toFixed(2)})</span>` : totalExposure.toFixed(2)}</div>`;
        }
        if (document.querySelector("#totalAvailBal")) {
            document.querySelector("#totalAvailBal").textContent = `INR ${((parseFloat(totalBalance) - Math.abs(totalExposure))).toFixed(2)}`;
        }
        if (document.querySelector("#mastersAvailBal")) {
            if (MainBalance === 0) {
                document.querySelector("#mastersAvailBal").textContent = "Loading ..."
            }
            else {
                document.querySelector("#mastersAvailBal").textContent = `INR ${((parseFloat(MainBalance) + parseFloat(totalBalance) - Math.abs(totalExposure))).toFixed(2)}`;
            }
        }
    }
    function getNews() {

        var data = JSON.stringify({
            "site_id": "60fc5aca67e1e3799230a6f7"
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}news/getNews`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setNews(response.data.resultData);

                let tmpUserList = response.data.resultData;
                const newUserList = tmpUserList.map((News, index) => {
                    tmpUserList[index]["id"] = index + 1;
                });
                //   console.log(tmpUserList);
                setNews(tmpUserList);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getMembersDataUsersData(userId) {
        // var user_type = params.user_type;
        var data = JSON.stringify({
            user_id: userId ? userId : uidQuery,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                // setMemberUserData(response.data);

                let tmpUserList = response.data;
                let tempSetData = tmpUserList.resultData[0];

                setMemberUserData(tempSetData);

                disableClassActive(tempSetData);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function disableClassActive(memeberUserData) {

        var betopen = memeberUserData.is_betting_open;
        var locked = memeberUserData.is_locked;
        if (betopen == "Yes" && locked == "No") {
            setDisableActive("disable");
        }
        if (betopen == "No" || locked == "Yes") {
            setDisableActive("active");
        }
        if (locked == "Yes") {
            setDisableLocked("disable");
        }
        if (locked == "No") {
            setDisableLocked("active");
        }
        if (betopen == "No") {
            setDisableSuspend("disable");
        }
        if (betopen == "Yes") {
            setDisableSuspend("active");
        }
    }
    function getUserInfoPopup(popupAction, userId) {
        getMembersDataUsersData(userId);
        setSettingsShow(popupAction);

    }

    function getBalance() {

        var data = JSON.stringify({
            user_id: params.user_id ? params.user_id : userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {

                if (response.data.result == 0) {
                } else {

                    // setExposure(parseFloat(response.data.resultData.exposure).toFixed(2));
                    setMainBalance(parseFloat(response.data.resultData.balance).toFixed(2));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    // function contansClass() {
    //     var actvieOpen = document.getElementById("activeBtn");
    //     var suspendOpen = document.getElementById("suspendBtn");
    //     var lockedOpen = document.getElementById("lockedBtn");

    //     var activeCheck = actvieOpen.classList.contains("disable");
    //     var suspendCheck = suspendOpen.classList.contains("disable");
    //     var lockedCheck = lockedOpen.classList.contains("disable");

    //     if (activeCheck == true) {
    //         actvieOpen.setAttribute("disabled", "disabled")
    //     }
    //     if (suspendCheck == true ) {
    //          suspendOpen.setAttribute("disabled", "disabled")
    //     }
    //     if (lockedCheck == true ) {
    //         lockedOpen.setAttribute("disabled", "disabled")
    //     }


    // }
    function handleActiveChange(clickType) {

        var activeDataID = document.getElementById("activeBtn").getAttribute("data-id");
        var suspendDataID = document.getElementById("suspendBtn").getAttribute("data-id");
        var lockedDataID = document.getElementById("lockedBtn").getAttribute("data-id");

        var actvieOpen = document.getElementById("activeBtn");
        var suspendOpen = document.getElementById("suspendBtn");
        var lockedOpen = document.getElementById("lockedBtn");

        if (activeDataID == 0) {
            if (clickType == "data-active") {
                actvieOpen.classList.add("open");
                actvieOpen.setAttribute("data-id", "1");

                suspendOpen.setAttribute("data-id", "0");
                lockedOpen.setAttribute("data-id", "0");
                suspendOpen.classList.remove("open");
                lockedOpen.classList.remove("open");
            }
        }
        if (activeDataID == 1) {
            if (clickType == "data-active") {
                actvieOpen.classList.remove("open");
                actvieOpen.setAttribute("data-id", "0");
            }
        }

        if (suspendDataID == 0) {
            if (clickType == "data-suspend") {
                suspendOpen.classList.add("open");
                suspendOpen.setAttribute("data-id", "1");

                actvieOpen.setAttribute("data-id", "0");
                lockedOpen.setAttribute("data-id", "0");
                actvieOpen.classList.remove("open");
                lockedOpen.classList.remove("open");

            }
        }
        if (suspendDataID == 1) {
            if (clickType == "data-suspend") {
                suspendOpen.classList.remove("open");
                suspendOpen.setAttribute("data-id", "0");
            }
        }


        if (lockedDataID == 0) {
            if (clickType == "data-locked") {
                lockedOpen.classList.add("open");
                lockedOpen.setAttribute("data-id", "1");

                actvieOpen.setAttribute("data-id", "0");
                suspendOpen.setAttribute("data-id", "0");
                actvieOpen.classList.remove("open");
                suspendOpen.classList.remove("open");


            }
        }
        if (lockedDataID == 1) {
            if (clickType == "data-locked") {
                lockedOpen.classList.remove("open");
                lockedOpen.setAttribute("data-id", "0");
            }
        }






    }

    function checkActiveSuspend() {
        var activecheck = document.getElementById("activeBtn").getAttribute("data-status");
        var suspendcheck = document.getElementById("suspendBtn").getAttribute("data-status");
        var lockedcheck = document.getElementById("lockedBtn").getAttribute("data-status");

        var disableActive = document.getElementById("activeBtn").classList.contains("disable");
        var suspendActive = document.getElementById("suspendBtn").classList.contains("disable");
        var lockedActive = document.getElementById("lockedBtn").classList.contains("disable");
    }
    function checkPasswordCorrect() {
        var oldPassword = userInfo.password
        var newSubPass = document.getElementById("changeStatusPassword").value;

        if (oldPassword != newSubPass) {
            document.getElementById("incorrectPass").innerHTML = "Password is Incorrect";
            return false
        }
        if (oldPassword == newSubPass) {
            document.getElementById("incorrectPass").innerHTML = "";
            return true
        }
    }
    const handleUpdateUserControl = (e) => {
        e.preventDefault();

        if (document.getElementById("suspendBtn").getAttribute("data-id") == 1) {
            var data = JSON.stringify({
                "user_id": uidQuery,
                "change_status": true,
                "UpdateField": {
                    "is_betting_open": "No",
                    "is_locked": "No",
                }
            });
        }
        if (document.getElementById("activeBtn").getAttribute("data-id") == 1) {
            var data = JSON.stringify({
                "user_id": uidQuery,
                "change_status": true,
                "UpdateField": {

                    "is_betting_open": "Yes",
                    "is_locked": "No",
                }
            });
        }
        if (document.getElementById("lockedBtn").getAttribute("data-id") == 1) {
            var data = JSON.stringify({
                "user_id": uidQuery,
                "change_status": true,
                "UpdateField": {
                    "is_betting_open": "No",
                    "is_locked": "Yes",
                }
            });
        }

        if (checkPasswordCorrect()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/updateUserInfo`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {

                    if (response.data.result) {
                        // notify(response.data.message, 'success');
                        console.log('Exposure Update Success');
                    }
                    else {
                        notify(response.data.message, 'error');
                    }
                    // console.log(JSON.stringify(response.data));
                    getMembersDataUsersData();
                    // getUsers();
                    refetch();
                    setSettingsShow(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    const handleSearch = (event) => {
        setSearched(event.target.value);
    }

    function getSearchButtton() {

        var user_type = params.user_type;
        var data = JSON.stringify({
            master_id: userInfo._id,
            user_type: user_type,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUsers`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                let tmpUserList = response.data;
                const newUserList = tmpUserList.map((chipList, index) => {
                    tmpUserList[index]["id"] = index + 1;
                });
                //         console.log("data new", tmpUserList);
                setUserList(tmpUserList);
                //        console.log('userList', response.data);
            })
            .catch(function (error) {
                //            console.log(error);
            });

    }
    // const handleFilterUser = (e) => {
    //     e.preventDefault();
    //     alert(userSearch.userId);

    //     var user_type = params.user_type;
    //     var data = JSON.stringify({
    //         master_id: userInfo._id,
    //         user_type: user_type,
    //     });
    //     //   console.log(data);
    //     var config = {
    //         method: "post",
    //         url: `${Appconfig.apiUrl}users/getUsers`,
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,

    //     };
    //     axios(config)
    //         .then(function (response) {
    //             setUserList(response.data);

    //             let tmpUserList = response.data;
    //             const newUserList = tmpUserList.map((chipList, index) => {
    //                 tmpUserList[index]["id"] = index + 1;
    //             });
    //             //         console.log("data new", tmpUserList);
    //             setUserList(tmpUserList);
    //             //        console.log('userList', response.data);
    //         })
    //         .catch(function (error) {
    //             //            console.log(error);
    //         });


    // }
    async function requestSearch(aStatus = null, data = null) {
        // alert('Here');
        let filteredRows = [];
        let accountStatus1;
        if (aStatus) {
            accountStatus1 = aStatus;
        }
        else {
            accountStatus1 = accountStatus;
        }
        let userListTemp = userList;
        if (data) {
            userListTemp = data;
        }
        console.log("aStatus", accountStatus1);
        if (accountStatus1 == "ALL") {

            filteredRows = await userListTemp.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase());
            });
        }
        if (accountStatus1 == "SUSPENDED") {

            filteredRows = await userListTemp.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase()) && row.is_betting_open == "No";
            });
        }
        if (accountStatus1 == "LOCKED") {
            filteredRows = await userListTemp.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase()) && row.is_locked == "Yes";
            });

        }
        if (accountStatus1 == "ACTIVE") {

            filteredRows = await userListTemp.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase()) && row.is_betting_open == "Yes";
            });

        }

        setFilterList(filteredRows);

    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    function showReferenceModal(user_id, index) {
        setCurrentUserCreditRef(FilterList[index].credit_reference)
        setUserRefData({
            ...userRefData,
            user_id: user_id,
            user_name: FilterList[index].user_name
        })
        document.querySelector("#creditReferenceModel").style.display = "block";
    }
    const closeReferenceModal = () => {
        document.querySelector("#creditReferenceModel").style.display = "none";
    }
    const handleSubmitCredRef = () => {

        var data = JSON.stringify(userRefData);
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/changeCredRef`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                // getUsers();
                refetch();
                closeReferenceModal();
                // console.log("userRefData", response);
            })
            .catch(function (error) {
                //            console.log(error);
            });

    }

    return (
        <div className="dashboard">
            <div id="poker_loading" className="loading-wrap" style={{ 'marginTop': '50px' }}>
                <ul className="loading">
                    <li><img src={Loader} /></li>
                    <li>Loading…</li>
                </ul>
            </div>
            <div className=""></div>
            {
                settingsShow ?
                    <div id="createModal" className="pop_bg">
                        <h3>{validationError}</h3>
                        {memeberUserData ?
                            <div className="pop_box ">
                                <Link className="close_pop" to="#" onClick={() => setSettingsShow(false)}>close_pop</Link>
                                <h3>Change Status</h3>
                                <div className="status_id">
                                    <p id="changeAccount"><span className="lv_4">PL</span>{memeberUserData.user_name}</p>

                                    {memeberUserData.is_betting_open == "No" && memeberUserData.is_locked == "No" ?

                                        <p className={memeberUserData.is_betting_open == "Yes" && memeberUserData.is_locked == "No" ? 'status-active' : 'status-inactive'} id="originalStatus">
                                            <img src={activeinactive} />{memeberUserData.is_betting_open == "Yes" && memeberUserData.is_locked == "No" ? 'Active' : 'Suspended'}
                                        </p>

                                        :

                                        <p className={memeberUserData.is_betting_open == "Yes" && memeberUserData.is_locked == "No" ? 'status-active' : 'status-inactive'} id="originalStatus">
                                            <img src={activeinactive} />{memeberUserData.is_betting_open == "Yes" && memeberUserData.is_locked == "No" ? 'Active' : 'Inactive'}
                                        </p>


                                    }



                                </div>
                                <div className="clear"></div>
                                <div className="white-wrap">
                                    <ul id="statusBtn" className="status_but">
                                        <li>
                                            <Link id="activeBtn" className={`but_active ${disableActive}`} to={`?uid=${uidQuery}`} data-id="0" onClick={() => handleActiveChange("data-active")} data-status="data-active">
                                                <span><img className="" src={Trans} /></span>
                                                <span>Active</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link id="suspendBtn" className={`but_suspend ${disableSuspend}`} to={`?uid=${uidQuery}`} data-id="0" onClick={() => handleActiveChange("data-suspend")} data-status="data-suspend">
                                                <span><img className="" src={Trans} /></span>
                                                <span>Suspend</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link id="lockedBtn" className={`but_locked ${disableLocked}`} to={`?uid=${uidQuery}`} data-id="0" onClick={() => handleActiveChange("data-locked")} data-status="data-locked">
                                                <span><img className="" src={Trans} /></span>
                                                <span>Locked</span>
                                            </Link>
                                        </li>
                                        <div className="clear"></div>

                                    </ul>
                                </div>
                                <div className="btn_box inline-form">
                                    <dl className="form_list">
                                        <dt>Password</dt>
                                        <dd>
                                            <input id="changeStatusPassword" type="password" name="password" placeholder="Enter" />
                                        </dd>
                                    </dl>
                                    <div className="btn_box">
                                        <Link id="changeStatusBtn" className="btn-send" onClick={handleUpdateUserControl}>Change</Link>
                                    </div>
                                    <div className="clear"></div>
                                    <span id="incorrectPass"></span>
                                </div>
                            </div>
                            : null}
                    </div>
                    : null
            }
            {
                show ?
                    <div id="createModal" className="pop_bg">
                        <h3>{validationError}</h3>
                        <div className="pop_box ">
                            <Link className="close_pop" to="#" onClick={() => setShow(false)}>close_pop</Link>

                            <h3>Add {params.user_type ? currentname(params.user_type) : getNextUserType()}</h3>
                            <form onSubmit={handleSubmit}>
                                <ul className="half_box add-member-box">
                                    <li className="add_account_box">
                                        <dl className="border_b">
                                            {/* <dt>E-mail</dt>
                                            <dd>
                                                <input id="email" name="email" type="text" onChange={handleInputChange} value={formData.email} placeholder="Enter Email" maxLength="50" />
                                                <span className="must">＊</span>
                                            </dd> */}
                                            <dt>Username</dt>
                                            <dd>
                                                <input id="userName" type="text" name="user_name" onChange={handleInputChange} value={formData.user_name} placeholder="Enter Username" maxLength="16" />
                                                <span className="must">＊</span>
                                                <span id="userNameErrorText" className="error-text" style={displaynone}></span>
                                            </dd>
                                            <dt>Password</dt>
                                            <dd>
                                                <input id="userPassword" type="password" name="password" onChange={handleInputChange} value={formData.password} placeholder="Enter Your Password" />
                                                <span className="must">＊</span>
                                                <span id="passwordErrorText" className="error-text" style={displaynone}></span>
                                            </dd>
                                            <dt>Confirm Password</dt>
                                            <dd>
                                                <input id="repeatPassword" type="password" name="cpassword" onChange={handleInputChange} placeholder="Enter Your Password Again" />
                                                <span className="must">＊</span>
                                                <span id="repeatPasswordErrorText" className="error-text" style={displaynone}></span>
                                            </dd>
                                        </dl>
                                        <dl className="">
                                            <dt>First Name</dt>
                                            <dd>
                                                <input id="firstName" value={formData.name} name="name" onChange={handleInputChange} type="text" placeholder="Enter" maxLength="16" />
                                            </dd>
                                            <dt>Last Name</dt>
                                            <dd>
                                                <input id="lastName" value={formData.last_name} name="last_name" onChange={handleInputChange} type="text" placeholder="Enter" maxLength="16" />
                                            </dd>
                                            <dt>Phone</dt>
                                            <dd>
                                                <input id="phone" value={formData.phone} type="text" placeholder="Enter" name="phone" onChange={handleInputChange} maxLength="16" />
                                            </dd>
                                            {/* {
                                                userInfo.user_type == "Agent" ||
                                                    params.user_type == "user" ?
                                                    <>
                                                        <dt>Exposure Limit</dt>
                                                        <dd>
                                                            <input id="exposer_limit" type="text" value={formData.exposer_limit} name="exposer_limit" onChange={handleInputChange} placeholder="Enter" />
                                                            <span className="must">＊</span>
                                                        </dd>
                                                    </> : ""
                                            } */}
                                            {
                                                userInfo.user_type == "Agent" ||
                                                    params.user_type == "user" ?
                                                    <>
                                                        <dt>Commission(%)</dt>
                                                        <dd>
                                                            <input id="master_commision" type="text" value={formData.master_commision} name="master_commision" onChange={handleInputChange} placeholder="Enter" />
                                                            {/* <span className="must">＊</span> */}
                                                        </dd>
                                                    </> : ""
                                            }


                                            {
                                                userInfo.user_type != "Agent" ?
                                                    params.user_type != "user" ?
                                                        <>
                                                            {/* <dt>Partnership(%)</dt>
                                                            <dd>
                                                                <input id="partnership" type="text" value={formData.partnership} name="partnership" onChange={handleInputChange} placeholder="Enter" />
                                                                <span className="must">＊</span>
                                                            </dd> */}
                                                        </> : "" : ""
                                            }

                                            <dt>Time Zone</dt>
                                            <dd>
                                                <select name="timezone" id="timezone">

                                                    <option defaultValue="Pacific/Midway"

                                                    >Pacific/Midway(GMT-11:00)</option>

                                                    <option defaultValue="Pacific/Honolulu"

                                                    >Pacific/Honolulu(GMT-10:00)</option>

                                                    <option defaultValue="America/Juneau"

                                                    >America/Juneau(GMT-9:00)</option>

                                                    <option defaultValue="America/Los_Angeles"

                                                    >America/Los_Angeles(GMT-8:00)</option>

                                                    <option defaultValue="America/Phoenix"

                                                    >America/Phoenix(GMT-7:00)</option>

                                                    <option defaultValue="America/Chicago"

                                                    >America/Chicago(GMT-6:00)</option>

                                                    <option defaultValue="America/New_York"

                                                    >America/New_York(GMT-5:00)</option>

                                                    <option defaultValue="America/Santiago"

                                                    >America/Santiago(GMT-4:00)</option>

                                                    <option defaultValue="America/Sao_Paulo"

                                                    >America/Sao_Paulo(GMT-3:00)</option>

                                                    <option defaultValue="Atlantic/South_Georgia"

                                                    >Atlantic/South_Georgia(GMT-2:00)</option>

                                                    <option defaultValue="Atlantic/Azores"

                                                    >Atlantic/Azores(GMT-1:00)</option>

                                                    <option defaultValue="Europe/London"

                                                    >Europe/London(GMT+0:00)</option>

                                                    <option defaultValue="Europe/Paris"

                                                    >Europe/Paris(GMT+1:00)</option>

                                                    <option defaultValue="Africa/Cairo"

                                                    >Africa/Cairo(GMT+2:00)</option>

                                                    <option defaultValue="Asia/Qatar"

                                                    >Asia/Qatar(GMT+3:00)</option>

                                                    <option defaultValue="Asia/Dubai"

                                                    >Asia/Dubai(GMT+4:00)</option>

                                                    <option defaultValue="Asia/Karachi"

                                                    >Asia/Karachi(GMT+5:00)</option>

                                                    <option defaultValue="IST"

                                                        selected="selected"

                                                    >IST(Bangalore / Bombay / New Delhi) (GMT+5:30)</option>

                                                    <option defaultValue="Asia/Kathmandu"

                                                    >Asia/Kathmandu(GMT+5:45)</option>

                                                    <option defaultValue="Asia/Dhaka"

                                                    >Asia/Dhaka(GMT+6:00)</option>

                                                    <option defaultValue="Asia/Bangkok"

                                                    >Asia/Bangkok(GMT+7:00)</option>

                                                    <option defaultValue="Asia/Hong_Kong"

                                                    >Asia/Hong_Kong(GMT+8:00)</option>

                                                    <option defaultValue="Asia/Tokyo"

                                                    >Asia/Tokyo(GMT+9:00)</option>

                                                    <option defaultValue="Australia/Adelaide"

                                                    >Australia/Adelaide(GMT+9:30)</option>

                                                    <option defaultValue="Australia/Melbourne"

                                                    >Australia/Melbourne(GMT+10:00)</option>

                                                    <option defaultValue="Asia/Magadan"

                                                    >Asia/Magadan(GMT+11:00)</option>

                                                    <option defaultValue="Pacific/Fiji"

                                                    >Pacific/Fiji(GMT+12:00)</option>

                                                </select>
                                                <span className="must">＊</span>
                                            </dd>
                                        </dl>
                                    </li>
                                    <li className="pt_allowed_box" style={displaynone}>





                                        <div className="pt_allowed" style={displaynone}>
                                            <dl>
                                                <dt className="dt_w50">PT Allowed To Master Agent(%)</dt>
                                                <dd value="0"><input id="memberMaxPT" type="text" placeholder="Enter" defaultValue="0" /> </dd>
                                            </dl>
                                        </div>
                                        <div className="sports_box" style={{ 'height': "350px", 'overflow': "hidden", 'overflowY': "auto" }}>
                                            <p>
                                                My Sports PT Setting
                                                <span id="settingNote" className="note" style={displaynone}>PT setting % value must be multiplier of 5 : </span>
                                            </p>

                                            <ul className="half_box">
                                                <li>
                                                    <div id="sportsSet" className="sports_set">
                                                        <dl className="bg_head">
                                                            <dt>All Sports Set</dt>
                                                            <dd>
                                                                <input id="allowOneMaxPT" type="text" placeholder="Enter" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#" name="copy_one_up">up</Link></li>
                                                                    <li><Link className="down" to="/#" name="copy_one_down">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>




                                                        </dl>

                                                        <dl className="">
                                                            <dt>Soccer</dt>

                                                            <dd className="" >
                                                                <input id="soccer_PT" type="text" name="normal" data-type="1" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Tennis</dt>

                                                            <dd className="" >
                                                                <input id="tennis_PT" type="text" name="normal/" data-type="2" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="">
                                                            <dt>Cricket</dt>

                                                            <dd className="" >
                                                                <input id="cricket_PT" type="text" name="normal/" data-type="4" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Rugby Union</dt>

                                                            <dd className="" >
                                                                <input id="rugby_union_PT" type="text" name="normal/" data-type="5" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="">
                                                            <dt>Horse Racing</dt>

                                                            <dd className="" >
                                                                <input id="horse_racing_PT" type="text" name="normal/" data-type="7" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Greyhound Racing</dt>

                                                            <dd className="" >
                                                                <input id="greyhound_racing_PT" type="text" name="normal/" data-type="4339" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="">
                                                            <dt>American Football</dt>

                                                            <dd className="" >
                                                                <input id="american_football_PT" type="text" name="normal/" data-type="6423" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Basketball</dt>

                                                            <dd className="" >
                                                                <input id="basketball_PT" type="text" name="normal/" data-type="7522" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="">
                                                            <dt>Politics</dt>

                                                            <dd className="" >
                                                                <input id="politics_PT" type="text" name="normal/" data-type="2378961" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Kabaddi </dt>

                                                            <dd className="" >
                                                                <input id="kabaddi_PT" type="text" name="normal/" data-type="138" defaultValue="0" />
                                                            </dd>
                                                            <div className="tips-popup" style={displaynone}>multiplier of 5</div>



                                                        </dl>

                                                    </div>
                                                    <p>My PT Allowed <strong id="yourPT"></strong>%</p>
                                                </li>

                                                <li>

                                                    <div id="fancyBetSet" className="sports_set"
                                                        style={{ 'display': "none", 'height': "150px" }}>
                                                        <dl className="bg_head">
                                                            <dt>FancyBet Setting</dt>
                                                        </dl>

                                                        <dl className="">
                                                            <dt>Super</dt>
                                                            <dd>
                                                                <input id="company_fancy_bet_PT" type="text" placeholder="Enter" data-type="company" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>

                                                        <dl className="bg_dark" id="fancyBetSSSPt">
                                                            <dt>SSS</dt>
                                                            <dd>
                                                                <input id="sss_fancy_bet_PT" type="text" placeholder="Enter" data-type="sss" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>

                                                        <dl className="" id="fancyBetDirectLevelPt">
                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="shareHolder_fancy_bet_PT" type="text" placeholder="Enter" data-type="shareHolder" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <p id="fancyBetTotalPTCol" style={displaynone}>
                                                        FancyBet total PT allowed <strong id="fancyBetTotalPT"></strong>%
                                                    </p>
                                                </li>

                                                <li>
                                                    <div id="bookMakerSet" className="sports_set"
                                                        style={{ 'display': "none", 'height': "115px" }}>
                                                        <dl className="bg_head">
                                                            <dt>BookMaker Setting</dt>
                                                        </dl>

                                                        <dl className="">
                                                            <dt>Super</dt>
                                                            <dd>
                                                                <input id="company_book_maker_PT" type="text" placeholder="Enter" data-type="company" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>

                                                        <dl className="bg_dark" id="bookMakerDirectLevelPt">
                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="shareHolder_book_maker_PT" type="text" placeholder="Enter" data-type="shareHolder" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <p id="bookMakerTotalPTCol" style={displaynone}>
                                                        BookMaker total PT allowed <strong id="bookMakerTotalPT"></strong>%
                                                    </p>
                                                </li>

                                                <li>
                                                    <div id="sportsbookTennisSet" className="sports_set" style={{ 'display': "none", 'height': "115px" }}>
                                                        <dl className="bg_head">
                                                            <dt>SportsBook</dt>
                                                            <dd><Link className="toggle_on" to="/#" id="edit_allow_sportsbookTennis">ON</Link></dd>
                                                        </dl>

                                                        <dl className="">
                                                            <dt>Super</dt>
                                                            <dd>
                                                                <input id="company_sportsbook_tennis_PT" type="text" placeholder="Enter" data-type="company" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="shareHolder_sportsbook_tennis_PT" type="text" placeholder="Enter" data-type="shareHolder" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <p style={displaynone}>
                                                        Sportsbook total PT allowed <strong id="sportsbookTennisTotalPT"></strong>%
                                                    </p>
                                                </li>

                                                <li>
                                                    <div id="sportsbookPremiumCricketSet" className="sports_set"
                                                        style={displaynone}>
                                                        <dl className="bg_head">
                                                            <dt>SportsBook - Premium</dt>
                                                            <dd><Link className="toggle_on" to="/#" id="edit_allow_sportsbookPremiumCricket">ON</Link></dd>
                                                        </dl>

                                                        <dl className="">
                                                            <dt>Super
                                                            </dt>
                                                            <dd>
                                                                <input id="company_sportsbookPremiumCricket_PT" type="text/"

                                                                    placeholder="Enter" data-type="company" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>
                                                                    multiplier of 5
                                                                </div>
                                                            </dd>

                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Master Agent
                                                            </dt>
                                                            <dd>
                                                                <input id="shareHolder_sportsbookPremiumCricket_PT" type="text/"

                                                                    placeholder="Enter" data-type="shareHolder" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>
                                                                    multiplier of 5
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <p style={displaynone}>
                                                        Premium total PT allowed <strong id="sportsbookPremiumCricketTotalPT"></strong>%
                                                    </p>
                                                </li>


                                                <li>
                                                    <div id="financialBinarySet" className="sports_set"
                                                        style={displaynone}>
                                                        <dl className="bg_head">
                                                            <dt>Binary Setting</dt>
                                                        </dl>

                                                        <dl className="">
                                                            <dt>Super
                                                            </dt>
                                                            <dd>
                                                                <input id="company_financialBinary_PT" type="text/"

                                                                    placeholder="Enter" data-type="company" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>
                                                                    multiplier of 5
                                                                </div>
                                                            </dd>

                                                        </dl>

                                                        <dl className="bg_dark">
                                                            <dt>Master Agent
                                                            </dt>
                                                            <dd>
                                                                <input id="shareHolder_financialBinary_PT" type="text/"

                                                                    placeholder="Enter" data-type="shareHolder" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>
                                                                    multiplier of 5
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <p style={displaynone}>
                                                        Binary total PT allowed <strong id="financialBinaryTotalPT"></strong>%
                                                    </p>
                                                </li>

                                                <li>
                                                    <div id="casinoSet" className="sports_set" style={displaynone}>
                                                        <dl className="bg_head">
                                                            <dt>Casino Setting</dt>
                                                            <dd style={displaynone}>
                                                                <Link className="toggle_on" to="/#" id="new_allow_casino">ON</Link>
                                                            </dd>
                                                        </dl>

                                                        <dl className="">
                                                            <dt>Live</dt><br />
                                                            <dt>Super</dt>

                                                            <dd>
                                                                <input id="live_agent_casino_PT" type="text" placeholder="Enter" data-type="agent" data-categorytype="live" defaultValue="100" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>

                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="live_downline_casino_PT" type="text" placeholder="Enter" data-type="downline" data-categorytype="live" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                        <p style={displaynone} >
                                                            LIVE total PT allowed <strong id="casino_live_TotalPT"></strong>%
                                                        </p>

                                                        <dl className="bg_dark">
                                                            <dt>R&G</dt><br />
                                                            <dt>Super</dt>

                                                            <dd>
                                                                <input id="rng_agent_casino_PT" type="text"
                                                                    placeholder="Enter" data-type="agent" data-categorytype="rng" defaultValue="100" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>

                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="rng_downline_casino_PT" type="text"
                                                                    placeholder="Enter" data-type="downline" data-categorytype="rng" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                        <p style={displaynone} >
                                                            RNG total PT allowed <strong id="casino_rng_TotalPT"></strong>%
                                                        </p>

                                                        <dl className="">
                                                            <dt>SLOT</dt><br />
                                                            <dt>Super</dt>

                                                            <dd>
                                                                <input id="slot_agent_casino_PT" type="text"
                                                                    placeholder="Enter" data-type="agent" data-categorytype="slot" defaultValue="100" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>

                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="slot_downline_casino_PT" type="text"
                                                                    placeholder="Enter" data-type="downline" data-categorytype="slot" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                        <p style={displaynone} >
                                                            SLOT total PT allowed <strong id="casino_slot_TotalPT"></strong>%
                                                        </p>

                                                        <dl className="bg_dark">
                                                            <dt>Virtual Sports</dt><br />
                                                            <dt>Super</dt>

                                                            <dd>
                                                                <input id="virtual_agent_casino_PT" type="text"
                                                                    placeholder="Enter" data-type="agent" data-categorytype="virtual" defaultValue="100" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>

                                                            <dt>Master Agent</dt>
                                                            <dd>
                                                                <input id="virtual_downline_casino_PT" type="text"
                                                                    placeholder="Enter" data-type="downline" data-categorytype="virtual" defaultValue="0" />
                                                                <ul className="odd-add">
                                                                    <li><Link className="up" to="/#">up</Link></li>
                                                                    <li><Link className="down" to="/#">down</Link></li>
                                                                </ul>
                                                                <div className="tips-popup" style={displaynone}>multiplier of 5</div>
                                                            </dd>
                                                        </dl>
                                                        <p style={displaynone} >
                                                            VIRTUAL total PT allowed <strong id="casino_virtual_TotalPT"></strong>%
                                                        </p>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_box">
                                    <button id="createBtn" className="btn-send add_user_btn">Create</button>
                                </div>

                            </form>
                        </div>
                    </div> : null
            }
            {/* Banking Pop Start */}
            <div id="bankingModal" className="pop_bg" style={displaynone}>
                <div className="pop_box">
                    {/* Loading Wrap */}
                    <div id="bankingModalLoading" className="overlay">
                        {/* add style="display:block" to show loading */}
                        <div className="loading-wrap" style={{ 'display': "block" }}>
                            <ul className="loading">
                                <li><img src="/images/loading40.gif" /></li>
                                <li>Loading…</li>
                            </ul>
                        </div>
                    </div>
                    <Link className="close_pop" to="/#" >close_pop</Link>

                    <h3>Edit Credit</h3>
                    <ul className="tab col2">
                        <li><Link id="deposit" to="/#">Credit IN
                        </Link></li>
                        <li><Link id="withdraw" to="/#">Credit OUT</Link></li>
                    </ul>
                    <div className="total_box">
                        <dl className="total_dl">
                            <dt id="bankingTitle"></dt>
                            <dd id="displayName"></dd>
                        </dl>
                        <dl id="bankingBalanceDL" className="total_dl" style={displaynone}>
                            <dt>Balance</dt>
                            <dd id="bankingBalance"></dd>
                        </dl>
                        <dl id="bankingCreditPLDL" className="total_dl" style={displaynone}>
                            <dt>Balance
                            </dt>
                            <dd id="bankingCreditPL"></dd>
                        </dl>
                        <dl id="bankingCreditLimitDL" className="total_dl" style={displaynone}>
                            <dt>Credit Limit
                            </dt>
                            <dd id="editCreditLimit"></dd>
                        </dl>
                    </div>
                    <dl className="form_list">
                        <dt>Amount</dt>
                        <dd id="allAmountOption" className="radio_enter">
                            <input id="allAmount" name="bankingAmount" className="radio" type="radio" defaultValue="all" />
                            <label htmlFor="allAmount">All</label>
                        </dd>
                        <dd id="cusAmountOption" className="radio_enter">
                            <input id="cusAmount" name="bankingAmount" className="radio" type="radio" defaultValue="cus" />
                            <input id="amount" type="text" placeholder="Enter" />
                            <span id="bankingAmountMust" className="must">＊</span>
                        </dd>
                        <dt>Password</dt>
                        <dd>
                            <input id="bankingPassword" type="password" placeholder="Enter" />
                            <span className="must">＊</span>
                        </dd>
                        <dt>Remarks</dt>
                        <dd>
                            <textarea id="remarks" type="text" cols="45" rows="5" placeholder="Enter"></textarea>
                        </dd>
                        <dd><Link id="bankingBtn" className="btn-send" to="/#"></Link></dd>
                    </dl>

                </div>
            </div>


            {/* Change status pop start */}
            <div id="changeStatusModal" className="pop_bg" style={displaynone}>
                <div className="pop_box">
                    <Link className="close_pop" to="/#">close_pop</Link>
                    <h3>Change Status</h3>
                    <div className="status_id">
                        <p id="changeAccount"></p>
                        <p className="status-suspend" id="originalStatus"></p>
                    </div>

                    <div className="white-wrap">

                        <ul id="statusBtn" className="status_but">
                            <li>
                                <Link id="activeBtn" className="but_active" to="/#" data-status="active">
                                    <img className="" src={Trans} />
                                    Active
                                </Link>
                            </li>
                            <li>
                                <Link id="suspendBtn" className="but_suspend" to="/#" data-status="suspend">
                                    <img className="" src={Trans} />
                                    Suspend
                                </Link>
                            </li>
                            <li>
                                <Link id="lockedBtn" className="but_locked" to="/#" data-status="locked">
                                    <img className="" src={Trans} />
                                    Locked
                                </Link>
                            </li>
                        </ul>

                    </div>

                    <div className="suspend-wrap" id="maxWinLossSuspendDiv" style={displaynone}>
                        <div className="status_id" id="maxWinLossSuspendStatus">

                        </div>

                        <div className="fix-content">
                            <p id="maxLossSuspendInfo" style={displaynone}>Please change downline Max Loss Limit over the total loss or executed on Transferable P/L, before you unsuspend.</p>
                            <p id="maxWinSuspendInfo" style={displaynone}>Please change downline Max Win Limit over the total win or executed on Transferable P/L, before you unsuspend.</p>

                            <ul className="status_but">
                                <li>
                                    <Link className="but_suspend" id="maxLossUnSuspendBtn" to="/#">Unsuspend <strong>Loss</strong> Limit</Link>
                                </li>
                                <li>
                                    <Link className="but_suspend" id="maxWinUnSuspendBtn" to="/#">Unsuspend <strong>Win</strong> Limit</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="suspend-wrap" id="passLockDiv" style={displaynone}>
                        <div className="sys-lock status_id">
                            <p className="status-lock"><img src={Trans} />Passlocked</p>
                        </div>

                        <div className="fix-content">
                            <ul className="status_but">
                                <li>
                                    <Link className="but_locked" id="unPassLockBtn" to="/#">Unlock</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="sys-suspend status_id" id="systemSuspendMessage" style={displaynone}>
                        <p className="status-suspend"><img src={Trans} />System Suspend</p>
                    </div>

                    <div className="sys-lock status_id" id="systemLockedMessage" style={displaynone}>
                        <p className="status-lock"><img src={Trans} />System Locked</p>
                    </div>

                    <div className="btn_box inline-form">
                        <dl className="form_list">

                            <dt>Password
                            </dt>
                            <dd>
                                <input id="changeStatusPassword" type="password" placeholder="Enter" />
                            </dd>
                        </dl>
                        <div className="btn_box">
                            <Link id="changeStatusBtn" className="btn-send" to="/#">Change
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div id="creditReferenceModel" className="pop_bg" style={displaynone}>
                <div className="pop_refer">
                    <Link className="close_pop" onClick={closeReferenceModal}>close_pop</Link>
                    <h3>Credit Reference Edit</h3>

                    <div className="wrap-refer_edit">
                        <dl>
                            <dt>Current</dt>
                            <dd>
                                <Link className="btn" id="creditReferenceLog" href='javascript:void(0)' onClick={() => window.open(`/oldCredits/${userRefData.user_name}/${userRefData.user_id}`, "MsgWindow", "width=600,height=900")}>Log</Link>
                                <strong id="creditReference">{currentUserCreditRef}</strong>
                            </dd>
                        </dl>

                        <dl>
                            <dt>New</dt>
                            <dd><input type="text" id="newCreditReference" name="credit_reference" onChange={handleCredRefChange} placeholder="Enter" /></dd>
                        </dl>

                        <dl>
                            <dt>Password</dt>
                            <dd>
                                <input id="changeCreditReferencePassword" name='password' onChange={handleCredRefChange} type="password" placeholder="Enter" /></dd>
                        </dl>
                    </div>
                    <ul className="btn-wrap">
                        <li style={{ display: "grid" }}><a id="changeCreditReferenceBtn" className="btn-send" href="javascript:void(0)" onClick={handleSubmitCredRef}>Submit</a></li>
                    </ul>
                </div>
            </div>

            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div id="mainWrap" className="main_wrap">

                <div className="marquee-box">
                    <h4>News</h4>
                    <marquee className="marquee" scrolldelay="100">
                        {News.length > 0 && News.map(newsdata => <Link to="#"><span>{newsdata.createdAt}</span>{newsdata.message}</Link>)}
                    </marquee>
                </div>
                <div className="total_all">

                    <div className="search-wrap" id="userSearchUl">
                        <div>
                            <input className="search-input" onChange={handleSearch} type="text" name="searchusername" id="userId" placeholder="Find member..." />
                            <button className="search-but" onClick={() => requestSearch()} id="searchUserId">Search</button>
                        </div>
                    </div>

                    <ul className="input-list" id="accountStatusUl">
                        <li><strong>Status</strong></li>
                        <li>
                            <select className="" value={accountStatus} name="accountStatus" id="accountStatus" onChange={handleAccountStatus}>

                                <option defaultValue="0" value="ACTIVE">ACTIVE
                                </option>

                                <option defaultValue="1" value="SUSPENDED">SUSPENDED
                                </option>

                                <option defaultValue="2" value="LOCKED">LOCKED
                                </option>

                                <option defaultValue="-1" value="ALL">ALL</option>
                            </select>
                        </li>
                    </ul>



                    <div className="agent_path" style={{ width: "100%", marginTop: 5 }}>
                        <ul id="agentPath" className="agent_path-L" >

                            {prevUser.length > 0 && prevUser.map((row, index) => {
                                return <li id="path6" className="" key={"path" + index}>
                                    <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                        <span className={`lv_${index}`}>
                                            {row.user_type.match(/\b\w/g).join('')}
                                        </span>
                                        <strong>{row.user_name}</strong>
                                    </Link>
                                </li>
                            })}

                        </ul>
                    </div>



                    <Link onClick={() => window.location.reload()} id="refresh" className="btn_replay"><img src={Trans} /></Link>


                    <Link className="add_member" to="#" onClick={() => setShow(true)}><img src={Trans} />Add {params.user_type ? currentname(params.user_type) : getNextUserType()}</Link>

                </div>

                <div id="totalBox" className="total_box">

                    <dl id="creditLimitDL" className="total_dl" style={displaynone}>
                        <dt>Credit Limit</dt>
                        <dd id="myCreditLimit"></dd>
                    </dl>
                    <dl id="creditGivenOutDL" className="total_dl" style={displaynone}>
                        <dt>Total Credit Given Out</dt>
                        <dd id="totalCreditGivenOut"></dd>
                    </dl>
                    <dl id="creditAvailBalDL" className="total_dl" style={displaynone}>
                        <dt>Credit Avail Bal</dt>
                        <dd id="myCreditAvailBal"></dd>
                    </dl>
                    <dl id="totalBalanceDL" className="total_dl">
                        <dt>Total Balance</dt>
                        <dd id="totalBalance">INR {totalBalance}</dd>
                    </dl>
                    <dl id="totalExposureDL" className="total_dl">
                        <dt>Total Exposure</dt>
                        <dd id="totalExposure">{CURRENCY_TYPE} 0.00</dd>
                    </dl>
                    <dl id="myCurrentPLDL" className="total_dl" style={displaynone}>
                        <dt>Today P/L with Upline</dt>
                        <dd id="myCurrentPL">{CURRENCY_TYPE} 0.00</dd>
                    </dl>
                    <dl id="availableBalanceDL" className="total_dl">
                    <dt>Total Avail. bal.</dt>
                        <dd id="totalAvailBal">{CURRENCY_TYPE} 00.00</dd>
                    </dl>
                    <dl id="masterBalanceDL" className="total_dl">
                    <dt>Balance</dt>
           
                        <dd id="mastersBalance"> {MainBalance ? "INR " + MainBalance : "Loading ..."}</dd>
                    </dl>
                    <dl id="masterAvailableBalanceDL" className="total_dl">
                        <dt>Available Balance</dt>
                        <dd id="mastersAvailBal">INR {MainBalance}</dd>
                    </dl>
                    <dl id="transferablePLWithUplineDL" className="total_dl" style={displaynone}>
                        <dt>Transferable P/L with Upline</dt>
                        <dd id="transferablePLWithUpline">{CURRENCY_TYPE} <span className="red">(187.00)</span></dd>
                    </dl>





                </div>

                {/* Center Column */}
                <div id="widthAll" className="width-all">


                    {/* Loading Wrap */}
                    <div id="loading" className="loading-wrap" style={displaynone}>
                        <ul className="loading">
                            <li><img src="/images/loading40.gif" /></li>
                            <li>Loading...</li>
                        </ul>
                    </div>

                    {/* Message */}
                    <div id="message" className="message-wrap">
                        <Link to="#" onClick={closeMessage} className="btn-close">Close</Link>
                        <p></p>
                    </div>


                    {/* Report Table */}
                    <table id="tableTemplate" className="table01 margin-table" style={displaynone}>
                        <tbody>
                            <tr>
                                <th id="accountTh" width="" className="align-L" style={displaynone}>Account</th>
                                <th id="creditRefTh" width="10%" style={displaynone}>Credit Ref.</th>
                                <th id="creditLimitTh" width="10%" style={displaynone}>Credit Limit</th>

                                <th id="currentPLTh" width="10%" style={displaynone}>Current P/L
                                </th>
                                <th id="transferablePLTh" width="10%" style={displaynone}>Transferable P/L
                                </th>
                                <th id="balanceTh" width="10%" style={displaynone}>Balance</th>
                                <th id="exposureTh" width="10%" style={displaynone}>Exposure</th>
                                <th id="availableBalanceTh" width="10%" style={displaynone}>Avail. bal.</th>
                                <th id="creditPLTh" width="10%" style={displaynone}>Balance</th>
                                <th id="playerBalanceTh" width="10%" style={displaynone}>Player Balance</th>
                                <th id="exposureLimitTh" width="10%" style={displaynone}>Exposure Limit</th>

                                <th id="refPLTh" width="10%" style={displaynone}>Ref. P/L</th>
                                <th id="statusTh" width="10%" style={displaynone}>Status</th>
                                <th id="actionTh" width="15%" style={displaynone}>Action</th>
                            </tr>
                            <tr id="dataTemplate" style={displaynone}>
                                <td id="accountCol" style={displaynone} className="align-L">

                                    <Link id="account" className="ico_account" to="/#"></Link>
                                </td>
                                <td id="creditRef" className="credit-amount-member" style={displaynone}><Link id="creditRefBtn" className="favor-set" to="/#"></Link></td>
                                <td id="creditLimit" style={displaynone}></td>

                                <td id="currentPL" style={displaynone}></td>
                                <td id="transferablePL" style={displaynone}></td>
                                <td id="balance" style={displaynone}></td>
                                <td id="currentExposure" style={displaynone}></td>
                                <td id="available" style={displaynone}></td>
                                <td id="creditPL" style={displaynone}></td>
                                <td id="playerBalance" style={displaynone}></td>
                                <td id="exposureLimit" style={displaynone}></td>

                                <td id="refPL" style={displaynone}></td>
                                <td id="statusCol" style={displaynone}>
                                    <ul id="tipsPopup" className="status-popup" style={displaynone}>
                                    </ul>
                                    <span id="status"></span>
                                </td>
                                <td id="actionCol" style={displaynone}>
                                    <ul className="action">
                                        <li>
                                            <Link id="banking" className="bank" to="#bank">Bank</Link>
                                        </li>
                                        <li>
                                            <Link id="p_l" className="p_l" to="/#">Betting Profit & Loss</Link>
                                        </li>
                                        <li>
                                            <Link id="betting_history" className="betting_history" to="/#">Betting History</Link>
                                        </li>
                                        <li>
                                            <Link id="change" className="status" to="#change">Change Status</Link>
                                        </li>
                                        <li>
                                            <Link id="profile" className="profile" to="/#">Profile</Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>






                    <table id="resultTable" className="table01 margin-table" style={{ 'display': "table" }}>
                        <tbody>
                            <tr>
                                <th id="accountTh" width="" className="align-L" >Account</th>
                                <th id="creditRefTh" width="10%" >Credit Ref.</th>
                                <th id="creditLimitTh" width="10%" style={displaynone}>Credit Limit</th>

                                <th id="currentPLTh" width="10%" style={displaynone}>Current P/L
                                </th>
                                <th id="transferablePLTh" width="10%" style={displaynone}>Transferable P/L
                                </th>
                                <th id="balanceTh" width="10%" >Balance</th>
                                <th id="exposureTh" width="10%" >Exposure</th>
                                <th id="availableBalanceTh" width="10%" >Avail. bal.</th>
                                <th id="creditPLTh" width="10%" style={displaynone}>Balance</th>
                                <th id="playerBalanceTh" width="10%" style={displaynone}>Player Balance</th>
                                <th id="exposureLimitTh" width="10%" style={displaynone}>Exposure Limit</th>

                                <th id="refPLTh" width="10%" >Ref. P/L</th>
                                <th id="statusTh" width="10%" >Status</th>
                                <th id="actionTh" width="15%" >Action</th>
                            </tr>

                            {FilterList && FilterList.map((userdata, index) =>

                                <tr className="account-user-data" key={userdata._id} userdata={userdata}>
                                    <td>
                                        <span className="lv_3">{userdata.user_type.match(/\b\w/g).join('')}</span>
                                        {userdata.user_type != "User" ? <Link to={`/dashboard/${userdata._id}/${newGetNextUserType(userdata.user_type.toLowerCase().replace(" ", "_"))}`} onClick={() => userShortCut(`/dashboard/${userdata._id}/${newGetNextUserType(userdata.user_type.toLowerCase().replace(" ", "_"))}`, userdata)}>{userdata.user_name}</Link> : userdata.user_name}</td>

                                    <td id="creditRef0" className="credit-amount-member"  ><a
                                     style={{float:"left"}}
                                    id="creditRefBtn" className="favor-set" href="javascript:void(0);" onClick={() => showReferenceModal(userdata._id, index)} >{userdata.credit_reference ? userdata.credit_reference : "00.00"}</a></td>

                                    <td>{updateTotalBalance(userdata.noDeductbalance)}{userdata.noDeductbalance}</td>
                                    <td>{updateTotalExposure(userdata.exposure)} {parseFloat(userdata.exposure) < 0 ?
                                        <span className="red">({parseFloat(Math.abs(userdata.exposure)).toFixed(2)})</span> : parseFloat(userdata.exposure).toFixed(2)}</td>
                                    <td>{(parseFloat(userdata.noDeductbalance)).toFixed(2) - (parseFloat(Math.abs(userdata.exposure))).toFixed(2)}</td>

                                    <td><span className='inttyped' data-value={userdata.credit_reference ? (parseFloat(userdata.noDeductbalance) - parseFloat(userdata.credit_reference)).toFixed(2) : parseFloat(userdata.noDeductbalance).toFixed(2)}>{Math.abs(userdata.credit_reference ? (parseFloat(userdata.noDeductbalance) - parseFloat(userdata.credit_reference)).toFixed(2) : parseFloat(userdata.noDeductbalance).toFixed(2))}</span></td>

                                    {userdata.is_betting_open == "Yes" && userdata.is_locked == "No" &&
                                        <td>
                                            <span id="status0" className='status-active'>
                                                <img src={activeinactive} />Active</span>
                                        </td>
                                    }
                                    {userdata.is_locked == "Yes" &&
                                        <td>
                                            <span id="status0" className='status-lock'>
                                                <img src={activeinactive} />Lock</span>
                                        </td>
                                    }
                                    {userdata.is_betting_open == "No" && userdata.is_locked == "No" &&
                                        <td>
                                            <span id="status0" className='status-inactive'>
                                                <img src={activeinactive} />Suspended</span>
                                        </td>
                                    }




                                    <td>

                                        <div className="footer_info action_info">
                                            <div className="main_wrap">
                                                <ul className="action">
                                                    {userdata.user_type == "User" ? <> <Link to={`/memberList/bethistory?uid=${userdata._id}`}><li className="betting_history"></li></Link>
                                                        <Link to={`/memberList/profitAndLoss?uid=${userdata._id}`}><li className="p_l"></li></Link></> : ""}
                                                    <Link to={`/profile?uid=${userdata._id}`}><li className="profile"></li></Link>
                                                    <Link to={`?uid=${userdata._id}`}><li className="status" id="settings_popup" onClick={() => getUserInfoPopup(true, userdata._id)}></li></Link>

                                                    {userInfo.user_type === "Super Admin" ?
                                                        ""// <Link to={`/user_gen_settings/${userdata._id}`}><li className="gensetting">i</li></Link>
                                                        : ""}
                                                </ul>
                                            </div>
                                        </div>

                                    </td>
                                </tr>)}
                            <tr>

                            </tr>
                            <tr id="dataTemplate" style={displaynone}>
                                <td id="accountCol" className="align-L">

                                    <Link id="account" className="ico_account" to="/#"></Link>
                                </td>
                                <td id="creditRef" className="credit-amount-member" ><Link id="creditRefBtn" className="favor-set" to="/#"></Link></td>
                                <td id="creditLimit" style={displaynone}></td>

                                <td id="currentPL" style={displaynone}></td>
                                <td id="transferablePL" style={displaynone}></td>
                                <td id="balance" ></td>
                                <td id="currentExposure" ></td>
                                <td id="available" ></td>
                                <td id="creditPL" style={displaynone}></td>
                                <td id="playerBalance" style={displaynone}></td>
                                <td id="exposureLimit" style={displaynone}></td>

                                <td id="refPL" ></td>
                                <td id="statusCol" >
                                    <ul id="tipsPopup" className="status-popup" style={displaynone}>
                                    </ul>
                                    <span id="status"></span>
                                </td>
                                <td id="actionCol" >
                                    <ul className="action">
                                        <li>
                                            <Link id="banking" className="bank" to="#bank">Bank</Link>
                                        </li>
                                        <li>
                                            <Link id="p_l" className="p_l" to="/#">Betting Profit &amp; Loss</Link>
                                        </li>
                                        <li>
                                            <Link id="betting_history" className="betting_history" to="/#">Betting History</Link>
                                        </li>
                                        <li>
                                            <Link id="change" className="status" to="#change">Change Status</Link>
                                        </li>
                                        <li>
                                            <Link id="profile" className="profile" to="/#">Profile</Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>





                    <table style={displaynone}>
                        <tbody>
                            <tr id="tempBalanceTr" className="expand first expand-balance">
                                <td colSpan="2"></td>
                                <td colSpan="7">
                                    <img className="expand-arrow" src="images/transparent.gif" />
                                    <table>
                                        <tbody id="tempVendorContent">
                                            <tr>
                                                <th width="12%" className="align-L">Game</th>
                                                <th width="13%">Balance</th>
                                                <th width="8%"><Link id="recallAll" to="/#" className="btn-recall">Recall All</Link></th>
                                                <th ></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr id="tempVendorTr">
                                <td id="vendorTitle" className="align-L">Houise</td>
                                <td id="vendorBalance">200,000,000,000.00</td>
                                <td><Link id="recall" to="/#" className="btn-recall">Recall</Link></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Report Table */}
                <div id="tableOneBox" className="table_one_box" style={displaynone}>


                </div>
            </div>


            <div>
                <ul style={displaynone}>
                    <li id="prev"><Link to="/#">Prev</Link></li>
                    <li id="next"><Link to="/#">Next</Link></li>
                    <li id="pageNumber"><Link to="/#"></Link></li>
                    <li id="more"><Link to="/#">...</Link></li>
                    <input type="text" id="goToPageNumber" maxLength="6" size="4" />
                    <li><Link id="goPageBtn" to="/#">GO</Link></li>
                </ul>

                <ul id="pageNumberContent" className="pages">
                </ul>
            </div>

            <div className="footer_info footer_info_new">
                <div className="main_wrap">
                    <ul className="action">
                        <li className="bank"><p>Bank</p></li>
                        <li className="p_l"><p>Betting Profit & Loss</p></li>
                        <li className="betting_history"><p>Betting History</p></li>
                        <li className="profile"><p>Profile</p></li>
                        <li className="status"><p>Change Status</p></li>
                    </ul>
                </div>
            </div>

            <div className="overlay" id="fairenterAnnouncement" style={displaynone}>
                <div className="announce-wrap news-wrap">
                    <div className="announce-header">Announcement</div>
                    <div className="announce-content">
                        <p>
                            We hereby would like to bring to your attention a very serious matter.<br />
                            All Fairenter players and partners should know that only Betfair decides if a ticket is paid or not.<br />
                            It can happen that Betfair pays out bets on a market to their direct players holding a BF account, or to other whitelabels / clones but not to Fairenter, or the opposite, pay Fairenter players but not players of other whitelabels / clones.<br />
                            This may happen because the integrity checks Betfair conducts are based on case by case scenarios and winnings may be suspended where they deem suspicious activity.<br />
                            Proof that Betfair actually proceeded to such an action would be an email like the attached which is a real case that happened.<br />
                            All players who place bets at Fairenter, accept and acknowledge the above condition and rule.
                        </p>
                        <img src="/images/announcement/tc-fairenter-letter.png" alt="" />
                    </div>
                    <ul className="announce-footer">
                        <li><Link to="/#" className="btn-send">Agree</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default Dashboard