import React, { useEffect, useState } from "react";
import Transparent from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import { Link } from "react-router-dom";
import moment from 'moment';
// import { useState } from 'react'
import Appconfig from '../config/config';
import axios from 'axios';
import { useParams } from "react-router-dom";
import Loader from '../assets/images/loading40.gif';
const displaynone = {
    display: "none"
}
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const checkIfNeg = (val, type = null) => {
    if (val >= 0) {
        return parseFloat(val).toFixed(2);
    } else {
        if (type == "html") {
            return `<span class="red">(${Math.abs(parseFloat(val).toFixed(2))})</span>`
        } else {
            return <span className="red">({Math.abs(parseFloat(val).toFixed(2))})</span>
        }
    }
}
function ProfitLossSportWise() {
    const params = useParams();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const nPrevu = window.sessionStorage.getItem("downLineprofitLoss_tree");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    React.useEffect(() => {
        loader_remove();
        if (params.fromdata) {
            setProfitAndLossC({
                ...profitAndLossCa,
                ['from_date']: params.fromdata,
                ['to_date']: params.todate,
            });
            profitLossDownline();
        }
        // getProfitLoss();
        // getOpenBets();
        const nPrevut = window.sessionStorage.getItem("downLineprofitLoss_tree");
        setPrevuser(nPrevut ? JSON.parse(nPrevut) : []);
    }, [params.user_id]);
    const [profitAndLossCa, setProfitAndLossC] = useState({
        "user_id": userInfo._id,
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': moment().format("YYYY-MM-DD"),
        'type': "All",
        startTime: "09:00",
        endTime: "08:59"
    })
    const [downProfitLoss, setdownProfitLoss] = React.useState([])
    const [downProfitLossInter, setdownProfitLossInter] = React.useState([])
    const handleChangeProfit = (event) => {
        setProfitAndLossC({
            ...profitAndLossCa,
            [event.target.name]: event.target.value,
        });
    };

    function setDownlineUser(user_id) {
        setProfitAndLossC({
                ...profitAndLossCa,
                user_id: user_id,
             })

 
             profitLossDownline(user_id)
    }
    function profitLossDownline(user_id = null) {
        loader_default();
        profitAndLossCa.from_date = params.fromdata ? params.fromdata : (profitAndLossCa.from_date ? moment(profitAndLossCa.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
        profitAndLossCa.to_date = params.todate ? params.todate : (profitAndLossCa.to_date ? moment(profitAndLossCa.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

        if(user_id)
        {
            profitAndLossCa.user_id = user_id;

        }
        else
        {
            profitAndLossCa.user_id = params.user_id ? params.user_id : userInfo._id;

        }
        var data = JSON.stringify(profitAndLossCa);

        console.log('profitAndLossCa',data);
        console.log('profitAndLossCa', `${Appconfig.apiUrl}reports/profitLossSportWise`);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossSportWise`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setdownProfitLoss(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((betHistoryList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                // alert("successss");
                setdownProfitLoss(tmpAccountList);
                if (response.data.resultData.length == 0) {
                    // document.querySelector("#stakeTotal").textContent = 0;
                    document.querySelector("#sports_pl_total").textContent = 0;
                    document.querySelector("#casino_pl_total1").textContent = 0;
                    document.querySelector("#comm_total").textContent = 0;
                    document.querySelector("#total_pl").textContent = 0;
                }
                loader_remove()
                if (response.data.result) {
                    if (!response.data.resultData.length) {
                        document.getElementById("message").style.display = "block";
                        document.getElementById("msgtext").textContent = "you have no bets in this time period";
                        setTimeout(function () {
                            closeMessage("message");
                        }, 3000);
                    }
                } else {
                    document.getElementById("errorMessage").style.display = "block";
                    document.getElementById("errorMessageText").textContent = response.data.resultMessage;
                    setTimeout(function () {
                        closeMessage("errorMessage");
                    }, 3000);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function profitLossByDownlineInternal(user_id, tr_id) {
        loader_default();
        profitAndLossCa.from_date = profitAndLossCa.from_date ? moment(profitAndLossCa.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        profitAndLossCa.to_date = profitAndLossCa.to_date ? moment(profitAndLossCa.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        var data = JSON.stringify({
            user_id: user_id,
            from_date: profitAndLossCa.from_date ? moment(profitAndLossCa.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            to_date: profitAndLossCa.to_date ? moment(profitAndLossCa.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossByDownlineInternal`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setdownProfitLossInter(response.data.resultData);
                createTRinnerHtml(response.data.resultData, tr_id);
                loader_remove()
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function createTRinnerHtml(data, tr_id) {
        let temphtml = "";
        if (data.length > 0) {
            data.map((row, index) => {
                temphtml += `<tr id="sub_deep0107_1" class="expand first expand_${tr_id}" style="display: table-row;">
<td class="align-L">
    <a id="_sportName" class="ico_account" href="#">${row.event_type_name}-${row.market_name}</a>
</td>
<td id="sport_stake">${row.totalStake}</td>
<td id="sport_profitLoss">
${row.totalPL.toFixed(2) < 0 ? `<span class="red"> ${row.totalPL.toFixed(2)}</span></td>` : `<span class="green">${row.totalPL.toFixed(2)}</span></td>`}
 </td>
<td id="sport_profitLossDownLine">00.00</td>
<td id="sport_profitLossUpLine">
${row.upline_pl.toFixed(2) < 0 ? `<span class="red"> ${row.upline_pl.toFixed(2)}</span></td>` : `<span class="green">${row.upline_pl.toFixed(2)}</span></td>`}
</tr>`;
            })
        }
        document.getElementsByClassName(`${tr_id}`)[0].insertAdjacentHTML('afterEnd', temphtml)
    }
    const handleFilterSubmitProfit = () => {
        // getProfit(formData.event_type);
        profitLossDownline()
    }
    // console.log("ndewww", userInfo); 
    let stakeTotal = 0;
    let sports_pl_total = 0;
    let casino_pl_total = 0;

    let comm_total = 0;
    let total_pl = 0;
    function update_total(fancy) {
        stakeTotal += fancy.totalStake;
        // document.querySelector("#stakeTotal").textContent = stakeTotal.toFixed(2)
        sports_pl_total += fancy.sports_pl;
        document.querySelector("#sports_pl_total").innerHTML = checkIfNeg(sports_pl_total.toFixed(2), "html");

        casino_pl_total += fancy.casino_pl;
        document.querySelector("#casino_pl_total").innerHTML = checkIfNeg(casino_pl_total.toFixed(2), "html")

        comm_total += fancy.commission;
        document.querySelector("#comm_total").innerHTML = checkIfNeg(0.00, "html")


        total_pl += fancy.total_pl;
        document.querySelector("#total_pl").innerHTML = checkIfNeg(total_pl.toFixed(2), "html");
    }
    function justFor(days) {
        if (days == 1) {
            profitAndLossCa.from_date = moment().startOf('day').format("YYYY-MM-DD HH:mm:ss");
            profitAndLossCa.to_date = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
        }
        if (days == 2) {
            profitAndLossCa.from_date = moment().subtract(days, 'days').format("YYYY-MM-DD HH:mm:ss");
            profitAndLossCa.to_date = moment().format("YYYY-MM-DD HH:mm:ss");
        }
        profitLossDownline();
    }
    function closeMessage(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).style.display = "none";
        }
    }
    function getExplicitData(user_id, plus_id, tr_id) {
        if (document.querySelector(`#${plus_id}`).classList.contains('expand-close')) {
            document.querySelector(`#${plus_id}`).classList.remove("expand-close");
            document.querySelector(`#${plus_id}`).classList.add("expand-open");
            profitLossByDownlineInternal(user_id, tr_id);
        }
        else {
            document.querySelector(`#${plus_id}`).classList.add("expand-close");
            document.querySelector(`#${plus_id}`).classList.remove("expand-open");
            const boxes = document.getElementsByClassName(`expand_${tr_id}`);
            for (var box of boxes) {
                box.style.setProperty("display", "none", "important")
            }
        }
    }
    const checkExtraTd = () => {
        return prevUser && prevUser.length > 0 ? (
            prevUser[prevUser.length - 1].user_type != "Agent" && userInfo.user_type != "Agent" ? true : false) : true;
    }
    function showCurrentBookmaker(click_id, rowarray) {
        var expandHtml = document.getElementById("expandCurrentBookmaker-" + click_id + "-toggle");
        var linkHtml = document.getElementById("expandCurrentBookmaker-" + click_id);
        // updateTotalStake(rowarray);
        if (linkHtml.getAttribute('data-id') == 0) {
            linkHtml.setAttribute('data-id', '1');
            expandHtml.style.display = "table-row";
            linkHtml.classList.add("expand-open");
            linkHtml.classList.remove("expand-close");
        } else {
            linkHtml.setAttribute('data-id', '0');
            expandHtml.style.display = "none";
            linkHtml.classList.add("expand-close");
            linkHtml.classList.remove("expand-open");
        }
    }
    return (
        <div className="dashboard downlineprofitloss">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            {/* Main Wrap Start */}
            <div className="main_wrap">
                <div id="message" className="message-wrap-new" style={{ background: "yellow", color: "#000" }}>
                    <Link to="#" onClick={closeMessage("message")} className="btn-close">Close</Link>
                    <p id="msgtext"> </p>
                </div>
                <div id="errorMessage" className="message-wrap-new" style={{ display: "none", background: "red", color: "#000" }}>
                    <Link to="#" onClick={closeMessage("errorMessage")} className="btn-close">Close</Link>
                    <p id="errorMessageText"> </p>
                </div>
                <div className="head-wrap-profit">
                    <h2>Profit/Loss Sport Wise</h2>
                    {/* <p>Data source :</p>
                    <select id="dataSource" name="dataSource">
                        <option value="db">DB</option>
                        <option value="elk">ELK</option>
                    </select> */}
                </div>
                {/* Time Zone Container */}
                <div className="function-wrap">
                    <ul className="input-list">
                        <div id="statusCondition" style={displaynone}>
                            <li><label>Bet Status:</label></li>
                            <li>
                                <select name="betStatus" id="betStatus">
                                </select>
                            </li>
                        </div>
                        <li><label>Type</label></li>
                        <li>
                            <select id="func_sports" name="sports" onChange={handleChangeProfit} value={profitAndLossCa.sports}>
                                <option value="Sports" selected="selected">Sports</option>
                            </select>
                        </li>
                       
                        <li></li>
                        <li><label>Period</label></li>
                        <li>
                            <input id="startDate" onChange={handleChangeProfit} value={profitAndLossCa.from_date} className="cal-input" name="from_date" type="date" placeholder="YYYY-MM-DD" />
                            <input id="startTime" onChange={handleChangeProfit} value={profitAndLossCa.startTime} style={{ width: 75 }} className="time-input" type="time" placeholder="09:00" maxLength="5" />
                            to
                            <input id="endDate" onChange={handleChangeProfit} value={profitAndLossCa.to_date} className="cal-input" name="to_date" type="date" placeholder="YYYY-MM-DD" />
                            <input id="endTime" onChange={handleChangeProfit} value={profitAndLossCa.endTime} style={{ width: 75 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                        </li>
                        <li style={displaynone}>(TimeZone:IST)</li>
                    </ul>
                    <ul className="input-list">
                        <li><Link id="today" to="#" className="btn" onClick={() => justFor(1)}>Just For Today</Link></li>
                        <li><Link id="yesterday" to="#" className="btn" onClick={() => justFor(2)}>From Yesterday</Link></li>
                        <li style={displaynone}><Link id="last7days" to="#" className="btn">Last 7 days</Link></li>
                        <li style={displaynone}><Link id="last30days" to="#" className="btn">Last 30 days</Link></li>
                        <li style={displaynone}><Link id="last2months" to="#" className="btn">Last 2 Months</Link></li>
                        <li style={displaynone}><Link id="last3months" to="#" className="btn">Last 3 Months</Link></li>
                        <li><Link id="getPL" to="#" onClick={handleFilterSubmitProfit} className="btn-send">Get P & L</Link></li>
                    </ul>
                </div>

                <table id="table_log" className="table01">
                    <thead>
                        <tr>
                            <th width="15%" className="align-L">UID</th>
                            <th width="18%">Sports PL</th>
                            <th width="18%">Casino P/L</th>
                            <th width="18%">Comm.</th>
                            <th width="16%">Total P/L</th>
                        </tr>
                    </thead>
                    <tbody id="content">
                        {/* {accountList.length>0 && accountList.map(accountData =>  */}
                        {downProfitLoss.map((rowfancy) => (
                            <>
                                <tr id="tempTr" className={`downline-profit-tr downline_profit_tr_${rowfancy.user_id}`}>
                                    <td id="createDate" className="align-L" style={{ width: "31%" }}>
                                        {rowfancy.user_type == "User" ? <span>{rowfancy.user_name}</span>
                                            :
                                            <>
                                           
                                                <a href="javascript:void(0)" onClick={() => setDownlineUser(rowfancy.user_id)}> 
                                                    <strong>{rowfancy.user_name}</strong>
                                                </a>
                                            </>
                                        }
                                    </td>
                                     <td >{checkIfNeg(rowfancy.sports_pl)}
                                    </td>
                                    <td >{checkIfNeg(rowfancy.casino_pl)}
                                    </td>
                                    
                                    <td >0.00</td>
                                    <td >{checkIfNeg(rowfancy.total_pl)}{update_total(rowfancy)}</td>
                                </tr>
                                
                            </>

                        ))}
                    </tbody>
                    <tfoot>
                        <td width="15%" className="align-L">Total</td>
                        <td width="18%" id="sports_pl_total">Sports P/L</td>
                        <td width="18%" id="casino_pl_total">Casino P/L</td>
                        <td width="18%" id="comm_total">Comm </td>


                         <td width="16%" id="total_pl">Total P/L</td>
                    </tfoot>
                </table>
                <div id="noReportMessage"></div>
                {/* Loading Wrap */}
                <div id="poker_loading" class="loading-wrap" style={{ 'marginTop': '50px' }}>
                    <ul class="loading">
                        <li><img src={Loader} /></li>
                        <li>Loading…</li>
                    </ul>
                </div>
                {/* Message */}
                <table style={displaynone}>
                    <tbody>
                        <tr id="tempTr">
                            <td className="align-L">
                                <Link id="_bySport" to="#" className="expand-close"></Link>
                                <Link id="_userName" className="ico_account" to="#">
                                </Link>
                            </td>
                            <td id="_stake"></td>
                            <td id="_profitLoss"></td>
                            <td id="_profitLossDownLine"></td>
                            <td id="_tax3"></td>
                            <td id="_rebate3"></td>
                            <td id="_tax2"></td>
                            <td id="_rebate2"></td>
                            <td id="_payout1"></td>
                            <td id="_tax1"></td>
                            <td id="_rebate1"></td>
                            <td id="_profitLoss1"></td>
                            <td id="_profitLossUpLine"></td>
                        </tr>
                        <tr id="subTempTr" className="expand">
                            <td className="align-L">
                                <Link id="_sportName" className="ico_account" to="#"></Link>
                            </td>
                            <td id="sport_stake"></td>
                            <td id="sport_profitLoss"></td>
                            <td id="sport_profitLossDownLine"></td>
                            <td id="sport_tax3"></td>
                            <td id="sport_rebate3"></td>
                            <td id="sport_tax2"></td>
                            <td id="sport_rebate2"></td>
                            <td id="sport_payout1"></td>
                            <td id="sport_tax1"></td>
                            <td id="sport_rebate1"></td>
                            <td id="sport_profitLoss1"></td>
                            <td id="sport_profitLossUpLine"></td>
                        </tr>
                        <tr id="tempTotalTr" className="total">
                            <td className="align-L">Total</td>
                            <td id="_totalStake"></td>
                            <td id="_totalProfitLoss"></td>
                            <td id="_totalProfitLossDownLine"></td>
                            <td id="_totalTax3"></td>
                            <td id="_totalRebate3"></td>
                            <td id="_totalTax2"></td>
                            <td id="_totalRebate2"></td>
                            <td id="_totalPayout1"></td>
                            <td id="_totalTax1"></td>
                            <td id="_totalRebate1"></td>
                            <td id="_totalProfitLoss1"></td>
                            <td id="_totalProfitLossUpLine"></td>
                        </tr>
                    </tbody>
                </table>
                <div className="total_all" id="userTreeDiv">
                    <ul style={displaynone}>
                        <li id="tempTree" className=""><Link><span className="lv_1"></span></Link></li>
                        <li id="tempCurrencyTree" className="currency_tag"><strong></strong></li>
                    </ul>
                    <div id="treePath" className="agent_path">
                        <ul className="agent_path-L" id="userTree">
                        </ul>
                    </div>
                    <div id="expandAllDiv" style={displaynone}>
                        <Link id="showDetail" to="#" className="btn_expand">Expand All</Link>
                    </div>
                </div>
                <div id="reportDiv" className="over-wrap" style={{ 'maxHeight': "calc(100% - 32px - 93px - 55px)" }}>
                    <table id="table_DL" className="table01 table-pt" style={displaynone}>
                        <thead>
                            <tr id="head">
                                <th id="header_userName" width="" className="align-L">UID</th>
                                <th width="11%" className="">Stake</th>
                                <th width="11%" className="">Player P/L</th>
                                <th id="header_profitLoss_downLine" width="11%" className="">Downline P/L</th>
                                <th id="header_tax_agent_3" width="11%" className="">Master Comm.</th>
                                <th id="header_rebate_agent_3" width="11%" className="">Master Rebate</th>
                                <th id="header_tax_agent_2" width="11%" className="">Super Comm.</th>
                                <th id="header_rebate_agent_2" width="11%" className="">Super Rebate</th>
                                <th id="header_payout_agent_1" width="11%" className="">SS PT</th>
                                <th id="header_tax_agent_1" width="11%" className="">SS Comm.</th>
                                <th id="header_rebate_agent_1" width="11%" className="">SS Rebate</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">SS Total</th>
                                <th width="11%" className="">Upline P/L</th>
                            </tr>
                        </thead>
                        <tbody id="content">
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Main Wrap End */}
        </div>
    )
}
export default ProfitLossSportWise
