import React from 'react'
import "../assets/style.css"
import Header from '../admin/Header'
import SideNav from '../admin/SideNav'
import { Link } from "react-router-dom";
import { useState } from 'react'
import Appconfig from '../config/config';
import { CURRENCY_TYPE } from '../config/config';
import { useLocation } from "react-router-dom";
import axios from 'axios';

import moment from 'moment';

const displaynone = {
    display: "none"
}

export default function EventSingle() {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [accountList, SetAccountList] = React.useState([]);
    const [balLoader, setBalLoader] = React.useState(false);
    const [MainBalance, setMainBalance] = React.useState(0);
    const [exposure, setExposure] = React.useState(0);
    const [memeberUserData, setMemberUserData] = React.useState({});
    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    const [formData, setFormData] = React.useState({
        "user_id": uidQuery ? uidQuery : userInfo._id,
        "type": "0",
        "from_date": moment().add(-1, 'days').format('YYYY-MM-DD'),
        "to_date": moment().format('YYYY-MM-DD'),
    })
    React.useEffect(() => {
        getAccount(0);
        getBalance();
        getMembersDataUsersData();
    }, []);
    function getMembersDataUsersData() {
        // var user_type = params.user_type;
        var data = JSON.stringify({
            "user_id": uidQuery ? uidQuery : userInfo._id,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                setMemberUserData(response.data);

                let tmpUserList = response.data;
                //console.log("data new", tmpUserList);
                let tempSetData = tmpUserList.resultData[0];
                setMemberUserData(tempSetData);
                //        console.log('userList', response.data);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    function getBalance() {

        var data = JSON.stringify({
            user_id: userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setBalLoader(false);
                if (response.data.result == 0) {
                } else {
                    console.log(response.data.resultData);
                    setExposure(response.data.resultData.exposure);
                    setMainBalance(response.data.resultData.balance);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function getAccount(type) {
        setFormData({
            ...formData,
            ["type"]: type,
        });
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/accountStatement`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                SetAccountList(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((accountList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetAccountList(tmpAccountList);
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div className="">

                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L" >

                        {memeberUserData.length ? prevUser.length && memeberUserData?._id != userInfo._id && prevUser.map((row, index) => {
                            return <li id="path6" className="" key={"path" + index}>
                                <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                    <span className={`lv_${index}`}>
                                        {row.user_type.match(/\b\w/g).join('')}
                                    </span>
                                    <strong>{row.user_name}</strong>
                                </Link>
                            </li>
                        }) : ""}
                        <li id="path6" className="" key={"pathuser"}>
                            <Link to={window.location}>
                                <span className={`lv_3`}>
                                    {console.log(memeberUserData)}
                                    {memeberUserData?.user_type?.match(/\b\w/g).join('')}
                                </span>
                                <strong>{memeberUserData?.user_name}</strong>
                            </Link>
                        </li>


                    </ul>
                </div>

                {/* Side Bar Code */}
                <div className="col-left">
                    <SideNav memeberUserData={memeberUserData} />
                </div>
                <div id="loading" className="loading-wrap" style={displaynone}>
                    <ul className="loading">
                        <li><img src="./images/loading40.gif" /></li>
                        <li>Loading...</li>
                    </ul>
                </div>

                <div id="message" className="message-wrap success" style={displaynone}>
                    <Link to="/#" className="btn-close">Close</Link>
                    <p></p>
                </div>
                <div className="col-center report account-summary">
                    <h2>Account Summary</h2>
                    <div className="white-wrap displayflexy">
                        <dl className="head-balance">
                            <dt>Your Balances</dt>
                            <dd id="yourBalance">{MainBalance} <span>{CURRENCY_TYPE}</span></dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div >
    );
}

