import React from 'react'
import "../assets/style.css"
import Header from '../admin/Header'
import SideNav from '../admin/SideNav'
import SideNavFilter from '../admin/BetHistorySideNav'
import { Link } from "react-router-dom";
import { useState } from 'react'
import Appconfig from '../config/config';
import axios from 'axios';
import { useLocation } from "react-router-dom";

import PropTypes from 'prop-types';
import { Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar } from '@material-ui/core';
import Transparent from '../assets/transparent.gif'

import TimeField from 'react-simple-timefield';
import { makeStyles, List, ListItem, ListItemText, FormGroup, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';

import { isMobile } from "react-device-detect";

import moment from 'moment';
const displaynone = {
    display: "none"
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
// const [totalStakes, settotalStakes] = React.useState(0);
// function updateTotalStake(temparray) {
//   let totalll = 0;

//   temparray.length>0 && temparray.map((rowinner) => {
//         totalll += rowinner.stake;
//   });

//   return totalll;
// //   settotalStakes(totalll);
// }

function showCurrentBookmaker(click_id, rowarray) {
    var expandHtml = document.getElementById("expandCurrentBookmaker-" + click_id + "-toggle");
    var linkHtml = document.getElementById("expandCurrentBookmaker-" + click_id);
    // updateTotalStake(rowarray);

    if (linkHtml.getAttribute('data-id') == 0) {

        linkHtml.setAttribute('data-id', '1');
        expandHtml.style.display = "table-row";
        linkHtml.classList.add("expand-open");
        linkHtml.classList.remove("expand-close");

    } else {
        linkHtml.setAttribute('data-id', '0');
        expandHtml.style.display = "none";
        linkHtml.classList.add("expand-close");
        linkHtml.classList.remove("expand-open");

    }


}



// const handleSubmit = (event) => {
//     event.preventDefault();
//     setFormData({
//         ...formData,
//         ["type"]: type,
//       });
//     var config = {
//         method: 'post',
//         url: `${Appconfig.apiUrl}reports/livebetHistory`,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         data: JSON.stringify(formData)
//     };
//     axios(config)
//         .then(function (response) {
//             console.log(response);
//             // storeUserinfo(response.data.resultData);
//             if (response.data.result) {
//                 // notify(response.data.resultMessage, 'success')
//                 storeUserinfo(response.data.resultData);
//                 props.setLoggedIn(true)
//             }
//             else {
//                 notify(response.data.resultMessage, 'error')
//             }
//         })
//         .catch(function (error) {
//             console.log(error);
//         });



// }



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

export default function DownlineProfitLossFilter(props) {

    // function DownlineProfitLossFilter() {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const usernameQuery = new URLSearchParams(search).get('username');
    // const userTypeQuery = new URLSearchParams(search).get('usertype')
    // alert(usernameQuery);
    // alert(uidQuery);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [newUserInfo, SetNewUserInfo] = React.useState([]);
    const [accountList, SetAccountList] = React.useState([]);
    // const [formData, setFormData] = React.useState({
    //     "user_id": userInfo._id,
    //     'event_type': "",
    //     'status': "Open",
    //     'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[0] + 'T' + moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[1],
    //     'to_date': moment().format("YYYY-MM-DD HH:mm:ss"),
    //   })
    const [formData, setFormData] = React.useState({
        "event_id": "0",
        "user_id": uidQuery,
        'event_type': "All",
        'status': "Settled",
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[0] + 'T' + moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[1],
        'to_date': moment().format("YYYY-MM-DD HH:mm:ss"),
    })
    const [profitAndLossCa, setProfitAndLossC] = React.useState({
        "user_id": uidQuery,
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[0] + 'T' + moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[1],
        'to_date': moment().format("YYYY-MM-DD HH:mm:ss"),
        'type': "All",
        startTime: "09:00",
        endTime: "08:59"
    })
    const [profitAndLossListt, setProfitAndLossfa] = React.useState([])

    const handleFormDataChange = (event) => {

        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }
    const handleFilterSubmit = () => {
        getBetHistory(formData.event_type);
    }
    const handleFilterSubmitProfit = () => {
        // getProfit(formData.event_type);
        getProfitLossExp(profitAndLossCa.type)
    }

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        console.log(formData);
    };
    const handleChangeProfit = (event) => {
        setProfitAndLossC({
            ...profitAndLossCa,
            [event.target.name]: event.target.value,
        });
        console.log(profitAndLossCa);
    };
    const searchURL = props.location.search
    let tabIndex = 0;
    if (searchURL === '?history') {
        tabIndex = 1;
    } else if (searchURL === '?PandL') {
        tabIndex = 2;
    }
    const lastSegment = window.location.pathname;
    // const [search, SetSearch] = React.useState(tabIndex);
    let segment = 0
    if (lastSegment === '/current-bets') {
        segment = 3;
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(search);
    const [valuen, setnValue] = React.useState(0);

    const handlexChange = (event, newValue) => {
        setxValue(newValue);
    };
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
    };

    const gridType = isMobile ? 10 : 8;


    const [betHistoryList, SetBetHistoryList] = React.useState([]);
    const [profitLossList, SetProfitLossList] = React.useState([]);
    const [plfetchData, SetplFetchData] = React.useState({
        "user_id": uidQuery,
        "from_date": moment().add(-365, 'days').format('YYYY-MM-DD'),
        "to_date": moment().format('YYYY-MM-DD'),
        "type": "All"
    });

    const handlePandLSubmit = () => {
        // getProfitLoss();
        getProfitLossExp();
    }

    const [openFancyList, setFancyOpenList] = React.useState([]);

    React.useEffect(() => {
        getBetHistory(formData.event_type);
        // getProfitLoss();
        getOpenBets();
        if (uidQuery) {
            getUser();
        }
    }, []);

    // function getBetHistory(event_type) {
    //     formData.event_type = event_type;
    //     var data = JSON.stringify(formData);

    //     var config = {
    //         method: 'post',
    //         url: `${Appconfig.apiUrl}reports/livebetHistory`,
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             // console.log(JSON.stringify(response.data));
    //             SetBetHistoryList(response.data.resultData);
    //             let tmpAccountList = response.data.resultData;
    //             const newChipList = tmpAccountList.map((betHistoryList, index) => {
    //                 tmpAccountList[index]["id"] = index + 1;
    //             });
    //             console.log('helllooooo',newChipList);
    //             SetBetHistoryList(tmpAccountList);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }
    // console.log(SetBetHistoryList);
    function getProfitLoss() {
        var data = JSON.stringify(plfetchData)
        console.log(data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLoss`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                SetProfitLossList(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((profitLossList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetProfitLossList(tmpAccountList);
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function getProfitLossExp() {
        var data = JSON.stringify(profitAndLossCa);
        profitAndLossCa.from_date = moment(profitAndLossCa.from_date).format('YYYY-MM-DD');
        profitAndLossCa.to_date = moment(profitAndLossCa.to_date).format('YYYY-MM-DD');
        console.log(data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossExplicit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setProfitAndLossfa(response.data.resultData);
                // let tmpAccountList = response.data.resultData;
                // const newChipList = tmpAccountList.map((profitLossList, index) => {
                //     tmpAccountList[index]["id"] = index + 1;
                // });
                // setProfitAndLossfa(tmpAccountList);
                // updateTotalStake(tmpAccountList);
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getOpenBets() {

        var data = JSON.stringify({
            "user_id": uidQuery
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/openBets`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log("responseeee", response.data.resultData);
                setFancyOpenList(response.data.resultData);
                console.log('Fancy Data',);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getUser() {

        var data = JSON.stringify({
            "user_id": uidQuery
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                SetNewUserInfo(response.data.resultData);
                console.log("responseeee", response.data.resultData);
                // setFancyOpenList(response.data.resultData);
                // console.log('Fancy Data',);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function filterBackBets(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Fancy" && element.status == "Open");
        return backBets
    }
    function filterProfitFancy(bettings) {
        let backBets = bettings.filter(element => element.market_name == "Fancy");
        return backBets
    }
    function filterProfitMatch(bettings) {
        let backBets = bettings.filter(element => element.market_name == "Match Odds");
        return backBets
    }
    function filterProfitBookmaker(bettings) {
        let backBets = bettings.filter(element => element.market_name == "Bookmaker");
        return backBets
    }
    function filterBookMaker(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Match" && element.market_name == "Bookmaker" && element.status == "Open");
        return backBets
    }
    function filterUnMatchOdds(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Match" && element.market_name == "Match Odds" && element.unmatch_bet == "No" && element.status == "Open");
        return backBets
    }
    function filterMatchOdds(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Match" && element.market_name == "Match Odds" && element.unmatch_bet != "No" && element.status == "Open");
        return backBets
    }
    function filterFancyHistory(bettings) {
        let backBets = bettings.filter(element => element.status == "Settled" && element.market_name == "Fancy");
        return backBets
    }
    // const handleFilterSubmitProfit = () => {

    //     profitLossDownline();
    // }
    // React.useEffect(() => {
    //     profitLossDownline();
    //     // getProfitLoss();
    //     // getOpenBets();
    // }, []);
    function getBetHistory(event_type) {
        formData.from_date = moment(formData.from_date).format('YYYY-MM-DD');
        formData.to_date = moment(formData.to_date).format('YYYY-MM-DD');
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/betHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                SetBetHistoryList(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((betHistoryList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetBetHistoryList(tmpAccountList);
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getTotalStakes(temparray) {
        let totalll = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalll += rowinner.stake;
        });
        return totalll;
        //   settotalStakes(totalll);
    }
    function getYesTotalStakes(temparray) {
        let totalYes = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.is_back == 1) {
                totalYes += rowinner.stake
            }
        });

        return totalYes;
        //   settotalStakes(totalll);
    }
    function getNoTotalStakes(temparray) {
        let totalNo = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.is_back == 0) {
                totalNo += rowinner.stake
            }
        });

        return totalNo;
        //   settotalStakes(totalll);
    }
    function getSubTotalStakes(temparray) {
        let totalSub = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalSub += rowinner.stake
        });

        return totalSub;
        //   settotalStakes(totalll);
    }
    function getMarketTotalStakes(temparray) {
        let totalSub = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalSub += rowinner.stake
        });

        return totalSub;
        //   settotalStakes(totalll);
    }
    // var olddate = moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[0] + 'T' + moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[1];
    //   var currentDate = moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[0] + 'T' + moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[1];




    return (
        <div className="dashboard downlinefilteruri">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div className="main_wrap">

                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L">
                        <li id="path6" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_0">
                                    COM
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path5" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_1">
                                    SS
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path4" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_2">
                                    SUP
                                </span>
                                <strong></strong>
                            </Link>
                        </li>


                        {usernameQuery ?
                            <li id="path3" className="last_li">
                                <Link to="/">
                                    <span className="lv_3">
                                        {newUserInfo && newUserInfo.length > 0 ? newUserInfo[0].user_type.match(/\b\w/g).join('') : ""}
                                    </span>
                                    <strong>
                                        {newUserInfo && newUserInfo.length > 0 ? newUserInfo[0].user_name : ""}
                                    </strong>
                                </Link>
                            </li>
                            : <li id="path3" className="last_li">
                                <Link to="/">
                                    <span className="lv_3">
                                        {userInfo.user_type.match(/\b\w/g).join('')}
                                    </span>
                                    <strong>{userInfo.user_name}</strong>
                                </Link>
                            </li>
                        }
                        <li id="path0" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_4">
                                    PL
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <ul className="account_pop" id="accountPop">
                            <li id="popTmp" style={displaynone}>
                                <Link to="/"></Link>
                            </li>
                        </ul>
                    </ul>
                </div>

                {/* Side Bar Code */}
                <div className="col-left">
                    <SideNavFilter />
                </div>
                <div id="loading" className="loading-wrap" style={displaynone}>
                    <ul className="loading">
                        <li><img src="./images/loading40.gif" /></li>
                        <li>Loading...</li>
                    </ul>
                </div>

                <div id="message" className="message-wrap success" style={displaynone}>
                    <Link to="/#" className="btn-close">Close</Link>
                    <p></p>
                </div>
                <div className="col-center report filter-data-downline">

                    <div className="Profit &amp; Loss pandlcont" value={valuex} index={2}>
                        <AppBar position="static" className="owntabfilter">
                            <Tabs value={valuen} variant="fullWidth" onChange={handlenChange}>
                                <Tab label="Exchange" {...a11yProps(0)} />
                                <Tab label="FancyBet" {...a11yProps(1)} />
                                <Tab label="Casino" {...a11yProps(2)} />
                                <Tab label="Sportsbook" {...a11yProps(3)} />
                                <Tab label="BookMaker" {...a11yProps(4)} />
                                <Tab label="BPoker" {...a11yProps(5)} />
                                <Tab label="Binary" {...a11yProps(6)} />
                                <Tab label="SABA" {...a11yProps(7)} />
                                <Tab label="MT5" {...a11yProps(8)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="Profit &amp; Loss" value={valuen} index={0}>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                <Box className="input-list">
                                    <Typography variant="span">Bet Status</Typography>
                                    <select id="betStatus" className="bet-status">
                                        <option value="">All</option>
                                        <option value="Unmatched">Unmatched</option>
                                        <option value="Matched">Matched</option>
                                    </select>
                                    <Typography variant="span">Order By</Typography>
                                    <input type="checkbox" name="betplace" />
                                    <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                    <input type="checkbox" name="market" />
                                    <Typography variant="span" className="ml-0">Market</Typography>
                                </Box>
                            </Box>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Unmatched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Sport Name</TableCell>
                                        <TableCell align="left">Event Name</TableCell>
                                        <TableCell align="left">Market Name</TableCell>
                                        <TableCell align="right">Result</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                        <TableCell align="right">Commission</TableCell>
                                        <TableCell align="right">Settle Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {profitLossList.map((row) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row">{row.market_name}</TableCell>
                                            <TableCell align="right">{row.event_name}</TableCell>
                                            <TableCell align="right">{row.market_name}</TableCell>
                                            <TableCell align="right">{row.result}</TableCell>
                                            <TableCell align="right">{row.totalPL}</TableCell>
                                            <TableCell align="right">{row.commission}</TableCell>
                                            <TableCell align="right">{moment(row.createdAt).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Matched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Sport Name</TableCell>
                                        <TableCell align="left">Event Name</TableCell>
                                        <TableCell align="left">Market Name</TableCell>
                                        <TableCell align="right">Result</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                        <TableCell align="right">Commission</TableCell>
                                        <TableCell align="right">Settle Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    <TableRow>
                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel className="Profit &amp; Loss fancy-bet-pandl" value={valuen} index={1} >
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                <Box className="input-list">
                                    <Typography variant="span">Bet Status</Typography>
                                    <select id="betStatus" className="bet-status">
                                        <option value="">All</option>
                                        <option value="Unmatched">Unmatched</option>
                                        <option value="Matched">Matched</option>
                                    </select>
                                    <Typography variant="span">Period</Typography>
                                    {/* <input type="checkbox" name="betplace" /> */}
                                    <input onChange={handleChangeProfit} type="date" id="fancyfromdate" name="from_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    <Typography variant="span"> to</Typography>
                                    <input onChange={handleChangeProfit} type="date" name="to_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    {/* <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
                                                    Get History
                                                </Button> */}
                                    <Link to="#" className="get-fancy-history" onClick={handleFilterSubmitProfit}>
                                        Get P &amp; L
                                    </Link>
                                </Box>
                            </Box>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Matched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Market</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">Settled date</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {profitAndLossListt.length > 0 && profitAndLossListt.map((rowfancy) => (
                                        rowfancy.market_name == "Fancy" ?

                                            <><TableRow className="pandlbets">
                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                <TableCell align="right"><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id.substr(rowfancy._id.length - 6))}>{rowfancy.totalPL}</Link></TableCell>
                                            </TableRow>

                                                <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                    <TableCell colspan="1"></TableCell>
                                                    <TableCell colspan="9">
                                                        {/* <img class="expand-arrow" src={Transparent} /> */}
                                                        <Table className={`bets-table ${classes.table}`}>
                                                            <TableHead className="table_head">
                                                                <TableCell>Bet Id</TableCell>
                                                                <TableCell>Selection</TableCell>
                                                                <TableCell>Odds</TableCell>
                                                                <TableCell>Stake</TableCell>
                                                                <TableCell>Type</TableCell>
                                                                <TableCell>Placed</TableCell>
                                                                <TableCell>Profit/Loss</TableCell>
                                                            </TableHead>
                                                            <TableBody className="table_body">

                                                                {filterProfitFancy(rowfancy.bettings).length > 0 && filterProfitFancy(rowfancy.bettings).map((rowinner) => (
                                                                    <TableRow>
                                                                        <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                        <TableCell>{rowinner.selection_id}</TableCell>
                                                                        <TableCell>{rowinner.price_val}</TableCell>
                                                                        <TableCell>{rowinner.stake}</TableCell>
                                                                        <TableCell>{rowinner.is_back == 0 ? "No" : "Yes"}</TableCell>
                                                                        <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                        <TableCell className={`${Math.sign(rowinner.p_l) == -1 ? "withliability" : "zeroliability"}`}>{Math.sign(rowinner.p_l) == -1 ? `(${rowinner.p_l})` : rowinner.p_l}</TableCell>
                                                                    </TableRow>

                                                                ))}
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Total Stakes
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Yes Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getYesTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        No Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getNoTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Market Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getSubTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="dottedborder-td">
                                                                    <TableCell align="right" colspan="6">
                                                                        Net Market Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getMarketTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow></>
                                            : null
                                    ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel className="Profit &amp; Loss " value={valuen} index={2} >
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                <Box className="input-list">
                                    <Typography variant="span">Bet Status</Typography>
                                    <select id="betStatus" className="bet-status">
                                        <option value="">All</option>
                                        <option value="Unmatched">Unmatched</option>
                                        <option value="Matched">Matched</option>
                                    </select>
                                    <Typography variant="span">Period</Typography>
                                    {/* <input type="checkbox" name="betplace" /> */}
                                    <input onChange={handleChangeProfit} type="date" name="from_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    <Typography variant="span"> to</Typography>
                                    <input onChange={handleChangeProfit} type="date" name="to_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    {/* <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
                                                    Get History
                                                </Button> */}
                                    <Link to="#" className="get-fancy-history" onClick={handleFilterSubmitProfit}>
                                        Get P &amp; L
                                    </Link>
                                </Box>
                            </Box>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Unmatched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Market</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">Settled date</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {profitAndLossListt.length > 0 && profitAndLossListt.map((rowfancy) => (
                                        <><TableRow className="pandlbets">

                                            <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                            {console.log("rowfancy",rowfancy)}
                                            <TableCell align="right"><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id.substr(rowfancy._id.length - 6))}>{rowfancy.totalPL}</Link></TableCell>
                                        </TableRow>
                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                <TableCell colspan="1"></TableCell>
                                                <TableCell colspan="9">
                                                    {/* <img class="expand-arrow" src={Transparent} /> */}
                                                    <Table className={`bets-table ${classes.table}`}>
                                                        <TableHead className="table_head">
                                                            <TableCell>Bet Id</TableCell>
                                                            <TableCell>Selection</TableCell>
                                                            <TableCell>Odds</TableCell>
                                                            <TableCell>Stake</TableCell>
                                                            <TableCell>Type</TableCell>
                                                            <TableCell>Placed</TableCell>
                                                            <TableCell>Profit/Loss</TableCell>
                                                        </TableHead>
                                                        <TableBody className="table_body">

                                                            {filterProfitMatch(rowfancy.bettings).length > 0 && filterProfitMatch(rowfancy.bettings).map((rowinner) => (
                                                                <TableRow>
                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                    <TableCell>{rowinner.price_val}</TableCell>
                                                                    <TableCell>{rowinner.stake}</TableCell>
                                                                    <TableCell>{rowinner.is_back == 0 ? "No" : "Yes"}</TableCell>
                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                    <TableCell className={`${Math.sign(rowinner.p_l) == -1 ? "withliability" : "zeroliability"}`}>{Math.sign(rowinner.p_l) == -1 ? `(${rowinner.p_l})` : rowinner.p_l}</TableCell>
                                                                </TableRow>

                                                            ))}
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    Total Stakes
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    -
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    Yes Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    -
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    No Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    -
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    Market Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    -
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="dottedborder-td">
                                                                <TableCell align="right" colspan="6">
                                                                    Net Market Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    -
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow></>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Matched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Market</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">Settled date</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {profitAndLossListt.length > 0 && profitAndLossListt.map((rowfancy) => (

                                        <><TableRow className="pandlbets">
                                            <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                            <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                            <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                            <TableCell align="right"><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id.substr(rowfancy._id.length - 6))}>{rowfancy.totalPL}</Link></TableCell>
                                        </TableRow>

                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                <TableCell colspan="1"></TableCell>
                                                <TableCell colspan="9">
                                                    {/* <img class="expand-arrow" src={Transparent} /> */}
                                                    <Table className={`bets-table ${classes.table}`}>
                                                        <TableHead className="table_head">
                                                            <TableCell>Bet Id</TableCell>
                                                            <TableCell>Selection</TableCell>
                                                            <TableCell>Odds</TableCell>
                                                            <TableCell>Stake</TableCell>
                                                            <TableCell>Type</TableCell>
                                                            <TableCell>Placed</TableCell>
                                                            <TableCell>Profit/Loss</TableCell>
                                                        </TableHead>
                                                        <TableBody className="table_body">

                                                            {filterProfitFancy(rowfancy.bettings).length > 0 && filterProfitFancy(rowfancy.bettings).map((rowinner) => (
                                                                <TableRow>
                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                    <TableCell>{rowinner.price_val}</TableCell>
                                                                    <TableCell>{rowinner.stake}</TableCell>
                                                                    <TableCell>{rowinner.is_back == 0 ? "No" : "Yes"}</TableCell>
                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                    <TableCell className={`${Math.sign(rowinner.p_l) == -1 ? "withliability" : "zeroliability"}`}>{Math.sign(rowinner.p_l) == -1 ? `(${rowinner.p_l})` : rowinner.p_l}</TableCell>
                                                                </TableRow>

                                                            ))}
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    Total Stakes
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    {getTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    Yes Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    {getYesTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    No Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    {getNoTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="sum-pl">
                                                                <TableCell align="right" colspan="6">
                                                                    Market Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    {getSubTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="dottedborder-td">
                                                                <TableCell align="right" colspan="6">
                                                                    Net Market Subtotal
                                                                </TableCell>
                                                                <TableCell colspan="3">
                                                                    {getMarketTotalStakes(filterProfitFancy(rowfancy.bettings))}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow></>

                                    ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel className="Profit &amp; Loss matchodds-bet-pandl" value={valuen} index={3} >
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                <Box className="input-list">
                                    <Typography variant="span">Bet Status</Typography>
                                    <select id="betStatus" className="bet-status">
                                        <option value="">All</option>
                                        <option value="Unmatched">Unmatched</option>
                                        <option value="Matched">Matched</option>
                                    </select>
                                    <Typography variant="span">Period</Typography>
                                    {/* <input type="checkbox" name="betplace" /> */}
                                    <input onChange={handleChangeProfit} type="date" name="from_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    <Typography variant="span"> to</Typography>
                                    <input onChange={handleChangeProfit} type="date" name="to_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    {/* <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
                                                    Get History
                                                </Button> */}
                                    <Link to="#" className="get-fancy-history" onClick={handleFilterSubmitProfit}>
                                        Get P &amp; L
                                    </Link>
                                </Box>
                            </Box>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Matched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Market</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">Settled date</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {profitAndLossListt.length > 0 && profitAndLossListt.map((rowfancy) => (
                                        rowfancy.market_name == "Match Odds" ?
                                            <><TableRow className="pandlbets">
                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                <TableCell align="right"><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id.substr(rowfancy._id.length - 6))}>{rowfancy.totalPL}</Link></TableCell>
                                            </TableRow>
                                                <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                    <TableCell colspan="1"></TableCell>
                                                    <TableCell colspan="9">
                                                        {/* <img class="expand-arrow" src={Transparent} /> */}
                                                        <Table className={`bets-table ${classes.table}`}>
                                                            <TableHead className="table_head">
                                                                <TableCell>Bet Id</TableCell>
                                                                <TableCell>Selection</TableCell>
                                                                <TableCell>Odds</TableCell>
                                                                <TableCell>Stake</TableCell>
                                                                <TableCell>Type</TableCell>
                                                                <TableCell>Placed</TableCell>
                                                                <TableCell>Profit/Loss</TableCell>
                                                            </TableHead>
                                                            <TableBody className="table_body">

                                                                {filterProfitMatch(rowfancy.bettings).length > 0 && filterProfitMatch(rowfancy.bettings).map((rowinner) => (
                                                                    <TableRow>
                                                                        <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                        <TableCell>{rowinner.selection_id}</TableCell>
                                                                        <TableCell>{rowinner.price_val}</TableCell>
                                                                        <TableCell>{rowinner.stake}</TableCell>
                                                                        <TableCell>{rowinner.is_back == 0 ? "No" : "Yes"}</TableCell>
                                                                        <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                        <TableCell className={`${Math.sign(rowinner.p_l) == -1 ? "withliability" : "zeroliability"}`}>{Math.sign(rowinner.p_l) == -1 ? `(${rowinner.p_l})` : rowinner.p_l}</TableCell>
                                                                    </TableRow>

                                                                ))}
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Total Stakes
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getTotalStakes(filterProfitMatch(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Yes Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getYesTotalStakes(filterProfitMatch(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        No Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getNoTotalStakes(filterProfitMatch(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Market Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getSubTotalStakes(filterProfitMatch(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="dottedborder-td">
                                                                    <TableCell align="right" colspan="6">
                                                                        Net Market Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getMarketTotalStakes(filterProfitMatch(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow></>
                                            : null
                                    ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel className="Profit &amp; Loss bookmaker-bet-pandl" value={valuen} index={4} >
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                <Box className="input-list">
                                    <Typography variant="span">Bet Status</Typography>
                                    <select id="betStatus" className="bet-status">
                                        <option value="">All</option>
                                        <option value="Unmatched">Unmatched</option>
                                        <option value="Matched">Matched</option>
                                    </select>
                                    <Typography variant="span">Period</Typography>
                                    {/* <input type="checkbox" name="betplace" /> */}
                                    <input onChange={handleChangeProfit} type="date" name="from_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    <Typography variant="span"> to</Typography>
                                    <input onChange={handleChangeProfit} type="date" name="to_date" />
                                    {/* <input type="checkbox" name="market" /> */}
                                    <TimeField onChange={handleChangeProfit} />
                                    {/* <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
                                                    Get History
                                                </Button> */}
                                    <Link to="#" className="get-fancy-history" onClick={handleFilterSubmitProfit}>
                                        Get P &amp; L
                                    </Link>
                                </Box>
                            </Box>
                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                <Typography variant="span">Matched</Typography>
                            </Box>
                            <Table className={`bets-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Market</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">Settled date</TableCell>
                                        <TableCell align="right">Profit/Loss</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {profitAndLossListt.length > 0 && profitAndLossListt.map((rowfancy) => (
                                        rowfancy.market_name == "Bookmaker" ?
                                            <><TableRow className="pandlbets">
                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                <TableCell align="right"><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id.substr(rowfancy._id.length - 6))}>{rowfancy.totalPL}</Link></TableCell>
                                            </TableRow>
                                                <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id.substr(rowfancy._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                    <TableCell colspan="1"></TableCell>
                                                    <TableCell colspan="9">
                                                        {/* <img class="expand-arrow" src={Transparent} /> */}
                                                        <Table className={`bets-table ${classes.table}`}>
                                                            <TableHead className="table_head">
                                                                <TableCell>Bet Id</TableCell>
                                                                <TableCell>Selection</TableCell>
                                                                <TableCell>Odds</TableCell>
                                                                <TableCell>Stake</TableCell>
                                                                <TableCell>Type</TableCell>
                                                                <TableCell>Placed</TableCell>
                                                                <TableCell>Profit/Loss</TableCell>
                                                            </TableHead>
                                                            <TableBody className="table_body">

                                                                {filterProfitBookmaker(rowfancy.bettings).length > 0 && filterProfitBookmaker(rowfancy.bettings).map((rowinner) => (
                                                                    <TableRow>
                                                                        <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                        <TableCell>{rowinner.selection_id}</TableCell>
                                                                        <TableCell>{rowinner.price_val}</TableCell>
                                                                        <TableCell>{rowinner.stake}</TableCell>
                                                                        <TableCell>{rowinner.is_back == 0 ? "No" : "Yes"}</TableCell>
                                                                        <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                        <TableCell className={`${Math.sign(rowinner.p_l) == -1 ? "withliability" : "zeroliability"}`}>{Math.sign(rowinner.p_l) == -1 ? `(${rowinner.p_l})` : rowinner.p_l}</TableCell>
                                                                    </TableRow>

                                                                ))}
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Total Stakes
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getTotalStakes(filterProfitBookmaker(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Yes Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getYesTotalStakes(filterProfitBookmaker(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        No Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getNoTotalStakes(filterProfitBookmaker(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="sum-pl">
                                                                    <TableCell align="right" colspan="6">
                                                                        Market Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getSubTotalStakes(filterProfitBookmaker(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className="dottedborder-td">
                                                                    <TableCell align="right" colspan="6">
                                                                        Net Market Subtotal
                                                                    </TableCell>
                                                                    <TableCell colspan="3">
                                                                        {getMarketTotalStakes(filterProfitBookmaker(rowfancy.bettings))}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow></>
                                            : null
                                    ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    </div>


                </div>
            </div>

        </div>
    );
}

// export default DownlineProfitLossFilter
