import React from 'react'
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation } from "react-router-dom";

const displaynone = {
    display: "none"
}
function MyAccount(props) {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const usernameQuery = new URLSearchParams(search).get('username');
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    // console.log("props", props);
    return (
        <div className="dashboard sidenav">
            <div className="sub_path">
                <ul className="menu-list">
                    <li className="class">Position</li>
                    {props.memeberUserData?._id == userInfo._id ? <li><Link id="accountStatement" to={`/account-statement?uid=${uidQuery}`}>Account Statement</Link></li> : ""}

                    <li><Link className={window.location.href.includes('profile') ? 'select' : ""} id="accountSummary" to={props.memeberUserData?._id != userInfo._id ? `/profile?uid=${uidQuery}` : `/myaccount?uid=${uidQuery}`}>Account Summary</Link></li>
                    <li className="class">{uidQuery != null && props.memeberUserData?._id != userInfo._id ? "Performance" : "Account Details"}</li>
                    {props.memeberUserData?.user_type == "User" && uidQuery != null ? <li className={window.location.href.includes('bethistory') ? 'select' : ""}><Link id="profile" to={`/memberList/bethistory?uid=${uidQuery}`}>Bet History</Link></li> : ""}
                    {props.memeberUserData?.user_type == "User" && uidQuery != null ? <li className={window.location.href.includes('profitAndLoss') ? 'select' : ""}><Link id="profile" to={`/memberList/profitAndLoss?uid=${uidQuery}`}>Betting Profit &amp; Loss</Link></li> : ""}
                    {props.memeberUserData?._id == userInfo._id ? <li><Link id="profile" to={`/profile?uid=${uidQuery}`}>Profile</Link></li> : ""}
                    {uidQuery != null && props.memeberUserData?._id != userInfo._id ? <li className={window.location.href.includes('account-statement') ? 'select' : ""}><Link id="profile" to={`/account-statement/${uidQuery}?uid=${uidQuery}`}>Transaction History</Link></li> : ""}
                    <li className={window.location.href.includes('activity-log') ? 'select' : ""}><Link id="activityLog" to={`/activity-log/${uidQuery}?uid=${uidQuery}`}>Activity Log</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default MyAccount
