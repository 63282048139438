
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';

import Header from '../admin/Header'
import SideNav from '../admin/SideNav';

// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams, useHistory } from "react-router-dom";


import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BetDeleteEvents() {
    const displaynone = {
        display: "none"
    }
    const history = useHistory();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_type_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [searched, setSearched] = React.useState("");
    const [tempEvents, setTempEvents] = React.useState([]);

    useEffect(() => {
        eventMatches(params.event_type_id)
    }, [])

    const columns = [

        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58
        },
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: 200,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Link to='#' onClick={() => history.push(`/betdelete-bet/${params.event_type_id}/${rowData.row.event_id}`)} >{rowData.row.event_name}</Link>
                );
            }
        }
    ];

    function eventMatches(event_type) {
        let tmpEventList;
        setEventMatchId(event_type);
        var data = JSON.stringify({
            "user_id": userInfo._id,
            event_type_id: event_type
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/allActiveEvents`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setEventMatchList(response.data.resultData);
                tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((EventMatchList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('newsss', response.data.resultData);
                setEventMatchList(tmpEventList)
                setTempEvents(tmpEventList);
                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            }).then(() => {
                if (!tmpEventList.length) {
                    document.getElementById("content").innerHTML = "<tr><td style='text-align:center'>No Rows Found</td></tr>"
                }

            });


    }
    function blockUnblockEvent(event_id) {

        var data = JSON.stringify({
            "user_id": userInfo._id,
            "event_id": event_id,
            "market_id": "0",
            "fancy_id": "0",
            "event_type_id": params.event_type_id,
            "type": "Event"

        });
        console.log('send string', data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log('blockUnblockEvent string', response);
                eventMatches(params.event_type_id)

            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const classes = useStyles();

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);
        // console.log("searchedVal",searchedVal);
        const filteredRows = EventMatchList.filter((row) => {
            return row.event_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setTempEvents(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    return (
        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div className="main_wrap">

                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L">
                        <li id="path6" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_0">
                                    COM
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path5" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_1">
                                    SS
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path4" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_2">
                                    SUP
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path3" className="last_li">
                            <Link >
                                <span className="lv_3">
                                    {userInfo.user_type.match(/\b(\w)/g).join('')}
                                </span>
                                <strong>{userInfo.user_name}</strong>
                            </Link>
                        </li>

                        <li id="path0" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_4">
                                    PL
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <ul className="account_pop" id="accountPop">
                            <li id="popTmp" style={displaynone}>
                                <Link to="/"></Link>
                            </li>
                        </ul>
                    </ul>
                </div>

                {/* Side Bar Code */}
                <div className="col-left">
                    <SideNav />
                </div>
                <div className="col-center report account-summary">
                    <div style={{ display: "flex" }}>
                        <div> <h2>Event Listing</h2></div>
                        <div style={{ marginLeft: "50%" }}> <input
                            value={searched}
                            placeholder='search'
                            onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        />
                        </div>
                    </div>
                    <div id="loading" className="loading-wrap" style={displaynone}>
                        <ul className="loading">
                            <li><img src="./images/loading40.gif" /></li>
                            <li>Loading...</li>
                        </ul>
                    </div>

                    <div id="message" className="message-wrap success" style={displaynone}>
                        <Link className="btn-close" to="#">Close</Link>
                        <p></p>
                    </div>

                    <table id="table_log" className="table01" style={{ textAlign: "left" }}>
                        <thead>
                            <tr>
                                <th >Name</th>
                            </tr>
                        </thead>
                        <tbody id="content">

                            {tempEvents.length > 0 && tempEvents.map(row =>
                                <tr id="tempTr">
                                    <td>
                                        <Link to='#' onClick={() => history.push(`/betdelete-bet/${params.event_type_id}/${row.event_id}`)} >{row.event_name}</Link>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}