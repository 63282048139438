import React from 'react';
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from './Header'
import { Link } from "react-router-dom";
import moment from "moment";
import Appconfig from "../config/config";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from '../assets/images/loading40.gif';
import rightarrowfill from '../assets/images/rightarrowfill.webp';

function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const displaynone = {
    display: "none"
}
export default function BetList() {
    const params = useParams();
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [intervalTimerid, SetIntervalTimerid] = React.useState();
    const [formData, setFormData] = React.useState({
        "user_id": userInfo._id,
        'event_type': "",
        'status': "Open",
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': moment().format("YYYY-MM-DD"),
    })
    const handleChange = (event) => {
        // alert(event.target.value);
        const name = event.target.name;
        setFormData({
            ...formData,
            [name]: event.target.value,
        });
    };
    const [betHistoryList, SetBetHistoryList] = React.useState([]);
    const [originalBetHistoryList, setOriginalBetHistoryList] = React.useState([]);

    let count = 1;
    React.useEffect(() => {
        getLiveBetHistory(formData.event_type);
    }, [params.user_id]);


    function getLiveBetHistory(event_type) {
        loader_default();
        if (event_type) {
            formData.event_type = event_type;
        }

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/livebetHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                SetBetHistoryList(response.data.resultData.reverse());
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((betHistoryList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetBetHistoryList(tmpAccountList);
                setOriginalBetHistoryList(tmpAccountList);
                loader_remove();

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    let refreshTimer;
    const handleRefresh = (e) => {
        if (e.target.value == 'stop') {
            if (intervalTimerid) {
                clearInterval(intervalTimerid)
            }
        } else {
            if (intervalTimerid) {
                clearInterval(intervalTimerid)
            }

            refreshTimer = setInterval(function () { getLiveBetHistory(formData.event_type) }, Number(e.target.value) * 1000);
            SetIntervalTimerid(refreshTimer);
        }
    }
    const handleRowLength = (e) => {
        let temprows = originalBetHistoryList.slice(0, Number(e.target.value));
        SetBetHistoryList(temprows);
    }

    return (
        <div className="dashboard betlist">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div id="poker_loading" className="loading-wrap" style={{ 'marginTop': '50px' }}>
                <ul className="loading">
                    <li><img src={Loader} /></li>
                    <li>Loading…</li>
                </ul>
            </div>
            {/* Main Wrap Start */}
            <div className="main_wrap">
                <div className="head-wrap-profit">
                    <h2>Bet List Live</h2>
                </div>
                {/* Time Zone Container */}

                {/* Radio List */}
                <ul className="input-list">
                    <li id="eventRadioBtnList">
                        <input type="radio" onChange={handleChange} checked={formData.event_type == "" ? true : false} name="event_type" id="events_1" data-eventtype="1" value="" />
                        All
                        <input type="radio" onChange={handleChange} checked={formData.event_type == 1 ? true : false} name="event_type" id="events_1" data-eventtype="1" value="1" />
                        Soccer
                        <input type="radio" onChange={handleChange} name="event_type" id="SOCCER_sportsbook" data-eventtype="1" value="1" />
                        S/R Soccer
                        <input type="radio" onChange={handleChange} name="event_type" id="SOCCER_ow" data-eventtype="1" value="1" />
                        S/B Soccer
                        <input type="radio" onChange={handleChange} name="event_type" id="SOCCER_BOOK" data-eventtype="1" data-categorytype="" value="1" />
                        BOOK Soccer
                        <input type="radio" checked={formData.event_type == 2 ? true : false} onChange={handleChange} name="event_type" id="events_2" data-eventtype="2" value="2" />
                        Tennis
                        <input type="radio" onChange={handleChange} name="event_type" id="TENNIS_sportsbook" data-eventtype="2" value="2" />
                        S/R Tennis
                        <input type="radio" onChange={handleChange} name="event_type" id="TENNIS_BOOK" data-eventtype="2" data-categorytype="5" value="2" />
                        BOOK Tennis
                        <input type="radio" checked={formData.event_type == 4 ? true : false} onChange={handleChange} name="event_type" id="events_4" data-eventtype="4" value="4" />
                        Cricket
                        <input type="radio" onChange={handleChange} name="event_type" id="CRICKET_fancy" data-eventtype="4" data-categorytype="2" value="4" />
                        Cricket/Fancy Bet
                        <input type="radio" onChange={handleChange} name="event_type" id="CRICKET_sportsbook" data-eventtype="4" value="4" />
                        S/R Cricket
                        <input type="radio" onChange={handleChange} name="event_type" id="CRICKET_BOOK" data-eventtype="4" data-categorytype="5" value="4" />
                        BOOK Cricket
                        <input type="radio" onChange={handleChange} name="event_type" id="ELECTION_fancy" data-eventtype="2378962" data-categorytype="2" value="9999999" />
                        Election/Fancy Bet
                        <input type="radio" onChange={handleChange} name="event_type" id="FANCYBET_fancy" data-eventtype="9999999" data-categorytype="2" value="9999999" />
                        FancyBet
                        <input type="radio" onChange={handleChange} checked={formData.event_type == 999 ? true : false} name="event_type" id="TS_BINARY" data-categorytype="6" value="999" />BINARY
                    </li>
                </ul>
                {/* Radio List End */}
                <ul className="input-list">

                    <li>
                        <label>Order of display:</label>
                        <select name="sortCondition" value="createdate" id="sortCondition">

                            <option value="stake" >
                                Stake
                            </option>

                            <option value="userid">
                                Player ID
                            </option>

                            <option value="createdate" selected="">
                                Time
                            </option>

                        </select>
                        <label> of </label>
                        <select name="orderByClause" id="orderByClause">
                            <option value="asc" selected="">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li>&nbsp;&nbsp;</li>



                    <li>
                        <label>Last:</label>
                        <select name="limit" id="limit" onChange={handleRowLength}>
                            <option value="100">100 Txn</option>
                            <option value="50">50 Txn</option>
                            <option value="25" selected="">25 Txn</option>
                        </select>
                    </li>
                    <li>&nbsp;&nbsp;</li>



                    <li>
                        <label>Auto Refresh (Seconds)</label>
                        <select name="refreshTime" id="refreshTime" onChange={handleRefresh}>
                            <option value="stop">Stop</option>
                            <option value="60">60</option>
                            <option value="30">30</option>
                            <option value="15" selected="">15</option>
                            <option value="5">5</option>
                            <option value="2">2</option>
                        </select>
                    </li>
                    <li>&nbsp;&nbsp;</li>




                    <li>
                        <label>Bet Status:</label>
                        <select name="betStatus" id="betStatus"><option value="all">All</option><option value="0">Unmatched</option><option value="1">Matched</option></select>
                    </li>
                    <li>&nbsp;&nbsp;</li>








                    <li><a style={{ width: "60px" }} href="#" className="btn-send" id="betListLiveRefresh" onClick={() => getLiveBetHistory()}>Refresh</a></li>
                </ul>

                <div id="noReportMessage"></div>


                {/* Message */}
                <div id="message" className="message-wrap success">
                    <Link className="btn-close">Close</Link>
                    <p></p>
                </div>
                <table style={displaynone}>
                    <tbody>
                        <tr id="tempTr">
                            <td className="align-L">
                                <Link id="_bySport" to="#" className="expand-close"></Link>
                                <Link id="_userName" className="ico_account" to="#">
                                </Link>
                            </td>
                            <td id="_stake"></td>
                            <td id="_profitLoss"></td>
                            <td id="_profitLossDownLine"></td>

                            <td id="_tax3"></td>
                            <td id="_rebate3"></td>
                            <td id="_tax2"></td>
                            <td id="_rebate2"></td>
                            <td id="_payout1"></td>
                            <td id="_tax1"></td>
                            <td id="_rebate1"></td>
                            <td id="_profitLoss1"></td>
                            <td id="_profitLossUpLine"></td>

                        </tr>

                        <tr id="subTempTr" className="expand">
                            <td className="align-L">
                                <Link id="_sportName" className="ico_account" to="#"></Link>
                            </td>
                            <td id="sport_stake"></td>
                            <td id="sport_profitLoss"></td>
                            <td id="sport_profitLossDownLine"></td>

                            <td id="sport_tax3"></td>
                            <td id="sport_rebate3"></td>
                            <td id="sport_tax2"></td>
                            <td id="sport_rebate2"></td>
                            <td id="sport_payout1"></td>
                            <td id="sport_tax1"></td>
                            <td id="sport_rebate1"></td>
                            <td id="sport_profitLoss1"></td>
                            <td id="sport_profitLossUpLine"></td>

                        </tr>

                        <tr id="tempTotalTr" className="total">
                            <td className="align-L">Total</td>
                            <td id="_totalStake"></td>
                            <td id="_totalProfitLoss"></td>
                            <td id="_totalProfitLossDownLine"></td>

                            <td id="_totalTax3"></td>
                            <td id="_totalRebate3"></td>
                            <td id="_totalTax2"></td>
                            <td id="_totalRebate2"></td>
                            <td id="_totalPayout1"></td>
                            <td id="_totalTax1"></td>
                            <td id="_totalRebate1"></td>
                            <td id="_totalProfitLoss1"></td>
                            <td id="_totalProfitLossUpLine"></td>

                        </tr>
                    </tbody>
                </table>

                <div id="reportDiv" className="over-wrap" style={{ 'maxHeight': "calc(100% - 32px - 93px - 55px)" }}>
                    <table id="table_DL" className="table01 table-pt">
                        <thead>
                            <tr id="head">
                                <th id="header_userName" width="" className="align-L">S.No	</th>
                                <th width="11%" className="">Datetime</th>
                                <th width="11%" className="">Client</th>
                                <th id="header_profitLoss_downLine" width="11%" className="">Description</th>
                                <th id="header_tax_agent_3" width="11%" className="">Selection</th>
                                <th id="header_rebate_agent_3" width="11%" className="">Type</th>
                                <th id="header_tax_agent_2" width="11%" className="">Odds</th>
                                <th id="header_rebate_agent_2" width="11%" className="">Stakes</th>
                                <th id="header_payout_agent_1" width="11%" className="">P_L</th>
                                <th id="header_tax_agent_1" width="11%" className="">Profit</th>
                                <th id="header_rebate_agent_1" width="11%" className="">Loss</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">Bet Type</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">Status</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">IP</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">Market</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">Device</th>
                            </tr>
                        </thead>
                        <tbody id="content">

                            {betHistoryList.length > 0 && betHistoryList.map(betdatData => <tr>


                                <td>{count++}</td>
                                <td>{betdatData.createdAt}</td>
                                <td>{betdatData.userInfo.user_name}</td>
                                <td>{betdatData.event_name}</td>
                                <td>{betdatData.place_name}</td>
                                <td></td>
                                <td>{betdatData.price_val.toFixed(2)}</td>
                                <td>{betdatData.stake}</td>
                                <td>{betdatData.p_l.toFixed(2)}</td>
                                <td>{betdatData.profit.toFixed(2)}</td>
                                <td>{betdatData.loss.toFixed(2)}</td>
                                <td>{betdatData.betting_type}</td>
                                <td>{betdatData.status}</td>
                                <td>{betdatData.ip_address?.replace("::ffff:", "")}</td>
                                <td>{Appconfig.event_types[betdatData.event_type] + " > " + betdatData.event_name} </td>
                                <td></td>
                                <td></td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Main Wrap End */}

        </div>
    );
}


