import React, { useState } from 'react';
// import ReactDOM from 'react-dom'
import { Button, Grid, makeStyles, Tabs, Tab, Box, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';


import Email from '@material-ui/icons/Email';
import Telegram from '@material-ui/icons/Telegram';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Instagram from '@material-ui/icons/Instagram';
import Clear from '@material-ui/icons/Clear';
import Skype from './assets/images/skype.svg';

// Import Images
import LOGO from './assets/images/lce.png';
import Verify from './assets/images/verifycode.gr';
import Transparent from './assets/images/transparent.gif';
import BetFair from './assets/images/betfair_black.png';
import ErrorEx from './assets/images/error-ex.png';
import axios from 'axios';
import Appconfig from './config/config';
import { toast } from 'react-toastify';
import { FormControl, Input, FormControlLabel, Checkbox } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
    },
    search: {
        position: 'relative',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '270px',
        },
        backgroundColor: '#ffffff',
        borderRadius: '6px'
    },
    searchIcon: {
        padding: theme.spacing(0, .5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        color: '#000000',
        fontSize: '14px !important'
    },
    inputRoot: {
        color: '#000000',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(.5, .5, .5, 0),
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: '14px'
    },
    sectionDesktop: {
        display: 'flex',
    },
    navButton: {
        fontSize: '12px',
        fontWeight: '600',
        padding: '2px 16px',
        textTransform: 'none',
        marginLeft: '8px',
        color: '#ffffff'
    },
    navBtnImg: {
        marginLeft: '6px'
    },
    navInput: {
        border: 'none',
        borderRadius: '4px',
        marginLeft: '6px',
        fontSize: '12px',
        paddingLeft: '8px',
        width: '122px',

    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function capthcreturn() {
    return Math.floor(1000 + Math.random() * 9000);
}

const LoginMobile = (props) => {
    const [captcha, setCaptcha] = React.useState(capthcreturn());
    const styleMobile = require('./assets/styleMobile.css');
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const history = useHistory();
    const [formData, setformData] = useState({
        user_name: '',
        password: '',
        site_code: Appconfig.sitecodes,
        validation_checker: '',
        user_type: "Super"
    });



    const [validationError, SetValidationError] = useState("");

    function checkValidation() {

        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let errorText;

        document.getElementById("user_name").style.background = "";
        document.getElementById("password").style.background = "";
        document.getElementById("user_captcha_input").style.background = "";
        document.getElementById("erroruserid").style.display = "none";
        document.getElementById("errorpassid").style.display = "none";

        if (formData.user_name == "") {

            document.getElementById("user_name").style.background = "#fbd7d3";
            document.getElementById("erroruserid").style.display = "none";
            errorText = "Username is empty."
            SetValidationError(errorText);
            return false;
        } else if (formData.password == "") {
            document.getElementById("password").style.background = "#fbd7d3";
            document.getElementById("errorpassid").style.display = "none";
            errorText = "Password is empty"
            SetValidationError(errorText);
            return false;
        } else if (formData.validation_checker == "") {
            document.getElementById("user_captcha_input").style.background = "#fbd7d3";
            errorText = "Validation code is empty"
            SetValidationError(errorText);
            return false;
        } else if (format.test(formData.user_name) == true) {

            errorText = "Username is only allow a-z and 0-9."
            SetValidationError(errorText);
            return false;
        } else if (doSubmit() == false) {
            errorText = "Invalid validation code!"
            SetValidationError(errorText);
            return false;

        } else {
            errorText = ""
            SetValidationError(errorText);
            return true;
        }


        //        return false;

    }

    const handleInputChange = (event) => {
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
        console.log(formData);
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/userAuthenticate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(formData)
            };
            axios(config)
                .then(function (response) {
                    console.log(response);
                    // storeUserinfo(response.data.resultData);
                    if (response.data.result) {
                        SetValidationError(response.data.resultMessage);
                        // document.querySelector("#successMsg").textContent = response.data.resultMessage;
                        notify(response.data.resultMessage, 'success')
                        storeUserinfo(response.data.resultData);

                        props.setLoggedIn(true)
                    }
                    else {
                        setCaptcha(capthcreturn());
                        SetValidationError(response.data.resultMessage);

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    function notify(message, type) {
        if (type === 'error') {
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    function storeUserinfo(userdata) {
        window.sessionStorage.setItem("loggedIn", true);
        window.sessionStorage.setItem("userData", JSON.stringify(userdata));
        window.location.href = '/dashboard';
    }
    function doSubmit() {
        let user_captcha = document.getElementById('validation_code').value;
        if (formData.validation_checker == captcha) {
            return true;
        }
        else {
            return false;
        }
    };
    return (
        <>
            {/* <Header /> */}
            <Grid container className="login-section">

                {/* <Link to="/dashboard" className="login-close"><Clear /></Link> */}
                <Grid item xs={12}>
                    <div className="login-header">
                        <img src={LOGO} />
                        <div>
                            <p>Powered by</p>
                            <img src={BetFair} />
                        </div>
                    </div>
                </Grid>
                <Grid className="form-login-all" item xs={12}>
                    <ValidatorForm

                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="right-login login-form">
                            <div>
                                <input validators={['required']} autoComplete="off"
                                    errorMessages={['this field is required']} value={formData.user_name} helperText="Username" placeholder="Username" id="user_name" name="user_name" onChange={handleInputChange} />
                                <span className="error-ex-cont"><img id="erroruserid" src={ErrorEx} className="errow-ex" /></span>
                            </div>
                            <div>
                                <input fullWidth validators={['required']} autoComplete="off"
                                    errorMessages={['this field is required']} id="password" type="password" value={formData.password} helperText="Password" placeholder="Password" name="password" onChange={handleInputChange} />
                                <span className="error-ex-cont"><img id="errorpassid" src={ErrorEx} className="errow-ex" /></span>
                            </div>
                            <div className="custom-captcha">
                                {/* <CaptchaTest /> */}



                            </div>
                            <div className="validation-input">
                                <input type="number" placeholder="Validation Code"
                                    inputMode='numeric' pattern="[0-9]*" id="user_captcha_input" value={formData.validation_checker} name="validation_checker" maxLength="4" className={classes.navInput} onChange={handleInputChange} />
                                {/* <img alt="skyexchange" src={Verify} /> */}
                                <div className="canvas-captcha">
                                    <span id="validation_code" style={{ fontSize: 25, color: "black", fontWeight: "bold", paddingTop: 0 }}>{captcha}</span>
                                </div>
                                <span className="error-ex-cont"><img id="errorid" src={ErrorEx} className="errow-ex" /></span>
                            </div>
                            <Button type="submit" variant="contained" class="w-100 popup-login-btn">Login</Button>
                            <span>{validationError} </span>
                        </div>
                    </ValidatorForm>
                    <div className="footer-links">
                        <Link href="#">
                            Privacy Policy
                        </Link>
                        <Link href="#">
                            Terms and Conditions
                        </Link>
                        <Link href="#">
                            Rules and Regulations
                        </Link>
                        <Link href="#">
                            KYC
                        </Link>
                        <Link href="#">
                            Responsible Gaming
                        </Link>
                        <Link href="#">
                            About Us
                        </Link>
                        <Link href="#">
                            Self-exclusion Policy
                        </Link>
                        <Link href="#">
                            Underage Policy
                        </Link>
                    </div>
                    <div className="social login-social">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab onMouseEnter={() => setValue(0)} label={<Email />} />
                            <Tab onMouseEnter={() => setValue(1)} label={<WhatsApp />} />
                            <Tab onMouseEnter={() => setValue(2)} label={<Telegram />} />
                            <Tab onMouseEnter={() => setValue(3)} label={<img alt="skyexchange" src={Skype} />} />
                            <Tab onMouseEnter={() => setValue(4)} label={<Instagram />} />
                        </Tabs>
                        <TabPanel className="tab-description" value={value} index={0}>
                            {/* <Link href="#">
                            +9173740005791
                            </Link> */}
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={1}>
                            {/* <Link href="#">
                              +9173740005791
                            </Link>
                            <Link href="#">
                            +9173740005791
                            </Link> */}
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={2}>
                            {/* <Link href="#">
                            +9173740005791
                            </Link> */}
                           
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={3}>
                            {/* <Link href="#">
                            +9173740005791
                            </Link> */}
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={4}>
                            {/* <Link href="#">
                            +9173740005791
                            </Link> */}
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default LoginMobile;
