import React from 'react';
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import { Link } from "react-router-dom";
import moment from "moment";
import Appconfig from "../config/config";
import axios from "axios";

const displaynone = {
    display: "none"
}
export default function BetList() {
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [eventType, setEventType] = React.useState('4')
    const [quickTime, setQuickTime] = React.useState('')

    const [fromDate, setFromDate] = React.useState(moment().subtract(1, 'days').format("YYYY-MM-DD"))
    const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"))

    const [fromTime, setFromTime] = React.useState('00:00')
    const [toTime, setToTime] = React.useState('23:59')



    // console.log(userInfo);
    const [formData, setFormData] = React.useState({
        "user_id": userInfo._id,
        //   'event_type': "",
        //   'status': "Open",
        //   'from_date': "2021-09-01",
        //   'to_date': "2021-09-30",
        //   "game_type": "All"

        'event_id': "0",
        'event_type': "4",
        'from_date': "2021-09-01",
        'matchUnmatch': " ",
        'selection_id': "0",
        'status': "Settled",
        'to_date': "2021-09-30"
    })
    const [betHistoryList, SetBetHistoryList] = React.useState([]);



    const [getYesturdayDate, setYesturdayDateTime] = React.useState(
        moment().subtract(1, 'days').format("YYYY-MM-DD")
    )
    const [getTodayDate, setTodayDate] = React.useState(
        moment().format("YYYY-MM-DD")
    )


    let count = 1;
    React.useEffect(() => {
        getBetHistory(formData.event_type);
    }, []);

    function getBetHistory() {

        const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
        let data = {
            user_id: userInfo._id,
            event_type: eventType,
            from_date: fromDate + ' ' + fromTime,
            to_date: toDate + ' ' + toTime,

        }
        data = JSON.stringify(data);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/betHistoryForAdmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                //     console.log(JSON.stringify(response.data));
                SetBetHistoryList(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((betHistoryList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetBetHistoryList(tmpAccountList);
                //   console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (

        <div className="dashboard betlist">
            {/* Top Start */}
            <Header />
            {/* Top End */}

            {/* Main Wrap Start */}
            <div className="main_wrap">
                <div className="head-wrap-profit">
                    <h2>Bet List</h2>
                </div>
                {/* Time Zone Container */}

                {/* Radio List */}
                <ul className="input-list">
                    <li id="eventRadioBtnList">
                        <label>
                            <input type="radio" name="betlistevents" id="events_1" data-eventtype="1" checked={eventType == 1 ? true : false} onClick={() => setEventType(1)} value="1" />
                            Soccer</label>

                        <label>
                            <input type="radio" name="betlistevents" id="SOCCER_sportsbook" data-eventtype="11" value="true" checked={eventType == 11 ? true : false} onClick={() => setEventType(11)} />
                            S/R Soccer
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" checked={eventType == 12 ? true : false} onClick={() => setEventType(12)} id="SOCCER_ow" data-eventtype="1" value="true" />
                            S/B Soccer
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" checked={eventType == 13 ? true : false} onClick={() => setEventType(13)} id="SOCCER_BOOK" data-eventtype="1" data-categorytype="5" />
                            BOOK Soccer
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="events_2" data-eventtype="2" value="2" checked={eventType == 2 ? true : false} onClick={() => setEventType(2)} />
                            Tennis
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="TENNIS_sportsbook" data-eventtype="2" value="true" checked={eventType == 21 ? true : false} onClick={() => setEventType(21)} />
                            S/R Tennis
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="TENNIS_BOOK" data-eventtype="2" data-categorytype="5" checked={eventType == 22 ? true : false} onClick={() => setEventType(22)} />
                            BOOK Tennis
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="events_4" data-eventtype="4" value="4" checked={eventType == 4 ? true : false} onClick={() => setEventType(4)} />
                            Cricket
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="CRICKET_fancy" data-eventtype="4" data-categorytype="2" value="true" checked={eventType == 41 ? true : false} onClick={() => setEventType(41)} />
                            Cricket/Fancy Bet
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="CRICKET_sportsbook" data-eventtype="4" value="true" checked={eventType == 42 ? true : false} onClick={() => setEventType(42)} />
                            S/R Cricket
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="CRICKET_BOOK" data-eventtype="4" data-categorytype="5" checked={eventType == 43 ? true : false} onClick={() => setEventType(43)} />
                            BOOK Cricket
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="ELECTION_fancy" data-eventtype="2378962" data-categorytype="2" value="true" checked={eventType == 99 ? true : false} onClick={() => setEventType(99)} />
                            Election/Fancy Bet
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="FANCYBET_fancy" data-eventtype="9999999" data-categorytype="2" value="9999999" checked={eventType == 88 ? true : false} onClick={() => setEventType(88)} />
                            FancyBet
                        </label>

                        <label>
                            <input type="radio" name="betlistevents" id="TS_BINARY" data-categorytype="6" checked={eventType == 77 ? true : false} onClick={() => setEventType(77)} />BINARY
                        </label>

                    </li>
                </ul>
                {/* Radio List End */}
                <div className="function-wrap">
                    <ul className="input-list">
                        <div id="statusCondition" style={displaynone}>
                            <li><label>Bet Status:</label></li>
                            <li>
                                <select name="betStatus" id="betStatus">
                                </select>
                            </li>
                        </div>
                        <li><label>Period</label></li>
                        <li>
                            <input id="startDate" className="cal-input" value={fromDate} type="date" placeholder="YYYY-MM-DD" onChange={(e) => setFromDate(e.target.value)} />
                            <input id="startTime" value={fromTime} onChange={(e) => {
                                console.log('e', e.target.value)
                                setFromTime(e.target.value)
                            }
                            } className="time-input " type="time" placeholder="09:00" style={{ width: 100 }} maxLength="5" />
                            to
                            <input id="endDate" className="cal-input" value={toDate} onChange={(e) => setToDate(e.target.value)} type="date" placeholder="YYYY-MM-DD" />
                            <input id="endTime" className="time-input " value={toTime} onChange={(e) => setToTime(e.target.value)} type="time" style={{ width: 100 }} placeholder="08:59" maxLength="5" />
                        </li>
                        <li style={displaynone}>(TimeZone:IST)</li>
                    </ul>

                    <ul className="input-list">
                        <li><Link id="today" to="#" className="btn active" onClick={() => {

                            setFromDate(moment().format("YYYY-MM-DD"))
                            setToDate(moment().format("YYYY-MM-DD"))

                            setFromTime('00:00')
                            setToTime('23:59')


                        }}>Just For Today</Link></li>
                        <li><Link id="yesterday" to="#" className="btn" onClick={() => {

                            setFromDate(moment().subtract(1, 'days').format("YYYY-MM-DD"))
                            setToDate(moment().format("YYYY-MM-DD"))

                            setFromTime('00:00')
                            setToTime('23:59')

                        }}>From Yesterday</Link></li>
                        <li style={displaynone}><Link id="last7days" to="#" className="btn">Last 7 days</Link></li>
                        <li style={displaynone}><Link id="last30days" to="#" className="btn">Last 30 days</Link></li>
                        <li style={displaynone}><Link id="last2months" to="#" className="btn">Last 2 Months</Link></li>
                        <li style={displaynone}><Link id="last3months" to="#" className="btn">Last 3 Months</Link></li>
                        <li><Link id="getPL" to="#" className="btn-send" onClick={() => getBetHistory()}>Get History</Link></li>
                    </ul>
                </div>
                <div id="noReportMessage"></div>

                <p>Bet List enables you to review the bets you have placed. <br />Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p><p>Bet List is available online for the past 30 days.</p>
                {/* Message */}
                <div id="message" className="message-wrap success">
                    <Link className="btn-close">Close</Link>
                    <p></p>
                </div>
                <table style={displaynone}>
                    <tbody>
                        <tr id="tempTr">
                            <td className="align-L">
                                <Link id="_bySport" to="#" className="expand-close"></Link>
                                <Link id="_userName" className="ico_account" to="#">
                                </Link>
                            </td>
                            <td id="_stake"></td>
                            <td id="_profitLoss"></td>
                            <td id="_profitLossDownLine"></td>

                            <td id="_tax3"></td>
                            <td id="_rebate3"></td>
                            <td id="_tax2"></td>
                            <td id="_rebate2"></td>
                            <td id="_payout1"></td>
                            <td id="_tax1"></td>
                            <td id="_rebate1"></td>
                            <td id="_profitLoss1"></td>
                            <td id="_profitLossUpLine"></td>

                        </tr>

                        <tr id="subTempTr" className="expand">
                            <td className="align-L">
                                <Link id="_sportName" className="ico_account" to="#"></Link>
                            </td>
                            <td id="sport_stake"></td>
                            <td id="sport_profitLoss"></td>
                            <td id="sport_profitLossDownLine"></td>

                            <td id="sport_tax3"></td>
                            <td id="sport_rebate3"></td>
                            <td id="sport_tax2"></td>
                            <td id="sport_rebate2"></td>
                            <td id="sport_payout1"></td>
                            <td id="sport_tax1"></td>
                            <td id="sport_rebate1"></td>
                            <td id="sport_profitLoss1"></td>
                            <td id="sport_profitLossUpLine"></td>

                        </tr>

                        <tr id="tempTotalTr" className="total">
                            <td className="align-L">Total</td>
                            <td id="_totalStake"></td>
                            <td id="_totalProfitLoss"></td>
                            <td id="_totalProfitLossDownLine"></td>

                            <td id="_totalTax3"></td>
                            <td id="_totalRebate3"></td>
                            <td id="_totalTax2"></td>
                            <td id="_totalRebate2"></td>
                            <td id="_totalPayout1"></td>
                            <td id="_totalTax1"></td>
                            <td id="_totalRebate1"></td>
                            <td id="_totalProfitLoss1"></td>
                            <td id="_totalProfitLossUpLine"></td>

                        </tr>
                    </tbody>
                </table>
                <div className="total_all" id="userTreeDiv">
                    <ul style={displaynone}>
                        <li id="tempTree" className=""><Link><span className="lv_1"></span></Link></li>
                        <li id="tempCurrencyTree" className="currency_tag"><strong></strong></li>
                    </ul>

                    <div id="treePath" className="agent_path">
                        <ul className="agent_path-L" id="userTree">
                        </ul>
                    </div>
                    <div id="expandAllDiv" style={displaynone}>
                        <Link id="showDetail" to="#" className="btn_expand">Expand All</Link>
                    </div>
                </div>
                <div id="reportDiv" className="over-wrap" style={{ 'maxHeight': "calc(100% - 32px - 93px - 55px)" }}>
                    <table id="table_DL" className="table01 table-pt">
                        <thead>
                            <tr id="head">
                                <th id="header_userName" width="" className="align-L">S.No	</th>
                                <th width="11%" className="">Datetime</th>
                                <th width="11%" className="">Client</th>
                                <th id="header_profitLoss_downLine" width="11%" className="">Description</th>
                                <th id="header_tax_agent_3" width="11%" className="">Selection</th>
                                <th id="header_rebate_agent_3" width="11%" className="">Type</th>
                                <th id="header_tax_agent_2" width="11%" className="">Odds</th>
                                <th id="header_rebate_agent_2" width="11%" className="">Stakes</th>
                                <th id="header_payout_agent_1" width="11%" className="">P_L</th>
                                <th id="header_tax_agent_1" width="11%" className="">Profit</th>
                                <th id="header_rebate_agent_1" width="11%" className="">Loss</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">Bet Type</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">Status</th>
                                <th id="header_profitLoss_agent_1" width="11%" className="">IP</th>
                           
                            </tr>
                        </thead>
                        <tbody id="content">

                            {betHistoryList.length > 0 && betHistoryList.map(betdatData => <tr>


                                <td>{count++}</td>
                                <td>{moment(betdatData.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</td>
                                <td>{betdatData.user_name}</td>
                                <td>{betdatData.event_name}</td>
                                <td>{betdatData.place_name}</td>
                                <td>{betdatData.betting_type}</td>
                                <td>{betdatData.price_val}</td>
                                <td>{betdatData.stake}</td>
                                <td>{parseFloat(betdatData.p_l).toFixed(2)}</td>
                                <td>{parseFloat(betdatData.profit).toFixed(2)}</td>
                                <td>{parseFloat(betdatData.loss).toFixed(2)}</td>

                                <td>{betdatData.betting_type}</td>
                                <td>{betdatData.status}</td>
                                <td>{betdatData.ip_address.toString().replace('::ffff:','')}</td>
                       
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Main Wrap End */}

        </div>
    );
}
