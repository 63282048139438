import React, { useRef } from 'react';
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import { Link } from "react-router-dom";
import { useState } from 'react'
import moment from 'moment';
import Appconfig from '../config/config';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { CURRENCY_TYPE } from '../config/config';
import Loader from '../assets/images/loading40.gif';
import { useQuery } from 'react-query';
import { createRef } from 'react';

const displaynone = {
    display: "none"
}

function loader_default() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "grid";
    }

}
function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }
}

function Banking() {
    const [refSt, SetrefSt] = useState();
    const btnref = createRef([]);
    btnref.current = [];
    const addToRefs = (el) => {
        if (el && !btnref.current.includes(el)) {
            btnref.current.push(el);

        }
    };

    const params = useParams();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [addedUser, SetAddedUser] = useState("");

    const [profitAndLossCa, setProfitAndLossC] = useState({
        "user_id": params.user_id ? params.user_id : userInfo._id,
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[0] + 'T' + moment().subtract(1, 'days').format("YYYY-MM-DD H:mm").split(' ')[1],
        'to_date': moment().format("YYYY-MM-DD HH:mm:ss"),
        'type': "All",
    })

    const [formData, setformData] = useState({
        password: ''
    });
    const [validationError, SetValidationError] = useState("");
    const [downProfitLoss, setdownProfitLoss] = React.useState([]);
    const [userList, setUserList] = React.useState([]);
    const [FilterList, setFilterList] = React.useState([]);
    const [searched, setSearched] = React.useState("");

    const [balLoader, setBalLoader] = React.useState(false);
    const [MainBalance, setMainBalance] = React.useState(0);
    const [exposure, setExposure] = React.useState(0);
    const [accountStatus, setAccountStatus] = useState("ALL");
    const [selectedCount, setSelectedCount] = React.useState(0);

    const [balanceUpdated, SetbalanceUpdated] = React.useState(false);

    const editCreditRef = async (i) => {
        setUserRefData({
            ...userRefData,
            [i]: document.querySelector(`#creditReferenceLable_${i}`).textContent,
        });
        document.querySelector(`#creditReferenceLable_${i}`).style.display = "none";
        document.querySelector(`#creditReferenceInput_${i}`).style.display = "block";
        document.querySelector(`#editCreditReferenceBtn_${i}`).style.display = "none";
        document.querySelector(`#canelCreditReferenceSubmit_${i}`).style.display = "block";
    }
    const cancelCreditRef = async (i) => {
        document.querySelector(`#creditReferenceLable_${i}`).style.display = "block";
        document.querySelector(`#creditReferenceInput_${i}`).style.display = "none";
        document.querySelector(`#editCreditReferenceBtn_${i}`).style.display = "block";
        document.querySelector(`#canelCreditReferenceSubmit_${i}`).style.display = "none";
        delete userRefData[i];
    }
    const clearCreditRef = () => {
        FilterList.map((row) => {
            document.querySelector(`#creditReferenceLable_${row._id}`).style.display = "block";
            document.querySelector(`#creditReferenceInput_${row._id}`).style.display = "none";
            document.querySelector(`#editCreditReferenceBtn_${row._id}`).style.display = "block";
            document.querySelector(`#canelCreditReferenceSubmit_${row._id}`).style.display = "none";
            delete userRefData[row._id];
        })
    }


    const submitButtonCount = async () => {
        let count = 0;

        
         await Promise.all(
            FilterList.map((row,index) => {
                let dboxes = document.getElementById(`depositMoney-${row._id}-${index}`) ? (document.getElementById(`depositMoney-${row._id}-${index}`).getAttribute("data-name") == 1 ? true : false) : false;
                let wboxes = document.getElementById(`withdrawMoney-${row._id}-${index}`) ? (document.getElementById(`withdrawMoney-${row._id}-${index}`).getAttribute("data-name") == 1 ? true : false) : false;

                let dboxes0 = document.getElementById(`depositMoney-${row._id}-${index}`) ? (document.getElementById(`depositMoney-${row._id}-${index}`).getAttribute("data-name") == 1 ? true : false) : false;
                let wboxes0 = document.getElementById(`withdrawMoney-${row._id}-${index}`) ? (document.getElementById(`withdrawMoney-${row._id}-${index}`).getAttribute("data-name") == 1 ? true : false) : false;


                if (dboxes || wboxes || dboxes0 || wboxes0) {
                    count += 1;
                }
            })

        )

        // alert('submitButtonCount'+userList.length);

        setSelectedCount(count);
    }


    const getUsersNew = () => {
        let config = JSON.stringify({
            master_id: params.user_id ? params.user_id : userInfo._id,
            user_type: params.user_type ? params.user_type : params.user_type,
            page: "AddAccount"
        })
        return axios.post(`${Appconfig.apiUrl}users/getUsersTest`, config, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        setUserList(data.data);
        console.log('data.data',data.data);
        setFilterList(data.data);
    }
    const { isLoading, isError, error, data, refetch } = useQuery(['userData', params.user_id ? params.user_id : userInfo._id], getUsersNew
        , { onSuccess, refetchOnWindowFocus: false })

    isLoading ? loader_default() : loader_remove();


    function handleChangeWith(checkId) {
        document.getElementById(checkId).setAttribute("data-change", "changed");
    }
    const handlePassowrdChange = (event) => {
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
        // console.log(formData);
    }

    function checkValidation() {
        let userNameCheck = userInfo.user_name;
        let passwordCheck = userInfo.password;
        let errorText;
        if (formData.password != "" && formData.password == passwordCheck) {

            errorText = '';
            //   SetAddedUser("enter password");

            return true;
        } else if (formData.password == "") {
            document.querySelector(".message-wrap-new").style.backgroundColor = "red";
            document.querySelector("#message").style.display = "block";
            document.querySelector("#message p").textContent = "enter password";

            return false;

        }
        document.querySelector(".message-wrap-new").style.backgroundColor = "red";
        document.querySelector("#message").style.display = "block";
        document.querySelector("#message p").textContent = "Wrong Password";
        return false;
    }

    function getNextUserType() {
        let sesstionUser = userInfo.user_type;
        let nextType = sesstionUser.toLowerCase().replace(" ", "_");
        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let tempUser = Appconfig.userTypes[index + 1];
        return tempUser;
    }
    function newGetNextUserType(nextType) {

        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let temp = Appconfig.userTypes[index + 1];
        return temp.toLowerCase().replace(" ", "_");
    }
    function currentname(nextType) {

        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let temp = Appconfig.userTypes[index];
        return temp;
    }

    async function handleSubmitPayment(data) {

        // var userArray[];
        var userArray = [];
        var finalArray = [];
 
        data.map((betHistoryList, index) => {
            var deposit = document.getElementById(data[index]['_id'] + '-' + index).value;
      
            var datachange = document.getElementById(data[index]['_id'] + '-' + index).getAttribute("data-change");
            var datatype = document.getElementById(data[index]['_id'] + '-' + index).getAttribute("data-type");
            var remark = document.getElementById(data[index]['_id'] + '-' + index+'_remark').value;
            // alert('remark '+remark);
            // return false;
            if (datachange == "changed") {

                if (datatype == 'W') {
                    userArray.push({ user_id: data[index]['_id'], amount: deposit, action: 'Credit',remark:remark });

                } else {

                    userArray.push({ user_id: data[index]['_id'], amount: deposit, action: 'Debit',remark:remark });
                }

            }

            deposit = document.getElementById(data[index]['_id'] + '-' + index).value = "";

            document.getElementById(data[index]['_id'] + '-' + index+'_remark').value = "";
            let id = data[index]['_id']+'-'+ index;
            // document.getElementById('depositMoney-' + id).style.backgroundColor = "#eee";
            // document.getElementById('withdrawMoney-' + id).style.backgroundColor = "#eee";



        });

  
        if (await checkValidation()) {
            // finalArray.push({master_id:userInfo._id,password : formData.password, userArray:userArray});
            // console.log(finalArray);
            var data = JSON.stringify({
                master_id: params.user_id ? params.user_id : userInfo._id,
                password: formData.password,
                userArray: userArray,
            });
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}ledger/saveBankingInfo`,
                headers: {
                    'Content-Type': 'application/json',
                },

                data: data,
            };

            axios(config)
                .then(function (response) {

                    formData.password = "";

                    if (response.data.result == 1) {
                        handleSubmitCredRef();
                        document.querySelector(".message-wrap-new").style.backgroundColor = "green";
                        document.querySelector("#message").style.display = "block";
                        document.querySelector("#message p").textContent = "Deposite/Withdraw Successful";


                    }
                    else {

                        document.querySelector("#message p").textContent = response.data.message;
                        document.querySelector(".message-wrap-new").style.backgroundColor = "red";
                        document.querySelector("#message").style.display = "block";

                    }
                    // getUsers();
                    refetch();
                    getBalance();
                    clearAll();
                    SetbalanceUpdated(!balanceUpdated)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        setTimeout(function () {
            closeMessage();
        }, 3000);
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    function handleDepositInput(id, type) {
        // alert("working");
        console.log('id',id)
        let dataname = document.getElementById('depositMoney-' + id).getAttribute("data-name");
        let datanameWithdraw = document.getElementById('withdrawMoney-' + id).getAttribute("data-name");

        if (dataname == 0) {
            document.getElementById('depositMoney-' + id).style.backgroundColor = "#5BAB03";
            document.getElementById('depositMoney-' + id).style.color = "white";
            document.getElementById('depositMoney-' + id).style.borderColor = "rgba(0,0,0,0.2)";
            document.getElementById('depositMoney-' + id).style.boxShadow = "inset 0 1px 3px 0 rgb(0 0 0 / 50%)";
            document.getElementById('depositMoney-' + id).setAttribute("data-name", 1);

        } else {

            document.getElementById('depositMoney-' + id).style.backgroundColor = "#eee";
            document.getElementById('depositMoney-' + id).style.color = "black";
            document.getElementById('depositMoney-' + id).style.borderColor = "#bbb";
            document.getElementById('depositMoney-' + id).style.boxShadow = "none";
            document.getElementById('depositMoney-' + id).setAttribute("data-name", 0);

        }
        if (datanameWithdraw == 1) {
            document.getElementById('withdrawMoney-' + id).style.backgroundColor = "#eee";
            document.getElementById('withdrawMoney-' + id).style.color = "black";
            document.getElementById('withdrawMoney-' + id).style.borderColor = "#bbb";
            document.getElementById('withdrawMoney-' + id).style.boxShadow = "none";
            document.getElementById('withdrawMoney-' + id).setAttribute("data-name", 0);

        }
        document.getElementById(id).setAttribute("data-type", "W");
        submitButtonCount();

    }
    function handleWithdrawInput(id, type) {
        let nid = id.split("-")[1];
        btnref.current[nid].disabled = false;
        SetrefSt(btnref)
        let dataname = document.getElementById('withdrawMoney-' + id).getAttribute("data-name");
        let datanameDeposit = document.getElementById('depositMoney-' + id).getAttribute("data-name");
        let fulldataid = document.getElementById('fullWithdrawMoney-' + id).getAttribute("data-name");

        if (dataname == 0) {
            document.getElementById('withdrawMoney-' + id).style.backgroundColor = "#D0021B";
            document.getElementById('withdrawMoney-' + id).style.color = "white";
            document.getElementById('withdrawMoney-' + id).style.boxShadow = "inset 0 1px 3px 0 rgb(0 0 0 / 50%)";
            document.getElementById('withdrawMoney-' + id).style.borderColor = "rgba(0,0,0,0.2)";
            // document.getElementById('fullWithdrawMoney-'+id).removeAttribute("disabled");
            // document.getElementById('fullWithdrawMoney-' + id).disabled = false;

            document.getElementById('withdrawMoney-' + id).setAttribute("data-name", 1);

        } else {

            document.getElementById('withdrawMoney-' + id).style.backgroundColor = "#eee";
            document.getElementById('withdrawMoney-' + id).style.color = "black";
            document.getElementById('withdrawMoney-' + id).style.borderColor = "#bbb";
            document.getElementById('withdrawMoney-' + id).style.boxShadow = "none";
            // document.getElementById('fullWithdrawMoney-' + id).disabled = true;
            document.getElementById('withdrawMoney-' + id).setAttribute("data-name", 0);

        }
        if (datanameDeposit == 1) {
            document.getElementById('depositMoney-' + id).style.backgroundColor = "#eee";
            document.getElementById('depositMoney-' + id).style.color = "black";
            document.getElementById('depositMoney-' + id).style.borderColor = "#bbb";
            document.getElementById('depositMoney-' + id).style.boxShadow = "none";
            document.getElementById('depositMoney-' + id).setAttribute("data-name", 0);

        }

        document.getElementById(id).setAttribute("data-type", "D");
        submitButtonCount();



    }

    const handleAccountStatus = (event) => {
        setAccountStatus(event.target.value);
        requestSearch(event.target.value)
    }

    function handleFullInput(id, backid) {

        let data = userList.filter((row) => row._id == backid);
        document.getElementById(id).setAttribute("data-change", "changed");
        document.getElementById(id).value = data[0].balance;
        // let fulldataid = document.getElementById('fullWithdrawMoney-'+id).getAttribute("data-name");
        // let dataWithdraw = document.getElementById('withdrawMoney-'+id).getAttribute("data-name");
        // if (dataWithdraw == 1 ) {

        // } else {
        //     document.getElementById('fullWithdrawMoney-'+id).setAttribute("disabled");
        // }
    }
    const handleSearch = (event) => {
        setSearched(event.target.value);
    }

    async function requestSearch(aStatus = null) {
        let filteredRows = [];
        let accountStatus1;
        if (aStatus) {
            accountStatus1 = aStatus;
        }
        else {
            accountStatus1 = accountStatus;
        }
        if (accountStatus1 == "ALL") {

            filteredRows = await userList.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase());
            });
        }
        if (accountStatus1 == "SUSPENDED") {

            filteredRows = await userList.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase()) && row.is_betting_open == "No";
            });
        }
        if (accountStatus1 == "LOCKED") {
            filteredRows = await userList.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase()) && row.is_locked == "Yes";
            });

        }
        if (accountStatus1 == "ACTIVE") {

            filteredRows = await userList.filter((row) => {
                return row.user_name.toLowerCase().includes(searched.toLowerCase()) && row.is_betting_open == "Yes";
            });

        }

        setFilterList(filteredRows);

    };


    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    React.useEffect(() => {
        getBalance();
        // profitLossBanking()
        // getUsers();
    }, [params.user_id]);

    function getBalance() {

        var data = JSON.stringify({
            user_id: params.user_id ? params.user_id : userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setBalLoader(false);
                if (response.data.result == 0) {
                } else {
                    setExposure(parseFloat(response.data.resultData.exposure).toFixed(2));
                    setMainBalance(parseFloat(response.data.resultData.balance).toFixed(2));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function clearAll() {
        clearCreditRef();
        const fullwithdrawbutt = document.getElementsByName("widrawanddeposit");

        for (var box of fullwithdrawbutt) {
            box.value = "";
        }
        const depositbutt = document.getElementsByClassName("depositbutt");
        for (var nbox of depositbutt) {
            nbox.style.backgroundColor = "#eee";
            nbox.style.color = "black";
            nbox.style.borderColor = "#bbb";
            nbox.style.boxShadow = "none";
            nbox.setAttribute("data-name", 0);
        }

        const wdepositbutt = document.getElementsByClassName("withdrawbutt");
        for (var wbox of wdepositbutt) {
            wbox.style.backgroundColor = "#eee";
            wbox.style.color = "black";
            wbox.style.borderColor = "#bbb";
            wbox.style.boxShadow = "none";
            wbox.setAttribute("data-name", 0);
        }
        setSelectedCount(0);
        // getUsers();
        refetch();
        getBalance();
    }
    const [currentUserCreditRef, setCurrentUserCreditRef] = useState(0);

    const [userRefData, setUserRefData] = useState({});

    const handleCredRefChange = (event, id) => {
        setUserRefData({
            ...userRefData,
            [id]: event.target.value,
        });
    };

    const handleSubmitCredRef = () => {

        var data = JSON.stringify(userRefData);
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/changeCredRefMulti`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                // getUsers();
                // refetch();
                // closeReferenceModal();
                clearCreditRef();
            })
            .catch(function (error) {
                //            console.log(error);
            });

    }

    function showReferenceModal(user_id, index) {
        setCurrentUserCreditRef(FilterList[index].credit_reference ? FilterList[index].credit_reference : "00.00")
        setUserRefData({
            ...userRefData,
            user_id: user_id,
            user_name: FilterList[index].user_name
        })
        document.querySelector("#creditReferenceModel").style.display = "block";
    }
    const closeReferenceModal = () => {
        document.querySelector("#creditReferenceModel").style.display = "none";
    }
    return (
        <div className="dashboard">
            <div id="poker_loading" className="loading-wrap" style={{ 'marginTop': '50px' }}>
                <ul className="loading">
                    <li><img src={Loader} /></li>
                    <li>Loadingâ¦</li>
                </ul>
            </div>
            {/* Top Start */}
            <Header balanceUpdated={balanceUpdated} />

            <div id="mainWrap" className="main_wrap">
                <div id="message" className="message-wrap-new">
                    <Link to="#" onClick={closeMessage} className="btn-close">Close</Link>
                    <p>{validationError}{addedUser}</p>
                </div>
                <h2>Banking</h2>
                <div className="total_all">

                    <div className="search-wrap" id="userSearchUl">
                        <div >
                            <input className="search-input" type="text" onChange={handleSearch} name="searchusername" id="userId" placeholder="Find member..." />
                            <button className="search-but" onClick={() => requestSearch()} id="searchUserId">Search</button>
                        </div>
                    </div>

                    <ul className="input-list" id="accountStatusUl">
                        <li><strong>Status</strong></li>
                        <li>
                            <select className="" name="accountStatus" id="accountStatus" onChange={handleAccountStatus}>

                                <option defaultValue="0" value="ACTIVE">ACTIVE
                                </option>

                                <option defaultValue="1" value="SUSPENDED">SUSPENDED
                                </option>

                                <option defaultValue="2" value="LOCKED">LOCKED
                                </option>

                                <option defaultValue="-1" value="ALL">ALL</option>
                            </select>
                        </li>
                    </ul>
                    <div className="agent_path">
                        <ul id="agentPath" className="agent_path-L" style={displaynone}>
                            <li id="path6" className="" style={displaynone}>
                                <Link to="/">
                                    <span className="lv_0">
                                        COM
                                    </span>
                                    <strong></strong>
                                </Link>
                            </li>

                            <li id="pathCurrency" className="currency_tag" style={displaynone} >
                                <Link to="javascript: void(0);">
                                    <span className=""></span>
                                    <strong></strong>
                                </Link>
                            </li>

                            <li id="path5" className="" style={displaynone}>
                                <Link to="javascript: void(0);">
                                    <span className="lv_1">
                                        SS
                                    </span>
                                    <strong></strong>
                                </Link>
                            </li>

                            <li id="path4" className="" style={displaynone}>
                                <Link to="javascript: void(0);">
                                    <span className="lv_2">
                                        SUP
                                    </span>
                                    <strong></strong>
                                </Link>
                            </li>

                            <li id="path3" className="" style={displaynone}>
                                <Link to="javascript: void(0);">
                                    <span className="lv_3">
                                        MA
                                    </span>
                                    <strong></strong>
                                </Link>
                            </li>

                            <li id="path0" className="" style={displaynone}>
                                <Link to="javascript: void(0);">
                                    <span className="lv_4">
                                        PL
                                    </span>
                                    <strong></strong>
                                </Link>
                            </li>
                            <ul className="account_pop" id="accountPop">
                                <li id="popTmp" style={displaynone}>
                                    <Link to=''></Link>
                                </li>
                            </ul>
                        </ul>
                    </div>
                    {/* <Link id="refresh" className="btn_replay" to="#"><img src={Trans} /></Link> */}
                    {/* <Link className="add_member" to="#">
                            <img src={Trans} />Add Master Agent</Link> */}
                </div>

                {/* Report Over Wrap Start */}

                <div className="report_over-wrap" style={{ 'max-height': "calc(100% - 208px)" }}>
                    <div className="function-wrap clearfix">
                        <dl className="banking-head float-L">
                            <dt>Your Balance</dt>
                            <dd id="yourBalance"><span>{CURRENCY_TYPE}</span>{MainBalance}</dd>
                        </dl>
                        <ul className="input-list float-R" style={displaynone}>
                            <li><label>Sort by</label></li>
                            <li>
                                <select id="sort">
                                    <option value="userId">UID</option>
                                    <option value="site">Site</option>
                                    <option value="currencyType">Currency</option>
                                </select>
                            </li>
                        </ul>
                    </div>

                    <table style={displaynone}>
                        <tr id="tempTr">
                            <td className="td-uid" id="userId"></td>
                            <td id="siteCol" style={displaynone}></td>
                            <td id="currencyTypeCol" style={displaynone}></td>
                            <td id="balance"></td>
                            <td id="availableBalance"></td>
                            <td id="userExposure"></td>

                            <td id="bankingBalance" className="border-l check_date">
                                <span id='bankingBalance_updateDate'></span>
                            </td>

                            <td className="DW-amount">
                                <ul className="btn_list-DW">
                                    <li><Link id="dBtn" className="btn" to="#">D</Link></li>
                                    <li><Link id="wBtn" className="btn" to="#">W</Link></li>
                                </ul>
                                <input id="amount" name="amount" className="" type="text" placeholder="0" />
                                <Link id="fullBtn" className="btn float-L disable">Full</Link>
                            </td>
                            <td className="credit-amount">

                                <Link to="#" id="userCreditReference"></Link>
                                <input id="editCreditReference" name="editCreditReference" className="" type="text" placeholder="0" maxlength="18" style={displaynone} />
                                <Link id="editCreditReferenceBtn" to="#" className="btn">Edit</Link>
                                <Link id="cancelCreditReferenceBtn" to="#" className="btn" style={displaynone}>Cancel</Link>
                            </td>
                            <td id="userReferencePL"></td>
                            <td className="border-l"><input id="remark" className="" type="text" placeholder="Remark" /></td>
                            <td className="full-amount">
                                <Link id="log" className="btn">Log</Link>
                            </td>
                        </tr>
                        <tr id="tempBalanceTr" className="expand first expand-balance">
                            <td ></td>
                            <td colspan="10">
                                <img className="expand-arrow" src="/images/transparent.gif" />
                                <table>
                                    <tbody id="tempVendorContent">
                                        <tr>
                                            <th width="9%" className="align-L">Game</th>
                                            <th width="11%">Balance</th>
                                            <th width="7%"><Link id="recallAll" to="#" className="btn-recall">Recall All</Link></th>
                                            <th ></th>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr id="tempVendorTr">
                            <td id="vendorTitle" className="align-L">Houise</td>
                            <td id="vendorBalance">200,000,000,000.00</td>
                            <td><Link id="recall" to="#" className="btn-recall">Recall</Link></td>
                            <td></td>
                        </tr>
                        <tr id="noDataTempTr">
                            <td className="no-data" colspan="11">
                                <p>No Data</p>
                            </td>
                        </tr>

                        <tr id="totalTempTr" className="total">
                            <td className="align-L">Total</td>
                            <td id="totalSite" style={displaynone}></td>
                            <td id="totalCurrencyType" style={displaynone}></td>
                            <td id="totalBalance"></td>
                            <td id="totalAvailableBalance"></td>
                            <td id="totalExposure"></td>

                            <td></td>

                            <td id="totalCreditReference" className="" colspan="2"></td>
                            <td id="totalReferencePL"></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <table id="table_transfer" className="table01 tab-transfer tab-banking">
                        {/* <col span="1" width="190" />
                            <col span="1" width="120" style={displaynone} id="siteColumn" />
                            <col span="1" width="120" style={displaynone} id="currencyTypeColumn" />
                            <col span="1" width="120" />
                            <col span="1" width="120" />
                            <col span="1" width="120" />
                            
                            <col span="1" width="220" />
                            
                            <col span="1" width="280" />
                            <col span="1" width="230" />
                            <col span="1" width="200" />
                            <col span="1" width="120" />
                            <col span="1" width="80" /> */}
                        <tr>
                            <th id="userIdTH" className="align-L sort-this">Sr. No</th>
                            <th id="userIdTH" className="align-L sort-this">UID</th>
                            {/* <th id="siteTH" style={displaynone}>Site</th>
                                <th id="currencyTypeTH" style={displaynone}>Currency</th> */}
                            <th>Balance</th>
                            <th>Available D/W</th>
                            <th>Exposure</th>

                            <th className="align-C border-l">Deposit / Withdraw</th>
                            <th className="border-l">Credit Reference</th>
                            <th className="">Reference P/L</th>
                            <th className="border-l">Remark</th>
                            <th className="full-amount">
                                <Link id="allLog" href='javascript:void(0)' className="btn-send" onClick={() => window.open(`/acStatementTab/${userInfo.user_name}/${userInfo._id}`, "MsgWindow", "width=900,height=900")}>All Log</Link>
                            </th>
                        </tr>
                        <tbody id="content">
                            {
                            FilterList.length > 0 && FilterList.map((rowfancy, i) => (
                                <tr>
                                  
                                    <td style={{textAlign:"center"}}>{ i + 1}</td>
                                    <td>{ rowfancy.user_name}</td>
                                    <td>{rowfancy.noDeductbalance}</td>
                                    <td>{rowfancy.balance}</td>
                                    <td>{rowfancy.exposure}</td>
                                    <td style={{ border: "1px solid #7E97A7", width: "255px" }}>
                                        <button className="depositbutt" data-name="0" id={`depositMoney-${rowfancy._id + '-' + i}`} onClick={() => handleDepositInput(rowfancy._id + '-' + i, 'depositBanking')}>D</button>
                                        <button className="withdrawbutt" id={`withdrawMoney-${rowfancy._id + '-' + i}`} onClick={() => handleWithdrawInput(rowfancy._id + '-' + i, 'withdrawBanking')} data-name="0">W</button>
                                        <input data-type="" type="text" data-change="" onKeyUp={() => handleChangeWith(rowfancy._id + '-' + i)} name="widrawanddeposit" id={rowfancy._id + '-' + i} />
                                        <button ref={addToRefs} className="fullwithdrawbutt" id={`fullWithdrawMoney-${rowfancy._id + '-' + i}`} onClick={() => handleFullInput(rowfancy._id + '-' + i, `${rowfancy._id}`)} disabled={refSt ? refSt.current[i].disabled : true} data-name="0">Full</button>
                                    </td>


                                    <td className="credit-amount">
                                        {/* <a href="javascript:void(0);" id="userCreditReference" style={{ display: "inline", marginRight: 9 }}>{rowfancy.credit_reference ? rowfancy.credit_reference : "00.00"}</a> */}
                                        <span style={{ float: "left" }} id={`creditReferenceLable_${rowfancy._id}`}>{rowfancy.credit_reference}</span>
                                        <input id={`creditReferenceInput_${rowfancy._id}`} onChange={(e) => handleCredRefChange(e, rowfancy._id)} style={{ display: "none" }} value={userRefData[rowfancy._id]} className="" type="text" placeholder="Credit Reference" />
                                        <a id={`editCreditReferenceBtn_${rowfancy._id}`} href="javascript:void(0);" onClick={() => editCreditRef(rowfancy._id, i)} className="btn" style={{ display: "block" }}>Edit</a>
                                        <a id={`canelCreditReferenceSubmit_${rowfancy._id}`} style={{ display: "none" }} href="javascript:void(0);" onClick={() => cancelCreditRef(rowfancy._id)} className="btn">Cancel</a>
                                    </td>
                                    <td><span className='inttyped' data-value={rowfancy.credit_reference ? (parseFloat(rowfancy.noDeductbalance) - parseFloat(rowfancy.credit_reference)).toFixed(2) : parseFloat(rowfancy.noDeductbalance).toFixed(2)}>{rowfancy.credit_reference ? (parseFloat(rowfancy.noDeductbalance) - parseFloat(rowfancy.credit_reference)).toFixed(2) : parseFloat(rowfancy.noDeductbalance).toFixed(2)}</span></td>
                                    <td className="border-l">
                                        <input  className="" type="text" placeholder="Remark"  id={rowfancy._id + '-' + i+'_remark'}/>
                                    </td>
                                    <td className="border-r full-amount">
                                        <a id="log" href='javascript:void(0)' onClick={() => window.open(`/acStatementTab/${userRefData.user_name}/${userRefData.user_id}`, "MsgWindow", "width=900,height=900")} className="btn">Log</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <ul style={displaynone}>
                            <li id="prev"><Link to="#">Prev</Link></li>
                            <li id="next"><Link to="#">Next</Link></li>
                            <li id="pageNumber"><Link to="#"></Link></li>
                            <li id="more"><Link to="#">...</Link></li>
                            <input type="text" id="goToPageNumber" maxlength="6" size="4" />
                            <li><Link id="goPageBtn">GO</Link></li>
                        </ul>

                        <ul id="pageNumberContent" className="pages">
                        </ul>
                    </div>
                </div>
                {/* Top End */}
                <div className="submit-wrap" id="settlementBar">

                    <ul>
                        <li><Link id="clearAllBtn" onClick={() => clearAll()} to="#" className="btn">Clear All</Link></li>
                        <li className="submit-payment">
                            <input id="paymentPassword" onChange={handlePassowrdChange} type="password" name="password" placeholder="Password" value={formData.password} />
                            <Link id="submit" to="#" onClick={() => handleSubmitPayment(FilterList)} className="btn-send">Submit <span id="submitCount">{selectedCount}</span> Payment</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="creditReferenceModel" className="pop_bg" style={displaynone}>
                <div className="pop_refer">
                    <Link className="close_pop" onClick={closeReferenceModal}>close_pop</Link>
                    <h3>Credit Reference Edit</h3>

                    <div className="wrap-refer_edit">
                        <dl>
                            <dt>Current</dt>
                            <dd>
                                <Link className="btn" id="creditReferenceLog" href='javascript:void(0)' onClick={() => window.open(`/oldCredits/${userRefData.user_name}/${userRefData.user_id}`, "MsgWindow", "width=600,height=900")}>Log</Link>
                                <strong id="creditReference">{currentUserCreditRef}</strong>
                            </dd>
                        </dl>

                        <dl>
                            <dt>New</dt>
                            {/* <dd><input type="text" id="newCreditReference" name="credit_reference" onChange={handleCredRefChange} placeholder="Enter" /></dd> */}
                        </dl>

                        <dl>
                            <dt>Password</dt>
                            <dd>
                                {/* <input id="changeCreditReferencePassword" name='password' onChange={handleCredRefChange} type="password" placeholder="Enter" /> */}
                            </dd>
                        </dl>
                    </div>
                    <ul className="btn-wrap">
                        <li style={{ display: "grid" }}><a id="changeCreditReferenceBtn" className="btn-send" href="javascript:void(0)" onClick={handleSubmitCredRef}>Submit</a></li>
                    </ul>
                </div>
            </div>
            {/* Top End */}
        </div>
    )
}

export default Banking