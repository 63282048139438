import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import {
    Table,
    TableBody,
    CardContent,
    Card,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    Dialog,
    TableRow,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Grid,
    Button,
    List,
    ListItemText,
    Modal,
    Typography,
    ListItem,
    Fade,
    Backdrop,
    TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import Appconfig from "../config/config";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function AddBanner() {
    const history = useHistory();
    const [validationError, SetValidationError] = useState("");
    const [addedUser, SetAddedUser] = useState("");
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [opens, setOpens] = React.useState(false);
    const [editopens, setEditOpens] = React.useState(false);

    const [formData, setFormData] = useState({
        amount: "",
        password: "",
        remarks: "",
    });

    const [currentBalance, setCurrentBalance] = React.useState(0);
    const [newBalance, setNewBalance] = React.useState(0);
    const [bannerList, setBannerList] = React.useState([]);
    const onFileChange = event => {
        if (event.target.files && event.target.files[0]) {

            /*Maximum allowed size in bytes
              5MB Example
              Change first operand(multiplier) for your needs*/
            const maxAllowedSize = 100 * 1024;
            if (event.target.files[0].size > maxAllowedSize) {
                alert("MAX 100kb file size allowed");
                event.target.value = null;
                setSelectedFile(null);
            } else {
                setSelectedFile(event.target.files[0]);
            }
        }

    };

    useEffect(() => {
        if (userInfo.user_type != "Super Admin") {
            history.push("/");
        }
        getBalance();
        getBanner();
    }, []);

    function getBalance() {
        var data = JSON.stringify({
            user_id: userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result == 0) {
                } else {
                    setCurrentBalance(parseFloat(response.data.resultData.balance));
                    setNewBalance(parseFloat(response.data.resultData.balance));
                }
                // console.log(chipList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function deleteBanner(id) {
        var data = JSON.stringify({
            id: id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}banner/deleteBanner`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                if (response.data.result == 0) {
                } else {
                    getBanner();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function getBanner() {
        var data = JSON.stringify({
            site_code: userInfo.site_code,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}banner/getDashboardBanner`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                if (response.data.result == 0) {
                } else {
                    setBannerList(response.data.resultData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function uploadBanner(id) {

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fileName", selectedFile.name);
        formData.append("site_code", userInfo.site_code);
        console.log(selectedFile);
        axios.post(`${Appconfig.apiUrl}banner/addDashboardBanner`, formData);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        uploadBanner();
        getBanner();
    };

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    return (


        <div className="dashboard bethistory-user">
            <>
                <Header />
                {/* Message */}

                <h2 >Add Banner</h2>
                <Grid container style={{ display: "grid", placeItems: "center", backgroundColor: "#E0E6E6", padding: 20, }}>
                    <div id="message" className="message-wrap">
                        <Link to="#" onClick={closeMessage} className="btn-close">Close</Link>
                        <p>{addedUser}</p>
                    </div>
                    <form onSubmit={handleSubmit} autoComplete="off">

                        <dl className="border_b">
                            <dt>upload Banner <b>size(widthxheight) 393x97.25</b> </dt>
                            <dd>
                                <input type="file" onChange={onFileChange} />
                            </dd>

                        </dl>
                        <div className="btn_box">
                            {selectedFile ? <button id="createBtn" className="btn-send" type="submit">Submit</button> : ""}

                        </div>
                    </form>
                </Grid>

                <table id="" className="table01 " >
                    <tbody>
                        <tr>
                            <th  width=""  >Banner</th>
                            <th  width="10%" >Delete</th>

                        </tr>

                        {bannerList?.map((row) => (
                            <tr><td>
                                <a href={Appconfig.apiUrl + 'banner/' + row.image_name} target="_blank"><img src={Appconfig.apiUrl + 'banner/' + row.image_name} alt={row.image_name} style={{ width: "10%" }} /></a>
                            </td>
                                <td>
                                    <button id="createBtn" onClick={() => deleteBanner(row._id)}>delete</button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </>
        </div>

    );
}
