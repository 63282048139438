import "../assets/fullmarket.css"
import "../assets/style.css"
// import "../assets/Responsive.css"

import React, { useRef, useContext } from 'react';
import Header from '../admin/Header';
import { ToastContainer, toast } from "react-toastify";
import { SocketContext } from "../context/socket";
import { Modal, Tooltip, Fade, IconButton, TableContainer, Paper, TableFooter, Backdrop, ClickAwayListener, Grid, makeStyles, Table, TableRow, TableCell, TableHead, TableBody, Link, Box, Typography, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, Button, AppBar, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
//IMAGES
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Replay from '@material-ui/icons/Replay';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SideNav from '../admin/SideNav';
import background from '../assets/images/bg-purple.png';
import KV1 from '../assets/images/KV2.png';
import pin from '../assets/images/pin.png';
import Greypin from '../assets/images/grey-pin-off.svg';
import DarkPin from '../assets/images/dark-pin-off.svg';
import transparent from '../assets/images/transparent.gif';
import bookmarkwithtime from '../assets/images/bookmarkwithtime.png';
import MinMax from '../assets/images/min-max.svg';
import Depth from '../assets/images/depth.svg';
// import Time from '../assets/images/time.png';
import Time from '../assets/images/clock-green.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import BackballDisabled from '../assets/images/bg-backall-disabled.png';
import SimplePin from '../assets/images/simple-pin.svg';
import BtnDelete from '../assets/images/btn-delete.svg';

import { useParams, useHistory } from "react-router-dom";
import { MobileView, BrowserView, isMobile } from 'react-device-detect';
import Appconfig from "../config/config";
import axios from 'axios';
import Loader from '../assets/images/loading40.gif';
import LiveTvIcon from '@material-ui/icons/LiveTv';

function createData(name1, value, name2, play, link, calories, fat, carbs, protein) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein };
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
        height: "100%",
    },
    tableHeight: {
        height: 'calc(100vh - 120px)',
    },
}));
const FullMarket = () => {
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView();
    const history = useHistory();
    const socket = useContext(SocketContext);
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const params = useParams();
    const [postionFancyList, setPostionFancyList] = React.useState([]);
    const [positionOpen, setPositionOpen] = React.useState(false);
    const [positionBookOpen, setPositionBookOpen] = React.useState(false);
    const [positionMatchOpen, setPositionMatchOpen] = React.useState(false);

    const [showtv, setShowtv] = React.useState(false);

    const eventId = params.event_id;
    const classes = useStyles();
    const [state, setState] = React.useState({
        checkedB: false,
    });

    const [isBetPlaced, setIsBetPlaced] = React.useState(false);
    const [bookButton, setBookButton] = React.useState([]);
    const [currentPositionList, setCurrentPositionList] = React.useState([]);
    const [prevRunners, setRunners] = React.useState([]);
    const [prevRunnersBm, setRunnersBm] = React.useState([]);
    const [betShow, setBetShow] = React.useState(false);
    const [EventData, setEventData] = React.useState([]);
    const [StakeValue, setStakeValue] = React.useState(0);
    const [betSlipList, setBetSlipList] = React.useState([]);
    const [placing, setPlacing] = React.useState(false);
    const [lossValue, setLossValue] = React.useState(0);
    const [newTemp, setNewTemp] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [tooltipOpen, settooltipOpen] = React.useState(false);
    const [toolBooktipOpen, setBooktooltipOpen] = React.useState(false);
    const [toolFancytipOpen, setFancytooltipOpen] = React.useState(null);
    const [defaultStake, setDefaultStake] = React.useState(0);
    const [BetPlaceData, setBetPlaceData] = React.useState({
        event_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
    });
    const [ProfitValue, setProfitValue] = React.useState(0);
    const [refresh, setRefresh] = React.useState(false);


    function getFancysExposure() {
        var data = JSON.stringify({
            event_id: params.event_id,
            user_id: userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getFancysExposure`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {

                document.querySelector(".fancywin").style.display = "none";
                if (response.data.resultData.length > 0) {

                    response.data.resultData.map((row, index) => {
                        if (document.querySelector(`.win_${params.event_id}_${row.selection_id}`)) {
                            if (document.querySelector(`#fancyBetBookBtn_${params.event_id}_${row.selection_id}`)) {
                                document.querySelector(`#fancyBetBookBtn_${params.event_id}_${row.selection_id}`).style.display = "block";
                            }

                            document.querySelector(`.win_${params.event_id}_${row.selection_id}`).style.display = "block";
                            document.querySelector(`.win_${params.event_id}_${row.selection_id}`).setAttribute("data-value", row.min);
                            document.querySelector(`.win_${params.event_id}_${row.selection_id}`).textContent = Math.abs(row.min);

                        }


                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getBetSlip() {
        let user_id;
        if (!params.user_id) {
            user_id = userInfo._id;
        }
        else {
            user_id = params.user_id;
        }
        var data = JSON.stringify({
            user_id: user_id,
            event_id: eventId,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getBettingsByUserAndEventId`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setBetSlipList(response.data.resultData);
                // console.log('betslip list ', response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleTooltipClose = () => {
        settooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        settooltipOpen(true);
    };

    const handleBookTooltipClose = () => {
        setBooktooltipOpen(false);
    };

    const handleBookTooltipOpen = () => {
        setBooktooltipOpen(true);
    };

    const handleFancyTooltipClose = () => {
        setFancytooltipOpen(false);
    };

    function handleFancyTooltipOpen(index) {
        // alert(index);
        setFancytooltipOpen(toolFancytipOpen === index ? null : index);
    };

    const placeStakeValue = (stake) => {
        // stake = parseFloat(stake);
        // setStakeValue(stake);
        // let profit = parseFloat(BetPlaceData.price) * parseFloat(stake) - stake;
        // setProfitValue(profit);

        setStakeValue(parseFloat(stake));
        // let profit;
        // if (BetPlaceData.is_fancy) {
        //   profit = stake;
        // }
        // else {
        //   profit = parseFloat(BetPlaceData.price) * parseFloat(stake) - stake;
        // }

        // setProfitValue(profit);
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };


    function getCurrentPosition(type) {
        let user_id;
        if (!params.user_id) {
            user_id = userInfo._id;
        }
        else {
            user_id = params.user_id;
        }
        var data = JSON.stringify({
            user_id: user_id,
            match_id: eventId,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getCurrentPositionWithBm`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result == 1) {
                    setCurrentPositionList(response.data.resultData);
                }
                else {
                    setCurrentPositionList([]);
                }
                if (type === "bookmaker") {
                    setPositionMatchOpen(false);
                    setPositionBookOpen(true);
                } else {
                    setPositionBookOpen(false);
                    setPositionMatchOpen(true);
                }

                // console.log('betslip list ', response.data.resultData);


            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const handleBetInputChange = (e) => {
        setStakeValue(e.target.value);
    }
    const rows = [
        createData('Daring king', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '1 - 1 ', 'maxime', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('king', '3 - 1 ', 'cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring', '2 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Forge', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
    ];

    const positionClose = () => {
        setPositionOpen(false);
    }
    const positionBookClose = () => {
        setPositionBookOpen(false);
    }
    const positionMatchClose = () => {
        setPositionMatchOpen(false);
    }
    const [tvUrl, setTvUrl] = React.useState("");
    const [scoreUrl, setScoreUrl] = React.useState("");
    const [iframeHeight, setScoreIframeHeight] = React.useState("");
    const [chipList, setchipList] = React.useState([]);

    function getDefaultStakes() {

        var data = JSON.stringify({
            user_id: userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getDefaultStakes`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {

                if (response.data.result == 0) {
                } else {
                    setDefaultStake(response.data.resultData.default_stake)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    let fancyExpInterval;
    React.useEffect(() => {

        if (userInfo) {
            getChips();
            getDefaultStakes()
            getBetSlip();
            fancyExpInterval = setInterval(getFancysExposure, 2000);

        }
        window.sessionStorage.setItem("fancyExpInterval", fancyExpInterval);
        window.addEventListener("message", (event) => {
            if (event.origin == "https://central.satsport247.com'")
                console.log("dont remove", "socre width")
            setScoreIframeHeight(event.data.scoreWidgetHeight)
        }, false);
        let userName = userInfo.user_name;
        let room = eventId;

        socket.emit("join_room", {
            username: userName,
            roomName: room
        });
        // console.log("profit,loss", [ProfitValue, lossValue])
        socket.on('fancy_update', function (data) {

            // console.log('FANCY UPDATE:', data);
            // console.log(data);
            fancyHtml(data);
        });


        socket.on("market_update", (data) => {

            // return false;
            //events   
            // console.log('MARKET UPDATE:', data);

            var matchId = eventId;
            // if (MarketId) {

            // var market = data.marketodds[matchId]

            if (data.marketodds.length > 0) {

                var market = data.marketodds.find(o => o.event_id == matchId);


                // console.log('market',market);
                if (market) {
                    if (market.market_types.length > 0) {
                        market.market_types.map((market_type, index) => {
                            if (market_type.hasOwnProperty("runners")) {
                                if (market_type.runners.length > 0) {

                                    market_type.runners.map((runner, index) => {




                                        if (market_type.status == 'OPEN') {
                                            // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeOut();
                                        } else {

                                            // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeIn();

                                            // document.getElementsByClassName('status_matchBoxs_' + market_type.market_id.replace('.', '')).text(market_type.status);
                                        }
                                        // console.log('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id[0]);
                                        var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)[0];

                                        if (runner.status == 'SUSPENDED') {



                                            if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                                                if (match_odd_row) {
                                                    match_odd_row.parentElement.setAttribute('data-title', "SUSPENDED");
                                                    match_odd_row.parentElement.classList.add("suspended");
                                                }
                                                if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                                                    document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;

                                                } else {
                                                    document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                                                }


                                                if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                                                    document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;

                                                } else {
                                                    document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                                                }

                                            }

                                        } else {

                                            if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                                                if (match_odd_row) {
                                                    match_odd_row.parentElement.setAttribute('data-title', "");
                                                    match_odd_row.parentElement.classList.remove("suspended");
                                                }
                                                if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {

                                                    document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);

                                                } else {
                                                    document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                                                }


                                                if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                                                    document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);

                                                } else {
                                                    document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                                                }
                                            }
                                            // if (parseFloat(document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_price)) {
                                            //     document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_price;

                                            // } else {
                                            //     document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_price;
                                            // }

                                            // if (parseFloat(document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_price)) {
                                            //     document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_price;

                                            // } else {
                                            //     document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_price;
                                            // }

                                        }


                                        /************************Size */

                                        // if (parseFloat(document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_3_size)) {
                                        //     document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                                        // } else {
                                        //     document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                        // }


                                        // if (parseFloat(document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_2_size)) {
                                        //     document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                                        // } else {
                                        //     document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                        // }
                                        if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                                            if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
                                                document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);

                                                // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                                            } else {

                                                document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                                                //  document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                            }


                                            if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
                                                document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);

                                                // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                                            } else {

                                                document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                                                // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                            }
                                        }
                                        // if (parseFloat(document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_size)) {
                                        //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                                        // } else {
                                        //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                        // }

                                        // if (parseFloat(document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_size)) {
                                        //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                                        // } else {
                                        //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                        // }


                                    });
                                }
                            }
                        });
                    }
                }

            }
        });
        getTvUrl(eventId)
        getScoreUrl(eventId)
        getEventData()
        setPositionBookOpen(false);
        setPositionMatchOpen(false);

    }, [params.user_id, refresh])



    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        //  var MatchMarketTypes = 'M';
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        // console.log("tempRunnersv", runners);
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;


            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);


                    } else {
                        curr = winloss + ((priceVal * stake) - stake);


                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));


                    } else {

                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));


                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = Math.round(curr);


            // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {

                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss)

                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss);
            }

            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).setAttribute('data-value', currV)
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML = Math.abs(currV);

                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).setAttribute('data-value', currV)

                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).innerHTML = Math.abs(currV);
            }
        }
    }

    function fancyHtml(data) {
        // console.log("kaka", data);
        var superiors = ["270", "259", "185", "177", "30"];
        var matchId = eventId;
        if (document.getElementById('fancyAPI')) {
            // document.getElementById('fancyAPI').innerHTML = `<tr class="MuiTableRow-root yelloBG"><td class="MuiTableCell-root MuiTableCell-body table_first_row colorDanger"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body bg_odd MuiTableCell-alignRight">Back</td><td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight">Lay</td></tr>`;
        }
        if (matchId) {

            if (data.fantacy.length > 0) {


                var fancys = data.fantacy.find(o => o.event_id == matchId);

                if (fancys) {

                    fancys = fancys.fancy_data;


                    if (fancys.length) {

                        for (var j = 0; j < fancys.length; j++) {


                            if (fancys[j].runner_name.includes('ball')) {
                                continue;

                            }
                            if (fancys[j].cron_disable == 'Yes') {

                            } else {
                                if (fancys[j].is_active == "No") {
                                    document.getElementsByClassName('fancy_' + fancys[j].selection_id).remove();
                                }
                                if (fancys[j]) {

                                    var block_market_fancys = fancys[j].block_market;
                                    var block_all_market_fancys = fancys[j].block_all_market;


                                    var find_fancy_all_block = block_all_market_fancys.filter(element => {

                                        return superiors.includes(element.user_id.toString())
                                    });

                                    if (find_fancy_all_block.length > 0) {

                                    } else {

                                        var find_fancy_block = block_market_fancys.filter(element => {

                                            return superiors.includes(element.user_id.toString())
                                        });

                                        if (find_fancy_block.length > 0) {

                                        } else {

                                            var fancyHtml = '';

                                            if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {






                                                fancyHtml = `<div class="all custom-container-fancy fancy_${fancys[j].selection_id} ">
<div id="fancyBetTag">
    <div id="" class="bets-wrap fancy-bet">
        <h5 p="1">
            <span id="marketName">${fancys[j].runner_name}</span>
              <a href="#" id="open-fancy_info" class="btn-fancy_info">fancybet info</a>
            <div id="fancy_popup2" class="fancy_info-popup">
                <dl>	
                    <dt>Min / Max</dt>
                    <dd id="minMax">1.00 / 800.00</dd>
                </dl>
                <dl>
                    <dt id="rebateName">Rebate</dt>
                    <dd id="rebate"></dd>
                </dl>
                <a href="#" id="close-fancy_info" class="close">Close</a>
            </div>
        </h5>
        <dl p="1" class="odd_even_clr odd_even_clr_${fancys[j].selection_id} bets-selections fancy_${fancys[j].selection_id} " >
            <dt class="line_market-selection">
                <dl class="tips">
                    <dt><span id="before" class="win"></span><span id="after" class="to-lose"></span></dt>
                    <dd id="remarkFirstRow"></dd>
                    <dd id="remarkSecondRow"></dd>
                </dl>
            </dt>
            <dd id="lay_1">
                <a class="lay-1 blue-core" onclick=
                handleOpen(
                  ${eventId},
                  "",
                  false,
                  ${fancys[j].lay_price1},
                  true,
                  ${fancys[j].selection_id},
                  ${fancys[j].runner_name},
                  fancy_lay_price_${fancys[j].selection_id}
                ) href="#">
                    <div class="widthhundredfancy" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div>
                    <span class="widthhundredfancy" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                </a>
            </dd>
            <dd id="back_1">
                <a class="back-1 pink-core" onclick=
                handleOpen(
                  ${eventId},
                  "",
                  true,
                  ${fancys[j].back_price1},
                  true,
                  ${fancys[j].selection_id},
                  ${fancys[j].runner_name},
                  fancy_lay_price_${fancys[j].selection_id}
                ) href="#">
                    <div class="widthhundredfancy" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)}</div>
                    <span class="widthhundredfancy" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                </a>
            </dd>
        </dl>
    </div>
</div>
<div p="1" class="pbb place-fancybet-block_${fancys[j].market_id}_${fancys[j].selection_id}">
    <div class="xs-bet-slip lcolor" colspan="7">
        <div class="bet-list">
            <div>
                <p>&nbsp;</p>
                <button class="MuiButtonBase-root MuiButton-root MuiButton-contained typed" tabindex="0" type="button"><span class="MuiButton-label"></span><span class="MuiTouchRipple-root"></span></button>
            </div>
            <div class="input-num">
                <p>Min Bet</p>
                <div>
                    <a href="#" style="border-top-left-radius: 1.6vw; border-bottom-left-radius: 1.6vw;">
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13H5v-2h14v2z"></path></svg>
                    </a>
                    <span class="typeing"></span>
                    <a href="#" style="border-top-right-radius: 1.6vw; border-bottom-right-radius: 1.6vw;">
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="coin-list">
            <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10</a>
            <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">20</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5000</a>
            <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10000</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">25000</a>
        </div>
        <div class="keyboard-wrap">
            <div class="btn-tel">
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">1</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">2</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">3</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">4</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">6</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">7</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">8</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">9</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">0</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">00</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">.</a>
            </div>
            <a href="#"><img src="/static/media/btn-delete.263a4b8e.svg" /></a>
        </div>
        <div class="btn-list"><a class="cancelbtn" href="#">Cancel</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover placebtn disable MuiTypography-colorPrimary" to="/#">Place Bet</a></div>
        <div class="acceptodds l-dark-color">
            <label><input type="checkbox" /><a>Accept Any Odds</a></label>
        </div>
    </div>
</div>
</div>
`;











                                                //                         fancyHtml += `<tr class="MuiTableRow-root fancy_${fancys[j].selection_id}" >`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body table_first_row">`;
                                                //                         fancyHtml += `<div class="text_left">
                                                //     <button onclick=getFancyPosition(${fancys[j].selection_id}) className="book-fancy">Book</button>
                                                //     <p >${fancys[j].runner_name}</p></div>`;
                                                //                         fancyHtml += `</td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight" 
                                                //     onclick=
                                                // handleOpen(
                                                //   ${eventId},
                                                //   "",
                                                //   false,
                                                //   ${fancys[j].lay_price1},
                                                //   true,
                                                //   ${fancys[j].selection_id},
                                                //   ${fancys[j].runner_name},
                                                //   fancy_lay_price_${fancys[j].match_id}_${fancys[j].selection_id}
                                                // )>`;
                                                //                         fancyHtml += `<div class="fancy-td-cell"><div class="fancy_lay_price_${fancys[j].selection_id}" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div><p class="eventP fancy_lay_size_${fancys[j].selection_id}" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</p></div>`;
                                                //                         fancyHtml += `</td >`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_odd sus-parent MuiTableCell-alignRight" 
                                                //     onclick=
                                                // handleOpen(
                                                //   ${eventId},
                                                //   "",
                                                //   true,
                                                //   ${fancys[j].back_price1},
                                                //   true,
                                                //   ${fancys[j].selection_id},
                                                //   ${fancys[j].runner_name},
                                                //   fancy_lay_price_${fancys[j].selection_id}
                                                // )>`;
                                                //                         fancyHtml += `<div class="fancy-td-cell"><div class="fancy_back_price_${fancys[j].selection_id}" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)} </div> <p class="eventP fancy_back_size_${fancys[j].selection_id}" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].back_size1)}</p></div></td>`;
                                                //                         fancyHtml += `</tr >`;




                                                // console.log('fancy html', fancyHtml);
                                                if (document.getElementById('fancyAPI')) {
                                                    document.getElementById('fancyAPI').innerHTML += fancyHtml;
                                                }

                                            }


                                            var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                                            var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];
                                            if (document.getElementById('fancy_lay_price_' + fancys[j].selection_id)) {
                                                if (fancys[j].game_status == 'Ball Running') {

                                                    newdiv.setAttribute('data-title', "BALL RUNNING");
                                                    newtr.classList.add("suspended");

                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';


                                                } else if (fancys[j].game_status == 'SUSPENDED') {
                                                    newdiv.setAttribute('data-title', "SUSPENDED");
                                                    newtr.classList.add("suspended");
                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';


                                                } else if (fancys[j].back_price1 == 0) {
                                                    newtr.classList.remove("suspended");
                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = '-';
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';


                                                } else {
                                                    newtr.classList.remove("suspended");

                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                                                    document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                                                    document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);

                                                }
                                            }
                                        }
                                    }

                                } else {

                                    var fancyHtml = '';

                                    if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {
                                        fancyHtml = `<div class="all custom-container-fancy fancy_${fancys[j].selection_id} ">
                                        <div id="fancyBetTag">
                                            <div id="" class="bets-wrap fancy-bet">
                                                <h5 p="1">
                                                    <span id="marketName">${fancys[j].runner_name}</span>
                                                      <a href="#" id="open-fancy_info" class="btn-fancy_info">fancybet info</a>
                                                    <div id="fancy_popup2" class="fancy_info-popup">
                                                        <dl>	
                                                            <dt>Min / Max</dt>
                                                            <dd id="minMax">1.00 / 800.00</dd>
                                                        </dl>
                                                        <dl>
                                                            <dt id="rebateName">Rebate</dt>
                                                            <dd id="rebate"></dd>
                                                        </dl>
                                                        <a href="#" id="close-fancy_info" class="close">Close</a>
                                                    </div>
                                                </h5>
                                                <dl p="1" class="odd_even_clr odd_even_clr_${fancys[j].selection_id} bets-selections fancy_${fancys[j].selection_id} " >
                                                    <dt class="line_market-selection">
                                                        <dl class="tips">
                                                            <dt><span id="before" class="win"></span><span id="after" class="to-lose"></span></dt>
                                                            <dd id="remarkFirstRow"></dd>
                                                            <dd id="remarkSecondRow"></dd>
                                                        </dl>
                                                    </dt>
                                                    <dd id="lay_1">
                                                        <a class="lay-1 blue-core" onclick=
                                                        handleOpen(
                                                          ${eventId},
                                                          "",
                                                          false,
                                                          ${fancys[j].lay_price1},
                                                          true,
                                                          ${fancys[j].selection_id},
                                                          ${fancys[j].runner_name},
                                                          fancy_lay_price_${fancys[j].selection_id}
                                                        ) href="#">
                                                            <div class="widthhundredfancy" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div>
                                                            <span class="widthhundredfancy" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                                                        </a>
                                                    </dd>
                                                    <dd id="back_1">
                                                        <a class="back-1 pink-core" onclick=
                                                        handleOpen(
                                                          ${eventId},
                                                          "",
                                                          true,
                                                          ${fancys[j].back_price1},
                                                          true,
                                                          ${fancys[j].selection_id},
                                                          ${fancys[j].runner_name},
                                                          fancy_lay_price_${fancys[j].selection_id}
                                                        ) href="#">
                                                            <div class="widthhundredfancy" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)}</div>
                                                            <span class="widthhundredfancy" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                                                        </a>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div p="1" class="pbb place-fancybet-block_${fancys[j].market_id}_${fancys[j].selection_id}">
                                            <div class="xs-bet-slip lcolor" colspan="7">
                                                <div class="bet-list">
                                                    <div>
                                                        <p>&nbsp;</p>
                                                        <button class="MuiButtonBase-root MuiButton-root MuiButton-contained typed" tabindex="0" type="button"><span class="MuiButton-label"></span><span class="MuiTouchRipple-root"></span></button>
                                                    </div>
                                                    <div class="input-num">
                                                        <p>Min Bet</p>
                                                        <div>
                                                            <a href="#" style="border-top-left-radius: 1.6vw; border-bottom-left-radius: 1.6vw;">
                                                                <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13H5v-2h14v2z"></path></svg>
                                                            </a>
                                                            <span class="typeing"></span>
                                                            <a href="#" style="border-top-right-radius: 1.6vw; border-bottom-right-radius: 1.6vw;">
                                                                <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="coin-list">
                                                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10</a>
                                                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">20</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5000</a>
                                                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10000</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">25000</a>
                                                </div>
                                                <div class="keyboard-wrap">
                                                    <div class="btn-tel">
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">1</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">2</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">3</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">4</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">6</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">7</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">8</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">9</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">0</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">00</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">.</a>
                                                    </div>
                                                    <a href="#"><img src="/static/media/btn-delete.263a4b8e.svg" /></a>
                                                </div>
                                                <div class="btn-list"><a class="cancelbtn" href="#">Cancel</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover placebtn disable MuiTypography-colorPrimary" to="/#">Place Bet</a></div>
                                                <div class="acceptodds l-dark-color">
                                                    <label><input type="checkbox" /><a>Accept Any Odds</a></label>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        `;

                                        //                 fancyHtml += `<tr class="MuiTableRow-root fancy_${fancys[j].selection_id}">`;
                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body table_first_row">`;
                                        //                 fancyHtml += `<div class="text_left">
                                        // <button onclick=getFancyPosition(${fancys[j].selection_id}) className="book-fancy">Book</button>
                                        // <p >${fancys[j].runner_name}</p></div>`;
                                        //                 fancyHtml += `</td>`;
                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight" onclick=
                                        // handleOpen(
                                        //   ${eventId},
                                        //   "",
                                        //   false,
                                        //   ${fancys[j].lay_price1},
                                        //   true,
                                        //   ${fancys[j].selection_id},
                                        //   ${fancys[j].runner_name},
                                        //   fancy_lay_price_${fancys[j].selection_id}
                                        // )>`;
                                        //                 fancyHtml += `<div class="fancy-td-cell"><div class="fancy_lay_price_${fancys[j].selection_id}" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div><p class="eventP fancy_lay_size_${fancys[j].selection_id}" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</p></div>`;
                                        //                 fancyHtml += `</td >`;
                                        //                 fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_odd sus-parent MuiTableCell-alignRight" onclick=
                                        // handleOpen(
                                        //   ${eventId},
                                        //   "",
                                        //   true,
                                        //   ${fancys[j].back_price1},
                                        //   true,
                                        //   ${fancys[j].selection_id},
                                        //   ${fancys[j].runner_name},
                                        //   fancy_lay_price_${fancys[j].selection_id}
                                        // )>`;
                                        //                 fancyHtml += `<div class="fancy-td-cell"><div class="fancy_back_price_${fancys[j].selection_id}" id="fancy_back_price_${fancys[j].selection_id}" >${parseFloat(fancys[j].back_price1)} </div> <p class="eventP fancy_back_size_${fancys[j].selection_id}" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].back_size1)}</p></div></td>`;

                                        //                 fancyHtml += `</tr >`;



                                        // console.log('fancy html', fancyHtml);
                                        if (document.getElementById('fancyAPI')) {
                                            document.getElementById('fancyAPI').innerHTML += fancyHtml;
                                        }
                                    }

                                    var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                                    var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];

                                    if (fancys[j].game_status == 'Ball Running') {

                                        newdiv.setAttribute('data-title', "BALL RUNNING");
                                        newtr.classList.add("suspended");

                                        document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                                        document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';

                                    } else {
                                        newtr.classList.remove("suspended");
                                        document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                                        document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                                        document.getElementsByClassName('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                                        document.getElementsByClassName('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleBetClick = () => {
        setBetShow(!betShow);
    }
    const [value, setValue] = React.useState(0);

    const fancyChange = (event, newValue) => {
        setValue(newValue);
    };

    function getEventData() {
        loader_default();
        const event_id = params.event_id;
        var data;
        let url;
        if (userInfo) {
            url = "getDashboardDataByeventId"
            data = JSON.stringify({
                user_id: userInfo._id,
                event_id: event_id,
            });
        } else {
            url = "getDashboardDataByEventIdWithoutUserID"
            data = JSON.stringify({
                event_id: event_id,
            });

        }

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log("setEventData", response.data.resultData);                
                setEventData(response.data.resultData);
                let tempData = [];
                let tempDataBm = [];
                let newrwo = response.data.resultData;
                // console.log("newrwo",newrwo);
                if (newrwo.length > 0) {
                    newrwo.map((Crick, index) => {
                        if (Crick.hasOwnProperty("marketTypes")) {
                            if (Crick.marketTypes.length > 0) {


                                Crick.marketTypes.map((marketTypes, index) => {

                                    if (marketTypes.market_name == "Match Odds") {
                                        if (marketTypes.hasOwnProperty("marketRunners")) {
                                            if (marketTypes.marketRunners.length > 0) {
                                                marketTypes.marketRunners.map((runner, index) => {
                                                    let shortName = runner.runner_name
                                                    tempData.push(
                                                        shortName
                                                    );
                                                });
                                            }
                                        }
                                    }
                                    if (marketTypes.market_name == "Bookmaker") {
                                        if (marketTypes.hasOwnProperty("marketRunners")) {
                                            if (marketTypes.marketRunners.length > 0) {
                                                marketTypes.marketRunners.map((runner, index) => {
                                                    let shortName = runner.runner_name
                                                    tempDataBm.push(
                                                        shortName
                                                    );
                                                });
                                            }
                                        }
                                    }

                                });
                            }
                        }
                    });
                }

                console.log(tempData);
                if (tempData.length < 3) {
                    tempData.push("Draw");
                }
                if (tempDataBm.length < 3) {
                    tempDataBm.push("Draw");
                }
                setRunners(tempData);
                setRunnersBm(tempDataBm);
                loader_remove();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    // console.log("setEventData", setEventData);
    // Stake Dynamic Start
    function getChips() {


        var data = JSON.stringify({
            "user_id": userInfo._id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setchipList(response.data);
                let tmpChipList = response.data;
                const newChipList = tmpChipList.map((chipList, index) => {
                    tmpChipList[index]["id"] = index + 1;
                });
                loader_remove();

                setchipList(tmpChipList);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    // Stake Dynamic end
    // function betPlace() {
    // Book Button In Fancy Market 

    function getFancyPosition(selectionId) {

        var data = JSON.stringify({
            user_id: userInfo._id,
            event_id: eventId,
            fancy_id: selectionId
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getFancyPositionSky`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setPostionFancyList(response.data.resultData);
                setPositionOpen(true);
                // console.log('postionFancyList', response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            });

        // console.log(postionFancyList)
    }




    // Stake Dynamic end
    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {

                setPlacing(true);
                loader_default();
                var data = JSON.stringify({
                    user_id: userInfo._id,
                    match_id: BetPlaceData.event_id,
                    selection_id: BetPlaceData.selection_id,
                    is_back: BetPlaceData.is_back,
                    stake: StakeValue,
                    price_val: BetPlaceData.price,
                    market_id: BetPlaceData.market_id,
                    is_fancy: BetPlaceData.is_fancy == 1 ? "Yes" : "No",
                    market_name: "Match odds",
                    profit: ProfitValue,
                    loss: lossValue,
                });
                // console.log("betting for fancy", data);

                var config = {
                    method: "post",
                    url: `${Appconfig.apiUrl}betting/addBetting`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                // alert("success");
                axios(config)
                    .then(function (response) {
                        // setOpen(false);
                        setBetPlaceData({
                            event_id: "",
                            market_id: "",
                            is_back: "",
                            price: "",
                            is_fancy: "",
                            selection_id: "",
                            runner_name: "",
                        });

                        setBookButton("showButtonBook");
                        setProfitValue(0);
                        setStakeValue(0);
                        hideAllBetBox()
                        if (response.data.result == 0) {
                            document.getElementById("msgBox").classList.remove("success");
                            document.getElementById("msgBox").classList.add("error");
                            document.getElementById("sideType").innerHTML = "Error";
                            document.getElementById("selectionName").innerHTML = response.data.resultMessage;
                            document.getElementById("stake_notify").innerHTML = "";
                            document.getElementById("price_notify_value").innerHTML = "";
                            var element = document.getElementById("sideType");
                            element.classList.add("red-type");
                        } else {
                            document.getElementById("msgBox").classList.add("success");
                            document.getElementById("msgBox").classList.remove("error");
                            setBookButton(ishow);
                            if (response.data.resultData[0].is_back == 1) {

                                document.getElementById("sideType").innerHTML = "Yes";
                                document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                var element = document.getElementById("sideType");
                                element.classList.add("blue-type");
                            }
                            if (response.data.resultData[0].is_back == 0) {

                                document.getElementById("sideType").innerHTML = "No";
                                document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                var element = document.getElementById("sideType");
                                element.classList.add("pink-type");

                            }


                        }
                        loader_remove();
                        document.getElementById("msgBox").style.display = "flex";
                        setTimeout(() => {
                            document.getElementById("msgBox").style.display = "none";
                        }, 3000);
                        // alert(response.data.result);
                        getEventData();
                        setIsBetPlaced(!isBetPlaced);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
        else {
            history.push("/login")
        }
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success",
            });
        }
    }


    async function getTvUrl(eventId) {
        await fetch(`${Appconfig.marketSarket}livetvurl/${eventId}`)
            .then(response => response.json())
            .then(data => setTvUrl(data.livetv));

    }
    async function getScoreUrl(eventId) {
        await fetch(`${Appconfig.marketSarket}animurl/${eventId}`)
            .then(response => response.json())
            .then(data => setScoreUrl(data.animation));
    }

    const hideAllBetBox = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
        const boxes = document.getElementsByClassName("pbb");
        const boxesWin = document.getElementsByClassName("to-win");

        for (var box of boxes) {
            box.style.setProperty("display", "none", "important")
        }
        for (var box of boxesWin) {
            box.style.setProperty("display", "none", "important")
        }
    }

    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back

        if (!isfancy) {
            // if (gameType == 'market') {
            //    if (MatchMarketTypes == 'M') {
            // var pl = Math.round((priceVal * t_stake) / 100);

            //    } else {
            var pl = Math.round((priceVal * t_stake) - t_stake);
            //    }
            // } else {
            // var pl = Math.round((priceVal * t_stake) / 100);
            // }
            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        } else {

            if (document.getElementById('fancy_lay_size_' + selection_id)) {
                var NoValume = parseInt(document.getElementById('fancy_lay_size_' + selection_id).innerHTML);
                var YesValume = parseInt(document.getElementById('fancy_back_size_' + selection_id).innerHTML);
                var inputno = parseFloat(document.getElementById(`fancy_lay_price_${selection_id}`).innerHTML);
                var inputyes = parseFloat(document.getElementById(`fancy_back_price_${selection_id}`).innerHTML);

            }

            console.log(NoValume + '  ' + YesValume);
            pl = parseFloat(t_stake);
            if (inputno == inputyes) {

                if (isback) {
                    setLossValue(pl.toFixed(2))
                    setProfitValue((YesValume * pl / 100).toFixed(2))
                } else {
                    setLossValue((NoValume * pl / 100).toFixed(2))
                    setProfitValue(pl.toFixed(2))
                }
            } else {
                setLossValue(pl.toFixed(2))
                setProfitValue(pl.toFixed(2))
            }
        }

    }

    const handleOpen = (
        event_id,
        market_id,
        is_back,
        price,
        is_fancy,
        selection_id,
        runner_name,
        htmlId
    ) => {
        return false;
        const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));




        hideAllBetBox();






        if (!is_fancy) {
            document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        } else {
            document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        }
        setProfitValue(0);
        setStakeValue(0)
        // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
        var priceNew = document.getElementById(htmlId).innerHTML;

        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "_BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }

        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        // console.log(runners);
        setNewTemp("");


        console.log('pricenew', {
            event_id: event_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
        });

        // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
        var priceNew = document.getElementById(htmlId).innerHTML;

        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "_BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }

        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        // console.log(runners);
        setNewTemp("");


        setBetPlaceData({
            event_id: event_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
        });

        setOpen(true);

        setTimeout(function () {
            SetPosition(0, priceNew, market_id, is_back, selection_id)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);

        }, 800);

        executeScroll();
        // console.log("Bet Place Data", BetPlaceData);

    };

    // const handleOpen = (
    //     event_id,
    //     market_id,
    //     is_back,
    //     price,
    //     is_fancy,
    //     selection_id,
    //     runner_name,
    //     htmlId
    // ) => {

    //     //alert(htmlId);
    //     hideAllBetBox();
    //     const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    //     // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
    //     const priceNew = document.getElementById(htmlId).innerHTML;

    //     // console.log(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)
    //     // document.getElementsByClassName("pbb").style.setProperty("display", "none", "important");
    //     if (!is_fancy) {
    //         document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "block", "important");
    //     } else {
    //         document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "block", "important");
    //     }

    //     setBetPlaceData({
    //         event_id: event_id,
    //         market_id: market_id,
    //         is_back: is_back ? 1 : 0,
    //         price: priceNew,
    //         is_fancy: is_fancy,
    //         selection_id: selection_id,
    //         runner_name: runner_name,
    //     });
    //     // setOpen(true);

    //     // console.log("Bet Place Data", BetPlaceData);

    // };
    const counter = 6;


    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        //  var MatchMarketTypes = 'M';
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        // console.log("tempRunnersv", runners);
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;


            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);


                    } else {
                        curr = winloss + ((priceVal * stake) - stake);


                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));


                    } else {

                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));


                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = Math.round(curr);


            // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {

                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss)

                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss);
            }

            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).style.display = "inherit";
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).setAttribute('data-value', currV)
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).innerHTML = Math.abs(currV);

                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).setAttribute('data-value', currV)

                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).innerHTML = Math.abs(currV);
            }
        }
    }

    return (
        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div className="main_wrap">

                <div className="report account-summary">
                    <div className={classes.root}>
                        <Grid container spacing={0} style={{ backgroundColor: '#F0ECE1' }}>
                            {isLoggedIn ? null :
                                <></>
                            }
                            {isMobile ?
                                <div id="msgBox" className="message-wrap-full success to-open_bets" style={{ display: 'none' }}>
                                    <div className="message">
                                        <h4 id="header">Bet Matched</h4>
                                        <p id="info">
                                            <span id="sideType" className="back_notify">Yes</span>
                                            <strong id="selectionName">Match 1st Over</strong> <strong id="stake_notify"> 100.00</strong> at odds <strong id="price_notify_value">7/100</strong>
                                        </p>
                                    </div>
                                    <Link id="close" className="close ui-link" href="#">Close</Link>
                                </div> : null
                            }
                            <div id="poker_loading" class="loading-wrap" style={{ 'marginTop': '50px' }}>
                                <ul class="loading">
                                    <li><img src={Loader} /></li>
                                    <li>Loading…</li>
                                </ul>
                            </div>
                            <Grid item lg={8} xs={12} spacing={2} className={`cricket-section ${classes.tableHeight}`}>
                                <Grid container id="ctitle" spacing={2} style={{ padding: '8px' }}>
                                    <Grid item lg={12} xs={12} className="md-none xp-0">
                                        <div className="match-block-header">
                                            {EventData.length > 0 && EventData[0].event_type == 4 ?
                                                <h5>Cricket</h5>
                                                :
                                                EventData.length > 0 && EventData[0].event_type == 1 ?
                                                    <h5>Soccer</h5>
                                                    :
                                                    EventData.length > 0 && EventData[0].event_type == 2 ?
                                                        <h5>Tennis</h5>
                                                        :
                                                        null
                                            }
                                            <span>
                                                {EventData.length > 0 && EventData[0].event_type == 4 && EventData[0].is_inplay == "True" ?
                                                    <span><img src={Time} /> In-Play</span>
                                                    :
                                                    EventData.length > 0 && EventData[0].event_type == 1 && EventData[0].is_inplay == "True" ?
                                                        <span><img src={Time} /> In-Play</span>
                                                        :
                                                        EventData.length > 0 && EventData[0].event_type == 2 && EventData[0].is_inplay == "True" ?
                                                            <span><img src={Time} /> In-Play</span>
                                                            :
                                                            null
                                                }
                                            </span>
                                        </div>
                                    </Grid>
                                    {scoreUrl ?
                                        <Grid item lg={12} xs={12} style={{ background: '#e0e6e6' }} className="cricket-banner xp-0">
                                            <iframe src={scoreUrl} title="match-score" style={{ height: iframeHeight + "px", width: "100%" }} />
                                            <div class="match-btn">
                                                <span><a className="btn-pin"> <img src={SimplePin} alt="image" /> Pin</a></span>
                                                <span><a className="btn-refresh" onClick={() => setRefresh(!refresh)}> <Replay /> Refresh</a></span>
                                                <span><a className="btn-refresh" style={{ marginLeft: "-13px" }} onClick={() => setShowtv(!showtv)}> <LiveTvIcon /> Tv</a></span>
                                            </div>
                                        </Grid>
                                        : null
                                    }
                                    {tvUrl && showtv ?
                                        <Grid item lg={12} xs={12} style={{ background: '#e0e6e6' }} className="cricket-banner xp-0">
                                            <iframe src={tvUrl} title="match-score" style={{ height: "459px", width: "100%" }} />

                                        </Grid>
                                        : null
                                    }
                                    <Grid item lg={12} className="p-0" xs={12} ref={myRef}>



                                        <div id="naviMarket" className="market-type ps ps--theme_default" data-ps-id="1dabf2f1-7040-4af7-cf37-256f4f3ca887">
                                            <ul id="naviMarketList">
                                                <li id="naviMarketTemaplate" ><Link to="#"></Link></li>
                                                <li id="naviMarket_1_188841345" className="select"><Link to="#">Match Odds</Link></li>
                                            </ul>
                                            <div className="ps__scrollbar-x-rail" >
                                                <div className="ps__scrollbar-x" tabindex="0"></div>
                                            </div>
                                            <div className="ps__scrollbar-y-rail" >
                                                <div className="ps__scrollbar-y" tabindex="0"></div>
                                            </div>
                                        </div>
                                        <div id="marketBetsWrap" class="bets-wrap">
                                            <div id="minMaxBox" class="limit_info-popup" style={{ display: 'none' }}>
                                                <a class="close" onclick="javascript:$j('#minMaxBox').fadeOut();">Close</a>
                                                <dl>
                                                    <dt id="minMaxDt" style={{ display: 'none' }}>Min / Max</dt>
                                                    <dt id="maxDt">Max</dt>
                                                    <dd id="minMaxInfo">1600</dd>
                                                </dl>
                                            </div>
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <HtmlTooltip title={
                                                    <React.Fragment>
                                                        <div id="minMaxBox" className="limit_info-popup" style={{ "display": "flex" }}>
                                                            <a className="close" onClick={handleTooltipClose}>Close</a>
                                                            <dl>
                                                                <dt id="minMaxDt" >{"Min / Max"}</dt>
                                                                <dt id="maxDt" style={{ "display": "none" }}>{"Max"}</dt>
                                                                <dd id="minMaxInfo">{"100 / 150000"}</dd>
                                                            </dl>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                    placement="right"
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose}
                                                    open={tooltipOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                >
                                                    <Link onClick={handleTooltipOpen} id="minMaxButton" class="bet-limit"></Link>
                                                </HtmlTooltip>
                                            </ClickAwayListener>
                                            <dl id="betsHead" class="bets-selections-head">
                                                <dt>

                                                    <a class="a-depth" href="#" id="marketDepthBtn">Markets Depth</a>

                                                    <p>
                                                        <span>Matched</span>
                                                        <strong id="totalMatched">PTE  8,718,629</strong>
                                                    </p>
                                                    <button className="bookbtn" onClick={() => getCurrentPosition("match_odds")}>Book</button>
                                                </dt>
                                                <dd class="mode-land"></dd>
                                                <dd class="mode-land"></dd>
                                                <dd>Back
                                                </dd>
                                                <dd>Lay
                                                </dd>
                                                {/* <dd class="mode-land"></dd>
                                                <dd class="mode-land"></dd> */}
                                            </dl>
                                            {EventData.length > 0 && EventData.map((event, index) =>
                                                event.hasOwnProperty("marketTypes") && event.marketTypes.map((market, index) =>
                                                    market.hasOwnProperty("marketRunners") && market.marketRunners.length > 0 && market.market_name == "Match Odds" ?
                                                        market.marketRunners.map((runner, i) => (
                                                            <>
                                                                <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={runner.exposure.toFixed(2)} />
                                                                <dl key={i * 14} className={`odd_even_clr table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`} data-title={runner.status === "suspended" ? "SUSPENDED" : ""} id="selection_4708889" class="bets-selections" style={{ display: 'flex' }} button>
                                                                    <dt>

                                                                        <div id="horseInfo" class="horse-info" style={{ display: 'none' }}>
                                                                            <ul id="horseBox" class="horse-box">
                                                                                <li id="clothNumberAlpha"></li>
                                                                                <li id="stallDraw"></li>
                                                                            </ul>
                                                                            <div id="uniform" class="uniform"><img src="" /></div>
                                                                            <ul id="horseName" class="horse-name">
                                                                                <li id="runnerName">{runner.runner_name}</li>
                                                                                <li id="jockyName"></li>
                                                                            </ul>
                                                                        </div>

                                                                        <h4 id="runnerName">{runner.runner_name}</h4>
                                                                        <ul id="winLoss">
                                                                            <li id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')} class="win market-exposure" data-value={runner.exposure} style={{ display: 'block' }}>{Math.abs(runner.exposure.toFixed(2))}</li>
                                                                            <li id="lossWithoutBet" class="win" style={{ display: 'none', marginRight: "1.06667vw" }}></li>
                                                                            <li id={runner.selection_id + "_maxprofit_list_loss_runner_next_" + market.market_id.replace('.', '')} class="to-win market-exposure" style={{ display: 'none', marginLeft: "1.06667vw" }}>0</li>
                                                                            <li id="lossWithBet" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroProfit" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroLiability" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroProfitWithBet" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroLiabilityWithBet" class="win" style={{ display: 'none' }}>
                                                                                {/* <p  class="market-exposure" ></p> */}
                                                                            </li>
                                                                        </ul>
                                                                    </dt>
                                                                    <dd id="suspend" class="suspend" style={{ display: 'none' }}><p>Suspend
                                                                    </p></dd>
                                                                    <dd id="closed" class="suspend" style={{ display: 'none' }}><p>Closed
                                                                    </p></dd>
                                                                    <dd id="nonRunner" class="suspend" style={{ display: 'none' }}><p>Non Runner
                                                                    </p></dd>
                                                                    <dd id="back_1">
                                                                        <Link onClick={() => handleOpen(
                                                                            event.event_id,
                                                                            market.market_id,
                                                                            true,
                                                                            runner.back_1_price,
                                                                            false,
                                                                            runner.selection_id,
                                                                            runner.runner_name,
                                                                            `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                        )
                                                                        } className={`back-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`} to="#"><span className="backprice-match" id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_price)}</span><span id={`availableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}> {parseFloat(runner.back_1_size)}</span></Link>
                                                                    </dd>
                                                                    <dd id="lay_1">
                                                                        <Link onClick={() => handleOpen(
                                                                            event.event_id,
                                                                            market.market_id,
                                                                            false,
                                                                            runner.lay_1_price,
                                                                            false,
                                                                            runner.selection_id,
                                                                            runner.runner_name,
                                                                            `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                        )
                                                                        } className={`lay-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`} to="#" fullmarketodds={parseFloat(runner.lay_1_price)}><span className="layprice-match" id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_price)}</span><span id={`availableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}> {parseFloat(runner.lay_1_size)}</span></Link>
                                                                    </dd>
                                                                </dl>

                                                                <div p={1} key={runner.selection_id} className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                                    <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                        <div className="bet-list" >
                                                                            <div>
                                                                                <p>&nbsp;</p>
                                                                                <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                            </div>
                                                                            <div className="input-num">
                                                                                <p>Min Bet</p>
                                                                                <div>
                                                                                    <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                        <RemoveIcon />
                                                                                    </a>
                                                                                    <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                    <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                        <AddIcon />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coin-list" >
                                                                            {chipList.slice(0, 6).map((row) => (
                                                                                <Link href="#" onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                            ))}
                                                                        </div>
                                                                        <div className="keyboard-wrap">
                                                                            <div className="btn-tel">
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                                <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                                <Link to="/#">.</Link>
                                                                            </div>
                                                                            <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                        </div>
                                                                        <div className="btn-list">
                                                                            <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                            <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => betPlace()}>Place Bet</Link>
                                                                            {/* <Button
                variant="contained"
                className="eventSingleSuccessBtn"
                onClick={() => betPlace()}
                disabled={placing}
                endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                >
                {placing ? "PLACING...." : "PLACE BET"}
            </Button> */}
                                                                        </div>
                                                                        <div className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                            <label>
                                                                                <input type="checkbox" />
                                                                                <a>Accept Any Odds</a>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)) : ""))}
                                        </div>


                                        {/* Match Odds Custom Section End */}


                                        {/* Book Marker Custom Section Start */}
                                        {EventData.length > 0 && EventData.map((event, index) =>
                                            event.hasOwnProperty("marketTypes") && event.marketTypes.map((market, index) => (
                                                <>
                                                    {
                                                        market.market_name != "Match Odds" ?
                                                            (
                                                                <div id="bookMakerMarket_30998640_130561" class="bets-wrap bets-bookmaker" style={{ display: 'block' }}>
                                                                    <h4>
                                                                        <a id="multiMarketPin" class="pin-off" href="#">Pin to Muilti Markets</a>
                                                                        <span>{market.market_name}</span>
                                                                        <p>| Zero Commission</p>
                                                                        <button className="bookbtn" onClick={() => getCurrentPosition("bookmaker")}>Book</button>
                                                                        <ClickAwayListener onClickAway={handleBookTooltipClose}>
                                                                            <HtmlTooltip title={
                                                                                <React.Fragment>
                                                                                    <div id="minMaxBox" className="limit_info-popup" style={{ "display": "flex" }}>
                                                                                        <a className="close" onClick={handleBookTooltipClose}>Close</a>
                                                                                        <dl>
                                                                                            <dt id="minMaxDt" >{"Min / Max"}</dt>
                                                                                            <dt id="maxDt" style={{ "display": "none" }}>{"Max"}</dt>
                                                                                            <dd id="minMaxInfo">{"100 / 150000"}</dd>
                                                                                        </dl>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                                placement="right"
                                                                                PopperProps={{
                                                                                    disablePortal: true,
                                                                                }}
                                                                                onClose={handleBookTooltipClose}
                                                                                open={toolBooktipOpen}
                                                                                disableFocusListener
                                                                                disableHoverListener
                                                                                disableTouchListener
                                                                            >
                                                                                <a href="#" onClick={handleBookTooltipOpen} id="openBookMakerInfo" className="btn-fancy_info"></a>
                                                                            </HtmlTooltip>
                                                                        </ClickAwayListener>
                                                                        <div id="bookMakerMinMax" class="fancy_info-popup">
                                                                            <dl>
                                                                                <dt>Min / Max</dt>
                                                                                <dd id="minMax"> 1.00 /  800.00</dd>
                                                                            </dl>
                                                                            <dl>
                                                                                <dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                                                                <dd id="rebate" style={{ display: 'none' }}></dd>
                                                                            </dl>
                                                                            <a href="" id="closeBookMakerInfo" class="close">Close</a>
                                                                        </div>
                                                                    </h4>
                                                                    <dl class="bets-selections-head back-lay-bookmarker">
                                                                        <dt>
                                                                        </dt>
                                                                        {/* <dd class="mode-land"></dd>
                                                                <dd class="mode-land"></dd> */}
                                                                        <dd>Back</dd>
                                                                        <dd>Lay</dd>
                                                                        {/* <dd class="mode-land"></dd>
                                                                <dd class="mode-land"></dd> */}
                                                                    </dl>
                                                                    {
                                                                        market.hasOwnProperty("marketRunners") && market.marketRunners.length > 0 ? (
                                                                            <div className="sectionbookmaker">
                                                                                {market.marketRunners.map((runner, i) => (
                                                                                    <>
                                                                                        <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={runner.exposure.toFixed(2)} />
                                                                                        <dl id="bookMakerSelection_30998640_130561_385354" class="bets-selections" style={{ display: 'flex' }}>
                                                                                            <dt>
                                                                                                <h4 id="runnerName">{runner.runner_name}</h4>
                                                                                                <ul id="winLoss">
                                                                                                    <li id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')} class="win market-exposure" data-value={runner.exposure} style={{ display: 'block' }}>{Math.abs(runner.exposure.toFixed(2))}</li>
                                                                                                    <li id="lossWithoutBet" class="win" style={{ display: 'none', marginRight: "1.06667vw" }}></li>
                                                                                                    <li id={runner.selection_id + "_maxprofit_list_loss_runner_next_" + market.market_id.replace('.', '')} class="to-win market-exposure" style={{ display: 'none', marginLeft: "1.06667vw" }}>0</li>
                                                                                                    <li id="lossWithBet" class="win" style={{ display: 'none' }}></li>
                                                                                                    <li id="zeroProfit" class="win" style={{ display: 'none' }}></li>
                                                                                                    <li id="zeroLiability" class="win" style={{ display: 'none' }}></li>
                                                                                                    <li id="zeroProfitWithBet" class="win" style={{ display: 'none' }}></li>
                                                                                                    <li id="zeroLiabilityWithBet" class="win" style={{ display: 'none' }}>
                                                                                                        {/* <p  class="market-exposure" ></p> */}
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </dt>
                                                                                            {/* <dd id="suspend" className={`suspend-fancy odd_even_clr bookmaker_${runner.selection_id} ${runner.status === "SUSPENDED" ? "suspended" : ""} ${runner.status === "Ball Running" ? "ballrunning-sus" : ""}`} style={{ display: 'none' }} data-title={runner.status === "SUSPENDED" ? "SUSPENDED" : ""}><p id="info">{runner.status === "SUSPENDED" ? "Suspend" : ""}{runner.status === "Ball Running" ? "Ball Running" : ""}</p></dd> */}
                                                                                            {/* <dd id="delayBetting" class="suspend-fancy" style={{ display: 'none' }}><p id="info"></p></dd> */}
                                                                                            {/* <dd id="suspend" class="" style={{display: 'none'}}><p id="info">Ball Running</p></dd>
                        <dd id="delayBetting" class="suspend-fancy" style={{display: 'none'}}><p id="info"></p></dd> */}
                                                                                            <dd id="back_1" className={`bookmaker-back-1 table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`}>
                                                                                                <Link className="back-1 pink-core" onClick={() =>
                                                                                                    handleOpen(
                                                                                                        event.event_id,
                                                                                                        market.market_id,
                                                                                                        true,
                                                                                                        runner.back_1_price,
                                                                                                        false,
                                                                                                        runner.selection_id,
                                                                                                        runner.runner_name,
                                                                                                        `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                                                    )
                                                                                                }><div className="back-box" id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_1_price}</div>
                                                                                                    <span className="bookmakerSize" id={`availableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_1_size}</span>
                                                                                                </Link>
                                                                                            </dd>

                                                                                            <dd id="lay_1" className="bookmaker-lay-1">
                                                                                                <Link className="lay-1 blue-core" onClick={() =>
                                                                                                    handleOpen(
                                                                                                        event.event_id,
                                                                                                        market.market_id,
                                                                                                        false,
                                                                                                        runner.lay_1_price,
                                                                                                        false,
                                                                                                        runner.selection_id,
                                                                                                        runner.runner_name,
                                                                                                        `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                                                    )
                                                                                                }><div className="lay-box" id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_1_price}</div>
                                                                                                    <span className="bookmakerSize" id={`availableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_1_size}</span>
                                                                                                </Link>
                                                                                            </dd>

                                                                                        </dl>
                                                                                        <div p={1} key={runner.selection_id} className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                                                            <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                                                <div className="bet-list" >
                                                                                                    <div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                                                    </div>
                                                                                                    <div className="input-num">
                                                                                                        <p>Min Bet</p>
                                                                                                        <div>
                                                                                                            <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                                                <RemoveIcon />
                                                                                                            </a>
                                                                                                            <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                                            <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                                                <AddIcon />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="coin-list" >
                                                                                                    {chipList.slice(0, 6).map((row) => (
                                                                                                        <Link href="#" onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                                                    ))}
                                                                                                </div>

                                                                                                <div className="keyboard-wrap">
                                                                                                    <div className="btn-tel">
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                                                        <Link to="/#">.</Link>
                                                                                                    </div>
                                                                                                    <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                                                </div>
                                                                                                <div className="btn-list">
                                                                                                    <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                                                    <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => betPlace()}>Place Bet</Link>
                                                                                                    {/* <Button
                                    variant="contained"
                                    className="eventSingleSuccessBtn"
                                    onClick={() => betPlace()}
                                    disabled={placing}
                                    endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                                    >
                                    {placing ? "PLACING...." : "PLACE BET"}
                                </Button> */}
                                                                                                </div>
                                                                                                <div className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                                                    <label>
                                                                                                        <input type="checkbox" />
                                                                                                        <a>Accept Any Odds</a>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div></>

                                                                                ))} </div>) : ""}
                                                                </div>
                                                            ) : null
                                                    }
                                                </>
                                            )))
                                        }



                                        {/* Book Marker Custom Section End */}


                                        {/* Book Marker Section Table */}

                                        {/*}
                                {EventData.length > 0 && EventData.map((event, index) =>
                                    event.marketTypes.map((market, index) =>
                                        market.marketRunners.length > 0 && market.market_name == "Bookmaker" ? (
                                            <Table className={classes.table} aria-label="simple table tableee">
                                                <TableHead>
                                                    <TableRow className="special_bet" >
                                                        <TableCell colSpan="7" className="lg-none">
                                                            <Link style={{ width: "300px", height: "10px" }} className="header-pin">
                                                                {isMobile ?
                                                                    <img alt="cricket" src={Greypin} />
                                                                    :
                                                                    <img alt="cricket" src={pin} />
                                                                }
                                                            </Link>
                                                            <span style={{ fontWeight: "bold", fontSize: "15px" }}>{market.market_name} Market </span>
                                                            <span style={{ color: "#a4adb3" }}> | Zero Commission</span>
                                                            <Link className="header-info-ico"></Link>
                                                        </TableCell>
                                                        <TableCell colSpan="8" className="xs-none">
                                                            <Box display='flex' justifyContent="space-between">
                                                                <Box display='flex'>
                                                                    <Link className="bookmark-last-icon"></Link>
                                                                    <Typography variant="span" style={{ fontWeight: "bold", fontSize: "15px" }}>{market.market_name} Market </Typography>
                                                                    <Typography variant="span" style={{ color: "#a4adb3" }}> | Zero Commission</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="span" className="fancy-info">Min</Typography>
                                                                    <Typography variant="span" className="bookmarket-header-text">500</Typography>
                                                                    <Typography variant="span" className="fancy-info">Max</Typography>
                                                                    <Typography variant="span" className="bookmarket-header-text">40,000.00</Typography>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="fullmarket_tbl_body">
                                                    <TableRow>
                                                        <TableCell colSpan="3"></TableCell>
                                                        <TableCell><strong>Back</strong></TableCell>
                                                        <TableCell><strong>Lay</strong></TableCell>
                                                        <TableCell colSpan="2" className="xs-none"></TableCell>
                                                    </TableRow>
                                                    {market.marketRunners.map((runner, i) => (
                                                        <>
                                                            <TableRow p={1} key={i * 15} className={`table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`} data-title={runner.status === "suspended" ? "SUSPENDED" : ""} button>
                                                                <TableCell className="table_first_row" colSpan="1">
                                                                    <div style={{ fontWeight: 'bold', }}>
                                                                        <span> {event.event_name} </span>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="right" onClick={handleBetClick} colSpan="3" className="fancy-suspend">
                                                                    <dl className="back-gradient back-block">
                                                                        <span className="xs-none" onClick={() =>
                                                                            handleOpen(
                                                                                event.event_id,
                                                                                market.market_id,
                                                                                true,
                                                                                runner.back_3_price,
                                                                                false,
                                                                                runner.selection_id,
                                                                                runner.runner_name,
                                                                                `availableToBack3_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                            )
                                                                        } id={`availableToBack3_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_3_price}</span>
                                                                        <span onClick={() =>
                                                                            handleOpen(
                                                                                event.event_id,
                                                                                market.market_id,
                                                                                true,
                                                                                runner.back_2_price,
                                                                                false,
                                                                                runner.selection_id,
                                                                                runner.runner_name,
                                                                                `availableToBack2_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                            )
                                                                        } className="xs-none" id={`availableToBack2_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_2_price}</span>
                                                                        <span onClick={() =>
                                                                            handleOpen(
                                                                                event.event_id,
                                                                                market.market_id,
                                                                                true,
                                                                                runner.back_1_price,
                                                                                false,
                                                                                runner.selection_id,
                                                                                runner.runner_name,
                                                                                `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                            )
                                                                        }><div className="back-box" id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_1_price}</div></span>
                                                                    </dl>
                                                                </TableCell>
                                                                <TableCell align="right" colSpan="3" className="fancy-suspend">
                                                                    <dl className="lay-gradient lay-block">
                                                                        <span onClick={() =>
                                                                            handleOpen(
                                                                                event.event_id,
                                                                                market.market_id,
                                                                                false,
                                                                                runner.lay_1_price,
                                                                                false,
                                                                                runner.selection_id,
                                                                                runner.runner_name,
                                                                                `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                            )
                                                                        }><div className="lay-box" id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_1_price}</div></span>
                                                                        <span onClick={() =>
                                                                            handleOpen(
                                                                                event.event_id,
                                                                                market.market_id,
                                                                                false,
                                                                                runner.lay_2_price,
                                                                                false,
                                                                                runner.selection_id,
                                                                                runner.runner_name,
                                                                                `availableToLay2_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                            )
                                                                        } className="xs-none" id={`availableToLay2_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_2_price}</span>
                                                                        <span onClick={() =>
                                                                            handleOpen(
                                                                                event.event_id,
                                                                                market.market_id,
                                                                                false,
                                                                                runner.lay_3_price,
                                                                                false,
                                                                                runner.selection_id,
                                                                                runner.runner_name,
                                                                                `availableToLay3_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                            )
                                                                        } className="xs-none" id={`availableToLay3_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_3_price}</span>
                                                                    </dl>
                                                                </TableCell>
                                                            </TableRow>
                                                            {isMobile ?

                                                                <TableRow p={1} key={runner.selection_id} className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                                    <TableCell className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                        <div className="bet-list" >
                                                                            <div>
                                                                                <p>&nbsp;</p>
                                                                                <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                            </div>
                                                                            <div className="input-num">
                                                                                <p>Min Bet</p>
                                                                                <div>
                                                                                    <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                        <RemoveIcon />
                                                                                    </a>
                                                                                    <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                    <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                        <AddIcon />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coin-list" >
                                                                            <a href="#" onClick={() => placeStakeValue(4)}>4</a>
                                                                            <a href="#" onClick={() => placeStakeValue(30)}>30</a>
                                                                            <a href="#" onClick={() => placeStakeValue(50)}>50</a>
                                                                            <a href="#" onClick={() => placeStakeValue(200)}>200</a>
                                                                            <a href="#" onClick={() => placeStakeValue(500)}>500</a>
                                                                            <a href="#" onClick={() => placeStakeValue(1000)}>1000</a>
                                                                        </div>
                                                                        <div className="keyboard-wrap">
                                                                            <div className="btn-tel">
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</a>
                                                                                <a href="#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</a>
                                                                                <a href="#">.</a>
                                                                            </div>
                                                                            <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                        </div>
                                                                        <div className="btn-list">
                                                                            <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                            <a className={!StakeValue ? "placebtn disable" : "placebtn"} href="#" onClick={() => betPlace()}>Place Bet</a>
                                                                        </div>
                                                                        <div className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                            <label>
                                                                                <input type="checkbox" />
                                                                                <a>Accept Any Odds</a>
                                                                            </label>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>

                                                                :
                                                                <>
                                                                    <TableRow p={1} key={runner.selection_id} className={`place-bet-block  place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                                        <TableCell></TableCell>
                                                                        <TableCell colSpan="6" className="place-bet-td">
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={state.checkedB}
                                                                                        onChange={handleChange}
                                                                                        name="checkedB"
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label="Accept Any Odds"
                                                                                className="checkbox"
                                                                            />
                                                                            <Button variant="controlled" className="site-btn cancel-btn">Cancel</Button>
                                                                            <span className="odds">83</span>
                                                                            <input value={StakeValue} />
                                                                            <Button variant="controlled" className="site-btn place-bet-btn">Place Bets</Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRowLink p={1} key={runner.selection_id} className="place-bet-sec-block" button>
                                                                        <TableCell colSpan="7" className="place-bet-td">
                                                                            <Button variant="controlled" className="site-btn bet-value" onClick={() => placeStakeValue(4)}>4</Button>
                                                                            <Button variant="controlled" className="site-btn bet-value" onClick={() => placeStakeValue(30)}>30</Button>
                                                                            <Button variant="controlled" className="site-btn bet-value" onClick={() => placeStakeValue(50)}>50</Button>
                                                                            <Button variant="controlled" className="site-btn bet-value" onClick={() => placeStakeValue(200)}>200</Button>
                                                                            <Button variant="controlled" className="site-btn bet-value" onClick={() => placeStakeValue(500)}>500</Button>
                                                                            <Button variant="controlled" className="site-btn bet-value" onClick={() => placeStakeValue(1000)}>1000</Button>
                                                                        </TableCell>
                                                                    </TableRowLink>
                                                                </>
                                                            }
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        ) : ""))}   */}
                                    </Grid>

                                    {/* Child Grid item End */}
                                </Grid>
                                {/* Child Grid Container End */}
                                {EventData.length > 0 && EventData[0].hasOwnProperty("fancy") && EventData[0].fancy.length > 0 ?
                                    <Grid container spacing={2} style={{ padding: '10px' }}>
                                        <Grid item spacing={2} lg={12} className="p-0" xs={12}>
                                            <Box>
                                                <Box class="cricket-fancy-head">
                                                    {/* <Typography variant="span">
                                            <Typography variant="span" className="fancy-header-text">
                                                <img src={DarkPin} /> <span><img src={bookmarkwithtime} /></span> Fancy Bet</Typography>
                                        </Typography> */}
                                                    {/* Extra Div Added For tabbing */}
                                                    <div id="fancyBetHead" class="fancy_bet-head">
                                                        <h4 class="in-play">
                                                            <a id="multiMarketPin" class="pin-off" href="#">Pin to Muilti Markets</a>
                                                            <span><pre>in-play</pre>Fancy Bet</span>
                                                            <a class="btn-head_rules">Rules</a>

                                                        </h4>
                                                        {EventData.length > 0 && EventData[0] != typeof undefined && EventData[0].event_type == 4 ?

                                                            <a id="showSportsBookBtn" class="other-tab">
                                                                <span class="tag-new">New</span>
                                                                Premium Cricket
                                                            </a> : ""
                                                        }

                                                    </div>
                                                    {/* Extra Div Added For Tabbing */}
                                                    {/* <Link className="btn-fancybet_rules"></Link> */}
                                                </Box>
                                            </Box>
                                            <Box flex={1} className="tab-cricket-fancy">
                                                <AppBar position="static" className="tab-fancy" >
                                                    <Tabs
                                                        fullWidth={true}
                                                        value={value}
                                                        onChange={fancyChange}
                                                        indicatorColor="secondary"
                                                        centered
                                                    >
                                                        <Tab label="All" {...a11yProps(0)} />
                                                        <Tab label="Fancy" {...a11yProps(1)} />
                                                        <Tab label="Khadda" {...a11yProps(2)} />
                                                        <Tab label="Lotery" {...a11yProps(3)} />
                                                        <Tab label="Odd/Even" {...a11yProps(4)} />
                                                    </Tabs>
                                                </AppBar>
                                            </Box>

                                            <TabPanel value={value} index={0} className="fancy-tab-content fancybet-data">
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow className="special_bet">

                                                            <TableCell colSpan="8" className="xs-none">
                                                                <Box display='flex' justifyContent="space-between">
                                                                    <Box display='flex'>
                                                                        <Link className="bookmark-last-icon"></Link>
                                                                        <Typography variant="span" style={{ fontWeight: "bold", fontSize: "15px" }}>Fancy Bet</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="financial_tbl_body" >
                                                        <TableRow className="">
                                                            <TableCell align="left" colSpan="6"></TableCell>
                                                            <TableCell align="center" colSpan=""><b>No</b></TableCell>
                                                            <TableCell align="center" colSpan=""><b>Yes</b></TableCell>
                                                            {/* <TableCell align="left" colSpan="2" className="xs-none"></TableCell> */}
                                                        </TableRow>
                                                        <TableRow className="hegintnone">
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="left" colSpan="2" className="xs-none"></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                {EventData.length && EventData.map((event, index) =>
                                                    event.fancy.map((fancy, i) => (
                                                        <div className={`all custom-container-fancy fancy_${fancy.selection_id}`}>
                                                            <div id="fancyBetTag">
                                                                <div id="fancyBetMarket_332715" class="bets-wrap fancy-bet">
                                                                    <h5 p={1} key={i * 66}>
                                                                        <span id="marketName">{fancy.runner_name}</span>
                                                                        <ClickAwayListener onClickAway={() => handleFancyTooltipOpen(null)}>
                                                                            <HtmlTooltip title={
                                                                                <React.Fragment>
                                                                                    <div id="minMaxBox" className="limit_info-popup" style={{ "display": "flex" }}>
                                                                                        <a className="close" onClick={() => handleFancyTooltipOpen(null)}>Close</a>
                                                                                        <dl>
                                                                                            <dt id="minMaxDt" >{"Min / Max"}</dt>
                                                                                            <dt id="maxDt" style={{ "display": "none" }}>{"Max"}</dt>
                                                                                            <dd id="minMaxInfo">{"100 / 150000"}</dd>
                                                                                        </dl>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                                placement="right"
                                                                                PopperProps={{
                                                                                    disablePortal: true,
                                                                                }}
                                                                                onClose={() => handleFancyTooltipOpen(null)}
                                                                                open={toolFancytipOpen === index}
                                                                                disableFocusListener
                                                                                disableHoverListener
                                                                                disableTouchListener
                                                                            >
                                                                                <a href="#" onClick={() => handleFancyTooltipOpen(index)} id="open-fancy_info" class="btn-fancy_info"></a>

                                                                            </HtmlTooltip>
                                                                        </ClickAwayListener>

                                                                        {/* <div id="fancy_popup2" class="fancy_info-popup">
                                                                    <dl>
                                                                        <dt>Min / Max</dt>
                                                                        <dd id="minMax"> 1.00 /  800.00</dd>
                                                                    </dl>
                                                                    <dl>
                                                                        <dt id="rebateName">Rebate</dt>
                                                                        <dd id="rebate"></dd>
                                                                    </dl>
                                                                    <a href="#" id="close-fancy_info" class="close">Close</a>
                                                                </div> */}
                                                                    </h5>
                                                                    <dl p={1} key={i * 66} className={`odd_even_clr odd_even_clr_${fancy.selection_id} bets-selections fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""}`} data-title={fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""} button>
                                                                        <dt class="line_market-selection">
                                                                            <dl class="tips">
                                                                                <dt>
                                                                                    <span id="before" class={`win fancywin win_${fancy.match_id}_${fancy.selection_id}`}></span>
                                                                                    <span id="after" class={`to-lose fancywin to-lose_${fancy.match_id}_${fancy.selection_id}`} ></span>
                                                                                </dt>
                                                                                <Link id={`fancyBetBookBtn_${fancy.match_id}_${fancy.selection_id}`} className={`btn-book `} onClick={() => getFancyPosition(fancy.selection_id)} style={{ display: "none" }}>Book</Link>
                                                                                <dd id="remarkFirstRow"></dd>
                                                                                <dd id="remarkSecondRow"></dd>
                                                                            </dl>
                                                                            {/* <a id="fancyBetBookBtn" href="#" class="btn-book">Book</a> */}
                                                                        </dt>
                                                                        {/* <dd id="suspend eded" className={`suspend-fancy odd_even_clr fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""} ${fancy.game_status === "Ball Running" ? "suspended" : ""}`} style={{ display: 'none' }} data-title={`${fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""}${fancy.game_status === "Ball Running" ? "ballrunning-sus" : ""}`}><p id="info">{fancy.game_status === "SUSPENDED" ? "Suspend" : ""}{fancy.game_status === "Ball Running" ? "Ball Running" : ""}</p></dd> */}
                                                                        {/* <dd id="delayBetting" class="suspend-fancy" style={{ display: 'none' }}><p id="info"></p></dd> */}
                                                                        <dd id="lay_1">
                                                                            <a className="lay-1 blue-core" href="#" onClick={() =>
                                                                                handleOpen(
                                                                                    event.event_id,
                                                                                    "",
                                                                                    false,
                                                                                    fancy.lay_price1,
                                                                                    true,
                                                                                    fancy.selection_id,
                                                                                    fancy.runner_name,
                                                                                    `fancy_lay_price_${fancy.selection_id}`
                                                                                )
                                                                            }>

                                                                                <div className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}>{parseFloat(fancy.lay_price1)}</div>
                                                                                <span className="widthhundredfancy" id={`fancy_lay_size_${fancy.selection_id}`}>{parseFloat(fancy.lay_size1)}</span>
                                                                            </a>
                                                                        </dd>
                                                                        <dd id="back_1">
                                                                            <a className="back-1 pink-core" href="#" onClick={() =>
                                                                                handleOpen(
                                                                                    event.event_id,
                                                                                    "",
                                                                                    true,
                                                                                    fancy.back_price1,
                                                                                    true,
                                                                                    fancy.selection_id,
                                                                                    fancy.runner_name,
                                                                                    `fancy_back_price_${fancy.selection_id}`
                                                                                )
                                                                            }>
                                                                                <div className="widthhundredfancy" id={`fancy_back_price_${fancy.selection_id}`}>{parseFloat(fancy.back_price1)}</div>
                                                                                <span className="widthhundredfancy" id={`fancy_back_size_${fancy.selection_id}`}>{parseFloat(fancy.back_size1)}</span>
                                                                            </a>
                                                                        </dd>
                                                                        {/* <div>
                                                    <div className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}> {parseFloat(fancy.lay_price1)}</div>
                                                    <span className="widthhundredfancy" id={`fancy_lay_size_${fancy.selection_id}`}>{parseFloat(fancy.lay_size1)}</span>
                                                </div> */}

                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            <div p={1} key={fancy.selection_id} className={`pbb place-fancybet-block_${fancy.match_id}_${fancy.selection_id}`} button>
                                                                <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                    <div className="bet-list" >
                                                                        <div>
                                                                            <p>&nbsp;</p>
                                                                            <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                        </div>
                                                                        <div className="input-num">
                                                                            <p>Min Bet</p>
                                                                            <div>
                                                                                <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                    <RemoveIcon />
                                                                                </a>
                                                                                <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                    <AddIcon />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coin-list" >
                                                                        {chipList.slice(0, 6).map((row) => (
                                                                            <Link href="#" onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                        ))}
                                                                    </div>
                                                                    <div className="keyboard-wrap">
                                                                        <div className="btn-tel">
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                            <Link to="/#">.</Link>
                                                                        </div>
                                                                        <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                    </div>
                                                                    <div className="btn-list fancyyyyybutttn">
                                                                        <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                        <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => betPlace(`fancyBetBookBtn_"${fancy.match_id}_${fancy.selection_id}`)}>Place Bet</Link>

                                                                        {/* <Button
                                                                            variant="contained"
                                                                            className="eventSingleSuccessBtn"
                                                                            onClick={() => betPlace()}
                                                                            disabled={placing}
                                                                            endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                                                                            >
                                                                            {placing ? "PLACING...." : "PLACE BET"}
                                                                        </Button> */}
                                                                    </div>
                                                                    <div className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                        <label>
                                                                            <input type="checkbox" />
                                                                            <a>Accept Any Odds</a>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div></div>)))}
                                            </TabPanel>
                                            <TabPanel value={value} index={1} className="fancy-tab-content fancybet-data">
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow className="special_bet">

                                                            <TableCell colSpan="8" className="xs-none">
                                                                <Box display='flex' justifyContent="space-between">
                                                                    <Box display='flex'>
                                                                        <Link className="bookmark-last-icon"></Link>
                                                                        <Typography variant="span" style={{ fontWeight: "bold", fontSize: "15px" }}>Fancy Bet</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="financial_tbl_body" >
                                                        <TableRow className="">
                                                            <TableCell align="left" colSpan="6"></TableCell>
                                                            <TableCell align="center" colSpan=""><b>No</b></TableCell>
                                                            <TableCell align="center" colSpan=""><b>Yes</b></TableCell>
                                                            {/* <TableCell align="left" colSpan="2" className="xs-none"></TableCell> */}
                                                        </TableRow>
                                                        <TableRow className="hegintnone">
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="left" colSpan="2" className="xs-none"></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                {EventData.length && EventData.map((event, index) =>
                                                    event.fancy.map((fancy, i) => (
                                                        <div className={`all custom-container-fancy fancy_${fancy.selection_id}`}>
                                                            <div id="fancyBetTag">
                                                                <div id="fancyBetMarket_332715" class="bets-wrap fancy-bet">
                                                                    <h5 p={1} key={i * 66}>
                                                                        <span id="marketName">{fancy.runner_name}</span>
                                                                        <ClickAwayListener onClickAway={() => handleFancyTooltipOpen(null)}>
                                                                            <HtmlTooltip title={
                                                                                <React.Fragment>
                                                                                    <div id="minMaxBox" className="limit_info-popup" style={{ "display": "flex" }}>
                                                                                        <a className="close" onClick={() => handleFancyTooltipOpen(null)}>Close</a>
                                                                                        <dl>
                                                                                            <dt id="minMaxDt" >{"Min / Max"}</dt>
                                                                                            <dt id="maxDt" style={{ "display": "none" }}>{"Max"}</dt>
                                                                                            <dd id="minMaxInfo">{"100 / 150000"}</dd>
                                                                                        </dl>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                                placement="right"
                                                                                PopperProps={{
                                                                                    disablePortal: true,
                                                                                }}
                                                                                onClose={() => handleFancyTooltipOpen(null)}
                                                                                open={toolFancytipOpen === index}
                                                                                disableFocusListener
                                                                                disableHoverListener
                                                                                disableTouchListener
                                                                            >
                                                                                <a href="#" onClick={() => handleFancyTooltipOpen(index)} id="open-fancy_info" class="btn-fancy_info"></a>

                                                                            </HtmlTooltip>
                                                                        </ClickAwayListener>

                                                                        {/* <div id="fancy_popup2" class="fancy_info-popup">
                                                                    <dl>
                                                                        <dt>Min / Max</dt>
                                                                        <dd id="minMax"> 1.00 /  800.00</dd>
                                                                    </dl>
                                                                    <dl>
                                                                        <dt id="rebateName">Rebate</dt>
                                                                        <dd id="rebate"></dd>
                                                                    </dl>
                                                                    <a href="#" id="close-fancy_info" class="close">Close</a>
                                                                </div> */}
                                                                    </h5>
                                                                    <dl p={1} key={i * 66} className={`odd_even_clr odd_even_clr_${fancy.selection_id} bets-selections fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""}`} data-title={fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""} button>
                                                                        <dt class="line_market-selection">
                                                                            <dl class="tips">
                                                                                <dt>
                                                                                    <span id="before" class="win"></span>
                                                                                    <span id="after" class="to-lose"></span>
                                                                                </dt>

                                                                                {/* <Link id={`fancyBetBookBtn_"${fancy.match_id}_${fancy.selection_id}`} className={`btn-book `} onClick={() => getFancyPosition(fancy.selection_id)} style={{ display: "none" }}>Book</Link> */}

                                                                                <dd id="remarkFirstRow"></dd>
                                                                                <dd id="remarkSecondRow"></dd>
                                                                            </dl>
                                                                            {/* <a id="fancyBetBookBtn" href="#" class="btn-book">Book</a> */}
                                                                        </dt>
                                                                        {/* <dd id="suspend eded" className={`suspend-fancy odd_even_clr fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""} ${fancy.game_status === "Ball Running" ? "suspended" : ""}`} style={{ display: 'none' }} data-title={`${fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""}${fancy.game_status === "Ball Running" ? "ballrunning-sus" : ""}`}><p id="info">{fancy.game_status === "SUSPENDED" ? "Suspend" : ""}{fancy.game_status === "Ball Running" ? "Ball Running" : ""}</p></dd> */}
                                                                        {/* <dd id="delayBetting" class="suspend-fancy" style={{ display: 'none' }}><p id="info"></p></dd> */}
                                                                        <dd id="lay_1">
                                                                            <a className="lay-1 blue-core" href="#" onClick={() =>
                                                                                handleOpen(
                                                                                    event.event_id,
                                                                                    "",
                                                                                    false,
                                                                                    fancy.lay_price1,
                                                                                    true,
                                                                                    fancy.selection_id,
                                                                                    fancy.runner_name,
                                                                                    `fancy_lay_price_${fancy.selection_id}`
                                                                                )
                                                                            }>

                                                                                <div className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}>{parseFloat(fancy.lay_price1)}</div>
                                                                                <span className="widthhundredfancy" id={`fancy_lay_size_${fancy.selection_id}`}>{parseFloat(fancy.lay_size1)}</span>
                                                                            </a>
                                                                        </dd>
                                                                        <dd id="back_1">
                                                                            <a className="back-1 pink-core" href="#" onClick={() =>
                                                                                handleOpen(
                                                                                    event.event_id,
                                                                                    "",
                                                                                    true,
                                                                                    fancy.back_price1,
                                                                                    true,
                                                                                    fancy.selection_id,
                                                                                    fancy.runner_name,
                                                                                    `fancy_back_price_${fancy.selection_id}`
                                                                                )
                                                                            }>
                                                                                <div className="widthhundredfancy" id={`fancy_back_price_${fancy.selection_id}`}>{parseFloat(fancy.back_price1)}</div>
                                                                                <span className="widthhundredfancy" id={`fancy_back_size_${fancy.selection_id}`}>{parseFloat(fancy.back_size1)}</span>
                                                                            </a>
                                                                        </dd>
                                                                        {/* <div>
                                                    <div className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}> {parseFloat(fancy.lay_price1)}</div>
                                                    <span className="widthhundredfancy" id={`fancy_lay_size_${fancy.selection_id}`}>{parseFloat(fancy.lay_size1)}</span>
                                                </div> */}

                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            <div p={1} key={fancy.selection_id} className={`pbb place-fancybet-block_${fancy.match_id}_${fancy.selection_id}`} button>
                                                                <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                    <div className="bet-list" >
                                                                        <div>
                                                                            <p>&nbsp;</p>
                                                                            <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                        </div>
                                                                        <div className="input-num">
                                                                            <p>Min Bet</p>
                                                                            <div>
                                                                                <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                    <RemoveIcon />
                                                                                </a>
                                                                                <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                    <AddIcon />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coin-list" >
                                                                        {chipList.slice(0, 6).map((row) => (
                                                                            <Link href="#" onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                        ))}
                                                                    </div>
                                                                    <div className="keyboard-wrap">
                                                                        <div className="btn-tel">
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                            <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                            <Link to="/#">.</Link>
                                                                        </div>
                                                                        <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                    </div>
                                                                    <div className="btn-list fancyyyyybutttn">
                                                                        <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                        <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => betPlace("fancyBetBookBtn" + i)}>Place Bet</Link>

                                                                        {/* <Button
                                                                            variant="contained"
                                                                            className="eventSingleSuccessBtn"
                                                                            onClick={() => betPlace()}
                                                                            disabled={placing}
                                                                            endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                                                                            >
                                                                            {placing ? "PLACING...." : "PLACE BET"}
                                                                        </Button> */}
                                                                    </div>
                                                                    <div className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                        <label>
                                                                            <input type="checkbox" />
                                                                            <a>Accept Any Odds</a>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div></div>)))}
                                            </TabPanel>



                                        </Grid>
                                        {/* Fancy Cricket Grid item End */}
                                    </Grid>
                                    : null}
                                <MobileView>
                                    <Grid item lg={4} xs={12} spacing={2} className="betSlipGrid " style={{ padding: 10 }}>
                                        <div id="bookMakerMarket_30998640_130561" class="bets-wrap bets-bookmaker" style={{ display: "block" }} >
                                            <h4>

                                                <span>Match Bets</span>



                                            </h4>
                                            <TableContainer component={Paper} className="bet-slip">
                                                <Table className={classes.table} id="betList" aria-label="simple table">

                                                    <TableHead>
                                                        <TableRow className="darktblhead">
                                                            <TableCell style={{ width: 15 }}>No.</TableCell>
                                                            {userInfo.user_type != "User" ? <TableCell style={{ width: 100 }}>User</TableCell> : ""}
                                                            <TableCell style={{ width: 280 }}>Runner</TableCell>
                                                            <TableCell style={{ width: 100 }}>Odds</TableCell>
                                                            <TableCell style={{ width: 100 }}>Stack</TableCell>
                                                            <TableCell style={{ width: 100 }}>P_L</TableCell>
                                                            <TableCell style={{ width: 100 }}>Bet Type</TableCell>
                                                            <TableCell style={{ width: 275 }}>Time</TableCell>
                                                            <TableCell>Id</TableCell>
                                                            <TableCell>IP</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {betSlipList.length > 0 &&
                                                            betSlipList.map((row, index) => (
                                                                <TableRow className={row.is_back == 0 ? "bg_even" : "bg_odd"}>
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    {userInfo.user_type != "User" ? <TableCell >{row.client}</TableCell> : ""}
                                                                    <TableCell>{row.runner_name}</TableCell>
                                                                    <TableCell>{row.odds}</TableCell>
                                                                    <TableCell>{row.stake}</TableCell>
                                                                    <TableCell>{row.profit.toFixed(2)}</TableCell>
                                                                    <TableCell>{row.betting_type}</TableCell>
                                                                    {/* <TableCell>{moment(row.createdAt).format("YYYY-MM-DD h:mm:ss a")}</TableCell> */}
                                                                    <TableCell>{row.hasOwnProperty("_id") ? (row._id).substr(-6) : ""}</TableCell>
                                                                    <TableCell>{row.hasOwnProperty("ip_address") ? row.ip_address.replace('::ffff:', '') : ""}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Grid>
                                </MobileView>
                            </Grid>
                            {/* End Main Item Grid */}

                            <Grid item lg={4} xs={12} spacing={2} className="betSlipGrid " style={{ padding: 10 }}>
                                <BrowserView>
                                    <div id="bookMakerMarket_30998640_130561" class="bets-wrap bets-bookmaker" style={{ display: "block" }} >
                                        <h4>

                                            <span>Match Bets</span>



                                        </h4>
                                        <TableContainer component={Paper} className="bet-slip">
                                            <Table className={classes.table} id="betList" aria-label="simple table">

                                                <TableHead>
                                                    <TableRow className="darktblhead">
                                                        <TableCell style={{ width: 15 }}>No.</TableCell>
                                                        {userInfo.user_type != "User" ? <TableCell style={{ width: 100 }}>User</TableCell> : ""}
                                                        <TableCell style={{ width: 280 }}>Runner</TableCell>
                                                        <TableCell style={{ width: 100 }}>Odds</TableCell>
                                                        <TableCell style={{ width: 100 }}>Stack</TableCell>
                                                        <TableCell style={{ width: 100 }}>P_L</TableCell>
                                                        <TableCell style={{ width: 100 }}>Bet Type</TableCell>
                                                        <TableCell style={{ width: 275 }}>Time</TableCell>
                                                        <TableCell>Id</TableCell>
                                                        <TableCell>IP</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {betSlipList.length > 0 &&
                                                        betSlipList.map((row, index) => (
                                                            <TableRow className={row.is_back == 0 ? "bg_even" : "bg_odd"}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                {userInfo.user_type != "User" ? <TableCell >{row.client}</TableCell> : ""}
                                                                <TableCell>{row.runner_name}</TableCell>
                                                                <TableCell>{row.odds}</TableCell>
                                                                <TableCell>{row.stake}</TableCell>
                                                                <TableCell>{row.profit.toFixed(2)}</TableCell>
                                                                <TableCell>{row.betting_type}</TableCell>
                                                                {/* <TableCell>{moment(row.createdAt).format("YYYY-MM-DD h:mm:ss a")}</TableCell> */}
                                                                <TableCell>{row.hasOwnProperty("_id") ? (row._id).substr(-6) : ""}</TableCell>
                                                                <TableCell>{row.hasOwnProperty("ip_address") ? row.ip_address.replace('::ffff:', '') : ""}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </BrowserView>
                            </Grid>

                        </Grid>
                    </div>



                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={positionOpen}
                        onClose={positionClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        style={{ height: "100%" }}
                    >

                        <Fade in={positionOpen}>

                            <div className="user_modal" >
                                <Grid container spacing={2}>
                                    <Grid item lg={11} xs={10}>
                                        <h2 id="transition-modal-title">Fancy Positions</h2>
                                    </Grid>
                                    <Grid item lg={1} xs={1}>
                                        <IconButton aria-label="delete" onClick={positionClose} color="secondary" className={classes.margin}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="tblhead darktblhead">

                                            <TableCell align="center" >Score </TableCell>
                                            <TableCell align="center" >Ammount</TableCell>

                                        </TableRow>

                                    </TableHead>


                                    <TableBody className="tbl_body ">

                                        {postionFancyList.map((item, index) => {
                                            if (item < 0)
                                                return <>
                                                    <TableRow className="">
                                                        <TableCell align="center" >{index}</TableCell>
                                                        <TableCell align="center" className="fancy-position" data-value={item}>{item}</TableCell>
                                                    </TableRow>
                                                </>

                                            if (item >= 0 && item != null)
                                                return <>
                                                    <TableRow className="">
                                                        <TableCell align="center" >{index}</TableCell>
                                                        <TableCell align="center" className="fancy-position" data-value={item}>{item}</TableCell>
                                                    </TableRow>
                                                </>
                                        })
                                        }

                                    </TableBody>
                                </Table>
                            </div>
                        </Fade>
                    </Modal>


                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={positionBookOpen}
                        onClose={positionBookClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        style={{ height: "100%" }}
                    >

                        <Fade in={positionBookOpen}>

                            <div className="cposition_model" >
                                <Grid container spacing={2}>
                                    <Grid item lg={11} xs={10}>
                                        <h2 id="transition-modal-title">Bookmaker Book</h2>
                                    </Grid>
                                    <Grid item lg={1} xs={1}>
                                        <IconButton aria-label="delete" onClick={positionBookClose} color="secondary" className={classes.margin}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} className="bet-slip">
                                    <Table className={classes.table} aria-label="simple table" >
                                        <TableHead>

                                            <TableRow className="darktblhead">
                                                <TableCell style={{ width: 15 }}>No.</TableCell>
                                                <TableCell style={{ width: 100 }}>User</TableCell>
                                                {
                                                    prevRunnersBm.map((runner, index) => {
                                                        return <TableCell style={{ width: 100, textAlign: "center" }}>{runner}</TableCell>
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {currentPositionList.length >= 0 && currentPositionList.map((row, index) => {
                                                return row.market_name != "Match Odds" ? <>
                                                    <TableRow >
                                                        <TableCell colSpan={4} style={{ width: 15 }}>
                                                            {/* {row.market_name} */}
                                                        </TableCell>
                                                    </TableRow>
                                                    {row.match_odds_position.map((nrow, nindex) => (
                                                        <TableRow >
                                                            <TableCell style={{ width: 15 }}>{nindex + 1}</TableCell>
                                                            {
                                                                nrow.user_type == 'User' ?
                                                                    <TableCell style={{ width: 200, fontWeight: 600 }}>{nrow.user_name} ({nrow.name})</TableCell> : <TableCell onClick={() => history.push(`/full-market/${params.event_id}/${nrow.user_id}`)} style={{ width: 200, fontWeight: 600 }}>{nrow.user_type != "User" ? <Link>{`${nrow.user_name} (${nrow.name})`}</Link> : `${nrow.user_name} (${nrow.name})`}</TableCell>
                                                            }


                                                            {nrow.exposure.length >= 0 && nrow.exposure.map((exp, mindex) => (
                                                                <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={exp.total_pl}>{Math.abs(exp.total_pl.toFixed(2))}</TableCell>
                                                            ))
                                                            }


                                                        </TableRow>

                                                    ))
                                                    }

                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center">Total P/L</TableCell>
                                                        {row.totalExposure && row.totalExposure.length >= 0 && row.totalExposure.map((tselfexp, index) => (
                                                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={tselfexp.total_pl}>{Math.abs(tselfexp.total_pl.toFixed(2))}</TableCell>
                                                        ))
                                                        }


                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell colSpan={2} align="center">Admin P&L</TableCell>
                                                        {row.selfExposure && row.selfExposure.length >= 0 && row.selfExposure.map((selfexp, index) => (
                                                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={selfexp.total_pl}>{Math.abs(selfexp.total_pl.toFixed(2))}</TableCell>
                                                        ))
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center">Super P&L</TableCell>
                                                        {row.uplineExposure && row.uplineExposure.length >= 0 && row.uplineExposure.map((uplineexp, index) => (
                                                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={uplineexp.total_pl}>{Math.abs(uplineexp.total_pl.toFixed(2))}</TableCell>
                                                        ))
                                                        }
                                                    </TableRow>
                                                </> : ""
                                            })}
                                        </TableBody>
                                        <TableFooter>

                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={positionMatchOpen}
                        onClose={positionMatchClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        style={{ height: "100%" }}
                    >

                        <Fade in={positionMatchOpen}>

                            <div className="cposition_model" >
                                <Grid container spacing={2}>
                                    <Grid item lg={11} xs={10}>
                                        <h2 id="transition-modal-title">Match Odds Book</h2>
                                    </Grid>
                                    <Grid item lg={1} xs={1}>
                                        <IconButton aria-label="delete" onClick={positionMatchClose} color="secondary" className={classes.margin}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} className="bet-slip">
                                    <Table className={classes.table} aria-label="simple table" >
                                        <TableHead>

                                            <TableRow className="darktblhead">
                                                <TableCell style={{ width: 15 }}>No.</TableCell>
                                                <TableCell style={{ width: 100 }}>User</TableCell>
                                                {
                                                    prevRunners.map((runner, index) => {
                                                        return <TableCell style={{ width: 100, textAlign: "center" }}>{runner}</TableCell>
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {currentPositionList.length >= 0 && currentPositionList.map((row, index) => {
                                                return row.market_name == "Match Odds" ? <>
                                                    <TableRow >
                                                        <TableCell colSpan={4} style={{ width: 15 }}>
                                                            {/* {row.market_name} */}
                                                        </TableCell>
                                                    </TableRow>
                                                    {row.match_odds_position.map((nrow, nindex) => (
                                                        <TableRow >
                                                            <TableCell style={{ width: 15 }}>{nindex + 1}</TableCell>
                                                            {
                                                                nrow.user_type == 'User' ?
                                                                    <TableCell style={{ width: 200, fontWeight: 600 }}>{nrow.user_name} ({nrow.name})</TableCell> : <TableCell onClick={() => history.push(`/full-market/${params.event_id}/${nrow.user_id}`)} style={{ width: 200, fontWeight: 600 }}>{nrow.user_type != "User" ? <Link>{`${nrow.user_name} (${nrow.name})`}</Link> : `${nrow.user_name} (${nrow.name})`}</TableCell>
                                                            }


                                                            {nrow.exposure.length >= 0 && nrow.exposure.map((exp, mindex) => (
                                                                <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={exp.total_pl}>{Math.abs(exp.total_pl.toFixed(2))}</TableCell>
                                                            ))
                                                            }


                                                        </TableRow>

                                                    ))
                                                    }

                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center">Total P/L</TableCell>
                                                        {row.totalExposure && row.totalExposure.length >= 0 && row.totalExposure.map((tselfexp, index) => (
                                                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={tselfexp.total_pl}>{Math.abs(tselfexp.total_pl.toFixed(2))}</TableCell>
                                                        ))
                                                        }


                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell colSpan={2} align="center">Admin P&L</TableCell>
                                                        {row.selfExposure && row.selfExposure.length >= 0 && row.selfExposure.map((selfexp, index) => (
                                                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={selfexp.total_pl}>{Math.abs(selfexp.total_pl.toFixed(2))}</TableCell>
                                                        ))
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center">Super P&L</TableCell>
                                                        {row.uplineExposure && row.uplineExposure.length >= 0 && row.uplineExposure.map((uplineexp, index) => (
                                                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={uplineexp.total_pl}>{Math.abs(uplineexp.total_pl.toFixed(2))}</TableCell>
                                                        ))
                                                        }
                                                    </TableRow>
                                                </> : ""
                                            })}
                                        </TableBody>
                                        <TableFooter>

                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </div>
        </div>

    )
}
export default FullMarket;

