
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import activeinactive from "../assets/icons/transparent (1).gif"

// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams, useHistory } from "react-router-dom";


import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BlockMarketEventsMarkets() {
    const history = useHistory();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [marketList, setmarketList] = React.useState([]);

    useEffect(() => {
        window.scroll(0, 0)
        eventMarket(params.event_id)
    }, [])
    const columns = [

        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58
        },
        // {
        //     field: 'event_type',
        //     headerName: 'BetfairId',
        //     editable: true,
        //     flex: 1,
        // },
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    rowData.row.market_name == "Fancy" ? <Link onClick={() => history.push(`/blockmarket-markets/${params.event_type_id}/${params.event_id}/fancy`)} >{rowData.row.market_name}</Link> : rowData.row.market_name
                );
            }
        },

        {
            field: "is_blocked",
            headerName: "Status",
            sortable: false,
            width: 74,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    rowData.row.is_blocked === "Yes" ? <Chip label="Inactive" color="secondary" /> : <Chip label="Active" color="primary" />
                );
            }
        },
        {
            field: "edit",
            headerName: "Action",
            sortable: false,
            width: 74,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Switch
                        checked={rowData.row.is_blocked === "Yes" ? false : true}
                        onChange={() => blockUnblockMarket(rowData.row.event_id, rowData.row.market_id)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                );
            }
        },
    ];

    const displaynone = {
        display: "none"
    }
    function eventMarket(eventId) {


        var data = JSON.stringify({
            "user_id": userInfo._id,
            "event_id": eventId
        });


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/blockMarketTypes`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setmarketList(response.data.resultData);
                console.log(response.data.resultData)
                let tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((marketList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('after match odds click', response.data.resultData);
                setmarketList(tmpEventList)

                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    function blockUnblockMarket(event_id, market_id) {
        console.log([event_id, params.event_type_id, market_id]);
        var data = JSON.stringify({

            "user_id": userInfo._id,
            "event_id": event_id,
            "market_id": market_id,
            "fancy_id": "0",
            "event_type_id": params.event_type_id,
            "type": "Market"

        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                eventMarket(params.event_id)
                // getEventList();

                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const classes = useStyles();
    return (

        <div className="dashboard myaccount m-width1300">
            <Header />
            <div className=" account-summary">
                <h2>Market Listing</h2>
                <div id="loading" className="loading-wrap" style={displaynone}>
                    <ul className="loading">
                        <li><img src="./images/loading40.gif" /></li>
                        <li>Loading...</li>
                    </ul>
                </div>

                <div id="message" className="message-wrap success" style={displaynone}>
                    <Link className="btn-close" to="#">Close</Link>
                    <p></p>
                </div>
                <table style={displaynone}>
                    <tbody>
                        <tr id="tempTr">
                            <td id="createDate" className="align-L"></td>
                            <td id="deposit">-</td>
                            <td id="withdraw">-</td>

                        </tr>

                        <tr id="noDataTempTr">
                            <td className="no-data" colSpan="5">
                                <p>No Data</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table id="table_log" className="table01">
                    <thead>
                        <tr>
                            <th width="15%" className="align-L">Name</th>
                            <th width="18%">Status</th>
                            <th width="18%">Action</th>

                        </tr>
                    </thead>
                    <tbody id="content">

                        {marketList.length > 0 && marketList.map(event =>
                            <tr id="tempTr">
                                <td className="align-L">{event.market_name == "Fancy" ? <Link onClick={() => history.push(`/blockmarket-markets/${params.event_type_id}/${params.event_id}/fancy`)} >{event.market_name}</Link> : event.market_name}</td>

                                <td><span id="status0" className={event.is_blocked !== "Yes" ? 'status-active' : 'status-inactive'}>
                                    <img src={activeinactive} />{event.is_blocked !== "Yes" ? 'Active' : 'Inactive'}</span>
                                </td>

                                <td>
                                    <Switch
                                        checked={event.is_blocked === "Yes" ? false : true}
                                        onChange={() => blockUnblockMarket(event.event_id, event.market_id)}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}