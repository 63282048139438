import React, { useState } from 'react';

import "./assets/style.css"

import Appconfig from "./config/config";
import axios from "axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Trans from './assets/transparent.gif'
import { Link } from "react-router-dom";
import moment from "moment";


const AcStatementTab = () => {

    const history = useHistory();
    const params = useParams();
    const userType = params.user_type;
    const [accountList, SetAccountList] = useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    React.useEffect(() => {
        getCreditRefsList();
    }, []);

    function getCreditRefsList() {
        var user_type = params.user_type;
        var data = JSON.stringify({
            "user_id": params.user_id,
            "type": "0",
            "game_type": "Free Chip",
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}reports/accountStatement`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                console.log("response", response);
                SetAccountList(response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="log-wrap" style={{ width: "900px" }}>
            <table className="game-team">
                <tbody><tr>
                    <td className="game-name">
                        Banking Logs
                        <span>{params.user_name}</span>
                        <a className="pop-close" onClick={() => window.open('', '_self', '') && window.close()}>
                            <img className="icon-back" src={Trans} />Close</a>
                    </td>
                </tr>
                </tbody></table>

            <div className="over-wrap" style={{ height: "513px" }}>

                <table id="table_log" className="table01">
                    <thead>
                        <tr>
                            <th width="15%" className="align-L">Date/Time</th>
                            <th width="18%">Deposit</th>
                            <th width="18%">Withdraw</th>
                            <th width="18%">Balance</th>
                            <th width="16%">Remark</th>
                            <th width="">From/To</th>
                        </tr>
                    </thead>
                    <tbody id="content">

                        {accountList.length > 0 && accountList.map(accountData => <tr id="tempTr">
                            <td id="createDate" className="align-L">{moment(accountData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td id="deposit" className="inttype" data-value={accountData.transaction_type == "Credit" || accountData.transaction_type != "Debit" && accountData.amount > 0
                                ? accountData.amount : 0}>{accountData.transaction_type == "Credit" || accountData.transaction_type != "Debit" && accountData.amount > 0
                                    ? accountData.amount : "-"}</td>
                            <td id="withdraw" className="inttype" data-value={accountData.transaction_type == "Debit" || accountData.transaction_type != "Credit" && accountData.amount < 0 ?
                                accountData.amount : 0}>{accountData.transaction_type == "Debit" || accountData.transaction_type != "Credit" && accountData.amount < 0 ?
                                    accountData.amount : "-"}</td>
                            <td id="balance">{accountData.balance}</td>
                            <td>

                                {accountData.type == "Settlement" ? accountData.type + " / " : ""}

                                {accountData.betting_type ? <Link onClick={() => history.push(`/memberList/bethistory?eventid=${accountData._id.x}&selection_id=${accountData.betting_type == 'Match' ? 'Match' : accountData.selection_id}`)}>{`${accountData.remarks}` + (accountData.betting_type ? `(${accountData.betting_type} / ${accountData.place_name})` : "")}</Link> : accountData.remarks}
                            </td>
                            <td>
                                <span id="from">{accountData.from ? accountData.from : "-"}</span>
                                <img className="fromto" src={Trans} />
                                <span id="to">{accountData.to ? accountData.to : "-"}</span>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>




        </div >

    );
}
export default AcStatementTab