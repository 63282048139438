import React from 'react'
import "../assets/style.css"
import Header from '../admin/Header'
import SideNav from '../admin/SideNav'
import { Link } from "react-router-dom";
import { useState } from 'react'
import Appconfig from '../config/config';
import { CURRENCY_TYPE } from '../config/config';
import { useLocation, useParams } from "react-router-dom";
import axios from 'axios';

import moment from 'moment';

const displaynone = {
    display: "none"
}

export default function FancyBook() {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [accountList, SetAccountList] = React.useState([]);
    const [balLoader, setBalLoader] = React.useState(false);
    const [MainBalance, setMainBalance] = React.useState(0);
    const [exposure, setExposure] = React.useState(0);
    const [memeberUserData, setMemberUserData] = React.useState({});
    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);
    const [formData, setFormData] = React.useState({
        "user_id": uidQuery ? uidQuery : userInfo._id,
        "type": "0",
        "from_date": moment().add(-1, 'days').format('YYYY-MM-DD'),
        "to_date": moment().format('YYYY-MM-DD'),
    })


    const [fancyBook, setFancyBook] = React.useState({
        event_name: "",
        fancy_name: "",
        position: []
    });



    const params = useParams();

    React.useEffect(() => {

        getFancyBook();
    }, []);

    function getFancyBook() {

        var data = JSON.stringify({
            "user_id": params.user_id,
            "event_id": params.event_id,
            "selection_id": params.selection_id

        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getFancyBookForUplines`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setBalLoader(false);
                if (response.data.result == 0) {
                } else {
                    console.log(response.data.resultData);
                    setFancyBook(response.data.resultData)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    return (
        <div className="dashboard myaccount m-width1300"  style={{width:300}}>

            <table class="game-team"  style={{width:300}}>
                <tbody><tr>
                    <td class="game-name" id="title">{fancyBook.event_name}<span>{fancyBook.fancy_name}</span></td>
                </tr>
                </tbody></table>


            <div class="pop-title"  style={{width:300}}>
                <h2>Run Position</h2>

                <div class="">
                    <table class="table01 tab-depth" style={{width:300}}>
                        <thead>
                            <tr>
                                <th id="run_name" class="align-L" style={{ width: 150 }}>Run</th>
                                <th class="border-l" style={{ width: 150 }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody id="runsPositionContent">
                            {fancyBook.position.length > 0 && fancyBook.position.map((book, index) =>
                                book != null ?
                                    <>
                                        {
                                            book > 0 ?
                                                <tr>
                                                    <td class="align-L back" id="runs">{index}</td>
                                                    <td class="back" id="exposure">${Math.abs(book.toFixed(2))}</td>
                                                </tr> :
                                                <tr>
                                                    <td class="align-L lay" id="runs">{index}</td>
                                                    <td class="lay" id="exposure"><span class="red">(${Math.abs(book.toFixed(2))})</span></td>
                                                </tr>

                                        }

                                    </>
                                    : null
                            )}





                            </tbody>
                    </table>
                </div>
            </div>
        </div >
    );
}

