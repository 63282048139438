import React from 'react'
import Transparent from '../assets/transparent.gif'
import "../assets/style.css"
import { Link } from "react-router-dom";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
// import TabPanel from "@material-ui/lab/TabPanel";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Grid, AppBar, Tab } from '@material-ui/core';
import TimeField from 'react-simple-timefield';
import Appconfig from '../config/config';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import SideNav from '../admin/SideNav';
import { isMobile } from "react-device-detect";
import Header from '../admin/Header'

const noReport = <div id="noReportMessage" >
    <p>Betting History enables you to review the bets you have placed.
        <br />Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p>
    <p>Betting Profit &amp; Loss is available online for the past 62 days.</p>
    <p>User can search up to 14 days records per query only .</p>
</div>
function TabPanel(props) {

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const BetHistoryUser = (props) => {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const eventIDQuery = new URLSearchParams(search).get('eventid');
    const selectionIDQuery = new URLSearchParams(search).get('selection_id');
    const usernameQuery = new URLSearchParams(search).get('username');
    const [betHistoryList, SetBetHistoryList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const nPrevu = window.sessionStorage.getItem("tech_sh");
    const [prevUser, setPrevuser] = React.useState(nPrevu ? JSON.parse(nPrevu) : []);

    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/current-bets') {
        segment = 3;
    }


    const gridType = isMobile ? 10 : 8;

    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(search);
    const [valuen, setnValue] = React.useState(0);
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 2,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            height: "100%",
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            width: '230px',
            marginLeft: 'auto',
            background: '#ffffff'
        },
        table: {
            minWidth: 650,
        },
    }));

    const classes = useStyles();
    const [memeberUserData, setMemberUserData] = React.useState([]);
    const [formData, setFormData] = React.useState({
        "event_id": "",
        "user_id": uidQuery,
        'event_type': "",
        'status': "Settled",
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': moment().format("YYYY-MM-DD"),
        'bet_type': "Exchange"
    })
    const [formData1, setFormData1] = React.useState({
        "event_id": eventIDQuery,
        "user_id": userInfo._id,
        'event_type': "",
        'status': "All",
        'matchUnmatch': " ",
        'selection_id': selectionIDQuery,
        'from_date': "",
        'to_date': "",
    })

    function justFor(days) {
        if (days == 1) {
            formData.from_date = moment().startOf('day').format("YYYY-MM-DD");
            formData.to_date = moment().endOf('day').format("YYYY-MM-DD");
        }
        if (days == 2) {
            formData.from_date = moment().subtract(days, 'days').format("YYYY-MM-DD");
            formData.to_date = moment().format("YYYY-MM-DD");
        }
        getBetHistory();
    }
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        setFormData1({
            ...formData1,
            [event.target.name]: event.target.value,
        });
        console.log(formData);
    };
    function getMembersDataUsersData() {
        // var user_type = params.user_type;
        var data = JSON.stringify({
            user_id: uidQuery,
        });
        //   console.log(data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUserById`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,

        };
        axios(config)
            .then(function (response) {
                setMemberUserData(response.data);

                let tmpUserList = response.data;
                //         console.log("data new", tmpUserList);
                let tempSetData = tmpUserList.resultData[0];


                setMemberUserData(tempSetData);
                //        console.log('userList', response.data);
            })
            .catch(function (error) {
                //            console.log(error);
            });
    }
    function showCurrentBookmaker(click_id, rowarray) {
        var expandHtml = document.getElementById("expandCurrentBookmaker-" + click_id + "-toggle");
        var linkHtml = document.getElementById("expandCurrentBookmaker-" + click_id);
        // updateTotalStake(rowarray);

        if (linkHtml.getAttribute('data-id') == 0) {

            linkHtml.setAttribute('data-id', '1');
            expandHtml.style.display = "table-row";
            linkHtml.classList.add("expand-open");
            linkHtml.classList.remove("expand-close");

        } else {
            linkHtml.setAttribute('data-id', '0');
            expandHtml.style.display = "none";
            linkHtml.classList.add("expand-close");
            linkHtml.classList.remove("expand-open");

        }


    }
    React.useEffect(() => {
        // getBetHistory()
        getMembersDataUsersData();
    }, [])
    function getBetHistory(event_type) {
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/betHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                SetBetHistoryList(response.data.resultData);
                if (!response.data.resultData.length) {
                    document.getElementById("message").style.display = "block";
                    document.getElementById("msgtext").textContent = "you have no bets in this time period";
                    setTimeout(function () {
                        closeMessage();
                    }, 3000);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    const handleFilterSubmit = () => {
        getBetHistory(formData.event_type);
    }
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
        if (newValue == 1) {
            formData.bet_type = "Fancy";
        } else if (newValue == 2) {
            formData.bet_type = "Premium";
        } else if (newValue == 3) {
            formData.bet_type = "Bookmaker";
        }
        else if (newValue == 4) {
            formData.bet_type = "Casino";
        } else {
            formData.bet_type = "Exchange"
        }
        SetBetHistoryList([]);

    };

    return (
        <div className="dashboard bethistory-user">
            <>
                <Header />
                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L" >

                        {memeberUserData.length ? prevUser.length && memeberUserData?._id != userInfo._id && prevUser.map((row, index) => {
                            return <li id="path6" className="" key={"path" + index}>
                                <Link to={row.id == userInfo._id ? `/dashboard` : row.url}>
                                    <span className={`lv_${index}`}>
                                        {row.user_type.match(/\b\w/g).join('')}
                                    </span>
                                    <strong>{row.user_name}</strong>
                                </Link>
                            </li>
                        }) : ""}
                        <li id="path6" className="" key={"pathuser"}>
                            <Link to={window.location}>
                                <span className={`lv_3`}>
                                    {memeberUserData.user_type}
                                </span>
                                <strong>{memeberUserData?.user_name}</strong>
                            </Link>
                        </li>

                    </ul>
                </div>
                <Grid className="current-bets-cont m-width1300"  container spacing={0} style={{ backgroundColor: '#F0ECE1' }}>
                    <div className="col-left">
                        <SideNav memeberUserData={memeberUserData} />
                    </div>

                    <Grid item lg={gridType} xs={12} spacing={2}>
                        <div id="message" className="message-wrap-new" style={{ background: "yellow", color: "#000" }}>
                            <Link to="#" onClick={closeMessage} className="btn-close">Close</Link>
                            <p id="msgtext"> </p>
                        </div>
                        <div className="Bets History bet-history-pd">
                            <TabContext value={valuen}>
                                <AppBar position="static" className="tab">
                                    <TabList value={valuen} variant="fullWidth" onChange={handlenChange}>
                                        <Tab label="Exchange" {...a11yProps(0)} />
                                        <Tab label="FancyBet" {...a11yProps(1)} />
                                        <Tab label="Sportsbook" {...a11yProps(2)} />
                                        <Tab label="BookMaker" {...a11yProps(3)} />
                                        <Tab label="Casino" {...a11yProps(4)} />
                                    </TabList>
                                </AppBar>
                                <TabPanel className="Bets History bet-history-combine bet-history-pdd" value={valuen} index={0}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>
                                            <Typography variant="span">Bet Status</Typography>
                                            <select id="betStatus" onChange={handleChange} value={formData.status} name="status" className="bet-status">
                                                <option value="All">All</option>
                                                <option value=" ">Unmatched</option>
                                                <option value="Open">Matched</option>
                                                <option value="Settled">Settled</option>
                                                <option value=" ">Cancelled</option>
                                                <option value=" ">Voided</option>
                                            </select>
                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="fromTime" type="time" maxlength="5" placeholder="09:00" value={formData.fromTime} style={{ width: 74 }} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="toTime" type="time" maxlength="5" placeholder="08:59" value={formData.toTime} style={{ width: 74 }} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getBetHistory()}><button id="getPL" className="btn-send">Get History</button></li>
                                        </ul>
                                    </Box>

                                    {betHistoryList.length > 0 ? <>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>

                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell align="left">PL ID</TableCell>
                                                    <TableCell align="left" style={{ width: "32%" }}>Market</TableCell>
                                                    <TableCell align="right">Selection</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Stake</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((row) => (
                                                    <TableRow>
                                                        {/* <TableCell component="th" scope="row">{row.event_name} {row.place_name}</TableCell> */}
                                                        {/* <TableCell align="right" className={row.is_back ? "back" : "lay"}>{row.is_back ? "Back" : "Lay"}</TableCell> */}
                                                        <TableCell align="right">{row._id.substr(row._id.length - 6)}</TableCell>
                                                        <TableCell align="right">{row.user_name}</TableCell>
                                                        <TableCell>{row.event_type == 4 ? 'CRICKET' : null} {row.event_type == 1 ? 'SOCCER' : null} {row.event_type == 2 ? 'TENNIS' : null} {![1, 2, 4].includes(row.event_type) ? 'Casino' : null} <img id="arrowww" class="fromto" src={Transparent} /><strong>{row.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {row.market_name}</TableCell>
                                                        <TableCell align="right">{row.place_name ? row.place_name : " "}</TableCell>
                                                        <TableCell align="right" className={row.is_back ? "back" : "lay"}>{row.is_back ? "Back" : "Lay"}</TableCell>
                                                        <TableCell align="right">{moment(row.updatedAt).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                        <TableCell align="right">{row.price_val}</TableCell>
                                                        <TableCell align="right">{row.stake}</TableCell>

                                                        <TableCell align="right">{row.bet_result == 'Minus' ? <span className='red'>({Math.abs(row.loss.toFixed(2))})</span> : <span >{row.profit.toFixed(2)}</span>}</TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </> : noReport}

                                </TabPanel>
                                <TabPanel className="Bets History bet-history-combine bethistory-filters-fancy" value={valuen} index={1}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>
                                            <Typography variant="span">Bet Status</Typography>
                                            <select id="betStatus" onChange={handleChange} value={formData.status} name="status" className="bet-status">
                                                <option value="All">All</option>
                                                <option value=" ">Unmatched</option>
                                                <option value="Open">Matched</option>
                                                <option value="Settled">Settled</option>
                                                <option value=" ">Cancelled</option>
                                                <option value=" ">Voided</option>
                                            </select>
                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="fromTime" type="time" maxlength="5" placeholder="09:00" value={formData.fromTime} style={{ width: 74 }} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="toTime" type="time" maxlength="5" placeholder="08:59" value={formData.toTime} style={{ width: 74 }} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getBetHistory()}><button id="getPL" className="btn-send">Get History</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>

                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell>PL ID</TableCell>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="right">Selection</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Stake</TableCell>
                                                    <TableCell align="right">Avg. odds matched	P</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {/* betHistoryList */}
                                                {betHistoryList.map((rowinner) => (

                                                    (rowinner.market_name == "Fancy") ?
                                                        <>
                                                            <TableRow>
                                                                <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                {/* <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowinner._id.substr(rowinner._id.length - 6))}>{rowinner._id.substr(rowinner._id.length - 6)}</Link></TableCell> */}
                                                                <TableCell align="left">{rowinner.user_name}</TableCell>
                                                                <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null} {![1, 2, 4].includes(rowinner.event_type) ? 'Casino' : null} <img id="arrowww" class="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                                <TableCell align="right">{rowinner.place_name}</TableCell>
                                                                <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Yes" : "No"}</TableCell>
                                                                <TableCell align="right">{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val}</TableCell>
                                                                <TableCell align="right">{rowinner.stake}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val}</TableCell>
                                                                <TableCell align="right">{rowinner.bet_result == 'Minus' ? <span className='red'>({Math.abs(rowinner.loss.toFixed(2))})</span> : <span >{rowinner.profit.toFixed(2)}</span>}</TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="3"></TableCell>
                                                                <TableCell colspan="7">
                                                                    {/* <img class="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet taken</TableCell>
                                                                            <TableCell>Odds req.</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Liability</TableCell>
                                                                            <TableCell>Odds matched</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">
                                                                            <TableRow>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                                <TableCell>{rowinner.stake}</TableCell>
                                                                                <TableCell className={`${rowinner.loss == 0 ? "zeroliability" : "withliability"}`}>{rowinner.loss == 0 ? "-" : `(${rowinner.loss})`}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow></>

                                                        : null
                                                ))}
                                            </TableBody>
                                        </Table></> : noReport}
                                </TabPanel>
                                <TabPanel className="Bets History bet-history-combine bethistory-filters-matchodds" value={valuen} index={2}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>
                                            <Typography variant="span">Bet Status</Typography>
                                            <select id="betStatus" onChange={handleChange} value={formData.status} name="status" className="bet-status">
                                                <option value="All">All</option>
                                                <option value=" ">Unmatched</option>
                                                <option value="Open">Matched</option>
                                                <option value="Settled">Settled</option>
                                                <option value=" ">Cancelled</option>
                                                <option value=" ">Voided</option>
                                            </select>
                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="fromTime" type="time" maxlength="5" placeholder="09:00" value={formData.fromTime} style={{ width: 74 }} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="toTime" type="time" maxlength="5" placeholder="08:59" value={formData.toTime} style={{ width: 74 }} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getBetHistory()}><button id="getPL" className="btn-send">Get History</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>

                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell>PL ID</TableCell>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Stake</TableCell>
                                                    <TableCell align="right">Avg. odds matched	P</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowinner) => (

                                                    (rowinner.market_name == "Match Odds" && rowinner.unmatch_bet == "Yes") ?
                                                        <> <TableRow>
                                                            <TableCell align="left">{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                            <TableCell align="left">{rowinner.user_name}</TableCell>
                                                            <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                            <TableCell align="left">{rowinner.place_name}</TableCell>
                                                            <TableCell align="left" className={rowinner.is_back == 1 ? "yesfancyhistory" : "nofancyhistory"}>{rowinner.is_back == 1 ? "Yes" : "No"}</TableCell>
                                                            <TableCell align="right">{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val}</TableCell>
                                                            <TableCell align="right">{rowinner.stake}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val}</TableCell>
                                                            <TableCell align="right">{rowinner.bet_result == 'Minus' ? <span className='red'>({Math.abs(rowinner.loss.toFixed(2))})</span> : <span >{rowinner.profit.toFixed(2)}</span>}</TableCell>
                                                        </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="3"></TableCell>
                                                                <TableCell colspan="7">
                                                                    {/* <img class="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet taken</TableCell>
                                                                            <TableCell>Odds req.</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Liability</TableCell>
                                                                            <TableCell>Odds matched</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">
                                                                            <TableRow>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                                <TableCell>{rowinner.stake}</TableCell>
                                                                                <TableCell className={`${rowinner.loss == 0 ? "zeroliability" : "withliability"}`}>{rowinner.loss == 0 ? "-" : `(${rowinner.loss})`}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow></>

                                                        : null

                                                    // <TableRow>
                                                    //     <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                    //     <TableCell align="left"></TableCell>
                                                    //     <TableCell align="left"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    //     <TableCell align="right"></TableCell>
                                                    // </TableRow>                                       
                                                ))}
                                            </TableBody>
                                        </Table>

                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell>PL ID</TableCell>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Stake</TableCell>
                                                    <TableCell align="right">Avg. odds matched	P</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowinner) => (
                                                    (rowinner.market_name == "Match Odds" && rowinner.unmatch_bet == "No") ?
                                                        <><TableRow>
                                                            <TableCell align="left">
                                                                {uidQuery != null ?
                                                                    <Link data-id="0" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}`} className="expand-close" to={`bethistory?uid=${uidQuery}`} onClick={() => showCurrentBookmaker(rowinner._id.substr(rowinner._id.length - 6))}>{rowinner._id.substr(rowinner._id.length - 6)}</Link>
                                                                    :
                                                                    <Link data-id="0" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}`} className="expand-close" to={`bethistory?eventid=${eventIDQuery}&selection_id=Match`} onClick={() => showCurrentBookmaker(rowinner._id.substr(rowinner._id.length - 6))}>{rowinner._id.substr(rowinner._id.length - 6)}</Link>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="left">{rowinner.user_name}</TableCell>
                                                            <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                            <TableCell align="left">{rowinner.place_name}</TableCell>
                                                            <TableCell align="left" className={rowinner.is_back == 1 ? "yesfancyhistory" : "nofancyhistory"}>{rowinner.is_back == 1 ? "Yes" : "No"}</TableCell>
                                                            <TableCell align="right">{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val}</TableCell>
                                                            <TableCell align="right">{rowinner.stake}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val}</TableCell>
                                                            <TableCell align="right">{rowinner.bet_result == 'Minus' ? <span className='red'>({Math.abs(rowinner.loss.toFixed(2))})</span> : <span >{rowinner.profit.toFixed(2)}</span>}</TableCell>
                                                        </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="3"></TableCell>
                                                                <TableCell colspan="7">
                                                                    {/* <img class="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet taken</TableCell>
                                                                            <TableCell>Odds req.</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Liability</TableCell>
                                                                            <TableCell>Odds matched</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">
                                                                            <TableRow>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                                <TableCell>{rowinner.stake}</TableCell>
                                                                                <TableCell className={`${rowinner.loss == 0 ? "zeroliability" : "withliability"}`}>{rowinner.loss == 0 ? "-" : `(${rowinner.loss})`}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow></>
                                                        : null
                                                ))}
                                            </TableBody>
                                        </Table></> : noReport}
                                </TabPanel>
                                <TabPanel className="Bets History bet-history-combine bethistory-filters-bookmaker" value={valuen} index={3}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>
                                            <Typography variant="span">Bet Status</Typography>
                                            <select id="betStatus" onChange={handleChange} value={formData.status} name="status" className="bet-status">
                                                <option value="All">All</option>
                                                <option value=" ">Unmatched</option>
                                                <option value="Open">Matched</option>
                                                <option value="Settled">Settled</option>
                                                <option value=" ">Cancelled</option>
                                                <option value=" ">Voided</option>
                                            </select>
                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="fromTime" type="time" maxlength="5" placeholder="09:00" value={formData.fromTime} style={{ width: 74 }} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="toTime" type="time" maxlength="5" placeholder="08:59" value={formData.toTime} style={{ width: 74 }} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getBetHistory()}><button id="getPL" className="btn-send">Get History</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>

                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell>PL ID</TableCell>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Stake</TableCell>
                                                    <TableCell align="right">Avg. odds matched	P</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((rowinner) => (
                                                    (rowinner.market_name == "Bookmaker" && rowinner.unmatch_bet == "No") ?
                                                        <><TableRow>
                                                            <TableCell align="left"><span data-id="0" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}`} className="expand-close" to="" onClick={() => showCurrentBookmaker(rowinner._id.substr(rowinner._id.length - 6))}>{rowinner._id.substr(rowinner._id.length - 6)}</span></TableCell>
                                                            <TableCell align="left">{rowinner.user_name}</TableCell>
                                                            <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" class="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                            <TableCell align="left">{rowinner.place_name}</TableCell>
                                                            <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Yes" : "No"}</TableCell>
                                                            <TableCell align="right">{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val}</TableCell>
                                                            <TableCell align="right">{rowinner.stake}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val}</TableCell>
                                                            <TableCell align="right">{rowinner.bet_result == 'Minus' ? <span className='red'>({Math.abs(rowinner.loss.toFixed(2))})</span> : <span >{rowinner.profit.toFixed(2)}</span>}</TableCell>
                                                        </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="3"></TableCell>
                                                                <TableCell colspan="7">
                                                                    {/* <img class="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet taken</TableCell>
                                                                            <TableCell>Odds req.</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Liability</TableCell>
                                                                            <TableCell>Odds matched</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">
                                                                            <TableRow>
                                                                                <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</TableCell>
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                                <TableCell>{rowinner.stake}</TableCell>
                                                                                <TableCell >{rowinner.bet_result == 'Minus' ? <span className='red'>({Math.abs(rowinner.loss.toFixed(2))})</span> : <span >{rowinner.profit.toFixed(2)}</span>}</TableCell>
                                                                                {/* <TableCell className={`${rowinner.loss == 0 ? "zeroliability" : "withliability"}`}>{rowinner.loss == 0 ? "-" : `(${rowinner.loss})`}</TableCell> */}
                                                                                <TableCell>{rowinner.price_val}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                        : null
                                                ))}
                                            </TableBody>
                                        </Table></> : noReport}
                                </TabPanel>
                                <TabPanel className="Bets History bet-history-combine" value={valuen} index={4}>
                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                        <Box className="input-list" style={{ width: "100%" }}>
                                            <Typography variant="span">Bet Status</Typography>
                                            <select id="betStatus" onChange={handleChange} value={formData.status} name="status" className="bet-status">
                                                <option value="All">All</option>
                                                <option value=" ">Unmatched</option>
                                                <option value="Open">Matched</option>
                                                <option value="Settled">Settled</option>
                                                <option value=" ">Cancelled</option>
                                                <option value=" ">Voided</option>
                                            </select>
                                            <Typography variant="span">Period</Typography>
                                            {/* <input type="checkbox" name="betplace" /> */}
                                            <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="fromTime" type="time" maxlength="5" placeholder="09:00" value={formData.fromTime} style={{ width: 74 }} />
                                            <Typography variant="span"> to</Typography>
                                            <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" />
                                            {/* <input type="checkbox" name="market" /> */}
                                            <input onChange={handleChange} name="toTime" type="time" maxlength="5" placeholder="08:59" value={formData.toTime} style={{ width: 74 }} />
                                        </Box>
                                        <ul className="input-list">
                                            <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                            <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                            <li onClick={() => getBetHistory()}><button id="getPL" className="btn-send">Get History</button></li>
                                        </ul>
                                    </Box>
                                    {betHistoryList.length > 0 ? <>

                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>

                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell align="left">PL ID</TableCell>
                                                    <TableCell align="left">Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Stake</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {betHistoryList.map((row) => (
                                                    <TableRow>
                                                        {/* <TableCell component="th" scope="row">{row.event_name} {row.place_name}</TableCell> */}
                                                        {/* <TableCell align="right" className={row.is_back ? "back" : "lay"}>{row.is_back ? "Back" : "Lay"}</TableCell> */}
                                                        <TableCell align="right">{row._id.substr(row._id.length - 6)}</TableCell>
                                                        <TableCell align="right">{row.user_name}</TableCell>
                                                        <TableCell>{row.event_type == 4 ? 'CRICKET' : null} {row.event_type == 1 ? 'SOCCER' : null} {row.event_type == 2 ? 'TENNIS' : null} {![1, 2, 4].includes(row.event_type) ? 'Casino' : null} <img id="arrowww" class="fromto" src={Transparent} /><strong>{row.event_name}</strong> <img id="arrowww" class="fromto" src={Transparent} /> {row.market_name}</TableCell>
                                                        <TableCell align="right">{row.place_name ? row.place_name : " "}</TableCell>
                                                        <TableCell align="right" className={row.is_back ? "back" : "lay"}>{row.is_back ? "Back" : "Lay"}</TableCell>
                                                        <TableCell align="right">{moment(row.updatedAt).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                        <TableCell align="right">{row.price_val}</TableCell>
                                                        <TableCell align="right">{row.stake}</TableCell>
                                                        <TableCell align="right">{row.bet_result == 'Minus' ? <span className='red'>({Math.abs(row.loss.toFixed(2))})</span> : <span >{row.profit.toFixed(2)}</span>}</TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        </Table></> : noReport}
                                </TabPanel>
                            </TabContext>
                        </div>
                    </Grid>
                </Grid></>
        </div >
    )
}

export default BetHistoryUser
