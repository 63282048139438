import { FormControl, Input, List, ListItem, ListItemText, FormControlLabel, Divider, TextField, Checkbox, Button, Card, CardActions, CardContent, CardHeader, Typography, Grid } from '@material-ui/core';
import Header from '../admin/Header'
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import React, { useState } from 'react';
import axios from 'axios';
import Appconfig from '../config/config';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, Link } from "react-router-dom";


function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        // marginTop: 140,
        // margin: 10,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    divbrown: {
        width: '100%',
        display: 'inline-block',
        margin: '0px 0px',
        background: '#e6e7e9',
        fontSize: 12,
        padding: '0px 10px',

    },
    headerdiv: {
        borderBottom: '1px solid #efefef',
        padding: 10,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const GenSetting = () => {
    const displaynone = {
        display: "none"
    }
    const params = useParams();
    const userId = params.user_id;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [superValidation, setSuperValidation] = useState(false);

    React.useEffect(() => {
        window.scroll(0, 0);
        setSuperValidation(false);
        if (userInfo.masters.length > 0) {
            setSuperValidation(false);
            let id = "";
            userInfo.masters.map((user) => {
                if (user.user_type == "Super Admin") {
                    id = user.id;
                }
            })
            console.log("masters id", id);
            getSuperGenSettings(id)
        }
        else {
            if (userId != 0) {
                setSuperValidation(false);
                getSuperGenSettings(userId)
            }
        }

        getUserGenSettings()
    }, [userId])


    const [cricket, setCricketData] = useState({
        min_stack_cricket: '',
        max_stack_cricket: '',
        max_profit_cricket: '',
        max_loss_cricket: '',
        bet_delay_cricket: '',
        pre_inplay_profit_cricket: '',
        pre_inplay_stack_cricket: '',
        min_odds_cricket: '',
        max_odds_cricket: '',
        unmatch_bet_cricket: false,
        lock_bet_cricket: false,
        update_all_users_cricket: false,
        match_odds_cricket: false
    });


    const [bookmaker, setBookmakerData] = useState({
        min_stack_bookmaker: '',
        max_stack_bookmaker: '',
        max_profit_bookmaker: '',
        max_loss_bookmaker: '',
        bet_delay_bookmaker: '',
        pre_inplay_profit_bookmaker: '',
        pre_inplay_stack_bookmaker: '',
        min_odds_bookmaker: '',
        max_odds_bookmaker: '',
        unmatch_bet_bookmaker: false,
        lock_bet_bookmaker: false,
        update_all_users_bookmaker: false,
        match_odds_bookmaker: false
    });

    const [tennis, setTennisData] = useState({
        min_stack_tennis: '',
        max_stack_tennis: '',
        max_profit_tennis: '',
        max_loss_tennis: '',
        bet_delay_tennis: '',
        pre_inplay_profit_tennis: '',
        pre_inplay_stack_tennis: '',
        min_odds_tennis: '',
        max_odds_tennis: '',
        unmatch_bet_tennis: false,
        lock_bet_tennis: false,
        update_all_users_tennis: false,
        match_odds_tennis: false
    });

    const [soccer, setSoccerData] = useState({
        min_stack_soccer: '',
        max_stack_soccer: '',
        max_profit_soccer: '',
        max_loss_soccer: '',
        bet_delay_soccer: '',
        pre_inplay_profit_soccer: '',
        pre_inplay_stack_soccer: '',
        min_odds_soccer: '',
        max_odds_soccer: '',
        unmatch_bet_soccer: false,
        lock_bet_soccer: false,
        update_all_users_soccer: false,
        match_odds_soccer: false
    });

    const [fancy, setFancyData] = useState({
        min_stack_fancy: '',
        max_stack_fancy: '',
        bet_delay_fancy: '',
        max_profit_fancy: '',
        unmatch_bet_fancy: false,
        lock_bet_fancy: false,
        update_all_users_fancy: false,
        match_odds_fancy: false

    });

    const [casino, setCasinoData] = useState({
        min_stack_casino: '',
        max_stack_casino: '',
        max_profit_casino: '',
        max_loss_casino: '',
        bet_delay_casino: '',
        pre_inplay_profit_casino: '',
        pre_inplay_stack_casino: '',
        min_odds_casino: '',
        max_odds_casino: '',
        unmatch_bet_casino: false,
        lock_bet_casino: false,
        update_all_users_casino: false,
        match_odds_casino: false

    });


    const [Scricket, setSCricketData] = useState({
        min_stack_cricket: 0,
        max_stack_cricket: 0,
        max_profit_cricket: 0,
        max_loss_cricket: 0,
        bet_delay_cricket: 0,
        pre_inplay_profit_cricket: 0,
        pre_inplay_stack_cricket: 0,
        min_odds_cricket: 0,
        max_odds_cricket: 0,
        unmatch_bet_cricket: false,
        lock_bet_cricket: false,
        update_all_users_cricket: false
    });

    const [Stennis, setSTennisData] = useState({
        min_stack_tennis: '',
        max_stack_tennis: '',
        max_profit_tennis: '',
        max_loss_tennis: '',
        bet_delay_tennis: '',
        pre_inplay_profit_tennis: '',
        pre_inplay_stack_tennis: '',
        min_odds_tennis: '',
        max_odds_tennis: '',
        unmatch_bet_tennis: false,
        lock_bet_tennis: false,
        update_all_users_tennis: false
    });

    const [Ssoccer, setSSoccerData] = useState({
        min_stack_soccer: '',
        max_stack_soccer: '',
        max_profit_soccer: '',
        max_loss_soccer: '',
        bet_delay_soccer: '',
        pre_inplay_profit_soccer: '',
        pre_inplay_stack_soccer: '',
        min_odds_soccer: '',
        max_odds_soccer: '',
        unmatch_bet_soccer: false,
        lock_bet_soccer: false,
        update_all_users_soccer: false
    });

    const [Sfancy, setSFancyData] = useState({
        min_stack_fancy: '',
        max_stack_fancy: '',
        bet_delay_fancy: '',
        max_profit_fancy: '',
        unmatch_bet_fancy: false,
        lock_bet_fancy: false,
        update_all_users_fancy: false
    });

    const [Scasino, setSCasinoData] = useState({
        min_stack_casino: '',
        max_stack_casino: '',
        max_profit_casino: '',
        max_loss_casino: '',
        bet_delay_casino: '',
        pre_inplay_profit_casino: '',
        pre_inplay_stack_casino: '',
        min_odds_casino: '',
        max_odds_casino: '',
        unmatch_bet_casino: false,
        lock_bet_casino: false,
        update_all_users_casino: false
    });

    const [Sbookmaker, setSbookmakerData] = useState({
        min_stack_bookmaker: '',
        max_stack_bookmaker: '',
        max_profit_bookmaker: '',
        max_loss_bookmaker: '',
        bet_delay_bookmaker: '',
        pre_inplay_profit_bookmaker: '',
        pre_inplay_stack_bookmaker: '',
        min_odds_bookmaker: '',
        max_odds_bookmaker: '',
        unmatch_bet_bookmaker: false,
        lock_bet_bookmaker: false,
        update_all_users_bookmaker: false
    });

    const handleBookmakerInputChange = (event) => {

        const re = /\d+\.?\d*/;
        if (event.target.checked) {

            setBookmakerData({
                ...bookmaker,
                [event.target.name]: event.target.checked
            });
            // console.log('bookmaker', bookmaker);
        }
        else if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.name == "max_odds_bookmaker") {
                ValidatorForm.removeValidationRule('max_odds_bookmaker_limit');
                ValidatorForm.addValidationRule('max_odds_bookmaker_limit', (value) => {
                    if (event.target.value > Sbookmaker.max_odds_bookmaker) {
                        return false;
                    }
                    return true;
                });
            }
            if (event.target.name == "min_odds_bookmaker") {
                ValidatorForm.removeValidationRule('min_odds_bookmaker_limit');
                ValidatorForm.addValidationRule('min_odds_bookmaker_limit', (value) => {
                    if ((event.target.value) > (Sbookmaker.min_odds_bookmaker)) {

                        return false;
                    }
                    return true;
                });
            }
            setBookmakerData({
                ...bookmaker,
                [event.target.name]: event.target.value
            });
            console.log("bookmaker", bookmaker);
        }

    }

    const handleCricketInputChange = (event) => {

        const re = /\d+\.?\d*/;
        if (event.target.checked) {

            setCricketData({
                ...cricket,
                [event.target.name]: event.target.checked
            });
            // console.log('cricket', cricket);
        }
        else if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.name == "max_odds_cricket") {
                ValidatorForm.removeValidationRule('max_odds_cricket_limit');
                ValidatorForm.addValidationRule('max_odds_cricket_limit', (value) => {
                    if (event.target.value > Scricket.max_odds_cricket) {
                        return false;
                    }
                    return true;
                });
            }
            if (event.target.name == "min_odds_cricket") {
                ValidatorForm.removeValidationRule('min_odds_cricket_limit');
                ValidatorForm.addValidationRule('min_odds_cricket_limit', (value) => {
                    if ((event.target.value) > (Scricket.min_odds_cricket)) {

                        return false;
                    }
                    return true;
                });
            }
            setCricketData({
                ...cricket,
                [event.target.name]: event.target.value
            });
        }

    }

    const handleTennisInputChange = (event) => {
        const re = /\d+\.?\d*/;
        if (event.target.checked) {

            setTennisData({
                ...tennis,
                [event.target.name]: event.target.checked
            });
            // console.log('cricket', cricket);
        }
        else if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.name == "max_odds_tennis") {
                ValidatorForm.removeValidationRule('max_odds_tennis_limit');
                ValidatorForm.addValidationRule('max_odds_tennis_limit', (value) => {
                    if (event.target.value > Stennis.max_odds_tennis) {
                        return false;
                    }
                    return true;
                });
            }
            if (event.target.name == "min_odds_tennis") {
                ValidatorForm.removeValidationRule('min_odds_tennis_limit');
                ValidatorForm.addValidationRule('min_odds_tennis_limit', (value) => {
                    if ((event.target.value) > (Stennis.min_odds_tennis)) {

                        return false;
                    }
                    return true;
                });
            }
            setTennisData({
                ...tennis,
                [event.target.name]: event.target.value
            });
        }
        console.log('tennis', tennis);
    }

    const handleSoccerInputChange = (event) => {
        const re = /\d+\.?\d*/;
        if (event.target.checked) {

            setSoccerData({
                ...soccer,
                [event.target.name]: event.target.checked
            });
            // console.log('cricket', cricket);
        }
        else if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.name == "max_odds_soccer") {
                ValidatorForm.removeValidationRule('max_odds_soccer_limit');
                ValidatorForm.addValidationRule('max_odds_soccer_limit', (value) => {
                    if (event.target.value > Ssoccer.max_odds_soccer) {
                        return false;
                    }
                    return true;
                });
            }
            if (event.target.name == "min_odds_soccer") {
                ValidatorForm.removeValidationRule('min_odds_soccer_limit');
                ValidatorForm.addValidationRule('min_odds_soccer_limit', (value) => {
                    if ((event.target.value) > (Ssoccer.min_odds_soccer)) {

                        return false;
                    }
                    return true;
                });
            }

            setSoccerData({
                ...soccer,
                [event.target.name]: event.target.value
            });
        }
        console.log('soccer', soccer);
    }

    const handleFancyInputChange = (event) => {
        const re = /\d+\.?\d*/;
        if (event.target.checked) {

            setFancyData({
                ...fancy,
                [event.target.name]: event.target.checked
            });
            // console.log('cricket', cricket);
        }
        else if (event.target.value === '' || re.test(event.target.value)) {

            if (event.target.name == "max_odds_fancy") {
                ValidatorForm.removeValidationRule('max_odds_fancy_limit');
                ValidatorForm.addValidationRule('max_odds_fancy_limit', (value) => {
                    if (event.target.value > Sfancy.max_odds_fancy) {
                        return false;
                    }
                    return true;
                });
            }
            if (event.target.name == "min_odds_fancy") {
                ValidatorForm.removeValidationRule('min_odds_fancy_limit');
                ValidatorForm.addValidationRule('min_odds_fancy_limit', (value) => {
                    if (event.target.value > Sfancy.min_odds_fancy) {

                        return false;
                    }
                    return true;
                });
            }


            setFancyData({
                ...fancy,
                [event.target.name]: event.target.value
            });
        }
        console.log('fancy', fancy);
    }
    const handleCasinoInputChange = (event) => {
        const re = /\d+\.?\d*/;
        if (event.target.checked) {

            setCasinoData({
                ...casino,
                [event.target.name]: event.target.checked
            });
            // console.log('cricket', cricket);
        }
        else if (event.target.value === '' || re.test(event.target.value)) {

            if (event.target.name == "max_odds_casino") {
                ValidatorForm.removeValidationRule('max_odds_casino_limit');
                ValidatorForm.addValidationRule('max_odds_casino_limit', (value) => {
                    if (event.target.value > Scasino.max_odds_casino) {
                        return false;
                    }
                    return true;
                });
            }
            if (event.target.name == "min_odds_casino") {

                ValidatorForm.removeValidationRule('min_odds_casino_limit');

                ValidatorForm.addValidationRule('min_odds_casino_limit', (value) => {
                    if (event.target.value > Scasino.min_odds_casino) {

                        return false;
                    }
                    return true;
                });
            }

            setCasinoData({
                ...casino,
                [event.target.name]: event.target.value
            });
        }
        console.log('casino', casino);
    }



    const handleBookmakerSubmit = (event) => {
        event.preventDefault();
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        const postobje = {
            "user_id": id,
            "event_type": 2000,
            "event_name": "bookmaker",
            "min_stake": bookmaker.min_stack_bookmaker,
            "max_stake": bookmaker.max_stack_bookmaker,
            "max_profit": bookmaker.max_profit_bookmaker,
            "max_loss": bookmaker.max_loss_bookmaker,
            "bet_delay": bookmaker.bet_delay_bookmaker,
            "pre_inplay_profit": bookmaker.pre_inplay_profit_bookmaker,
            "pre_inplay_stake": bookmaker.pre_inplay_stack_bookmaker,
            "min_odds": bookmaker.min_odds_bookmaker,
            "max_odds": bookmaker.max_odds_bookmaker,
            "unmatch_bet": bookmaker.unmatch_bet_bookmaker ? 'Yes' : 'No',
            "lock_bet": bookmaker.lock_bet_bookmaker ? 'Yes' : 'No',
            "is_unmatch_allowed": bookmaker.unmatch_bet_bookmaker ? 'Yes' : 'No',
            "is_odds_allowed": bookmaker.lock_bet_bookmaker ? 'Yes' : 'No',
            "update_all_users": bookmaker.update_all_users_bookmaker ? 'Yes' : 'No',
            "is_odds_active": "Yes",
            "is_fancy_active": "Yes",
            "is_bookmaker_active": bookmaker.match_odds_bookmaker ? 'No' : 'Yes',

        }
        save(postobje);
        console.log('arr', postobje);
    }
    const handleCricketSubmit = (event) => {
        event.preventDefault();
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        const postobje = {
            "user_id": id,
            "event_type": 4,
            "event_name": "cricket",
            "min_stake": cricket.min_stack_cricket,
            "max_stake": cricket.max_stack_cricket,
            "max_profit": cricket.max_profit_cricket,
            "max_loss": cricket.max_loss_cricket,
            "bet_delay": cricket.bet_delay_cricket,
            "pre_inplay_profit": cricket.pre_inplay_profit_cricket,
            "pre_inplay_stake": cricket.pre_inplay_stack_cricket,
            "min_odds": cricket.min_odds_cricket,
            "max_odds": cricket.max_odds_cricket,
            "unmatch_bet": cricket.unmatch_bet_cricket ? 'Yes' : 'No',
            "lock_bet": cricket.lock_bet_cricket ? 'Yes' : 'No',
            "is_unmatch_allowed": cricket.unmatch_bet_cricket ? 'Yes' : 'No',
            "is_odds_allowed": cricket.lock_bet_cricket ? 'Yes' : 'No',
            "update_all_users": cricket.update_all_users_cricket ? 'Yes' : 'No',
            "is_odds_active": cricket.match_odds_cricket ? 'No' : 'Yes',
            "is_fancy_active": "Yes",
            "is_bookmaker_active": "Yes",

        }
        save(postobje);
        console.log('arr', postobje);
    }

    const handleTennisSubmit = (event) => {
        event.preventDefault();
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        const postobje = {
            "user_id": id,
            "event_type": 2,
            "event_name": "tennis",
            "min_stake": tennis.min_stack_tennis,
            "max_stake": tennis.max_stack_tennis,
            "max_profit": tennis.max_profit_tennis,
            "max_loss": tennis.max_loss_tennis,
            "bet_delay": tennis.bet_delay_tennis,
            "pre_inplay_profit": tennis.pre_inplay_profit_tennis,
            "pre_inplay_stake": tennis.pre_inplay_stack_tennis,
            "min_odds": tennis.min_odds_tennis,
            "max_odds": tennis.max_odds_tennis,
            "unmatch_bet": tennis.unmatch_bet_tennis ? 'Yes' : 'No',
            "lock_bet": tennis.lock_bet_tennis ? 'Yes' : 'No',
            "is_unmatch_allowed": tennis.unmatch_bet_tennis ? 'Yes' : 'No',
            "is_odds_allowed": tennis.lock_bet_tennis ? 'Yes' : 'No',
            "update_all_users": tennis.update_all_users_tennis ? 'Yes' : 'No',
            "is_odds_active": tennis.match_odds_tennis ? 'No' : 'Yes',
            "is_fancy_active": "Yes",
            "is_bookmaker_active": "Yes",
        }
        save(postobje);
    }

    const handleSoccerSubmit = (event) => {
        event.preventDefault();
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        const postobje = {
            "user_id": id,
            "event_type": 1,
            "event_name": "soccer",
            "min_stake": soccer.min_stack_soccer,
            "max_stake": soccer.max_stack_soccer,
            "max_profit": soccer.max_profit_soccer,
            "max_loss": soccer.max_loss_soccer,
            "bet_delay": soccer.bet_delay_soccer,
            "pre_inplay_profit": soccer.pre_inplay_profit_soccer,
            "pre_inplay_stake": soccer.pre_inplay_stack_soccer,
            "min_odds": soccer.min_odds_soccer,
            "max_odds": soccer.max_odds_soccer,
            "unmatch_bet": soccer.unmatch_bet_soccer ? 'Yes' : 'No',
            "lock_bet": soccer.lock_bet_soccer ? 'Yes' : 'No',
            "is_unmatch_allowed": soccer.unmatch_bet_soccer ? 'Yes' : 'No',
            "is_odds_allowed": soccer.lock_bet_soccer ? 'Yes' : 'No',
            "update_all_users": soccer.update_all_users_soccer ? 'Yes' : 'No',
            "is_odds_active": soccer.match_odds_soccer ? 'No' : 'Yes',
            "is_fancy_active": "Yes",
            "is_bookmaker_active": "Yes",
        }
        save(postobje);
    }

    const handleFancySubmit = (event) => {
        event.preventDefault();
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        const postobje = {
            "user_id": id,
            "event_type": 999,
            "event_name": "fancy",
            "min_stake": fancy.min_stack_fancy,
            "max_stake": fancy.max_stack_fancy,
            "max_profit": fancy.max_profit_fancy,
            "max_loss": fancy.max_loss_fancy,
            "bet_delay": fancy.bet_delay_fancy,
            "pre_inplay_profit": fancy.pre_inplay_profit_fancy,
            "pre_inplay_stake": fancy.pre_inplay_stack_fancy,
            "min_odds": fancy.min_odds_fancy,
            "max_odds": fancy.max_odds_fancy,
            "unmatch_bet": fancy.unmatch_bet_fancy ? 'Yes' : 'No',
            "lock_bet": fancy.lock_bet_fancy ? 'Yes' : 'No',
            "is_unmatch_allowed": fancy.unmatch_bet_fancy ? 'Yes' : 'No',
            "is_odds_allowed": fancy.lock_bet_fancy ? 'Yes' : 'No',
            "update_all_users": fancy.update_all_users_fancy ? 'Yes' : 'No',
            "is_odds_active": "Yes",
            "is_fancy_active": fancy.match_odds_fancy ? 'No' : 'Yes',
            "is_bookmaker_active": "Yes",
        }
        save(postobje);
    }

    const handleCasinoSubmit = (event) => {
        event.preventDefault();
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        const postobje = {
            "user_id": id,
            "event_type": 1000,
            "event_name": "casino",
            "min_stake": casino.min_stack_casino,
            "max_stake": casino.max_stack_casino,
            "max_profit": casino.max_profit_casino,
            "max_loss": casino.max_loss_casino,
            "bet_delay": casino.bet_delay_casino,
            "pre_inplay_profit": casino.pre_inplay_profit_casino,
            "pre_inplay_stake": casino.pre_inplay_stack_casino,
            "min_odds": casino.min_odds_casino,
            "max_odds": casino.max_odds_casino,
            "unmatch_bet": casino.unmatch_bet_casino ? 'Yes' : 'No',
            "lock_bet": casino.lock_bet_casino ? 'Yes' : 'No',
            "is_unmatch_allowed": casino.unmatch_bet_casino ? 'Yes' : 'No',
            "is_odds_allowed": casino.lock_bet_casino ? 'Yes' : 'No',
            "update_all_users": casino.update_all_users_casino ? 'Yes' : 'No',
            "is_odds_active": casino.match_odds_casino ? 'No' : 'Yes',
            "is_fancy_active": "Yes",
            "is_bookmaker_active": "Yes",
        }
        save(postobje);
    }
    function save(formData) {

        console.log('save', formData);


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}generalSetting/addSetting`,
            headers: {
                'Content-Type': 'application/json',

            },
            data: JSON.stringify(formData)
        };

        axios(config)
            .then(function (response) {

                // storeUserinfo(response.data.resultData);
                if (!response.data.result) {
                    notify(response.data.resultMessage, 'error')
                    getUserGenSettings();
                    // storeUserinfo(response.data.resultData);

                }
                else if (response.data.result) {

                    notify('success', 'Setting Updated')

                }
                else {
                    notify('faliure', 'error')

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function notify(message, type) {
        document.querySelector("#notifyMsg").textContent = message;
        if (type === 'error') {
            document.querySelector("#message").style.background = "red";
        }
        else {

            document.querySelector("#message").style.background = "green";
        }
        document.getElementById("message").style.display = "block";
        setTimeout(function () {
            closeMessage();
        }, 3000);
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    const getSuperGenSettings = (id) => {

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}generalSetting/getSetting`,
            headers: {
                'Content-Type': 'application/json',

            },
            data: JSON.stringify({ "user_id": id })
        };

        axios(config)
            .then(function (response) {

                response.data.map((row, index) => {
                    if (row.event_name === 'cricket') {
                        setSCricketData({
                            min_stack_cricket: row.min_stake,
                            max_stack_cricket: row.max_stake,
                            max_profit_cricket: row.max_profit,
                            max_loss_cricket: row.max_loss,
                            bet_delay_cricket: row.bet_delay,
                            pre_inplay_profit_cricket: row.pre_inplay_profit,
                            pre_inplay_stack_cricket: row.pre_inplay_stake,
                            min_odds_cricket: row.min_odds,
                            max_odds_cricket: row.max_odds,
                            unmatch_bet_cricket: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_cricket: row.lock_bet == "Yes" ? true : false
                        });
                    }
                    if (row.event_name === 'soccer') {
                        setSSoccerData({
                            min_stack_soccer: row.min_stake,
                            max_stack_soccer: row.max_stake,
                            max_profit_soccer: row.max_profit,
                            max_loss_soccer: row.max_loss,
                            bet_delay_soccer: row.bet_delay,
                            pre_inplay_profit_soccer: row.pre_inplay_profit,
                            pre_inplay_stack_soccer: row.pre_inplay_stake,
                            min_odds_soccer: row.min_odds,
                            max_odds_soccer: row.max_odds,
                            unmatch_bet_soccer: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_soccer: row.lock_bet == "Yes" ? true : false
                        });
                    }
                    if (row.event_name === 'tennis') {
                        setSTennisData({
                            min_stack_tennis: row.min_stake,
                            max_stack_tennis: row.max_stake,
                            max_profit_tennis: row.max_profit,
                            max_loss_tennis: row.max_loss,
                            bet_delay_tennis: row.bet_delay,
                            pre_inplay_profit_tennis: row.pre_inplay_profit,
                            pre_inplay_stack_tennis: row.pre_inplay_stake,
                            min_odds_tennis: row.min_odds,
                            max_odds_tennis: row.max_odds,
                            unmatch_bet_tennis: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_tennis: row.lock_bet == "Yes" ? true : false
                        });
                    }
                    if (row.event_name === 'casino') {
                        setSCasinoData({
                            min_stack_casino: row.min_stake,
                            max_stack_casino: row.max_stake,
                            max_profit_casino: row.max_profit,
                            max_loss_casino: row.max_loss,
                            bet_delay_casino: row.bet_delay,
                            pre_inplay_profit_casino: row.pre_inplay_profit,
                            pre_inplay_stack_casino: row.pre_inplay_stake,
                            min_odds_casino: row.min_odds,
                            max_odds_casino: row.max_odds,
                            unmatch_bet_casino: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_casino: row.lock_bet == "Yes" ? true : false
                        });
                    }


                    if (row.event_name === 'fancy') {
                        setSFancyData({
                            min_stack_fancy: row.min_stake,
                            max_stack_fancy: row.max_stake,
                            max_profit_fancy: row.max_profit,
                            bet_delay_fancy: row.bet_delay,
                            lock_bet_fancy: row.lock_bet == "Yes" ? true : false
                        });
                    }
                })

                // storeUserinfo(response.data.resultData);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getUserGenSettings = () => {
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}generalSetting/getSetting`,
            headers: {
                'Content-Type': 'application/json',

            },
            data: JSON.stringify({ "user_id": id })
        };

        axios(config)
            .then(function (response) {

                response.data.map((row, index) => {
                    if (row.event_name === 'cricket') {
                        setCricketData({
                            min_stack_cricket: row.min_stake,
                            max_stack_cricket: row.max_stake,
                            max_profit_cricket: row.max_profit,
                            max_loss_cricket: row.max_loss,
                            bet_delay_cricket: row.bet_delay,
                            pre_inplay_profit_cricket: row.pre_inplay_profit,
                            pre_inplay_stack_cricket: row.pre_inplay_stake,
                            min_odds_cricket: row.min_odds,
                            max_odds_cricket: row.max_odds,
                            unmatch_bet_cricket: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_cricket: row.lock_bet == "Yes" ? true : false,
                            match_odds_cricket: row.is_odds_active != "Yes" ? true : false

                        });
                    }
                    if (row.event_name === 'soccer') {
                        setSoccerData({
                            min_stack_soccer: row.min_stake,
                            max_stack_soccer: row.max_stake,
                            max_profit_soccer: row.max_profit,
                            max_loss_soccer: row.max_loss,
                            bet_delay_soccer: row.bet_delay,
                            pre_inplay_profit_soccer: row.pre_inplay_profit,
                            pre_inplay_stack_soccer: row.pre_inplay_stake,
                            min_odds_soccer: row.min_odds,
                            max_odds_soccer: row.max_odds,
                            unmatch_bet_soccer: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_soccer: row.lock_bet == "Yes" ? true : false,
                            match_odds_soccer: row.is_odds_active != "Yes" ? true : false
                        });
                    }
                    if (row.event_name === 'tennis') {
                        setTennisData({
                            min_stack_tennis: row.min_stake,
                            max_stack_tennis: row.max_stake,
                            max_profit_tennis: row.max_profit,
                            max_loss_tennis: row.max_loss,
                            bet_delay_tennis: row.bet_delay,
                            pre_inplay_profit_tennis: row.pre_inplay_profit,
                            pre_inplay_stack_tennis: row.pre_inplay_stake,
                            min_odds_tennis: row.min_odds,
                            max_odds_tennis: row.max_odds,
                            unmatch_bet_tennis: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_tennis: row.lock_bet == "Yes" ? true : false,
                            match_odds_tennis: row.is_odds_active != "Yes" ? true : false
                        });
                    }
                    if (row.event_name === 'casino') {
                        setCasinoData({
                            min_stack_casino: row.min_stake,
                            max_stack_casino: row.max_stake,
                            max_profit_casino: row.max_profit,
                            max_loss_casino: row.max_loss,
                            bet_delay_casino: row.bet_delay,
                            pre_inplay_profit_casino: row.pre_inplay_profit,
                            pre_inplay_stack_casino: row.pre_inplay_stake,
                            min_odds_casino: row.min_odds,
                            max_odds_casino: row.max_odds,
                            unmatch_bet_casino: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_casino: row.lock_bet == "Yes" ? true : false,
                            match_odds_casino: row.is_odds_active != "Yes" ? true : false
                        });
                    }


                    if (row.event_name === 'fancy') {
                        setFancyData({
                            min_stack_fancy: row.min_stake,
                            max_stack_fancy: row.max_stake,
                            max_profit_fancy: row.max_profit,
                            bet_delay_fancy: row.bet_delay,
                            lock_bet_fancy: row.lock_bet == "Yes" ? true : false,
                            match_odds_fancy: row.is_fancy_active != "Yes" ? true : false
                        });
                    }

                    if (row.event_name === 'bookmaker') {
                        setBookmakerData({
                            min_stack_bookmaker: row.min_stake,
                            max_stack_bookmaker: row.max_stake,
                            max_profit_bookmaker: row.max_profit,
                            max_loss_bookmaker: row.max_loss,
                            bet_delay_bookmaker: row.bet_delay,
                            pre_inplay_profit_bookmaker: row.pre_inplay_profit,
                            pre_inplay_stack_bookmaker: row.pre_inplay_stake,
                            min_odds_bookmaker: row.min_odds,
                            max_odds_bookmaker: row.max_odds,
                            unmatch_bet_bookmaker: row.is_unmatch_allowed == "Yes" ? true : false,
                            lock_bet_bookmaker: row.lock_bet == "Yes" ? true : false,
                            match_odds_bookmaker: row.is_bookmaker_active != "Yes" ? true : false
                        });
                    }

                })

                // storeUserinfo(response.data.resultData);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    return (
        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}

            <div id="message" className="message-wrap-new" style={displaynone}>
                <Link className="btn-close" onClick={closeMessage} to="#">Close</Link>
                <p id="notifyMsg"></p>
            </div>
            <div className="main_wrap">
                <div className="account-summary" style={{ padding: 10 }}>
                    <div style={{ display: "flex" }}>
                        <div> <h2> Set General Setting</h2></div>


                    </div>

                    <Grid className="bodypart custom-container grid-row">
                        <Grid item lg={12} xs={12} className="report-section">
                            {superValidation ?
                                <Card className={classes.root} variant="outlined" >



                                    {/* <div className={classes.headerdiv}>
                            <Typography variant="h6" component="h2">Set General Setting</Typography>
                        </div> */}

                                    {/* <div className={classes.divbrown}>
                            <Typography className="subHeading">User Commission</Typography>
                        </div>

                        <CardContent>
                            <form noValidate autoComplete="off">
                                <div>
                                    <Grid container >

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"

                                                id="outlined-disabled"
                                                label="Odds Commission"
                                                variant="outlined"
                                                name="odds_commission"

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"

                                                id="outlined-disabled"
                                                label="Session Commission"
                                                variant="outlined"
                                                name="session_commission"

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"

                                                id="outlined-disabled"
                                                label="Other Commission"
                                                variant="outlined"
                                                name="other_commission"

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <button className="btn-send" size="small" variant="contained" color="primary">
                                                Update
                                            </button>
                                        </Grid>

                                    </Grid>
                                </div>
                            </form>
                        </CardContent> */}
                                    {/* <CardContent>
                    <form noValidate autoComplete="off">
                        <div>
                            <Grid container >
                                <Grid item xs={6} sm={3}>
                                   <TextField size="small"

                                        id="standard-read-only-input"
                                        label="User ID"
                                        variant="outlined"
                                        defaultValue="User ID"
                                        name="user_id"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                   <TextField size="small"
                                        id="standard-read-only-input"
                                        label="User Name"
                                        variant="outlined"
                                        name="user_name"
                                        defaultValue="User Name"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox

                                                onChange={() => alert('checked')}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Update From General Setting"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container justifyContent="flex-end">

                            <button className="btn-send" size="small" variant="contained" color="primary">
                                Update
                            </button>

                        </Grid>
                    </form>

                </CardContent> */}

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Cricket</Typography>
                                    </div>

                                    <CardContent>
                                        <ValidatorForm
                                            //
                                            onSubmit={handleCricketSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_cricket"
                                                            value={cricket.min_stack_cricket}
                                                            validators={[`maxNumber:${Scricket.min_stack_cricket}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.min_stack_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_cricket"
                                                            value={cricket.max_stack_cricket}
                                                            validators={[`maxNumber:${Scricket.max_stack_cricket}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.max_stack_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_cricket"
                                                            value={cricket.max_profit_cricket}
                                                            validators={[`maxNumber:${Scricket.max_profit_cricket}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.max_profit_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_cricket"
                                                            validators={[`maxNumber:${Scricket.max_loss_cricket}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.max_loss_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.max_loss_cricket}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_cricket"
                                                            validators={[`maxNumber:${Scricket.bet_delay_cricket}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.bet_delay_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.bet_delay_cricket}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_cricket"
                                                            validators={[`maxNumber:${Scricket.pre_inplay_profit_cricket}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Scricket.pre_inplay_profit_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.pre_inplay_profit_cricket}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_cricket"
                                                            validators={[`maxNumber:${Scricket.pre_inplay_stack_cricket}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.pre_inplay_stack_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.pre_inplay_stack_cricket}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_cricket"
                                                            validators={[`min_odds_cricket_limit`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.min_odds_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.min_odds_cricket}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_cricket"
                                                            validators={['max_odds_cricket_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Scricket.max_odds_cricket}`, 'this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.max_odds_cricket}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}

                                                                    name="unmatch_bet_cricket"
                                                                    color="primary"
                                                                    checked={cricket.unmatch_bet_cricket}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}
                                                                    name="lock_bet_cricket"
                                                                    color="primary"
                                                                    value={(event) => event.target.checked}
                                                                    checked={cricket.lock_bet_cricket}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}
                                                                    name="match_odds_cricket"
                                                                    color="primary"
                                                                    checked={cricket.match_odds_cricket}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}

                                                                    name="update_all_users_cricket"
                                                                    color="primary"
                                                                    checked={cricket.update_all_users_cricket}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button>
                                                    </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Tennis</Typography>
                                    </div>
                                    <CardContent>
                                        <ValidatorForm

                                            onSubmit={handleTennisSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_tennis"
                                                            value={tennis.min_stack_tennis}
                                                            validators={[`maxNumber:${Stennis.min_stack_tennis}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Stennis.min_stack_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_tennis"
                                                            value={tennis.max_stack_tennis}
                                                            validators={[`maxNumber:${Stennis.max_stack_tennis}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Stennis.max_stack_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_tennis"
                                                            value={tennis.max_profit_tennis}
                                                            validators={[`maxNumber:${Stennis.max_profit_tennis}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Stennis.max_profit_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_tennis"
                                                            validators={[`maxNumber:${Stennis.max_loss_tennis}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Stennis.max_loss_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.max_loss_tennis}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_tennis"
                                                            onChange={handleTennisInputChange}
                                                            validators={[`maxNumber:${Stennis.bet_delay_tennis}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Stennis.bet_delay_tennis}`, 'this field is required']}
                                                            value={tennis.bet_delay_tennis}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_tennis"
                                                            validators={[`maxNumber:${Stennis.pre_inplay_profit_tennis}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Stennis.pre_inplay_profit_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.pre_inplay_profit_tennis}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_tennis"
                                                            validators={[`maxNumber:${Stennis.pre_inplay_stack_tennis}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Stennis.pre_inplay_stack_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.pre_inplay_stack_tennis}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_tennis"
                                                            validators={['min_odds_tennis_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Stennis.min_odds_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.min_odds_tennis}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_tennis"
                                                            validators={['max_odds_tennis_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Stennis.max_odds_tennis}`, 'this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.max_odds_tennis}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}

                                                                    name="unmatch_bet_tennis"
                                                                    color="primary"
                                                                    checked={tennis.unmatch_bet_tennis}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="lock_bet_tennis"
                                                                    color="primary"
                                                                    checked={tennis.lock_bet_tennis}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="match_odds_tennis"
                                                                    color="primary"
                                                                    checked={tennis.match_odds_tennis}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="update_all_users_tennis"
                                                                    color="primary"
                                                                    checked={tennis.update_all_users_tennis}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button>
                                                    </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Soccer</Typography>
                                    </div>
                                    {/* <div className={classes.divbrown}>
                            <Typography variant="h6" component="h2">Soccer</Typography>
                        </div> */}
                                    <CardContent>
                                        <ValidatorForm

                                            onSubmit={handleSoccerSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_soccer"
                                                            value={soccer.min_stack_soccer}
                                                            validators={[`maxNumber:${Ssoccer.min_stack_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.min_stack_soccer}`, 'this field is required']}
                                                            onChange={handleSoccerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_soccer"
                                                            value={soccer.max_stack_soccer}
                                                            validators={[`maxNumber:${Ssoccer.min_stack_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.min_stack_soccer}`, 'this field is required']}
                                                            onChange={handleSoccerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_soccer"
                                                            value={soccer.max_profit_soccer}
                                                            validators={[`maxNumber:${Ssoccer.max_profit_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.max_profit_soccer}`, 'this field is required']}
                                                            onChange={handleSoccerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.max_loss_soccer}
                                                            validators={[`maxNumber:${Ssoccer.max_loss_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.max_loss_soccer}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.bet_delay_soccer}
                                                            validators={[`maxNumber:${Ssoccer.bet_delay_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.bet_delay_soccer}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.pre_inplay_profit_soccer}
                                                            validators={[`maxNumber:${Ssoccer.pre_inplay_profit_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.pre_inplay_profit_soccer}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.pre_inplay_stack_soccer}
                                                            validators={[`maxNumber:${Ssoccer.pre_inplay_stack_soccer}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.pre_inplay_stack_soccer}`, 'this field is required']}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.min_odds_soccer}
                                                            validators={['min_odds_soccer_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.min_odds_soccer}`, 'this field is required']}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.max_odds_soccer}
                                                            validators={['max_odds_soccer_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Ssoccer.max_odds_soccer}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}

                                                                    name="unmatch_bet_soccer"
                                                                    color="primary"
                                                                    checked={soccer.unmatch_bet_soccer}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}

                                                                    name="lock_bet_soccer"
                                                                    color="primary"
                                                                    checked={soccer.lock_bet_soccer}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="match_odds_soccer"
                                                                    color="primary"
                                                                    checked={tennis.match_odds_soccer}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}
                                                                    name="update_all_users_soccer"
                                                                    color="primary"
                                                                    checked={soccer.update_all_users_soccer}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>  <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                        Update
                                                    </button>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                        </ValidatorForm>

                                    </CardContent>


                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Fancy</Typography>
                                    </div>
                                    {/* <div className={classes.divbrown}>
                            <Typography variant="h6" component="h2">Fancy</Typography>
                        </div> */}
                                    <CardContent>
                                        <ValidatorForm

                                            onSubmit={handleFancySubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_fancy"
                                                            value={fancy.min_stack_fancy}
                                                            validators={[`maxNumber:${Sfancy.min_stack_fancy}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Sfancy.min_stack_fancy}`, 'this field is required']}
                                                            onChange={handleFancyInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_fancy"
                                                            value={fancy.max_stack_fancy}
                                                            validators={[`maxNumber:${Sfancy.min_stack_fancy}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Sfancy.min_stack_fancy}`, 'this field is required']}
                                                            onChange={handleFancyInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_fancy"
                                                            onChange={handleFancyInputChange}
                                                            value={fancy.bet_delay_fancy}
                                                            validators={[`maxNumber:${Sfancy.bet_delay_fancy}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Sfancy.bet_delay_fancy}`, 'this field is required']}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_fancy"
                                                            value={fancy.max_profit_fancy}
                                                            validators={[`maxNumber:${Sfancy.max_profit_fancy}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Sfancy.max_profit_fancy}`, 'this field is required']}
                                                            onChange={handleFancyInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="lock_bet_fancy"
                                                                    color="primary"
                                                                    checked={fancy.lock_bet_fancy}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="match_odds_fancy"
                                                                    color="primary"
                                                                    checked={fancy.match_odds_fancy}
                                                                />
                                                            }
                                                            label="Fancy"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="update_all_users_fancy"
                                                                    color="primary"
                                                                    checked={fancy.update_all_users_fancy}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>

                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button>
                                                    </Grid>
                                                </Grid>

                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Casino</Typography>
                                    </div>

                                    <CardContent style={{ marginBottom: '100px' }}>
                                        <ValidatorForm

                                            onSubmit={handleCasinoSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_casino"
                                                            value={casino.min_stack_casino}
                                                            validators={[`maxNumber:${Scasino.min_stack_casino}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scasino.min_stack_casino}`, 'this field is required']}
                                                            onChange={handleCasinoInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_casino"
                                                            value={casino.max_stack_casino}
                                                            validators={[`maxNumber:${Scasino.max_stack_casino}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scasino.max_stack_casino}`, 'this field is required']}
                                                            onChange={handleCasinoInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_casino"
                                                            value={casino.max_profit_casino}
                                                            validators={[`maxNumber:${Scasino.max_profit_casino}`, 'required']}
                                                            errorMessages={[`can't go over superior ${Scasino.max_profit_casino}`, 'this field is required']}
                                                            onChange={handleCasinoInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.max_loss_casino}
                                                            validators={[`maxNumber:${Scasino.max_loss_casino}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Scasino.max_loss_casino}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.bet_delay_casino}
                                                            validators={[`maxNumber:${Scasino.bet_delay_casino}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Scasino.bet_delay_casino}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.pre_inplay_profit_casino}
                                                            validators={[`maxNumber:${Scasino.pre_inplay_profit_casino}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Scasino.pre_inplay_profit_casino}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.pre_inplay_stack_casino}
                                                            validators={[`maxNumber:${Scasino.pre_inplay_stack_casino}`, 'required']}

                                                            errorMessages={[`can't go over superior ${Scasino.pre_inplay_stack_casino}`, 'this field is required']}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.min_odds_casino}
                                                            validators={['min_odds_casino_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Scasino.min_odds_casino}`, 'this field is required']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.max_odds_casino}
                                                            validators={['max_odds_casino_limit', 'required']}
                                                            errorMessages={[`can't go over superior ${Scasino.max_odds_casino}`, 'this field is required']}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="unmatch_bet_casino"
                                                                    color="primary"
                                                                    checked={casino.unmatch_bet_casino}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="lock_bet_casino"
                                                                    color="primary"
                                                                    checked={casino.lock_bet_casino}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="match_odds_casino"
                                                                    color="primary"
                                                                    checked={casino.match_odds_casino}
                                                                />
                                                            }
                                                            label="Fancy"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="update_all_users_casino"
                                                                    color="primary"
                                                                    checked={casino.update_all_users_casino}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button> </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                </Card>

                                : <Card className={classes.root} variant="outlined" >



                                    {/* <div className={classes.headerdiv}>
                                 <Typography variant="h6" component="h2">Set General Setting</Typography>
                             </div> */}

                                    {/* <div className={classes.divbrown}>
                                 <Typography className="subHeading">User Commission</Typography>
                             </div>
     
                             <CardContent>
                                 <form noValidate autoComplete="off">
                                     <div>
                                         <Grid container >
     
                                             <Grid item xs={6} sm={3}>
                                                 <TextField size="small"
     
                                                     id="outlined-disabled"
                                                     label="Odds Commission"
                                                     variant="outlined"
                                                     name="odds_commission"
     
                                                 />
                                             </Grid>
                                             <Grid item xs={6} sm={3}>
                                                 <TextField size="small"
     
                                                     id="outlined-disabled"
                                                     label="Session Commission"
                                                     variant="outlined"
                                                     name="session_commission"
     
                                                 />
                                             </Grid>
                                             <Grid item xs={6} sm={3}>
                                                 <TextField size="small"
     
                                                     id="outlined-disabled"
                                                     label="Other Commission"
                                                     variant="outlined"
                                                     name="other_commission"
     
                                                 />
                                             </Grid>
                                             <Grid item xs={6} sm={3}>
                                                 <button className="btn-send" size="small" variant="contained" color="primary">
                                                     Update
                                                 </button>
                                             </Grid>
     
                                         </Grid>
                                     </div>
                                 </form>
                             </CardContent> */}
                                    {/* <CardContent>
                         <form noValidate autoComplete="off">
                             <div>
                                 <Grid container >
                                     <Grid item xs={6} sm={3}>
                                        <TextField size="small"
     
                                             id="standard-read-only-input"
                                             label="User ID"
                                             variant="outlined"
                                             defaultValue="User ID"
                                             name="user_id"
                                             InputProps={{
                                                 readOnly: true,
                                             }}
                                         />
                                     </Grid>
                                     <Grid item xs={6} sm={3}>
                                        <TextField size="small"
                                             id="standard-read-only-input"
                                             label="User Name"
                                             variant="outlined"
                                             name="user_name"
                                             defaultValue="User Name"
                                             InputProps={{
                                                 readOnly: true,
                                             }}
                                         />
                                     </Grid>
                                     <Grid item xs={6} sm={3}>
                                         <FormControlLabel
                                             control={
                                                 <Checkbox
     
                                                     onChange={() => alert('checked')}
                                                     name="checkedB"
                                                     color="primary"
                                                 />
                                             }
                                             label="Update From General Setting"
                                         />
                                     </Grid>
                                 </Grid>
                             </div>
                             <Grid container justifyContent="flex-end">
     
                                 <button className="btn-send" size="small" variant="contained" color="primary">
                                     Update
                                 </button>
     
                             </Grid>
                         </form>
     
                     </CardContent> */}

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Cricket</Typography>
                                    </div>

                                    <CardContent>
                                        <ValidatorForm
                                            //
                                            onSubmit={handleCricketSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_cricket"
                                                            value={cricket.min_stack_cricket}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_cricket"
                                                            value={cricket.max_stack_cricket}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_cricket"
                                                            value={cricket.max_profit_cricket}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_cricket"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.max_loss_cricket}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_cricket"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.bet_delay_cricket}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_cricket"
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.pre_inplay_profit_cricket}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_cricket"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.pre_inplay_stack_cricket}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDSs"
                                                            variant="outlined"
                                                            name="min_odds_cricket"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.min_odds_cricket}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_cricket"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCricketInputChange}
                                                            value={cricket.max_odds_cricket}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}

                                                                    name="unmatch_bet_cricket"
                                                                    color="primary"
                                                                    checked={cricket.unmatch_bet_cricket}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}
                                                                    name="lock_bet_cricket"
                                                                    color="primary"
                                                                    value={(event) => event.target.checked}
                                                                    checked={cricket.lock_bet_cricket}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}
                                                                    name="match_odds_cricket"
                                                                    color="primary"
                                                                    checked={cricket.match_odds_cricket}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCricketInputChange}

                                                                    name="update_all_users_cricket"
                                                                    color="primary"
                                                                    checked={cricket.update_all_users_cricket}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button>
                                                    </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Tennis</Typography>
                                    </div>
                                    <CardContent>
                                        <ValidatorForm

                                            onSubmit={handleTennisSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_tennis"
                                                            value={tennis.min_stack_tennis}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_tennis"
                                                            value={tennis.max_stack_tennis}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_tennis"
                                                            value={tennis.max_profit_tennis}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_tennis"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.max_loss_tennis}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_tennis"
                                                            onChange={handleTennisInputChange}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            value={tennis.bet_delay_tennis}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_tennis"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.pre_inplay_profit_tennis}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_tennis"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.pre_inplay_stack_tennis}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_tennis"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.min_odds_tennis}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_tennis"
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleTennisInputChange}
                                                            value={tennis.max_odds_tennis}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}

                                                                    name="unmatch_bet_tennis"
                                                                    color="primary"
                                                                    checked={tennis.unmatch_bet_tennis}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="lock_bet_tennis"
                                                                    color="primary"
                                                                    checked={tennis.lock_bet_tennis}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="match_odds_tennis"
                                                                    color="primary"
                                                                    checked={tennis.match_odds_tennis}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleTennisInputChange}
                                                                    name="update_all_users_tennis"
                                                                    color="primary"
                                                                    checked={tennis.update_all_users_tennis}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button>
                                                    </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Soccer</Typography>
                                    </div>
                                    {/* <div className={classes.divbrown}>
                                 <Typography variant="h6" component="h2">Soccer</Typography>
                             </div> */}
                                    <CardContent>
                                        <ValidatorForm

                                            onSubmit={handleSoccerSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_soccer"
                                                            value={soccer.min_stack_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleSoccerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_soccer"
                                                            value={soccer.max_stack_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleSoccerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_soccer"
                                                            value={soccer.max_profit_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleSoccerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.max_loss_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.bet_delay_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.pre_inplay_profit_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.pre_inplay_stack_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.min_odds_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_soccer"
                                                            onChange={handleSoccerInputChange}
                                                            value={soccer.max_odds_soccer}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}

                                                                    name="unmatch_bet_soccer"
                                                                    color="primary"
                                                                    checked={soccer.unmatch_bet_soccer}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}

                                                                    name="lock_bet_soccer"
                                                                    color="primary"
                                                                    checked={soccer.lock_bet_soccer}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}
                                                                    name="match_odds_soccer"
                                                                    color="primary"
                                                                    checked={soccer.match_odds_soccer}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleSoccerInputChange}
                                                                    name="update_all_users_soccer"
                                                                    color="primary"
                                                                    checked={soccer.update_all_users_soccer}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>  <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                        Update
                                                    </button>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                        </ValidatorForm>

                                    </CardContent>


                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Fancy</Typography>
                                    </div>
                                    {/* <div className={classes.divbrown}>
                                 <Typography variant="h6" component="h2">Fancy</Typography>
                             </div> */}
                                    <CardContent>
                                        <ValidatorForm

                                            onSubmit={handleFancySubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_fancy"
                                                            value={fancy.min_stack_fancy}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleFancyInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_fancy"
                                                            value={fancy.max_stack_fancy}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleFancyInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_fancy"
                                                            onChange={handleFancyInputChange}
                                                            value={fancy.bet_delay_fancy}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_fancy"
                                                            value={fancy.max_profit_fancy}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleFancyInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="lock_bet_fancy"
                                                                    color="primary"
                                                                    checked={fancy.lock_bet_fancy}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="match_odds_fancy"
                                                                    color="primary"
                                                                    checked={fancy.match_odds_fancy}
                                                                />
                                                            }
                                                            label="Fancy"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleFancyInputChange}
                                                                    name="update_all_users_fancy"
                                                                    color="primary"
                                                                    checked={fancy.update_all_users_fancy}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>

                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button>
                                                    </Grid>
                                                </Grid>

                                            </div>

                                        </ValidatorForm>

                                    </CardContent>

                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Casino</Typography>
                                    </div>

                                    <CardContent style={{ marginBottom: '100px' }}>
                                        <ValidatorForm

                                            onSubmit={handleCasinoSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_casino"
                                                            value={casino.min_stack_casino}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCasinoInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_casino"
                                                            value={casino.max_stack_casino}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCasinoInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_casino"
                                                            value={casino.max_profit_casino}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleCasinoInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.max_loss_casino}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.bet_delay_casino}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.pre_inplay_profit_casino}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.pre_inplay_stack_casino}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.min_odds_casino}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_casino"
                                                            onChange={handleCasinoInputChange}
                                                            value={casino.max_odds_casino}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="unmatch_bet_casino"
                                                                    color="primary"
                                                                    checked={casino.unmatch_bet_casino}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="lock_bet_casino"
                                                                    color="primary"
                                                                    checked={casino.lock_bet_casino}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="match_odds_casino"
                                                                    color="primary"
                                                                    checked={casino.match_odds_casino}
                                                                />
                                                            }
                                                            label="Match Odds"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleCasinoInputChange}
                                                                    name="update_all_users_casino"
                                                                    color="primary"
                                                                    checked={casino.update_all_users_casino}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button> </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>





                                    <div className={classes.divbrown}>
                                        <Typography className="subHeading">Bookmaker</Typography>
                                    </div>

                                    <CardContent style={{ marginBottom: '100px' }}>
                                        <ValidatorForm

                                            onSubmit={handleBookmakerSubmit}
                                            autoComplete="off"
                                        >

                                            <div className="viewinfo_event_div">
                                                <Grid container >

                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN STAKE"
                                                            variant="outlined"
                                                            name="min_stack_bookmaker"
                                                            value={bookmaker.min_stack_bookmaker}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleBookmakerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX STAKE"
                                                            variant="outlined"
                                                            name="max_stack_bookmaker"
                                                            value={bookmaker.max_stack_bookmaker}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleBookmakerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX PROFIT"
                                                            variant="outlined"
                                                            name="max_profit_bookmaker"
                                                            value={bookmaker.max_profit_bookmaker}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            onChange={handleBookmakerInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="Max Loss"
                                                            variant="outlined"
                                                            name="max_loss_bookmaker"
                                                            onChange={handleBookmakerInputChange}
                                                            value={bookmaker.max_loss_bookmaker}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="BET DELAY"
                                                            variant="outlined"
                                                            name="bet_delay_bookmaker"
                                                            onChange={handleBookmakerInputChange}
                                                            value={bookmaker.bet_delay_bookmaker}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY PROFIT"
                                                            variant="outlined"
                                                            name="pre_inplay_profit_bookmaker"
                                                            onChange={handleBookmakerInputChange}
                                                            value={bookmaker.pre_inplay_profit_bookmaker}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="PRE INPLAY STAKE"
                                                            variant="outlined"
                                                            name="pre_inplay_stack_bookmaker"
                                                            onChange={handleBookmakerInputChange}
                                                            value={bookmaker.pre_inplay_stack_bookmaker}
                                                            validators={['required']}

                                                            errorMessages={['this field is required']}
                                                        /> </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MIN ODDS"
                                                            variant="outlined"
                                                            name="min_odds_bookmaker"
                                                            onChange={handleBookmakerInputChange}
                                                            value={bookmaker.min_odds_bookmaker}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container >


                                                    <Grid item xs={6} sm={3}>
                                                        <TextValidator size="small"
                                                            id="outlined-disabled"
                                                            label="MAX ODDS"
                                                            variant="outlined"
                                                            name="max_odds_bookmaker"
                                                            onChange={handleBookmakerInputChange}
                                                            value={bookmaker.max_odds_bookmaker}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleBookmakerInputChange}
                                                                    name="unmatch_bet_bookmaker"
                                                                    color="primary"
                                                                    checked={bookmaker.unmatch_bet_bookmaker}
                                                                />
                                                            }
                                                            label="UNMATCH BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleBookmakerInputChange}
                                                                    name="lock_bet_bookmaker"
                                                                    color="primary"
                                                                    checked={bookmaker.lock_bet_bookmaker}
                                                                />
                                                            }
                                                            label="LOCK BET"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleBookmakerInputChange}
                                                                    name="match_odds_bookmaker"
                                                                    color="primary"
                                                                    checked={bookmaker.match_odds_bookmaker}
                                                                />
                                                            }
                                                            label="Bookmaker Odds"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox

                                                                    onChange={handleBookmakerInputChange}
                                                                    name="update_all_users_bookmaker"
                                                                    color="primary"
                                                                    checked={bookmaker.update_all_users_bookmaker}
                                                                />
                                                            }
                                                            label="Click to update for all users"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <button className="btn-send" size="small" variant="contained" type="submit" color="primary">
                                                            Update
                                                        </button> </Grid>
                                                </Grid>


                                            </div>

                                        </ValidatorForm>

                                    </CardContent>


                                </Card>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default GenSetting;