// import './App.css';
// import Report from './admin/Report';
// import Dashboard from './admin/Dashboard';
import Dashboard from './Dashboard';
import { SocketContext, socket } from './context/socket';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';

// import EventDetail from './admin/EventDetail';
// import Accountinfo from './admin/Accountinfo';
// import Profitloss from './admin/Profitloss';
// import Bethistory from './admin/Bethistory';
// import LiveBethistory from './admin/LiveBethistory';

// import "./assets/Responsive.css"


import Login from './Login';
import { useState, useEffect } from 'react';

import Banking from './admin/Banking';
import Downlineprofitloss from './admin/DownlineProfitloss';
import Marketprofitloss from './admin/MarketProfitLoss';
import ProfitLossSportWise from './admin/ProfitLossSportWise';
import CasinoDownlineMonthly from './admin/casinoDownlineMonthly';

import Betlist from './admin/BetList';
import BetListDemo from './admin/BetListDemo';
import Betlistlive from './admin/LiveBetList';
import RiskManagement from './admin/RiskManagement';
import MyAccount from './admin/MyAccount';
import SideNav from './admin/SideNav';
import AccountStatement from './admin/AccountStatement';
import Profile from './admin/Profile';
import ActivityLog from './admin/ActivityLog';
import ProfitDownline from './admin/DownlineProfitLossFilter';
import SideNavBetFilter from './admin/BetHistorySideNav';
import BetHistoryUser from './admin/BetHistoryUser';
import ProfitandLossUser from './admin/ProfitandLossUser';
import AdminFund from './admin/AdminFund';
import BlockMarket from './admin/BlockMarket';
import BlockMarketEvents from './admin/BlockMarketEvents';
import BlockMarketEventsMarkets from './admin/BlockMarketEventsMarkets';
import BlockMarketEventsFancys from './admin/BlockMarketEventsFancys';
import News from './admin/News';
import BetDelete from './admin/BetDelete';
import BetDeleteEvents from './admin/BetDeleteEvents';
import BetDeleteEventsBet from './admin/BetDeleteEventsBet';
import GenSetting from './admin/GenSetting';
import FullMarket from './admin/FullMarket';
import CreditRefsTab from './CreditRefsTab';
import AcStatementTab from './AcStatementTab';
import DeleteUser from './DeleteUser';
import LoginMobile from './LoginMobile';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AddBanner from './admin/AddBanner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DownlineMarket from './admin/DownlineMarket';


import FancyBook from './admin/FancyBook';


function App() {
  const [loggedin, setLoggedIn] = useState(false);
  let loggedIn = window.sessionStorage.getItem("loggedIn");
  useEffect(() => {
    loggedIn = window.sessionStorage.getItem("loggedIn");
  }, [loggedin]);
  return (
    <div className="app">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SocketContext.Provider value={socket}>
        <Router>
          <Switch>
            <Route exact path="/oldCredits/:user_name/:user_id/" component={CreditRefsTab} />
            <Route exact path="/acStatementTab/:user_name/:user_id/" component={AcStatementTab} />

            {
              loggedIn === 'true'
                ? (
                  <>
                    <Route exact path="/" component={isMobile ? LoginMobile : Login} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/:user_id" component={Dashboard} />
                    <Route exact path="/dashboard/:user_id/:user_type" component={Dashboard} />
                    <Route exact path="/deleteuser" component={DeleteUser} />
                    <Route exact path="/deleteuser/:user_id" component={DeleteUser} />
                    <Route exact path="/deleteuser/:user_id/:user_type" component={DeleteUser} />
                    <Route exact path="/banking" component={Banking} />
                    <Route exact path="/banking/:user_id" component={Banking} />
                    <Route exact path="/banking/:user_id/:user_type" component={Banking} />
                    <Route exact path="/downlineprofitLoss" component={Downlineprofitloss} />
                    <Route exact path="/downlineprofitLoss/:user_id" component={Downlineprofitloss} />
                    <Route exact path="/downlineprofitLoss/:user_id/:fromdata/:todate" component={Downlineprofitloss} />
                    <Route exact path="/marketprofitloss" component={Marketprofitloss} />
                    <Route exact path="/profitlosssportwise" component={ProfitLossSportWise} />
                    <Route exact path="/casinodownlinemonthly" component={CasinoDownlineMonthly} />
                    <Route exact path="/betlist" component={Betlist} />
                    <Route exact path="/betlistDemo" component={BetListDemo} />
                    <Route exact path="/betlistlive" component={Betlistlive} />
                    <Route exact path="/riskmanagement" component={RiskManagement} />
                    <Route exact path="/myaccount" component={MyAccount} />
                    <Route exact path="/fancybook/:user_id/:event_id/:selection_id" component={FancyBook} />

                    
                    <Route exact path="/account-statement" component={AccountStatement} />
                    <Route exact path="/account-statement/:user_id" component={AccountStatement} />
                    <Route exact path="/sidenav" component={SideNav} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/activity-log" component={ActivityLog} />
                    <Route exact path="/activity-log/:user_id" component={ActivityLog} />
                    <Route exact path="/downline-profitloss-filter" component={ProfitDownline} />
                    <Route exact path="/sidenav-betfilter" component={SideNavBetFilter} />
                    <Route exact path="/memberList/bethistory" component={BetHistoryUser} />
                    <Route exact path="/memberList/profitAndLoss" component={ProfitandLossUser} />
                    <Route exact path="/adminfund" component={AdminFund} />
                    <Route exact path="/blockmarket" component={BlockMarket} />
                    <Route exact path="/blockmarket/:event_type_id" component={BlockMarketEvents} />
                    <Route exact path="/blockmarket-markets/:event_type_id/:event_id" component={BlockMarketEventsMarkets} />
                    <Route exact path="/blockmarket-markets/:event_type_id/:event_id/fancy" component={BlockMarketEventsFancys} />
                    <Route exact path="/news" component={News} />
                    <Route exact path="/betdelete" component={BetDelete} />
                    <Route exact path="/betdelete-event/:event_type_id" component={BetDeleteEvents} />
                    <Route exact path="/betdelete-bet/:event_type_id/:event_id" component={BetDeleteEventsBet} />
                    {/* <Route exact path="/user_gen_settings" component={GenSetting} /> */}
                    <Route exact path="/user_gen_settings/:user_id" component={GenSetting} />
                    <Route exact path="/full-market/:event_id" component={FullMarket} />
                    <Route exact path="/full-market/:event_id/:user_id" component={FullMarket} />
                    <Route exact path="/addbanner" component={AddBanner} />
                    <Route exact path="/downline-market/:event_id/:user_id/:market_id/:user_type" component={DownlineMarket} />

                 

                    {/*<Route exact path="/accountinfo" component={Accountinfo} />
          <Route exact path="/profitloss" component={Profitloss} />
          <Route exact path="/bethistory" component={Bethistory} />
          <Route exact path="/livebethistory" component={LiveBethistory} /> */}
                  </>
                )
                : (<Route component={() => isMobile ? <LoginMobile setLoggedIn={setLoggedIn} /> : <Login setLoggedIn={setLoggedIn} />} />)
            }

          </Switch>

        </Router>
      </SocketContext.Provider>
    </div>
  );
}
export default App;
