import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from '../admin/Header'
// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import SideNav from '../admin/SideNav';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";

import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function BetDelete() {
  const displaynone = {
    display: "none"
  }
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
  const [opens, setOpens] = React.useState(false);
  const history = useHistory();

  const [EventList, setEventList] = React.useState([]);
  const [EventMatchList, setEventMatchList] = React.useState([]);
  const [dialougeOpen, setDialougeOpen] = React.useState(false);
  const [deleteEventListid, setDeleteEventListid] = React.useState('');
  const [editopens, setEditOpens] = React.useState(false);
  const [showMatchList, setshowMatchList] = React.useState(false);
  const [EventMatchId, setEventMatchId] = React.useState('');
  const [pageCount, setPageCount] = React.useState(10);
  const [formEditData, setEditFormData] = useState({
    site_id: "60fc5aca67e1e3799230a6f7",
    message: '',
    is_active: "Yes",
    is_delete: "No",
    id: ''
  });

  const handleUpdateInputChange = (event) => {


    setEditFormData({
      ...formEditData,
      [event.target.name]: event.target.value
    })
  }


  const editmodalClose = () => {
    setEditOpens(false);
  };
  const modalOpen = () => {
    setOpens(true);

  };


  const modalClose = () => {
    setOpens(false);
  };


  const [formData, setFormData] = useState({
    site_id: "60fc5aca67e1e3799230a6f7",
    message: '',
    is_active: "Yes",
    is_delete: "No"


  });


  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }
  function handleClickDialogeOpen() {
    setDialougeOpen(true);
  };

  const handleDialogeClose = () => {
    setDialougeOpen(false);
  };
  const handleSubmit = (e) => {

    e.preventDefault();

    var axios = require('axios');
    var data = JSON.stringify({
      "user_id": userInfo._id
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}EventList/addEventList`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(data);
    axios(config)
      .then(function (response) {
        getEventList();
        modalClose();
        if (response.data.result) {
          notify('success', response.data.resultMessage)
        }
        else {
          notify('error', response.data.resultMessage)
        }

      })
      .catch(function (error) {
        console.log(error);
      });


  }




  const handleUpdate = (e) => {

    e.preventDefault();


    var data = JSON.stringify({
      _id: formEditData.id,
      data: {
        message: formData.message,
        is_active: "Yes",
        is_delete: "No"
      }
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}EventList/updateEventList`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(data);
    axios(config)
      .then(function (response) {

        getEventList();
        editmodalClose();
        if (response.data.result) {
          notify('success', response.data.resultMessage)
          setEditOpens(false);
          setFormData({ message: '' });
        }
        else {
          notify('error', response.data.resultMessage)
        }

      })
      .catch(function (error) {
        console.log(error);
      });


  }

  const classes = useStyles();

  useEffect(() => {
    if (userInfo.user_type != "Super Admin") {
      history.push("/");
    }

    getEventList();
  }, []);

  function getEventList() {

    var data = JSON.stringify({
      "user_id": userInfo._id
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}blockMarket/blockEventTypes`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setEventList(response.data.resultData);
        let tmpEventList = response.data.resultData;
        const newEventList = tmpEventList.map((EventList, index) => {
          tmpEventList[index]['id'] = index + 1;
        });
        console.log('getEventList string after event click', tmpEventList);
        setEventList(tmpEventList);
        // console.log(EventList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function editEventList(id, index) {

    // console.log(EventList[index - 1].message);
    formData.message = EventList[index - 1].message
    formEditData.id = EventList[index - 1]._id
    setEditOpens(true);
  }

  function deleteEventList(id) {
    setDeleteEventListid(id);
    handleClickDialogeOpen();
  }

  const confirmDelete = () => {
    var data = JSON.stringify({
      "_id": deleteEventListid
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}EventList/deleteEventList`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // setdeletechipid('');
        setDialougeOpen(false);
        // console.log('Delete', response.data);
        if (response.data.result) {
          getEventList();
          notify(response.data.resultMessage, 'success')
        }
        else {
          notify(response.data.resultMessage, 'error')
        }
      })
      .catch(function (error) {

        console.log(error);
      });
  }
  function notify(message, type) {
    toast.configure();
    if (type === 'error') {
      toast.error(message, {
        toastId: "error"
      });
    }
    else {
      toast.success(message, {
        toastId: "success"
      });
    }
  }

  function eventMatches(eventId) {

    setEventMatchId(eventId);
    var data = JSON.stringify({
      "user_id": userInfo._id,
      event_type_id: eventId
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}blockMarket/blockEvents`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setEventMatchList(response.data.resultData);
        let tmpEventList = response.data.resultData;
        const newEventMatchList = tmpEventList.map((EventMatchList, index) => {
          tmpEventList[index]['id'] = index + 1;
        });
        console.log('newsss', response.data.resultData);
        setEventMatchList(tmpEventList)
        setshowMatchList(true)
        //  console.log(EventList);


        // console.log(EventList);
      })
      .catch(function (error) {
        console.log(error);
      }).then(() => {

        if (!EventList.length) {
          document.getElementsByClassName("MuiDataGrid-window")[0].innerHTML = "<div class='no-row'><span>No Rows</span></div>"
        }

      });


  }

  const columns = [


    {
      field: 'id',
      headerName: 'S.NO',
      editable: true,
      width: 58,
    },

    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      width: 143,
      disableClickEventBubbling: true,
      renderCell: rowData => {
        return (
          <Link to='#' onClick={() => history.push("/betdelete-event/" + rowData.row.event_type)} >{rowData.row.name}</Link>
        );
      }
    },
  ];

  function blockUnblockSports(event_type) {
    var data = JSON.stringify({
      "user_id": userInfo._id,
      "event_id": "0",
      "market_id": "0",
      "fancy_id": "0",
      "event_type_id": event_type,
      "type": "Sport"

    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        getEventList();
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  return (
    <div className="dashboard myaccount m-width1300">
      {/* Top Start */}
      <Header />
      {/* Top End */}
      <div className="main_wrap">

        <div className="agent_path">
          <ul id="agentPath" className="agent_path-L">
            <li id="path6" className="" style={displaynone}>
              <Link to="/">
                <span className="lv_0">
                  COM
                </span>
                <strong></strong>
              </Link>
            </li>

            <li id="path5" className="" style={displaynone}>
              <Link to="/">
                <span className="lv_1">
                  SS
                </span>
                <strong></strong>
              </Link>
            </li>

            <li id="path4" className="" style={displaynone}>
              <Link to="/">
                <span className="lv_2">
                  SUP
                </span>
                <strong></strong>
              </Link>
            </li>

            <li id="path3" className="last_li">
              <Link >
                <span className="lv_3">
                  {userInfo.user_type.match(/\b(\w)/g).join('')}
                </span>
                <strong>{userInfo.user_name}</strong>
              </Link>
            </li>

            <li id="path0" className="" style={displaynone}>
              <Link to="/">
                <span className="lv_4">
                  PL
                </span>
                <strong></strong>
              </Link>
            </li>

            <ul className="account_pop" id="accountPop">
              <li id="popTmp" style={displaynone}>
                <Link to="/"></Link>
              </li>
            </ul>
          </ul>
        </div>

        {/* Side Bar Code */}
        <div className="col-left">
          <SideNav />
        </div>
        <div className="col-center report account-summary">
          <h2>Sport Listing</h2>

          <div id="loading" className="loading-wrap" style={displaynone}>
            <ul className="loading">
              <li><img src="./images/loading40.gif" /></li>
              <li>Loading...</li>
            </ul>
          </div>

          <div id="message" className="message-wrap success" style={displaynone}>
            <Link className="btn-close" to="#">Close</Link>
            <p></p>
          </div>

          <table id="table_log" className="table01" style={{ textAlign: "left" }}>
            <thead>
              <tr>
                <th >Name</th>
              </tr>
            </thead>
            <tbody id="content">

              {EventList.length > 0 && EventList.map(row =>
                <tr id="tempTr">
                  <td> <Link to='#' onClick={() => history.push("/betdelete-event/" + row.event_type)} >{row.name}</Link></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

