import React from 'react'
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from './Header'
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useLocation} from "react-router-dom";

const displaynone = {
    display: "none"
}
function MyAccount() {
    const search = useLocation().search;
    const uidQuery = new URLSearchParams(search).get('uid');
    const usernameQuery = new URLSearchParams(search).get('username');
    const userTypeQuery = new URLSearchParams(search).get('usertype')

    return (
        <div className="dashboard sidenav">
                <div className="sub_path">
                    <ul className="menu-list">                
                        <li className="class">Position</li>                    
                        {/* <li><Link id="accountStatement" to="/account-statement">Account Statement</Link></li>                                         */}
                        <li><Link id="accountSummary" to="/myaccount">Account Summary</Link></li>
                        {/* <li>{
                            uidQuery == "" ? "nothing" : "athere"
                        }</li> */}
                        <li className="class">Performance</li>
                        <li><Link id="profile" to="/memberList/bethistory">Bet History</Link></li>
                        <li><Link id="activityLog" to="/activity-log">Betting Profit &amp; Loss</Link></li>                                        
                        <li><Link id="profile" to="/bethistory">Transaction History</Link></li>
                        <li><Link id="activityLog" to="/activity-log">Activity Log</Link></li>                                        
                    </ul>
                </div>
            </div>
    )
}

export default MyAccount
