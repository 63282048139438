import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Trans from '../assets/transparent.gif'

// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';
import activeinactive from "../assets/icons/transparent (1).gif"

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";

import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BlockMarket() {


    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [opens, setOpens] = React.useState(false);
    const history = useHistory();

    const [EventList, setEventList] = React.useState([]);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [deleteEventListid, setDeleteEventListid] = React.useState('');
    const [editopens, setEditOpens] = React.useState(false);
    const [showMatchList, setshowMatchList] = React.useState(false);
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [pageCount, setPageCount] = React.useState(10);
    const [formEditData, setEditFormData] = useState({
        site_id: "60fc5aca67e1e3799230a6f7",
        message: '',
        is_active: "Yes",
        is_delete: "No",
        id: ''
    });

    const handleUpdateInputChange = (event) => {


        setEditFormData({
            ...formEditData,
            [event.target.name]: event.target.value
        })
    }


    const editmodalClose = () => {
        setEditOpens(false);
    };
    const modalOpen = () => {
        setOpens(true);

    };


    const modalClose = () => {
        setOpens(false);
    };


    const [formData, setFormData] = useState({
        site_id: "60fc5aca67e1e3799230a6f7",
        message: '',
        is_active: "Yes",
        is_delete: "No"


    });


    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }
    function handleClickDialogeOpen() {
        setDialougeOpen(true);
    };

    const handleDialogeClose = () => {
        setDialougeOpen(false);
    };
    const handleSubmit = (e) => {

        e.preventDefault();

        var axios = require('axios');
        var data = JSON.stringify({
            "user_id": userInfo._id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}EventList/addEventList`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data);
        axios(config)
            .then(function (response) {
                getEventList();
                modalClose();
                if (response.data.result) {
                    notify('success', response.data.resultMessage)
                }
                else {
                    notify('error', response.data.resultMessage)
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }




    const handleUpdate = (e) => {

        e.preventDefault();


        var data = JSON.stringify({
            _id: formEditData.id,
            data: {
                message: formData.message,
                is_active: "Yes",
                is_delete: "No"
            }
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}EventList/updateEventList`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data);
        axios(config)
            .then(function (response) {

                getEventList();
                editmodalClose();
                if (response.data.result) {
                    notify('success', response.data.resultMessage)
                    setEditOpens(false);
                    setFormData({ message: '' });
                }
                else {
                    notify('error', response.data.resultMessage)
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const classes = useStyles();

    useEffect(() => {
        window.scroll(0, 0)
        getEventList();
    }, []);

    function getEventList() {

        var data = JSON.stringify({
            "user_id": userInfo._id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/blockEventTypes`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setEventList(response.data.resultData);
                let tmpEventList = response.data.resultData;
                const newEventList = tmpEventList.map((EventList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('getEventList string after event click', tmpEventList);
                setEventList(tmpEventList);
                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function editEventList(id, index) {

        console.log(EventList[index - 1].message);
        formData.message = EventList[index - 1].message
        formEditData.id = EventList[index - 1]._id
        setEditOpens(true);
    }

    function deleteEventList(id) {
        setDeleteEventListid(id);
        handleClickDialogeOpen();
    }

    const confirmDelete = () => {
        var data = JSON.stringify({
            "_id": deleteEventListid
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}EventList/deleteEventList`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // setdeletechipid('');
                setDialougeOpen(false);
                console.log('Delete', response.data);
                if (response.data.result) {
                    getEventList();
                    notify(response.data.resultMessage, 'success')
                }
                else {
                    notify(response.data.resultMessage, 'error')
                }
            })
            .catch(function (error) {

                console.log(error);
            });
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    function eventMatches(eventId) {

        setEventMatchId(eventId);
        var data = JSON.stringify({
            "user_id": userInfo._id,
            event_type_id: eventId
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/blockEvents`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setEventMatchList(response.data.resultData);
                let tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((EventMatchList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('newsss', response.data.resultData);
                setEventMatchList(tmpEventList)
                setshowMatchList(true)
                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const columns = [


        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58,
        },

        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: 143,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Link to='#' onClick={() => history.push("/blockmarket/" + rowData.row.event_type)} >{rowData.row.name}</Link>
                );
            }
        },

        {
            field: "is_blocked",
            headerName: "Status",
            sortable: false,
            width: 80,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    rowData.row.is_blocked === "Yes" ? <Chip label="Inactive" color="secondary" /> : <Chip label="Active" color="primary" />
                );
            }
        },
        {
            field: "edit",
            headerName: "Action",
            sortable: false,
            width: 80,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Switch
                        checked={rowData.row.is_blocked === "Yes" ? false : true}
                        onChange={() => blockUnblockSports(rowData.row.event_type)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                );
            }
        },
    ];

    function blockUnblockSports(event_type) {
        var data = JSON.stringify({
            "user_id": userInfo._id,
            "event_id": "0",
            "market_id": "0",
            "fancy_id": "0",
            "event_type_id": event_type,
            "type": "Sport"

        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                getEventList();
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const displaynone = {
        display: "none"
    }
    return (


        <div className="dashboard myaccount m-width1300">
            <Header />
            <div className=" account-summary">
                <h2>Account Statement</h2>
                <div id="loading" className="loading-wrap" style={displaynone}>
                    <ul className="loading">
                        <li><img src="./images/loading40.gif" /></li>
                        <li>Loading...</li>
                    </ul>
                </div>

                <div id="message" className="message-wrap success" style={displaynone}>
                    <Link className="btn-close" to="#">Close</Link>
                    <p></p>
                </div>
                <table style={displaynone}>
                    <tbody>
                        <tr id="tempTr">
                            <td id="createDate" className="align-L"></td>
                            <td id="deposit">-</td>
                            <td id="withdraw">-</td>

                        </tr>

                        <tr id="noDataTempTr">
                            <td className="no-data" colSpan="5">
                                <p>No Data</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table id="table_log" className="table01">
                    <thead>
                        <tr>
                            <th width="15%" className="align-L">Name</th>
                            <th width="18%">Status</th>
                            <th width="18%">Action</th>

                        </tr>
                    </thead>
                    <tbody id="content">

                        {EventList.length > 0 && EventList.map(event =>
                            <tr id="tempTr">
                                <td className="align-L">{<Link to='#' onClick={() => history.push("/blockmarket/" + event.event_type)} >{event.name}</Link>}</td>

                                <td><span id="status0" className={event.is_blocked !== "Yes" ? 'status-active' : 'status-inactive'}>
                                    <img src={activeinactive} />{event.is_blocked !== "Yes" ? 'Active' : 'Inactive'}</span>
                                </td>

                                <td>
                                    <Switch
                                    defaultChecked={event.is_blocked === "Yes" ? false : true}
                                    onChange={() => blockUnblockSports(event.event_type)}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

