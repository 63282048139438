import React from 'react'
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from './Header'
import { Link, useParams } from "react-router-dom";
import Appconfig from "../config/config";
import axios from 'axios';
import moment from 'moment';
import Transparent from '../assets/transparent.gif'
import Loader from '../assets/images/loading40.gif';


const displaynone = {
    display: "none"
}
function DownlineMarket() {

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [matchOddsList, setMatchOddsList] = React.useState([]);
    const [eventName, setEventName] = React.useState('');
    const [loading, setLoading] = React.useState(true);



    console.log('matchOddsList', matchOddsList);
    const params = useParams();

    const eventId = params.event_id;
    const userId = params.user_id;
    const marketId = params.market_id;
    const userType = params.user_type;


    React.useEffect(() => {
        getDownlineMarketData(eventId, userId, marketId, userType);
    }, [eventId, userId, marketId, userType]);

    function getDownlineMarketData(eventId, userId, marketId, userType) {
        setLoading(true);
        var data = JSON.stringify({
            event_id: eventId,
            user_id: userId,
            market_id: marketId,
            user_type: userType
        });
        console.log('data', data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/downlineMarketPosition`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log('response.data.resultData', response.data.resultData);

                setMatchOddsList(response.data.resultData);
                setEventName(response.data.resultData[0].event_name);
                total(response.data.resultData);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [teamA, setTeamA] = React.useState(0);
    const [teamB, setTeamB] = React.useState(0);
    const [draw, setDraw] = React.useState(0);


    function total(matchOddsList) {
        if (matchOddsList.length > 0) {
            let teamA = 0;
            let teamB = 0;
            let draw = 0;

            matchOddsList.map(val => {
                teamA += Number(val.team_a);
                teamB += Number(val.team_b);
                draw += Number(val.draw);
            })

            setTeamA(teamA ? teamA : 0);
            setTeamB(teamB ? teamB : 0);
            setDraw(draw ? draw : 0);


        }
    }

    return (
        <div className="dashboard downlineprofitloss">
            {/* Top Start */}
            <Header />
            {/* Top End */}

            {/* Main Wrap Start */}
            <div className="main_wrap">
                {/* Loading Wrap */}
                <div id="loading" className="loading-wrap" style={displaynone}>
                    <ul className="loading">
                        <li><img src="/images/loading40.gif" /></li>
                        <li>Loading...</li>
                    </ul>
                </div>
                {/* Message */}
                <div id="message" className="message-wrap success" style={displaynone}>
                    <a className="btn-close">Close</a>
                    <p></p>
                </div>
                {/* Report Table */}
                <div className="total_all">
                    <h2>Downline Market
                    </h2>
                    <a className="btn_replay" href="javascript:void(0);" id="refresh_Top_Racing"><img src={Trans} /></a>
                </div>

                <div className="match-wrap">
                    <div className="total_all">
                        <h2>{eventName}</h2>
                        <a className="btn_replay" href="javascript:void(0);" id="refresh_Match_Odds"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Match_Odds" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>


                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Downline</th>
                            <th width="21%" className="align-C border-l bg-yellow" colspan="3">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Downline P/L</th>
                        </tr>
                        <tr>
                            <th width="7%" className="border-l bg-yellow">1</th>
                            <th width="7%" className="bg-yellow">X</th>
                            <th width="7%" className="bg-yellow">2</th>
                        </tr>

                        <tbody id="content_MATCH_ODDS_DRAW">
                            {
                                loading ?
                                    <tr>
                                        <td colspan="2">
                                            <img src={Loader} />
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {
                                            matchOddsList.length > 0 ?
                                                matchOddsList.map((val, index) => {
                                                    return (
                                                        <tr id="tempTr_BookMaker_MatchOdds_Draw" className="border-t">
                                                            <td className="align-L" rowspan="1" id="0_1">
                                                                <span id="eventType" href="javascript:void(0);">
                                                                    {val.userss.user_name}
                                                                </span>
                                                            </td>

                                                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1"><span className="inttype" data-value={val.team_a}>{val.hasOwnProperty("team_a") ? val.team_a : 0}</span></a></td>
                                                            <td><a className="" href="javascript:void(0);" id="selection_exposure_3"><span className="inttype" data-value={val.hasOwnProperty("draw") ? val.draw : 0}>{val.hasOwnProperty("draw") ? val.draw : 0}</span></a></td>
                                                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2"><span className="inttype" data-value={val.team_b}>{val.hasOwnProperty("team_b") ? val.team_b : 0}</span></a></td>

                                                            <td className="border-l">
                                                                {
                                                                    val.userss.user_type == "User" ?
                                                                        <Link to={`/full-market/${val.match_id}`} className="btn" >
                                                                            View</Link>
                                                                        :
                                                                        <Link to={`/downline-market/${val.match_id}/${val.userss._id}/${val.market_id}/${val.userss.user_type}`} className="btn" >
                                                                            View</Link>

                                                                }
                                                            </td>
                                                        </tr>

                                                    );

                                                })


                                                :
                                                <tr>
                                                    <td className="no-data" colspan="7">
                                                        <p>No Data
                                                        </p>
                                                    </td>
                                                </tr>
                                        }
                                        <tr>
                                            <td>
                                                <p>Total
                                                </p>
                                            </td>
                                            <td>
                                                <p>{teamA}
                                                </p>
                                            </td>
                                            <td>
                                                <p>{draw}
                                                </p>
                                            </td>
                                            <td>
                                                <p>{teamB}
                                                </p>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </>
                            }
                        </tbody>


                    </table>
                </div>


            </div>
        </div >
    )
}

export default DownlineMarket
