import React from 'react'
import Trans from '../assets/transparent.gif'
import "../assets/style.css"
import Header from '../admin/Header'
import { Link } from "react-router-dom";
import Appconfig from "../config/config";
import axios from 'axios';
import moment from 'moment';
import Transparent from '../assets/transparent.gif'
const displaynone = {
    display: "none"
}
function RiskManagement() {

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [fancyList, setFancyList] = React.useState([]);
    const [bookmakerList, setBookmakerList] = React.useState([]);
    const [matchOddsList, setMatchOddsList] = React.useState([]);
    console.log('matchOddsList',matchOddsList);

    const [allList, setAllList] = React.useState([]);
    React.useEffect(() => {
        getList(1);
        getListByMarketName();
        getListByFancy();
    }, []);

    function getList(type) {
        setAllList([]);
        var data = JSON.stringify({
            user_id: userInfo._id,
            type: type
        });
        //  console.log(data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/riskManagement`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setAllList(response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getListByMarketName() {
        var data = JSON.stringify({
            user_id: userInfo._id
        });
        //  console.log(data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/riskManagementByMarketName`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                let bookmaker = [];
                let matchOdds = [];
                response.data.resultData.map((val, index) => {
                    if (val.market_name == "Match Odds") {
                        matchOdds.push(val);
                    }
                    if (val.market_name == "Bookmaker") {
                        bookmaker.push(val);
                    }

                })
                setBookmakerList(bookmaker);
                setMatchOddsList(matchOdds);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function marketRefresh() {
        getListByMarketName();
    }

    function fancyRefresh() {
        getListByFancy();
    }
    function getListByFancy() {
        var data = JSON.stringify({
            user_id: userInfo._id
        });
        //  console.log(data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/riskManagementByFancy`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let fancy = [];
                setFancyList(response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function topTenMatchAmount() {
        getList(1);
        document.getElementById("top10ExposureLi").classList.remove("select");
        document.getElementById("top10MatchedAmountLi").classList.add("select");
    }

    function topTenExposure() {
        getList(2);

        document.getElementById("top10MatchedAmountLi").classList.remove("select");
        document.getElementById("top10ExposureLi").classList.add("select");

    }
    return (
        <div className="dashboard downlineprofitloss">
            {/* Top Start */}
            <Header />
            {/* Top End */}

            {/* Main Wrap Start */}
            <div className="main_wrap">
                {/* Loading Wrap */}
                <div id="loading" className="loading-wrap" style={displaynone}>
                    <ul className="loading">
                        <li><img src="/images/loading40.gif" /></li>
                        <li>Loading...</li>
                    </ul>
                </div>
                {/* Message */}
                <div id="message" className="message-wrap success" style={displaynone}>
                    <a className="btn-close">Close</a>
                    <p></p>
                </div>
                {/* Report Table */}
                <div className="total_all">
                    <h2>Risk Management Summary
                    </h2>
                    <a className="btn_replay" href="javascript:void(0);" id="refresh_Top_Racing"><img src={Trans} /></a>
                </div>
                <div className="play_race-wrap">
                    <div className="top_player-wrap">
                        <table style={displaynone}>
                            <tr id="tempTr_top">
                                <td className="align-L">
                                    <span className="order" id="number"></span>
                                    <a href="javascript:void(0);" id="userId"></a>
                                </td>
                                <td id="exposure"></td>
                                <td id="amount"></td>
                            </tr>

                            <tr id="tempTr_noData_top">
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>

                        <h3>
                            <ul className="tab-topplay">
                                <li id="top10MatchedAmountLi" onClick={() => topTenMatchAmount()} className="select"><a href="#">Top 10 Matched Amount Player</a></li>
                                <li id="top10ExposureLi" onClick={() => topTenExposure()}><a href="#">Top 10 Exposure Player</a></li>
                            </ul>
                        </h3>

                        <div className="col-2">
                            <ul className="slip-head">
                                <li className="col-playID">UID</li>
                                <li className="col-exp">Exposure</li>
                                <li className="col-amount">Matched Amount</li>
                            </ul>

                            <table className="table01">
                                <tbody id="content_top_1_5">
                                    {[0, 1, 2, 3, 4].map((x, i) =>
                                        allList[x] ?
                                            <tr>
                                                <td><Link onClick={() => console.log('ere')}>{allList[x].user_name}</Link></td>
                                                <td>{allList[x].exposure}</td>
                                                <td>{allList[x].stake}</td>
                                            </tr> : <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                    )}


                                </tbody>
                            </table>
                        </div>

                        <div className="col-2">
                            <ul className="slip-head">
                                <li className="col-playID">UID</li>
                                <li className="col-exp">Exposure</li>
                                <li className="col-amount">Matched Amount</li>
                            </ul>

                            <table className="table01">
                                <tbody id="content_top_6_10">
                                    {[5, 6, 7, 8, 9].map((x, i) =>
                                        allList[x] ?
                                            <tr>
                                                <td><Link>{allList[x].user_name}</Link></td>
                                                <td>{allList[x].exposure}</td>
                                                <td>{allList[x].stake}</td>
                                            </tr> : <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Match Odds</h2>
                        <a className="btn_replay" onClick={() => marketRefresh()} href="javascript:void(0);" id="refresh_Match_Odds"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Match_Odds" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_MATCH_ODDS_DRAW">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" className="btn open-odds" id="showOddsBtn" >Open</a>
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="selection_exposure_3">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2">-</a></td>
                            <td className="border-l"><a href="javascript:void(0);" className="btn" id="view">View</a></td>
                        </tr>


                        <tr id="tempTr_MATCH_ODDS">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" className="btn open-odds" id="showOddsBtn" >Open</a>
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2">-</a></td>
                            <td className="border-l"><a href="javascript:void(0);" className="btn" id="view">View</a></td>
                        </tr>

                        <tr id="tempTr_expand_MATCH_ODDS_DRAW" className="expand" style={displaynone}>
                            <td className="border-l align-L" colspan="4">
                                <img className="expand-arrow" src={Trans} />
                                <iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
                                <div className="risk-message" id="risk_message" style={displaynone}></div>
                            </td>
                            <td width="80" className="border-l"></td>
                        </tr>

                        <tr id="tempTr_expand_MATCH_ODDS" className="expand" style={displaynone}>
                            <td className="border-l align-L" colspan="3">
                                <img className="expand-arrow" src={Trans} />
                                <iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
                                <div className="risk-message" id="risk_message" style={displaynone}></div>
                            </td>
                            <td width="80" className="border-l"></td>
                        </tr>

                        <tr id="tempTr_noData_MATCH_ODDS_DRAW">
                            <td className="no-data" colspan="7">
                                <p>No Data
                                </p>
                            </td>
                        </tr>

                        <tr id="tempTr_noData_MATCH_ODDS">
                            <td className="no-data" colspan="6">
                                <p>No Data
                                </p>
                            </td>
                        </tr>
                    </table>

                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Sports</th>
                            <th width="7%" className="align-L" rowspan="2">Market Date</th>
                            <th className="align-L" rowspan="2">Event/Market Name</th>
                            <th width="21%" className="align-C border-l bg-yellow" colspan="3">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Downline P/L</th>
                        </tr>
                        <tr>
                            <th width="7%" className="border-l bg-yellow">1</th>
                            <th width="7%" className="bg-yellow">X</th>
                            <th width="7%" className="bg-yellow">2</th>
                        </tr>

                        <tbody id="content_MATCH_ODDS_DRAW">
                            {
                                matchOddsList.length > 0 ?
                                    matchOddsList.map((val, index) => {
                                        return <tr id="tempTr_BookMaker_MatchOdds_Draw" className="border-t">
                                            <td className="align-L" rowspan="1" id="0_1">
                                                <a id="eventType" href="javascript:void(0);">
                                                    {val.eventTypeInfo[0].event_type == 4 ? "Cricket" : ""}
                                                    {val.eventTypeInfo[0].event_type == 2 ? "Tennis" : ""}
                                                    {val.eventTypeInfo[0].event_type == 1 ? "Soccer" : ""}
                                                </a>
                                            </td>
                                            <td className="align-L border-l" rowspan="1" id="1_1" > {moment(val.created_at).format("YYYY-MM-DD")}</td>
                                            <td className="align-L border-l">
                                                <Link to={`/full-market/${val.match_id}`} id="marketPath">
                                                    <strong id="eventName">{val.eventTypeInfo[0].event_name} </strong>
                                                    <img className="fromto" src={Transparent} />
                                                    <span id="marketName">{val.market_name}</span>
                                                </Link>
                                            </td>
                                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1"><span className="inttype" data-value={val.team_a}>{val.hasOwnProperty("team_a") ? val.team_a : 0}</span></a></td>
                                            <td><a className="" href="javascript:void(0);" id="selection_exposure_3"><span className="inttype" data-value={val.hasOwnProperty("draw") ? val.draw : 0}>{val.hasOwnProperty("draw") ? val.draw : 0}</span></a></td>
                                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2"><span className="inttype" data-value={val.team_b}>{val.hasOwnProperty("team_b") ? val.team_b : 0}</span></a></td>
                                            <td className="border-l">
                                            <Link to={`/downline-market/${val.match_id}/${userInfo._id}/${val.market_id}/${userInfo.user_type}`} className="btn" >
                                                View</Link></td>
                                        </tr>

                                    })
                                    :
                                    <tr>
                                        <td className="no-data" colspan="7">
                                            <p>No Data
                                            </p>
                                        </td>
                                    </tr>
                            }

                        </tbody>
                    </table>

                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Sports</th>
                            <th width="8%" className="align-L" rowspan="2">Market Date</th>
                            <th className="align-L" rowspan="2">Event/Market Name</th>
                            <th width="18%" className="align-C border-l bg-yellow" colspan="2">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Downline P/L</th>
                        </tr>
                        <tr>
                            <th width="9%" className="border-l bg-yellow">1</th>
                            <th width="9%" className="bg-yellow">2</th>
                        </tr>

                        <tbody id="content_MATCH_ODDS">
                            <tr>
                                <td className="no-data" colspan="6">
                                    <p>No Data
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Book Maker</h2>
                        <a className="btn_replay" href="javascript:void(0);" id="refresh_BookMaker_MatchOdds"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_BookMaker_MatchOdds" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_BookMaker_MatchOdds_Draw">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="selection_exposure_3">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2">-</a></td>
                            <td className="border-l"><a href="javascript:void(0);" className="btn" id="view">View</a></td>
                        </tr>


                        <tr id="tempTr_BookMaker_MatchOdds">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2">-</a></td>
                            <td className="border-l"><a href="javascript:void(0);" className="btn" id="view">View</a></td>
                        </tr>

                        <tr id="tempTr_noData_BookMaker_MatchOdds_Draw">
                            <td className="no-data" colspan="7">
                                <p>No Datas
                                </p>
                            </td>
                        </tr>

                        <tr id="tempTr_noData_BookMaker_MatchOdds">
                            <td className="no-data" colspan="6">
                                <p>No Datas
                                </p>
                            </td>
                        </tr>
                    </table>

                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Sports</th>
                            <th width="7%" className="align-L" rowspan="2">Market Date</th>
                            <th className="align-L" rowspan="2">Event/Market Name</th>
                            <th width="21%" className="align-C border-l bg-yellow" colspan="3">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Downline P/L</th>
                        </tr>
                        <tr>
                            <th width="7%" className="border-l bg-yellow">1</th>
                            <th width="7%" className="bg-yellow">X</th>
                            <th width="7%" className="bg-yellow">2</th>
                        </tr>

                        <tbody id="content_BookMaker_MatchOdds_Draw">
                            {
                                bookmakerList.length > 0 ?
                                    bookmakerList.map((val, index) => {
                                        return <tr id="tempTr_BookMaker_MatchOdds_Draw" className="border-t">
                                            <td className="align-L" rowspan="1" id="0_1">
                                                <a id="eventType" href="javascript:void(0);">
                                                    {val.eventTypeInfo[0].event_type == 4 ? "Cricket" : ""}
                                                    {val.eventTypeInfo[0].event_type == 2 ? "Tennis" : ""}
                                                    {val.eventTypeInfo[0].event_type == 1 ? "Soccer" : ""}
                                                </a>
                                            </td>
                                            <td className="align-L border-l" rowspan="1" id="1_1" >    {moment(val.created_at).format("YYYY-MM-DD")}</td>
                                            <td className="align-L border-l">
                                                <Link to={`/full-market/${val.match_id}`} id="marketPath">
                                                    <strong id="eventName">{val.eventTypeInfo[0].event_name} </strong>
                                                    <img className="fromto" src={Transparent} />
                                                    <span id="marketName">{val.market_name}</span>
                                                </Link>
                                            </td>
                                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1"><span className="inttype" data-value={val.team_a}>{val.hasOwnProperty("team_a") ? val.team_a : 0}</span></a></td>
                                            <td><a className="" href="javascript:void(0);" id="selection_exposure_3"><span className="inttype" data-value={val.hasOwnProperty("draw") ? val.draw : 0}>{val.hasOwnProperty("draw") ? val.draw : 0}</span></a></td>
                                            <td><a className="" href="javascript:void(0);" id="selection_exposure_2"><span className="inttype" data-value={val.team_b}>{val.hasOwnProperty("team_b") ? val.team_b : 0}</span></a></td>
                                            <td className="border-l"> <Link to={`/full-market/${val.match_id}`} className="btn" >
                                                View</Link></td>
                                        </tr>

                                    })
                                    :
                                    <tr>
                                        <td className="no-data" colspan="7">
                                            <p>No Data
                                            </p>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>

                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Sports</th>
                            <th width="8%" className="align-L" rowspan="2">Market Date</th>
                            <th className="align-L" rowspan="2">Event/Market Name</th>
                            <th width="18%" className="align-C border-l bg-yellow" colspan="2">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Downline P/L</th>
                        </tr>
                        <tr>
                            <th width="9%" className="border-l bg-yellow">1</th>
                            <th width="9%" className="bg-yellow">2</th>
                        </tr>

                        <tbody id="content_BookMaker_MatchOdds">
                            <tr>
                                <td className="no-data" colspan="6">
                                    <p>No Data
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Fancy Bet</h2>
                        <a className="btn_replay" onClick={() => fancyRefresh()} href="javascript:void(0);" id="refresh_Fancy_Bet"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Fancy_Bet" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_FANCY_BET">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" className="btn open-odds" id="showOddsBtn" >Open</a>
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l"><a className="" href="javascript:void(0);" id="min_exposure">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="max_exposure">-</a></td>
                            <td className="border-l"><a href="javascript:void(0);" className="btn-book" id="books">Books</a></td>
                        </tr>

                        <tr id="tempTr_expand_FANCY_BET" className="expand" style={displaynone}>
                            <td className="border-l align-L" colspan="3">
                                <img className="expand-arrow" src={Trans} />
                                <iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
                                <div className="risk-message" id="risk_message" style={displaynone}></div>
                            </td>
                            <td width="80" className="border-l"></td>
                        </tr>

                        <tr id="tempTr_noData_FANCY_BET">
                            <td className="no-data" colspan="6">
                                <p>No Data
                                </p>
                            </td>
                        </tr>
                    </table>

                    {/* Fancy Bet */}
                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Sports</th>
                            <th width="8%" className="align-L" rowspan="2">Market Date</th>
                            <th className="align-L" rowspan="2">Event/Market Name</th>
                            <th width="18%" className="align-C border-l bg-yellow" colspan="2">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Books</th>
                        </tr>
                        <tr>
                            <th width="9%" className="border-l bg-yellow">MIN</th>
                            <th width="9%" className="bg-yellow">MAX</th>
                        </tr>

                        <tbody id="content_FANCY_BET">
                            {
                                fancyList.length > 0 ?
                                    fancyList.map((val, index) => {
                                        return <tr id="tempTr_BookMaker_MatchOdds_Draw" className="border-t">
                                            <td className="align-L" rowspan="1" id="0_1">
                                                <a id="eventType" href="javascript:void(0);">
                                                    {val.event_type == 4 ? "Cricket" : ""}
                                                    {val.event_type == 2 ? "Tennis" : ""}
                                                    {val.event_type == 1 ? "Soccer" : ""}
                                                </a>
                                            </td>
                                            <td className="align-L border-l" rowspan="1" id="1_1" >    {moment(val.created_at).format("YYYY-MM-DD")}</td>
                                            <td className="align-L border-l">
                                                <Link to={`#`} onClick={() => {
                                                    window.open("https://ag.skyexchangeart.in/fancybook/"+userInfo._id+"/"+val.match_id+"/"+val.selection_id, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
                                                }} id="marketPath">
                                                    <strong id="eventName">{val.event_name} / {val.place_name} </strong>
                                                    <img className="fromto" src={Transparent} />
                                                    <span id="marketName">{val.market_name}</span>
                                                </Link>
                                            </td>
                                            <td className="border-l"><a className="" href="javascript:void(0);" id="selection_exposure_1"><span className="inttype" data-value={val.min}>{val.min}</span></a></td>
                                            <td className=""><a className="" href="javascript:void(0);" id="selection_exposure_1"><span className="inttype" data-value={val.max}>{val.max}</span></a></td>

                                            <td className="border-l"><a href="javascript:void(0);" onClick={() => {
                                            <td className=""><a className="" href="javascript:void(0);" id="selection_exposure_1"><span className="inttype" data-value={val.max}>{val.max}</span></a></td>
window.open("https://ag.skyexchangeart.in/fancybook/"+userInfo._id+"/"+val.match_id+"/"+val.selection_id, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
// http://localhost:3000/riskmanagement
                                            }}id="marketPath" className='btn'>
                                                    View
                                                </a></td>
                                        </tr>

                                    })
                                    :
                                    <tr>
                                        <td className="no-data" colspan="7">
                                            <p>No Data
                                            </p>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Sports Book</h2>
                        <a className="btn_replay" href="javascript:void(0);" id="refresh_Ow"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Sportsbook" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_Ow">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l" id="amount"></td>
                        </tr>

                        <tr id="tempTr_noData_Ow">
                            <td className="no-data" colspan="4">
                                <p>No Data
                                </p>
                            </td>
                        </tr>
                    </table>

                    <table className="table01">
                        <tr>
                            <th width="10%" className="align-L">Sports</th>
                            <th width="8%" className="align-L">Market Date</th>
                            <th className="align-L">Event/Market Name</th>
                            <th width="26%" className="">Matched Amount</th>
                        </tr>

                        <tbody id="content_Ow">
                            <tr>
                                <td className="no-data" colspan="4">
                                    <p>No Data
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Premium Cricket</h2>
                        <a className="btn_replay" href="javascript:void(0);" id="refresh_Sportradar"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Sportradar" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_Sportradar">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l" id="amount"></td>
                        </tr>

                        <tr id="tempTr_noData_Sportradar">
                            <td className="no-data" colspan="4">
                                <p>No Data
                                </p>
                            </td>
                        </tr>
                    </table>

                    <table className="table01">
                        <tr>
                            <th width="10%" className="align-L">Sports</th>
                            <th width="8%" className="align-L">Market Date</th>
                            <th className="align-L">Event/Market Name</th>
                            <th width="26%" className="">Matched Amount</th>
                        </tr>

                        <tbody id="content_Sportradar">
                            <tr>
                                <td className="no-data" colspan="4">
                                    <p>No Data
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Binary</h2>
                        <a className="btn_replay" href="javascript:void(0);" id="refresh_Binary"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Binary" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_TS_BINARY">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" className="btn open-odds" id="showOddsBtn" >Open</a>
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l"><a className="" href="javascript:void(0);" id="min_exposure">-</a></td>
                            <td><a className="" href="javascript:void(0);" id="max_exposure">-</a></td>
                            <td className="border-l"><a href="javascript:void(0);" className="btn-book" id="books">Books</a></td>
                        </tr>

                        <tr id="tempTr_expand_TS_BINARY" className="expand" style={displaynone}>
                            <td className="border-l align-L" colspan="3">
                                <img className="expand-arrow" src={Trans} />
                                <iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
                                <div className="risk-message" id="risk_message" style={displaynone}></div>
                            </td>
                            <td width="80" className="border-l"></td>
                        </tr>

                        <tr id="tempTr_noData_TS_BINARY">
                            <td className="no-data" colspan="6">
                                <p>No Data
                                </p>
                            </td>
                        </tr>
                    </table>

                    <table className="table01 risk_matchodd">
                        <tr>
                            <th width="10%" className="align-L" rowspan="2">Sports</th>
                            <th width="8%" className="align-L" rowspan="2">Market Date</th>
                            <th className="align-L" rowspan="2">Event/Market Name</th>
                            <th width="18%" className="align-C border-l bg-yellow" colspan="2">Player P/L</th>
                            <th width="6%" className="align-C border-l" rowspan="2">Books</th>
                        </tr>
                        <tr>
                            <th width="9%" className="border-l bg-yellow">MIN</th>
                            <th width="9%" className="bg-yellow">MAX</th>
                        </tr>

                        <tbody id="content_TS_BINARY">
                            <tr>
                                <td className="no-data" colspan="6">
                                    <p>No Data
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="match-wrap">
                    <div className="total_all">
                        <h2>Other Markets</h2>
                        <a className="btn_replay" href="javascript:void(0);" id="refresh_Other_Market"><img src={Trans} /></a>
                        <a className="btn_replay" href="javascript:void(0);" id="downloadFile_Other_Market" style={{ 'display': "none", 'width': "70px" }}>Download</a>
                    </div>

                    <table style={displaynone}>

                        <tr id="tempTr_OTHER_MARKET">
                            <td className="align-L" rowspan="1" ><a id="eventType" href="javascript:void(0);"></a></td>
                            <td className="align-L border-l" rowspan="1" id="marketTime" ></td>
                            <td className="align-L border-l">
                                <a href="javascript:void(0);" id="marketPath">
                                    <strong id="eventName"></strong>
                                    <img className="fromto" src={Trans} />
                                    <span id="marketName"></span>
                                </a>
                            </td>
                            <td className="border-l" id="amount"></td>
                        </tr>

                        <tr id="tempTr_noData_OTHER_MARKET">
                            <td className="no-data" colspan="4">
                                <p>No Data
                                </p>
                            </td>
                        </tr>
                    </table>

                    <table className="table01">
                        <tr>
                            <th width="10%" className="align-L">Sports</th>
                            <th width="8%" className="align-L">Market Date</th>
                            <th className="align-L">Event/Market Name</th>
                            <th width="26%" className="">Matched Amount</th>
                        </tr>

                        <tbody id="content_OTHER_MARKET">
                            <tr>
                                <td className="no-data" colspan="4">
                                    <p>No Data
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                {/* 
                <div className="play_race-wrap">
                    <div className="top_player-wrap">
                        <Tabs className="tabs">
                            <TabList>
                                <Tab id="top10MatchedAmountLi"><Link to="#">Top 10 Matched Amount Player</Link></Tab>
                                <Tab id="top10ExposureLi"><Link to="#">Top 10 Exposure Player</Link></Tab>
                            </TabList>

                            <TabPanel>
                                <div  className="col-2">
                                    <ul className="slip-head">
                                        <li className="col-playID">UID</li>
                                        <li className="col-exp">Exposure</li>
                                        <li className="col-amount">Matched Amount</li>
                                    </ul>

                                    <table className="table01">
                                        <tbody id="content_top_1_5">
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div  className="col-2">
                                    <ul className="slip-head">
                                        <li className="col-playID">UID</li>
                                        <li className="col-exp">Exposure</li>
                                        <li className="col-amount">Matched Amount</li>
                                    </ul>

                                    <table className="table01">
                                        <tbody id="content_top_1_5">
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div  className="col-2">
                                    <ul className="slip-head">
                                        <li className="col-playID">UID</li>
                                        <li className="col-exp">Exposure</li>
                                        <li className="col-amount">Matched AmountR</li>
                                    </ul>

                                    <table className="table01">
                                        <tbody id="content_top_1_5">
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div  className="col-2">
                                    <ul className="slip-head">
                                        <li className="col-playID">UIDD</li>
                                        <li className="col-exp">Exposure</li>
                                        <li className="col-amount">Matched Amount</li>
                                    </ul>

                                    <table className="table01">
                                        <tbody id="content_top_1_5">
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
                 */}
            </div>
        </div >
    )
}

export default RiskManagement
