import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import {
    Table,
    TableBody,
    CardContent,
    Card,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    Dialog,
    TableRow,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Grid,
    Button,
    List,
    ListItemText,
    Modal,
    Typography,
    ListItem,
    Fade,
    Backdrop,
    TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import Appconfig from "../config/config";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function AdminFund() {
    const history = useHistory();
    const [validationError, SetValidationError] = useState("");
    const [addedUser, SetAddedUser] = useState("");
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [chipList, setchipList] = React.useState([]);
    const [opens, setOpens] = React.useState(false);
    const [editopens, setEditOpens] = React.useState(false);
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [deletechipid, setdeletechipid] = React.useState("");
    const [formData, setFormData] = useState({
        amount: "",
        password: "",
        remarks: "",
    });

    const [currentBalance, setCurrentBalance] = React.useState(0);
    const [newBalance, setNewBalance] = React.useState(0);

    const [formEditData, setEditFormData] = useState({
        chip_name: "",
        chip_value: "",
        chip_id: "",
    });
    const handleInputChange = (event) => {
        if (event.target.name == "amount") {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
            });
            let tempBalance = parseFloat(newBalance) + parseFloat(event.target.value);

            // console.log(tempBalance);

            if (tempBalance == NaN) {
            } else {
                setNewBalance(tempBalance);
            }
        } else {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleUpdateInputChange = (event) => {
        setEditFormData({
            ...formEditData,
            [event.target.name]: event.target.value,
        });
    };
    const modalOpen = () => {
        setOpens(true);
    };

    const modalClose = () => {
        setOpens(false);
    };

    const editmodalClose = () => {
        setEditOpens(false);
    };


    useEffect(() => {
        if (userInfo.user_type != "Super Admin") {
            history.push("/");
        }
        getBalance();
    }, []);

    function getBalance() {
        var data = JSON.stringify({
            user_id: userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result == 0) {
                } else {
                    setCurrentBalance(parseFloat(response.data.resultData.balance));
                    setNewBalance(parseFloat(response.data.resultData.balance));
                }
                // console.log(chipList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function addFund(id) {
        var data = JSON.stringify({
            user_id: userInfo._id,
            password: formData.password,
            amount: formData.amount,
            remarks: formData.remarks,
        });

        // console.log("Add Fund Api Call", data);
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/addFund`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                setOpens(false);
                // getChips();
                setFormData({
                    amount: "",
                    password: "",
                    remarks: "",
                });
                getBalance()
                if (response.data.result == 0) {
                    document.querySelector(".message-wrap").style.backgroundColor = "red";
                    document.querySelector("#message").style.display = "block";
                    SetAddedUser(response.data.message);

                } else {
                    document.querySelector(".message-wrap").style.backgroundColor = "green";
                    document.getElementById("message").style.display = "block";
                    SetAddedUser("Fund Deposit successfully");

                }
            })
            .catch(function (error) {
                notify("error occured", "error");
                console.log(error);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        addFund();
        console.log("yes", "event");
    };

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    return (


        <div className="dashboard bethistory-user">
            <>
                <Header />
                {/* Message */}

                <h2 >Admin Funds</h2>
                <Grid container style={{ display: "grid", placeItems: "center", backgroundColor: "#E0E6E6", padding: 20, }}>
                    <div id="message" className="message-wrap">
                        <Link to="#" onClick={closeMessage} className="btn-close">Close</Link>
                        <p>{addedUser}</p>
                    </div>
                    <form onSubmit={handleSubmit} autoComplete="off">

                        <dl className="border_b">
                            <dt>  Current Balance</dt>
                            <dd>
                                <input style={{ background: "lightgrey" }} readOnly value={currentBalance.toFixed(2)} />
                            </dd>
                            <dt>Deposit Fund</dt>
                            <dd>
                                <input id="amount" type="number" name="amount" min="1" max="100000000" onChange={handleInputChange} value={formData.amount} placeholder="Enter Deposit Fund" />
                                <span className="must">＊</span>
                                <span id="amountErrorText" className="error-text" ></span>

                            </dd>
                            <dt>Password</dt>
                            <dd>
                                <input id="userPassword" type="password" name="password" onChange={handleInputChange} value={formData.password} placeholder="Enter Your Password" />
                                <span className="must">＊</span>
                                <span id="passwordErrorText" className="error-text" ></span>
                            </dd>

                            <dt>Remarks</dt>
                            <dd>
                                <input id="remarks" type="text" name="remarks" onChange={handleInputChange} value={formData.remarks} placeholder="Enter remarks" />
                                <span className="must">＊</span>
                                <span id="remarksErrorText" className="error-text" ></span>
                            </dd>

                            <dt>Total Balance</dt>
                            <dd>
                                <input style={{ background: "lightgrey" }} value={newBalance.toFixed(2)} readOnly />

                            </dd>
                        </dl>
                        <div className="btn_box">
                            <button id="createBtn" className="btn-send" type="submit">Submit</button>
                        </div>
                    </form>
                </Grid>

            </>
        </div>

    );
}
