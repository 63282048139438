import React from 'react'
import '../assets/style.css'
import Trans from '../assets/transparent.gif'
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Appconfig from "../config/config";
import axios from "axios";
import { CURRENCY_TYPE } from '../config/config';
import { AppBar, Toolbar, CardContent, CardHeader, Card, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Slide, InputBase, Dialog, IconButton, Button, BottomNavigation, BottomNavigationAction, Modal, Backdrop, Fade, Grid, Menu, MenuItem, List, ListItem, ListItemText, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
// import LOGO from '../static/media/lce.f94a1876.png';
// console.log("userdaaataaa",userInfo);
const displaynone = {
	display: "none"
}
// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />;
//   });

const useStyles = makeStyles((theme) => ({

	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		display: 'block',
		width: "inherit"
	},
	search: {
		position: 'relative',
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: '270px',
		},
		backgroundColor: '#ffffff',
		borderRadius: '6px'
	},
	searchIcon: {
		padding: theme.spacing(0, .5),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#ffffff',
		borderRadius: '6px',
		color: '#000000',
		fontSize: '14px !important'
	},
	inputRoot: {
		color: '#000000',
		width: '100%'
	},
	inputInput: {
		padding: theme.spacing(.5, .5, .5, 0),
		paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		fontSize: '14px'
	},
	sectionDesktop: {
		display: 'flex',
	},
	navButton: {
		fontSize: '12px',
		fontWeight: '600',
		padding: '2px 16px',
		textTransform: 'none',
		marginLeft: '8px',
	},
	navBtnImg: {
		marginLeft: '6px'
	},
	navInput: {
		border: 'none',
		borderRadius: '4px',
		marginLeft: '6px',
		fontSize: '12px',
		paddingLeft: '8px',
		width: '122px',

	},
	marginLeftAuto: {
		marginLeft: 'auto'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));
const Header = (props) => {
	const classes = ((theme) => ({
		root: {
			height: 300,
			flexGrow: 1,
			minWidth: 300,
			transform: 'translateZ(0)',
			// The position fixed scoping doesn't work in IE 11.
			// Disable this demo to preserve the others.
			'@media all and (-ms-high-contrast: none)': {
				display: 'none',
			},
		},
		modal: {
			display: 'flex',
			padding: theme.spacing(1),
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			width: 400,
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}));
	const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
	const tabIndex = JSON.parse(window.sessionStorage.getItem("tabIndex"));
	const history = useHistory();
	const [balLoader, setBalLoader] = React.useState(false);
	const [MainBalance, setMainBalance] = React.useState(0);
	const [exposure, setExposure] = React.useState(0);
	const [activeItemIndex, seActiveItemIndex] = React.useState(tabIndex);
	const [psModalOpen, setPsModalOpen] = React.useState(false);
	const [cpformData, setcpformData] = React.useState({
		opassword: '',
		npassword: '',
		cpassword: '',
	});
	const [formData, setformData] = React.useState({
		user_name: '',
		password: '',
		site_code: Appconfig.sitecodes,
		validation_checker: ''
	});
	function handleItemIndex(val) {
		sessionStorage.setItem("tabIndex", val);
	}
	const logout = () => {
		sessionStorage.clear();
		sessionStorage.setItem("loggedIn", false);
		history.push("/");
	};

	function getBalance() {

		var data = JSON.stringify({
			user_id: userInfo._id,
		});

		var config = {
			method: "post",
			url: `${Appconfig.apiUrl}ledger/getUserBalance`,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				setBalLoader(false);
				if (response.data.result == 0) {
				} else {
					console.log(response.data.resultData);
					setExposure(response.data.resultData.exposure);
					setMainBalance(response.data.resultData.balance);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	React.useEffect(() => {
		chkAndUpdateFirstLogin();
		getBalance();
		closeAllInterVals();
	}, [props.balanceUpdated])

	const chkAndUpdateFirstLogin = () => {
		if (userInfo.cpmodal == "No") {
			// setPsModalOpen(true);
		}
	}
	const psModalClose = () => {
		setPsModalOpen(false);
	}
	const handleCpSumit = (e) => {
		e.preventDefault();
		if (cpformData.opassword != userInfo.password) {
			notify("Old password did not match", "error");
			return false;
		}
		if (cpformData.npassword != cpformData.cpassword) {
			notify("confirm password did not match", "error");
			return false;
		}
		var data = JSON.stringify({
			"user_id": userInfo._id,
			"UpdateField": { "password": cpformData.npassword }
		});

		var config = {
			method: 'post',
			url: `${Appconfig.apiUrl}users/updateUserInfo`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.resultData.n) {
					userInfo.password = cpformData.npassword;
					userInfo.cpmodal = "Yes";
					window.sessionStorage.setItem("userData", JSON.stringify(userInfo));
					psModalClose();
					notify("Password changed Successfully", "success");
					setChangePModal();
					addCpLog();
				} else {
					notify(response.data.resultData.resultMessage, "error");
				}

			})
			.catch(function (error) {
				notify(error, "error");
				console.log(error);
			});
	}

	function notify(message, type) {
		if (type === 'error') {
			toast.error(message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		else {
			toast.success(message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}

	}
	const addCpLog = () => {

		var data = JSON.stringify({
			"user_id": userInfo._id,
			"master_id": userInfo.master_id,
			"remark": "Password Changed By Self"
		});

		var config = {
			method: 'post',
			url: `${Appconfig.apiUrl}passwordHistory/add_password_history`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.result) {
					console.log("response.data", response.data);
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const setChangePModal = () => {

		var data = JSON.stringify({
			"user_id": userInfo._id,
			"UpdateField": { "cpmodal": "Yes" }
		});

		var config = {
			method: 'post',
			url: `${Appconfig.apiUrl}users/updateUserInfo`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.result) {
					console.log("response.data", response.data);
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const handleCpChange = (event) => {
		setcpformData({
			...cpformData,
			[event.target.name]: event.target.value
		});
		console.log(formData);
	}

	function closeAllInterVals() {
		if (window.location.href.indexOf("full-market") > -1) {
		} else {
			clearInterval(window.sessionStorage.getItem("fancyExpInterval"))
		}

	}
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={psModalOpen}
				onClose={(_, reason) => {
					if (reason !== "backdropClick") {
						psModalClose();
					}
				}}

				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={psModalOpen}>
					<Card variant="outlined" className='cpmodal' style={{ "width": "100vw",    marginTop: "14ch" }}>
						<Grid container >
							<Grid item xs={12}>
								{/* <CardHeader title="Change Password" className='report-card-header' /> */}
								<CardContent className='report-card-content' style={{ "background-color": "#ffb80c", "padding": "0px" }}>
									<div className='cpmodal-row' >
										<div className="cpmodal-col">
											<ul>
												<li>Password must contain 8 to 15 alphanumeric without white space</li>
												<li>Password cannot be same as username/nickname</li>
												<li>Must contain atleast 1 capital letter, 1 small letter and 1 number</li>
												<li>Password must not contain any special letter</li>
											</ul>
										</div>
										<div className='cpmodal-col'>
											<h1>Change Password</h1>
											<form onSubmit={handleCpSumit}>
												<div className="cpmodal-right-login">

													<div>
														<input placeholder="Old Password" onChange={handleCpChange} name="opassword" type="password" />
													</div>
													<div>
														<input placeholder="New Password" onChange={handleCpChange} name="npassword" type="password" />
													</div>
													<div>
														<input placeholder="Confirm Password" onChange={handleCpChange} name="cpassword" type="password" />
													</div>

													<button variant="contained" type='submit' className="cpmodal-button">Change</button>
												</div>
											</form>
										</div>
									</div>
									<div className='cpmodal-row' style={{ "background-color": "black", "padding": "18px" }}>
										{/* <img alt="skyexchange" className="logo" src={"../static/media/lce.f94a1876.png"} style={{ "margin-left": "32%" }} /> */}
									</div>
								</CardContent>

							</Grid>
						</Grid>
					</Card>
					{/* <div className='card' style={{width:"393px"}}>
						<div className='card-header'>
							Change Password
						</div>
						<div className='card-body'>
							<form onSubmit={handleCpSumit}>
								<div className="">
									<div>
										<input className='form-control' placeholder="Old Password" onChange={handleCpChange} name="opassword" type="password" />
									</div>
									<div>
										<input className='form-control' placeholder="New Password" onChange={handleCpChange} name="npassword" type="password" />
									</div>
									<div>
										<input className='form-control' placeholder="Confirm Password" onChange={handleCpChange} name="cpassword" type="password" />
									</div>

									<button className="btn btn-warning">Yes</button>
								</div>
							</form>
						</div>
					</div> */}


				</Fade>
			</Modal>
			<div className="top">
				<div className="header">
					<h1 onClick={() => history.push("/dashboard")}><Link to="/dashboard">SKYEXCHANGE</Link></h1>
					<ul className="account-wrap">
						<li><span>SUP</span><strong>{userInfo ? userInfo.user_name : ""}</strong></li>
						{/* single balance 加 no-multi */}
						<li className="main-wallet no-multi">
							<Link to="#multi-balance_pop" className="a-wallet">
								<ul>
									<li>
										<span>Main</span>
										<strong id="mainBalance">{CURRENCY_TYPE} {MainBalance}</strong>

									</li>
								</ul>
								<p className="loading-bar" id="menuRefreshLoading" style={displaynone}>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</p>
							</Link>

							<Link id="topRefresh" to="#" className="a-refresh" style={{ 'cursor': "pointer" }}><img src={Trans} /></Link>
						</li>
					</ul>
				</div>

				{/* Menu Wrap */}
				<div className="menu-wrap">
					<div className="main_wrap">
						<ul className="menu">
							<li><Link id="menu_downline_list" onClick={() => handleItemIndex(0)} className={activeItemIndex === 0 ? "select" : null} to="/dashboard">Downline List
							</Link></li>

							<li onClick={() => handleItemIndex(1)} className={activeItemIndex === 1 ? "select" : null}  >
								<Link id="menu_my_account" className="" to={`/myaccount?uid=${userInfo._id}`}>My Account
								</Link>
							</li>
							<li onClick={() => handleItemIndex(2)} className={activeItemIndex === 2 ? "select" : null}  >
								<span id="menu_my_report" className="menu-drop" >My Report
								</span>
								<ul>

									<li><Link to="/downLineprofitLoss">Profit/Loss Report by Downline
									</Link></li>
									<li><Link to="/marketprofitloss">Profit/Loss Report by Market
									</Link></li>
									<li><Link to="/profitlosssportwise">Profit/Loss Sport Wise
									</Link></li>
									<li><Link to="/casinodownlinemonthly">Casino P/L Downline Monthly
									</Link></li>
									{/* <li><Link to="#">Profit/Loss Casino
									</Link></li>
									<li><Link to="#">Profit/Loss Downline
									</Link></li>
									<li><Link to="#">Profit/Loss Sport Wise
									</Link></li> */}


								</ul>
							</li>



							<li onClick={() => handleItemIndex(3)} className={activeItemIndex === 3 ? "select" : null}  ><Link id="menu_bet_list"  to="/betlist" className=""   >BetList
							</Link></li>


							<li onClick={() => handleItemIndex(4)} className={activeItemIndex === 4 ? "select" : null} >
								<Link id="menu_bet_list_live" to="/betlistlive" className="" >BetListLive
								</Link>
							</li>



							<li onClick={() => handleItemIndex(5)} className={activeItemIndex === 5 ? "select" : null} >
								<Link id="menu_risk_management" className="" to="/riskmanagement">Risk Management
								</Link>
							</li>


							<li onClick={() => handleItemIndex(6)} className={activeItemIndex === 6 ? "select" : null} >
								<Link id="menu_banking" className="" to="/banking">Banking
								</Link>
							</li>

							{/* {userInfo.user_type === "Super Admin" ?
								<li onClick={() => handleItemIndex(7)} className={activeItemIndex === 7 ? "select" : null}>
									<Link id="menu_account_statement" className="" to="/account-statement">Account Statement
									</Link>
								</li>
								: ""} */}
							{userInfo.user_type === "Super Admin" ?
								<li onClick={() => handleItemIndex(8)} className={activeItemIndex === 8 ? "select" : null}><span id="menu_my_report" className="menu-drop" >My Settings
								</span>
									<ul>
										{userInfo.user_type === "Super Admin" ? <li>
											<Link id="admin_fund" className="" to="/adminfund">Admin Fund
											</Link>
										</li> : ""}

										<li>
											<Link id="admin_fund" className="" to="/blockmarket">Block Market
											</Link>
										</li>
										{userInfo.user_type === "Super Admin" ?
											<li>
												<Link id="betdelete" className="" to="/betdelete">Bet Delete
												</Link>
											</li> : ""
										}
										{userInfo.user_type === "Super Admin" ?
											<>
												<li>
													<Link id="addbanner" className="" to="/addbanner">Add Banner
													</Link>
												</li>
												<li>
													<Link id="news" className="" to="/news">News
													</Link>
												</li>
												{/* <li>
													<Link id="gensetting" className="" to="/user_gen_settings/0">GenSetting
													</Link>
												</li> */}
												
												</> : ""
										}

										{userInfo.user_type === "Super Admin" ?
											<li>
												<Link id="deleteuser" className="" to="/deleteuser">Delete user
												</Link>
											</li> : ""
										}

									</ul>
								</li>
								: ""}

							<li className="logout">
								<button id="logout" onClick={logout}>Logout<img src={Trans} /></button>
							</li>
							<li className="time_zone"><span>Time Zone :</span> GMT+5:30</li>
						</ul>
					</div>
				</div>
			</div>


			{/* Top End */}
		</div>
	)
}
export default Header;