
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from '../admin/Header'

// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import SideNav from '../admin/SideNav';


import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams, useHistory } from "react-router-dom";


import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BetDeleteEventsBet() {
    const displaynone = {
        display: "none"
    }
    const history = useHistory();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [marketList, setmarketList] = React.useState([]);
    const [selectedIndex, setSelectedUserIndex] = React.useState([]);
    const [chekeduser, setSelectedUser] = React.useState(Array);

    const [searched, setSearched] = React.useState("");
    const [tempMarketList, setTempMarketList] = React.useState([]);

    useEffect(() => {
        eventMarket(params.event_id)
    }, [])
    const columns = [

        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58
        },
        {
            field: 'user_name',
            headerName: 'User',
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (rowData.row.userdetail[0].user_name)
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 67,
            disableClickEventBubbling: true,
        },
        {
            field: 'market_name',
            headerName: 'Market',
            sortable: false,
            width: 90,
            disableClickEventBubbling: true,
        },
        {
            field: 'runner_name',
            headerName: 'Name',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    moment(rowData.row.createdAt).format('DD/MM/YYYY hh:mm:ss ddd')
                );
            }
        },
        {
            field: 'stake',
            headerName: 'Stake',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
        },
        {
            field: 'price_val',
            headerName: 'Price',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
        },
        {
            field: 'bet_result',
            headerName: 'Bet Result',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
        },

        // {
        //     field: "edit",
        //     headerName: "Action",
        //     sortable: false,
        //     width: 74,
        //     disableClickEventBubbling: true,
        //     renderCell: rowData => {
        //         return (
        //             <Switch
        //                 checked={rowData.row.is_blocked === "Yes" ? false : true}
        //                 onChange={() => blockUnblockMarket(rowData.row.event_id, rowData.row.market_id)}
        //                 color="primary"
        //                 name="checkedB"
        //                 inputProps={{ 'aria-label': 'primary checkbox' }}
        //             />
        //         );
        //     }
        // },
    ];
    function eventMarket(eventId) {

        let tmpEventList;
        var data = JSON.stringify({
            // "user_id": userInfo._id,
            "event_id": eventId
        });


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/getBettingByEventId`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setmarketList(response.data.resultData);
                console.log(response.data.resultData)
                tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((marketList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('after match odds click', response.data.resultData);
                setmarketList(tmpEventList)
                setTempMarketList(tmpEventList);
                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            }).then(() => {

                if (!tmpEventList.length) {
                    document.getElementById("content").innerHTML = "<div class='no-row'><span>No Rows</span></div>"
                }

            });


    }
    function blockUnblockMarket(event_id, market_id) {
        console.log([event_id, params.event_type_id, market_id]);
        var data = JSON.stringify({

            "user_id": userInfo._id,
            "event_id": event_id,
            "market_id": market_id,
            "fancy_id": "0",
            "event_type_id": params.event_type_id,
            "type": "Market"

        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                eventMarket(params.event_id)
                // getEventList();

                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const classes = useStyles();
    function setSelectedUsers(newUserList) {
        let tmpSelectUserList = [...newUserList];

        console.log("tmpSelectUserList", tmpSelectUserList);
        setSelectedUser(tmpSelectUserList);
        console.log("select", chekeduser);
    }

    const handleAction = (e) => {
        e.preventDefault();

        var axios = require("axios");
        var data = JSON.stringify({
            bet_id: selectedIndex,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/deletebets`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    eventMarket(params.event_id)
                    notify(response.data.resultMessage, "success");
                    setSelectedUserIndex([]);
                    console.log(JSON.stringify(response.data));
                }
                else {
                    notify(response.data.resultMessage, "error");
                    console.log(JSON.stringify(response.data));
                }

            })
            .catch(function (error) {
                notify("Some Error Occured", "error");
                console.log(error);
            });

    };

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);
        // console.log("searchedVal",searchedVal);
        const filteredRows = marketList.filter((row) => {
            return row.event_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.betting_type.toLowerCase().includes(searchedVal.toLowerCase()) || row.bet_result.toLowerCase().includes(searchedVal.toLowerCase()) || row.userdetail[0].user_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setTempMarketList(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    function collectId(id, e) {

        if (!e.target.checked) {
            setSelectedUserIndex(selectedIndex.filter(item => item !== id));
        }
        else {
            setSelectedUserIndex([...selectedIndex, id]);
        }
    }
    return (

        <div className="dashboard myaccount m-width1300">
            {/* Top Start */}
            <Header />
            {/* Top End */}
            <div className="main_wrap">

                <div className="agent_path">
                    <ul id="agentPath" className="agent_path-L">
                        <li id="path6" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_0">
                                    COM
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path5" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_1">
                                    SS
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path4" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_2">
                                    SUP
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <li id="path3" className="last_li">
                            <Link >
                                <span className="lv_3">
                                    {userInfo.user_type.match(/\b(\w)/g).join('')}
                                </span>
                                <strong>{userInfo.user_name}</strong>
                            </Link>
                        </li>

                        <li id="path0" className="" style={displaynone}>
                            <Link to="/">
                                <span className="lv_4">
                                    PL
                                </span>
                                <strong></strong>
                            </Link>
                        </li>

                        <ul className="account_pop" id="accountPop">
                            <li id="popTmp" style={displaynone}>
                                <Link to="/"></Link>
                            </li>
                        </ul>
                    </ul>
                </div>

                {/* Side Bar Code */}
                <div className="col-left">
                    <SideNav />
                </div>
                <div className="col-center report account-summary">
                    <div style={{ display: "flex" }}>
                        <div> <h2>Bet Listing</h2></div>
                        <div style={{ marginLeft: "50%" }}> <input
                            value={searched}
                            placeholder='search'
                            onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        />
                        </div>
                        <div>
                            <button style={{ float: "right" }} className="close-btn" disabled={selectedIndex.length > 0 ? false : true} onClick={handleAction}>
                                Delete
                            </button>
                        </div>
                    </div>
                    <div id="loading" className="loading-wrap" style={displaynone}>
                        <ul className="loading">
                            <li><img src="./images/loading40.gif" /></li>
                            <li>Loading...</li>
                        </ul>
                    </div>

                    <div id="message" className="message-wrap success" style={displaynone}>
                        <Link className="btn-close" to="#">Close</Link>
                        <p></p>
                    </div>

                    <table id="table_log" className="table01" style={{ textAlign: "left" }}>
                        <thead>
                            <tr>
                                <th >&nbsp;</th>
                                <th >User Name</th>
                                <th >Status</th>
                                <th >Market Name</th>
                                <th >Runner Name</th>
                                <th >Created At</th>
                                <th >Stake</th>
                                <th >Price</th>

                                <th >Bet Result</th>

                            </tr>
                        </thead>
                        <tbody id="content">

                            {tempMarketList.length > 0 && tempMarketList.map(row =>
                                <tr id="tempTr">
                                    <td ><input onClick={(e) => collectId(row._id, e)} type='checkbox' /></td>
                                    <td >{row.userdetail[0].user_name}</td>
                                    <td >{row.status}</td>
                                    <td >{row.market_name}</td>
                                    <td >{row.runner_name}</td>
                                    <td >{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss ddd')}</td>
                                    <td >{row.stake}</td>
                                    <td >{row.price_val}</td>
                                    <td >{row.bet_result}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}